/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OptionDtoStatoStrutturaOrganizzativa
 */
export interface OptionDtoStatoStrutturaOrganizzativa {
    /**
     * 
     * @type {string}
     * @memberof OptionDtoStatoStrutturaOrganizzativa
     */
    'value'?: OptionDtoStatoStrutturaOrganizzativaValueEnum;
    /**
     * 
     * @type {string}
     * @memberof OptionDtoStatoStrutturaOrganizzativa
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof OptionDtoStatoStrutturaOrganizzativa
     */
    'valore': OptionDtoStatoStrutturaOrganizzativaValoreEnum;
}

export const OptionDtoStatoStrutturaOrganizzativaValueEnum = {
    Compilata: 'COMPILATA',
    Registrata: 'REGISTRATA',
    Censita: 'CENSITA',
    Cessata: 'CESSATA'
} as const;

export type OptionDtoStatoStrutturaOrganizzativaValueEnum = typeof OptionDtoStatoStrutturaOrganizzativaValueEnum[keyof typeof OptionDtoStatoStrutturaOrganizzativaValueEnum];
export const OptionDtoStatoStrutturaOrganizzativaValoreEnum = {
    Compilata: 'COMPILATA',
    Registrata: 'REGISTRATA',
    Censita: 'CENSITA',
    Cessata: 'CESSATA'
} as const;

export type OptionDtoStatoStrutturaOrganizzativaValoreEnum = typeof OptionDtoStatoStrutturaOrganizzativaValoreEnum[keyof typeof OptionDtoStatoStrutturaOrganizzativaValoreEnum];



/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContattoDTO } from '../model';
// @ts-ignore
import { ConvenzioneDto } from '../model';
// @ts-ignore
import { ConvenzioneInsertModifyRequestDto } from '../model';
// @ts-ignore
import { EnteDTO } from '../model';
// @ts-ignore
import { PagedDtoConvenzioneDto } from '../model';
// @ts-ignore
import { TipologicaDto } from '../model';
/**
 * ConvenzioniControllerApi - axios parameter creator
 * @export
 */
export const ConvenzioniControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per il dettaglio di una convenzione
         * @summary Elimina convenzione
         * @param {number} idConvenzione 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniDelete: async (idConvenzione: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idConvenzione' is not null or undefined
            assertParamExists('convenzioniDelete', 'idConvenzione', idConvenzione)
            const localVarPath = `/v1/convenzioni/{idConvenzione}`
                .replace(`{${"idConvenzione"}}`, encodeURIComponent(String(idConvenzione)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il dettaglio di una convenzione
         * @summary Dettaglio convenzione
         * @param {number} idConvenzione 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniDetail: async (idConvenzione: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idConvenzione' is not null or undefined
            assertParamExists('convenzioniDetail', 'idConvenzione', idConvenzione)
            const localVarPath = `/v1/convenzioni/{idConvenzione}`
                .replace(`{${"idConvenzione"}}`, encodeURIComponent(String(idConvenzione)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento di una convenzioni
         * @summary Inserimento convenzione
         * @param {ConvenzioneInsertModifyRequestDto} convenzioneInsertModifyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniInsert: async (convenzioneInsertModifyRequestDto: ConvenzioneInsertModifyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'convenzioneInsertModifyRequestDto' is not null or undefined
            assertParamExists('convenzioniInsert', 'convenzioneInsertModifyRequestDto', convenzioneInsertModifyRequestDto)
            const localVarPath = `/v1/convenzioni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convenzioneInsertModifyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch convenzione
         * @param {ConvenzioneInsertModifyRequestDto} convenzioneInsertModifyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniPatch: async (convenzioneInsertModifyRequestDto: ConvenzioneInsertModifyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'convenzioneInsertModifyRequestDto' is not null or undefined
            assertParamExists('convenzioniPatch', 'convenzioneInsertModifyRequestDto', convenzioneInsertModifyRequestDto)
            const localVarPath = `/v1/convenzioni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convenzioneInsertModifyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca delle convenzioni
         * @summary Ricerca convenzioni
         * @param {string} [nomeConvenzione] 
         * @param {string} [scopoConvenzione] 
         * @param {number} [enteSottoscrittore] 
         * @param {string} [responsabile] 
         * @param {string} [contattoTecnico] 
         * @param {string} [contattoAmministrativo] 
         * @param {string} [strutturaReferente] 
         * @param {string} [stato] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniSearch: async (nomeConvenzione?: string, scopoConvenzione?: string, enteSottoscrittore?: number, responsabile?: string, contattoTecnico?: string, contattoAmministrativo?: string, strutturaReferente?: string, stato?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/convenzioni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nomeConvenzione !== undefined) {
                localVarQueryParameter['nomeConvenzione'] = nomeConvenzione;
            }

            if (scopoConvenzione !== undefined) {
                localVarQueryParameter['scopoConvenzione'] = scopoConvenzione;
            }

            if (enteSottoscrittore !== undefined) {
                localVarQueryParameter['enteSottoscrittore'] = enteSottoscrittore;
            }

            if (responsabile !== undefined) {
                localVarQueryParameter['responsabile'] = responsabile;
            }

            if (contattoTecnico !== undefined) {
                localVarQueryParameter['contattoTecnico'] = contattoTecnico;
            }

            if (contattoAmministrativo !== undefined) {
                localVarQueryParameter['contattoAmministrativo'] = contattoAmministrativo;
            }

            if (strutturaReferente !== undefined) {
                localVarQueryParameter['strutturaReferente'] = strutturaReferente;
            }

            if (stato !== undefined) {
                localVarQueryParameter['stato'] = stato;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la lista dei contatti
         * @summary Lista Contatti
         * @param {number} [id] 
         * @param {string} [nominativo] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaContatti: async (id?: number, nominativo?: string, email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/convenzioni/find-all-contatti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (nominativo !== undefined) {
                localVarQueryParameter['nominativo'] = nominativo;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la lista degli enti
         * @summary Lista Enti
         * @param {number} [id] 
         * @param {string} [nomeEnte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaEnti: async (id?: number, nomeEnte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/convenzioni/find-all-enti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (nomeEnte !== undefined) {
                localVarQueryParameter['nomeEnte'] = nomeEnte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la lista dei possibili stati di una convenzione
         * @summary Lista Stati Convenzione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaStati: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/convenzioni/find-all-stati-convenzione`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la lista delle strutture organizzative
         * @summary Lista Strutture Organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaStrutture: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/convenzioni/find-all-strutture-organizzative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConvenzioniControllerApi - functional programming interface
 * @export
 */
export const ConvenzioniControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConvenzioniControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per il dettaglio di una convenzione
         * @summary Elimina convenzione
         * @param {number} idConvenzione 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convenzioniDelete(idConvenzione: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvenzioneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convenzioniDelete(idConvenzione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il dettaglio di una convenzione
         * @summary Dettaglio convenzione
         * @param {number} idConvenzione 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convenzioniDetail(idConvenzione: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvenzioneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convenzioniDetail(idConvenzione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento di una convenzioni
         * @summary Inserimento convenzione
         * @param {ConvenzioneInsertModifyRequestDto} convenzioneInsertModifyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convenzioniInsert(convenzioneInsertModifyRequestDto: ConvenzioneInsertModifyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvenzioneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convenzioniInsert(convenzioneInsertModifyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch convenzione
         * @param {ConvenzioneInsertModifyRequestDto} convenzioneInsertModifyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convenzioniPatch(convenzioneInsertModifyRequestDto: ConvenzioneInsertModifyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvenzioneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convenzioniPatch(convenzioneInsertModifyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca delle convenzioni
         * @summary Ricerca convenzioni
         * @param {string} [nomeConvenzione] 
         * @param {string} [scopoConvenzione] 
         * @param {number} [enteSottoscrittore] 
         * @param {string} [responsabile] 
         * @param {string} [contattoTecnico] 
         * @param {string} [contattoAmministrativo] 
         * @param {string} [strutturaReferente] 
         * @param {string} [stato] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convenzioniSearch(nomeConvenzione?: string, scopoConvenzione?: string, enteSottoscrittore?: number, responsabile?: string, contattoTecnico?: string, contattoAmministrativo?: string, strutturaReferente?: string, stato?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoConvenzioneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convenzioniSearch(nomeConvenzione, scopoConvenzione, enteSottoscrittore, responsabile, contattoTecnico, contattoAmministrativo, strutturaReferente, stato, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la lista dei contatti
         * @summary Lista Contatti
         * @param {number} [id] 
         * @param {string} [nominativo] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listaContatti(id?: number, nominativo?: string, email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContattoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listaContatti(id, nominativo, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la lista degli enti
         * @summary Lista Enti
         * @param {number} [id] 
         * @param {string} [nomeEnte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listaEnti(id?: number, nomeEnte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnteDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listaEnti(id, nomeEnte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la lista dei possibili stati di una convenzione
         * @summary Lista Stati Convenzione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listaStati(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TipologicaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listaStati(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la lista delle strutture organizzative
         * @summary Lista Strutture Organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listaStrutture(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TipologicaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listaStrutture(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConvenzioniControllerApi - factory interface
 * @export
 */
export const ConvenzioniControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConvenzioniControllerApiFp(configuration)
    return {
        /**
         * API Rest per il dettaglio di una convenzione
         * @summary Elimina convenzione
         * @param {ConvenzioniControllerApiConvenzioniDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniDelete(requestParameters: ConvenzioniControllerApiConvenzioniDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<ConvenzioneDto> {
            return localVarFp.convenzioniDelete(requestParameters.idConvenzione, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il dettaglio di una convenzione
         * @summary Dettaglio convenzione
         * @param {ConvenzioniControllerApiConvenzioniDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniDetail(requestParameters: ConvenzioniControllerApiConvenzioniDetailRequest, options?: AxiosRequestConfig): AxiosPromise<ConvenzioneDto> {
            return localVarFp.convenzioniDetail(requestParameters.idConvenzione, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento di una convenzioni
         * @summary Inserimento convenzione
         * @param {ConvenzioniControllerApiConvenzioniInsertRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniInsert(requestParameters: ConvenzioniControllerApiConvenzioniInsertRequest, options?: AxiosRequestConfig): AxiosPromise<ConvenzioneDto> {
            return localVarFp.convenzioniInsert(requestParameters.convenzioneInsertModifyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch convenzione
         * @param {ConvenzioniControllerApiConvenzioniPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniPatch(requestParameters: ConvenzioniControllerApiConvenzioniPatchRequest, options?: AxiosRequestConfig): AxiosPromise<ConvenzioneDto> {
            return localVarFp.convenzioniPatch(requestParameters.convenzioneInsertModifyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca delle convenzioni
         * @summary Ricerca convenzioni
         * @param {ConvenzioniControllerApiConvenzioniSearchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convenzioniSearch(requestParameters: ConvenzioniControllerApiConvenzioniSearchRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoConvenzioneDto> {
            return localVarFp.convenzioniSearch(requestParameters.nomeConvenzione, requestParameters.scopoConvenzione, requestParameters.enteSottoscrittore, requestParameters.responsabile, requestParameters.contattoTecnico, requestParameters.contattoAmministrativo, requestParameters.strutturaReferente, requestParameters.stato, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la lista dei contatti
         * @summary Lista Contatti
         * @param {ConvenzioniControllerApiListaContattiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaContatti(requestParameters: ConvenzioniControllerApiListaContattiRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ContattoDTO>> {
            return localVarFp.listaContatti(requestParameters.id, requestParameters.nominativo, requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la lista degli enti
         * @summary Lista Enti
         * @param {ConvenzioniControllerApiListaEntiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaEnti(requestParameters: ConvenzioniControllerApiListaEntiRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<EnteDTO>> {
            return localVarFp.listaEnti(requestParameters.id, requestParameters.nomeEnte, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la lista dei possibili stati di una convenzione
         * @summary Lista Stati Convenzione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaStati(options?: AxiosRequestConfig): AxiosPromise<Array<TipologicaDto>> {
            return localVarFp.listaStati(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la lista delle strutture organizzative
         * @summary Lista Strutture Organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listaStrutture(options?: AxiosRequestConfig): AxiosPromise<Array<TipologicaDto>> {
            return localVarFp.listaStrutture(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for convenzioniDelete operation in ConvenzioniControllerApi.
 * @export
 * @interface ConvenzioniControllerApiConvenzioniDeleteRequest
 */
export interface ConvenzioniControllerApiConvenzioniDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ConvenzioniControllerApiConvenzioniDelete
     */
    readonly idConvenzione: number
}

/**
 * Request parameters for convenzioniDetail operation in ConvenzioniControllerApi.
 * @export
 * @interface ConvenzioniControllerApiConvenzioniDetailRequest
 */
export interface ConvenzioniControllerApiConvenzioniDetailRequest {
    /**
     * 
     * @type {number}
     * @memberof ConvenzioniControllerApiConvenzioniDetail
     */
    readonly idConvenzione: number
}

/**
 * Request parameters for convenzioniInsert operation in ConvenzioniControllerApi.
 * @export
 * @interface ConvenzioniControllerApiConvenzioniInsertRequest
 */
export interface ConvenzioniControllerApiConvenzioniInsertRequest {
    /**
     * 
     * @type {ConvenzioneInsertModifyRequestDto}
     * @memberof ConvenzioniControllerApiConvenzioniInsert
     */
    readonly convenzioneInsertModifyRequestDto: ConvenzioneInsertModifyRequestDto
}

/**
 * Request parameters for convenzioniPatch operation in ConvenzioniControllerApi.
 * @export
 * @interface ConvenzioniControllerApiConvenzioniPatchRequest
 */
export interface ConvenzioniControllerApiConvenzioniPatchRequest {
    /**
     * 
     * @type {ConvenzioneInsertModifyRequestDto}
     * @memberof ConvenzioniControllerApiConvenzioniPatch
     */
    readonly convenzioneInsertModifyRequestDto: ConvenzioneInsertModifyRequestDto
}

/**
 * Request parameters for convenzioniSearch operation in ConvenzioniControllerApi.
 * @export
 * @interface ConvenzioniControllerApiConvenzioniSearchRequest
 */
export interface ConvenzioniControllerApiConvenzioniSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly nomeConvenzione?: string

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly scopoConvenzione?: string

    /**
     * 
     * @type {number}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly enteSottoscrittore?: number

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly responsabile?: string

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly contattoTecnico?: string

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly contattoAmministrativo?: string

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly strutturaReferente?: string

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly stato?: string

    /**
     * 
     * @type {number}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiConvenzioniSearch
     */
    readonly q?: string
}

/**
 * Request parameters for listaContatti operation in ConvenzioniControllerApi.
 * @export
 * @interface ConvenzioniControllerApiListaContattiRequest
 */
export interface ConvenzioniControllerApiListaContattiRequest {
    /**
     * 
     * @type {number}
     * @memberof ConvenzioniControllerApiListaContatti
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiListaContatti
     */
    readonly nominativo?: string

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiListaContatti
     */
    readonly email?: string
}

/**
 * Request parameters for listaEnti operation in ConvenzioniControllerApi.
 * @export
 * @interface ConvenzioniControllerApiListaEntiRequest
 */
export interface ConvenzioniControllerApiListaEntiRequest {
    /**
     * 
     * @type {number}
     * @memberof ConvenzioniControllerApiListaEnti
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof ConvenzioniControllerApiListaEnti
     */
    readonly nomeEnte?: string
}

/**
 * ConvenzioniControllerApi - object-oriented interface
 * @export
 * @class ConvenzioniControllerApi
 * @extends {BaseAPI}
 */
export class ConvenzioniControllerApi extends BaseAPI {
    /**
     * API Rest per il dettaglio di una convenzione
     * @summary Elimina convenzione
     * @param {ConvenzioniControllerApiConvenzioniDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public convenzioniDelete(requestParameters: ConvenzioniControllerApiConvenzioniDeleteRequest, options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).convenzioniDelete(requestParameters.idConvenzione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il dettaglio di una convenzione
     * @summary Dettaglio convenzione
     * @param {ConvenzioniControllerApiConvenzioniDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public convenzioniDetail(requestParameters: ConvenzioniControllerApiConvenzioniDetailRequest, options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).convenzioniDetail(requestParameters.idConvenzione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento di una convenzioni
     * @summary Inserimento convenzione
     * @param {ConvenzioniControllerApiConvenzioniInsertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public convenzioniInsert(requestParameters: ConvenzioniControllerApiConvenzioniInsertRequest, options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).convenzioniInsert(requestParameters.convenzioneInsertModifyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch convenzione
     * @param {ConvenzioniControllerApiConvenzioniPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public convenzioniPatch(requestParameters: ConvenzioniControllerApiConvenzioniPatchRequest, options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).convenzioniPatch(requestParameters.convenzioneInsertModifyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca delle convenzioni
     * @summary Ricerca convenzioni
     * @param {ConvenzioniControllerApiConvenzioniSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public convenzioniSearch(requestParameters: ConvenzioniControllerApiConvenzioniSearchRequest = {}, options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).convenzioniSearch(requestParameters.nomeConvenzione, requestParameters.scopoConvenzione, requestParameters.enteSottoscrittore, requestParameters.responsabile, requestParameters.contattoTecnico, requestParameters.contattoAmministrativo, requestParameters.strutturaReferente, requestParameters.stato, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la lista dei contatti
     * @summary Lista Contatti
     * @param {ConvenzioniControllerApiListaContattiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public listaContatti(requestParameters: ConvenzioniControllerApiListaContattiRequest = {}, options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).listaContatti(requestParameters.id, requestParameters.nominativo, requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la lista degli enti
     * @summary Lista Enti
     * @param {ConvenzioniControllerApiListaEntiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public listaEnti(requestParameters: ConvenzioniControllerApiListaEntiRequest = {}, options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).listaEnti(requestParameters.id, requestParameters.nomeEnte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la lista dei possibili stati di una convenzione
     * @summary Lista Stati Convenzione
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public listaStati(options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).listaStati(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la lista delle strutture organizzative
     * @summary Lista Strutture Organizzative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvenzioniControllerApi
     */
    public listaStrutture(options?: AxiosRequestConfig) {
        return ConvenzioniControllerApiFp(this.configuration).listaStrutture(options).then((request) => request(this.axios, this.basePath));
    }
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NotificheControllerApi, NotificaDto, PagedDtoNotificaDto, ElencoStatiLetturaNotificheContestoForm, FormsControllerApi, NotificheControllerApiAggiornaStatoLetturaRequest, NotificheControllerApiEliminaNotificaRequest, FeedbackNotificaInsertRequestDto, NotificaDtoStatoEnum } from "../services/ms-anagrafica-unica";
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants";


interface InserisciFeedbackRequest {
    idNotifica: number,
    feedbackNotificaInsertRequestDto: FeedbackNotificaInsertRequestDto
}

export interface NotificheState {
    searchResult: PagedDtoNotificaDto,
    searchStatus: string,
    searchInput: SearchNotificheRequest | null,
    insertStatus: string,
    updateResult: NotificaDto | null,
    updateStatus: string,
    deleteResult: NotificaDto | null,
    deleteStatus: string,
    formSearchComponents: ElencoStatiLetturaNotificheContestoForm | null,
    notificheNonLetteStatus: string,
    notificheNonLette: number
}

const initialState: NotificheState = {
    searchResult: {
        pageNum: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        data: []
    },
    searchStatus: '',
    searchInput: null,
    insertStatus: '',
    updateResult: null,
    updateStatus: '',
    deleteResult: null,
    deleteStatus: '',
    formSearchComponents: null,
    notificheNonLetteStatus: '',
    notificheNonLette: 0
}

export interface SearchNotificheRequest {
    pageSize?: number,
    pageNum?: number,
    stato?: any, 
    notArchiviate?: boolean, 
    sort?: string,
    direction?: any,
    q?: string
}

export const searchNotifiche = createAsyncThunk(
    'notifiche/search',
    async (args: SearchNotificheRequest) => {
        const api = new NotificheControllerApi(MS_AU_CONFIG);
        const response = await api.searchNotifiche({
            pageNum: args.pageNum,
            pageSize: args.pageSize,
            stato: args.stato,
            notArchiviate: args.notArchiviate,
            direction: args.direction,
            sort: args.sort,
        });
        return response.data;
    }
)

export const getNotificheNonLette = createAsyncThunk(
    'notifiche/non-lette',
    async (args: SearchNotificheRequest) => {
        const api = new NotificheControllerApi(MS_AU_CONFIG);
        const response = await api.notificheNonLette({
            stato: args.stato,
        });

        return response;
    }
)

export const loadStatiFormSearch = createAsyncThunk(
    'notifiche/loadFormSearch',
    async () => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoStatiLetturaNotificheContestoForm();
        return response.data;
    }
);

export const patchNotifica = createAsyncThunk(
    'notifiche/patch',
    async (args: NotificheControllerApiAggiornaStatoLetturaRequest) => {
        const api = new NotificheControllerApi(MS_AU_CONFIG);
        const response = await api.aggiornaStatoLettura({
            idNotifica: args.idNotifica,
            notificaPatchRequestDto: args.notificaPatchRequestDto
        });
        return response.data;
    }
);

export const deleteNotifica = createAsyncThunk(
    'notifiche/delete',
    async (args: NotificheControllerApiEliminaNotificaRequest) => {
        const api = new NotificheControllerApi(MS_AU_CONFIG);
        const response = await api.eliminaNotifica({
            idNotifica: args.idNotifica,
        });
        return response.data;
    }
);

export const inserisciFeedbackNotifica = createAsyncThunk(
    'notifiche/inserisci-feedback',
    async (args: InserisciFeedbackRequest) => {
        const api = new NotificheControllerApi(MS_AU_CONFIG);
        const response = await api.inserisciFeedbackNotifica({
            idNotifica: args.idNotifica,
            feedbackNotificaInsertRequestDto: args.feedbackNotificaInsertRequestDto
        });
        return response.data;
    }
);

export const notificheSlice = createSlice({
    name: 'notificheSlice',
    initialState,
    reducers: {
        resetInputForm: (state) => {
            state.insertStatus = ''
        },
        resetSearchValues: (state) => {
            state.searchResult = {
                pageNum: 0,
                pageSize: 10,
            };
            state.searchStatus = '';
            state.searchInput = null;
        },
        resetStatus: (state) => {
            state.insertStatus = '';
            state.updateStatus = '';
            state.deleteStatus = '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(searchNotifiche.pending, (state: any, action: any) => {
            state.searchStatus = STATUS_PENDING;
        });
        builder.addCase(searchNotifiche.fulfilled, (state: NotificheState, action: any) => {
            state.searchResult = action.payload;
            state.searchStatus = STATUS_FULLFILLED;
            state.searchInput = action.meta.arg;
            
        });
        builder.addCase(searchNotifiche.rejected, (state: any, action: any) => {
            state.searchStatus = STATUS_REJECTED;
        });

        builder.addCase(loadStatiFormSearch.pending, (state: NotificheState, action: any) => {

        });
        builder.addCase(loadStatiFormSearch.fulfilled, (state: NotificheState, action: any) => {
            state.formSearchComponents = action.payload;
        });
        builder.addCase(loadStatiFormSearch.rejected, (state: NotificheState, action: any) => {

        });

        builder.addCase(patchNotifica.pending, (state: NotificheState, action: any) => {
            state.updateStatus = STATUS_PENDING
        })
        builder.addCase(patchNotifica.fulfilled, (state: NotificheState, action: any) => {
            state.updateResult = action.payload
            state.updateStatus = STATUS_FULLFILLED
        })
        builder.addCase(patchNotifica.rejected, (state: NotificheState, action: any) => {
            state.updateStatus = STATUS_REJECTED
        })

        builder.addCase(deleteNotifica.pending, (state: NotificheState, action: any) => {
            state.deleteStatus = STATUS_PENDING
        })
        builder.addCase(deleteNotifica.fulfilled, (state: NotificheState, action: any) => {
            state.deleteResult = action.payload
            state.deleteStatus = STATUS_FULLFILLED
        })
        builder.addCase(deleteNotifica.rejected, (state: NotificheState, action: any) => {
            state.deleteStatus = STATUS_REJECTED
        })
        //insert feedback
        builder.addCase(inserisciFeedbackNotifica.pending, (state: NotificheState, action: any) => {
            state.insertStatus = STATUS_PENDING;
        });
        builder.addCase(inserisciFeedbackNotifica.fulfilled, (state: NotificheState, action: any) => {
            state.insertStatus = STATUS_FULLFILLED
        });
        builder.addCase(inserisciFeedbackNotifica.rejected, (state: NotificheState, action: any) => {
            state.insertStatus = STATUS_REJECTED;
        });

        builder.addCase(getNotificheNonLette.pending, (state: NotificheState, action: any) => {
            state.notificheNonLetteStatus = STATUS_PENDING
        });
        builder.addCase(getNotificheNonLette.fulfilled, (state: NotificheState, action: any) => {
            state.notificheNonLette = action.payload.data
            state.notificheNonLetteStatus = STATUS_FULLFILLED
        });
        builder.addCase(getNotificheNonLette.rejected, (state: NotificheState, action: any) => {
            state.notificheNonLetteStatus = STATUS_REJECTED
        });
    }
})

export const {resetInputForm, resetSearchValues, resetStatus} = notificheSlice.actions;

export default notificheSlice.reducer;
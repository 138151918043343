import { Badge, Button, Col, Icon, Input, Table, TextArea, Toggle } from "design-react-kit";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import CustomSelect, { CustomSelectItem } from "../../components/custom-select/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { searchRuoli } from "../../store/ruoloSlice";
import { useEffect, useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { getContestoNotifiche, getPlaceholder, insertConfigurazione } from "../../store/configurazioniNotificheSlice";
import { useNavigate } from 'react-router-dom';
import { ConfigNotificheInsertRequestDtoFunzionalitaEnum } from "../../services/ms-anagrafica-unica";
import { HREF_GESTIONE_NOTIFICHE } from "../../components/sidemenu/sidemenuConstants";
import PageHeader from "../../components/page-header/PageHeader";

interface FormikType {
    funzionalita?: ConfigNotificheInsertRequestDtoFunzionalitaEnum,
    ruoli: string,
    listaDistribuzione: string,
    oggetto: string,
    testo: string,
    filtro: boolean
}

const formikInitialValues: FormikType = {
    funzionalita: undefined,
    ruoli: '',
    listaDistribuzione: '',
    oggetto: '',
    testo: '',
    filtro: false
}

const ruoliInitialValues = {
    stato: "",
    ruolo: "",
    pageNum: 0,
    pageSize: 10,
    sort: "",
    direction: "",
    dipendente: "",
    funzionalita: "",
}

let schema: any = z.object({
    funzionalita: z.string({ required_error: 'required' }),
    ruoli: z.string({ required_error: 'required' }),
    listaDistribuzione: z.string({ required_error: 'required' }).optional(),
    oggetto: z.string({ required_error: 'required' }),
    testo: z.string({ required_error: 'required' })
});

function NuovaConfigurazioneNotifica() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { contestoResult, getPlaceholderResult } = useAppSelector((state) => state.configurazioniNotifiche);

    // FUNZIONALITA
    // elenco delle funzionalità restituite da servizio.
    const [funzionalitaSelect, setFunzionalitaSelect] = useState<CustomSelectItem[]>([]);
    const [funzionalitaSelezionata, setFunzionalitaSelezionata] = useState('');
    const [funzionalitaDisponibiliSelect, setFunzionalitaDisponibiliSelect] = useState<CustomSelectItem[]>([]);

    // RUOLI
    // ruoli selezionabili nella select dei ruoli.
    const [ruoliSelect, setRuoliSelect] = useState<CustomSelectItem[]>([]);
    // ruoli selezionati che si visualizzano in tabella.
    const [ruoliTable, setRuoliTable] = useState<Array<any>>([]);
    // ruolo temporaneo selezionato dalla select.
    const [ruoloSelezionato, setRuoloSelezionato] = useState('');

    // LISTA DI DISTRIBUZIONE
    // email temporanea impostata nella input.
    const [emailSelezionata, setEmailSelezionata] = useState('');
    // email impostate che si visualizzano in tabella.
    const [emailsTable, setEmailsTable] = useState<Array<string>>([]);

    // FILTRO
    const [filtro, setFiltro] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema: toFormikValidationSchema(schema),

        onSubmit: values => {
            const ruoli: Array<any> = [];
            ruoliTable.forEach(r => contestoResult && contestoResult.ruoli && contestoResult.ruoli?.filter(ruolo => (ruolo.label === r) &&
                ruoli.push({
                    valore: ruolo.valore,
                    label: ruolo.label
                })
            ));

            const params = {
                ...values,
                ruoli
            }
            dispatch(insertConfigurazione(params))
        }
    });

    const getFieldError = (
        form: any,
        fieldName: any
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const createSelectItems = (obj: any, valoreLabel?: string, labelLabel?: string) => {
        const v = valoreLabel || 'valore';
        const l = labelLabel || 'label';
        const selectItems: Array<CustomSelectItem> = [];
        obj.forEach((f: any) => {
            if ((f.stato && f.stato === 'ATTIVO') || !f.stato) {
                selectItems.push({
                    value: f[v],
                    label: f[l]
                })
            }
        })
        return selectItems;
    };

    const addRuolo = () => {
        if (!ruoloSelezionato) {
            return false;
        }
        // aggiungo il ruolo alla tabella
        const ruoloSelezionatoMappato = contestoResult && contestoResult?.ruoli && contestoResult.ruoli.filter(r => {
            if (r.valore === Number(ruoloSelezionato)) {
                return r;
            }
        }).at(0)?.label;
        const r = [...ruoliTable, ruoloSelezionatoMappato];
        setRuoliTable(r);

        // rimuovo il ruolo dai ruoli selezionabili
        const ruoliSelezionabili = [...ruoliSelect];
        ruoliSelezionabili.splice(ruoliSelect.findIndex(e => e.value === ruoloSelezionato), 1);
        setRuoliSelect(ruoliSelezionabili)

        formik.setFieldValue('ruoli', r.join(','))
        setRuoloSelezionato('')
    }

    const rimuoviRuolo = (selectValue: string) => {
        // rimuovo il ruolo dalla tabella
        const r = [...ruoliTable];
        r.splice(r.findIndex(e => e === selectValue), 1)
        setRuoliTable(r)

        //recupero il ruolo dto completo per le informazioni mancanti
        const ruolo = contestoResult && contestoResult.ruoli && contestoResult.ruoli?.filter(ruolo => ruolo.label === selectValue).at(0);

        // aggiungo il ruolo nei ruoli selezionabili
        const ruoliSelezionabili = [...ruoliSelect]
        ruolo && ruolo.label && ruolo.valore && ruoliSelezionabili.push({
            label: ruolo?.label,
            value: ruolo?.valore
        })
        setRuoliSelect(ruoliSelezionabili);

        formik.setFieldValue('ruoli', r.join(','));

        // cancello il ruolo dalla select
        setRuoloSelezionato('')
    }

    const addEmailNuovaConfigurazioneNotifica = () => {
        const emailsVisual = [...emailsTable, emailSelezionata];
        const inputEmailRegExp = /^[A-Za-z0-9_.-]{1,50}@[A-Za-z0-9.-]{1,40}\.[A-Za-z0-9\-\.]{1,10}$/;
        if (emailsTable.includes(emailSelezionata)) {
            formik.errors.listaDistribuzione = 'email repeated';
            setEmailSelezionata('')
        } else if (!inputEmailRegExp.test(emailSelezionata)) {
            formik.errors.listaDistribuzione = 'invalid email';
            setEmailSelezionata('')
        } else {
            formik.errors.listaDistribuzione = '';
            setEmailsTable(emailsVisual)
            formik.setFieldValue('listaDistribuzione', emailsVisual.join(';'));
            setEmailSelezionata('')
        }
    }

    const rimuoviEmailNuovaConfigurazioneNotifica = (index: number, selectValue: string) => {
        const e = [...emailsTable];
        e.splice(e.findIndex(e => e === selectValue), 1)
        setEmailsTable(e)
        formik.setFieldValue('listaDistribuzione', e.join(';'));
    }

    const toggleFiltroNuovaConfigurazioneNotifica = (e: any) => {
        formik.setFieldValue('filtro', e.target.checked);
    }

    const setFunzionalitaNuovaConfigurazioneNotifica = (e: any) => {
        setFunzionalitaSelezionata(e)
        dispatch(getPlaceholder({
            idConfigurazioneNotifiche: e
        }))
        formik.setFieldValue('funzionalita', e)
    }

    useEffect(() => {
        dispatch(searchRuoli(ruoliInitialValues)).unwrap().then(resp => {
            if (resp && resp.data && resp.data.length > 0) {
                setRuoliSelect(createSelectItems(resp.data, 'codice', 'nome'))
            }
        });
        dispatch(getContestoNotifiche()).unwrap().then(resp => {
            if (resp) {
                if (resp.funzionalita && resp.funzionalita.length > 0) {
                    setFunzionalitaSelect(createSelectItems(resp.funzionalita))
                }
                if (resp.funzionalitaDisponibili && resp.funzionalitaDisponibili.length > 0) {
                    setFunzionalitaDisponibiliSelect(createSelectItems(resp.funzionalitaDisponibili))
                }
            }
        })
    }, [dispatch]);

    useEffect(() => {
        dispatch(getContestoNotifiche()).unwrap().then(resp => {
            if (resp) {
                setFunzionalitaSelect(createSelectItems(resp.funzionalita))
                setRuoliSelect(createSelectItems(resp.ruoli, 'value', 'label'))
            }
        })
    }, [dispatch]);

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_GESTIONE_NOTIFICHE} title={t("nuova notifica")!} />
            {contestoResult && !!contestoResult.ruoli && !!contestoResult.funzionalita && (
                <div className="py-5">
                    <div className="form-row mb-5">
                        <Col>
                            <CustomSelect label="Funzionalità"
                                placeholder="Seleziona funzionalità"
                                wrapperClass="required"
                                options={funzionalitaDisponibiliSelect}
                                name="funzionalita"
                                invalid={!!getFieldError(formik, "funzionalita")}
                                infoText={t(getFieldError(formik, "funzionalita")) || ""}
                                value={formik.values.funzionalita}
                                onChange={(e) => setFunzionalitaNuovaConfigurazioneNotifica(e)} />
                        </Col>
                    </div>
                    <div className="form-row mb-5">
                        <Col md={10}>
                            <CustomSelect
                                wrapperClass="required"
                                label="Ruoli"
                                options={ruoliSelect}
                                value={ruoloSelezionato}
                                placeholder={ruoliSelect.length > 0 ? "Seleziona ruoli" : "Nessun ruolo selezionabile"}
                                onChange={(e) => setRuoloSelezionato(e)}
                                invalid={!!getFieldError(formik, "ruoli")}
                                infoText={t(getFieldError(formik, "ruoli")) || ""}
                                name="ruoli"
                                disabled={ruoliSelect.length === 0}
                            />
                        </Col>
                        <div className="col-2">
                            <Button outline color='primary' onClick={() => addRuolo()}>
                                <Icon icon='it-plus-circle' aria-hidden size='sm' color="primary" />Aggiungi
                            </Button>
                        </div>
                        {ruoliTable && ruoliTable.length > 0 && (
                            <Table striped size='sm' className='col-md-11'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='col-md-10' >Ruoli</th>
                                        <th className='col-md-2' ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ruoliTable?.map((value, index) =>
                                        <tr key={index}>
                                            <td className='notSaveElement'>*</td>
                                            <td> {contestoResult?.ruoli?.map((ruolo) => String(ruolo.label) === value ? ruolo.label : '')}</td>
                                            <td> <Button size='xs' color='danger' outline onClick={() => rimuoviRuolo(value)} >  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                    <div className="form-row mb-5">
                        <Col md={10}>
                            <Input
                                type="text"
                                label="Lista di distribuzione"
                                id="input-emails"
                                placeholder="Inserisci una email"
                                wrapperClassName="col"
                                name="listaDistribuzione"
                                invalid={!!getFieldError(formik, 'listaDistribuzione')}
                                infoText={t(getFieldError(formik, "ruoli")) || ""}
                                value={emailSelezionata}
                                onChange={(e: any) => setEmailSelezionata(e.target.value)}
                            />
                        </Col>
                        <div className="col-2">
                            <Button outline color='primary' onClick={() => addEmailNuovaConfigurazioneNotifica()}> <Icon icon='it-plus-circle' aria-hidden size='sm' color="primary" />Aggiungi</Button>
                        </div>
                        {emailsTable && emailsTable.length > 0 && (
                            <Table striped size='sm' className='col-md-11'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='col-md-10' >Lista di distribuzione</th>
                                        <th className='col-md-2' ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emailsTable?.map((value, index) =>
                                        <tr key={index}>
                                            <td className='notSaveElement'>*</td>
                                            <td> {value}</td>
                                            <td> <Button size='xs' color='danger' outline onClick={() => rimuoviEmailNuovaConfigurazioneNotifica(index, value)}>  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                    <div className="form-row mb-5">
                        <Col>
                            <Input
                                type="text"
                                label="Oggetto"
                                placeholder="Inserisci l'oggetto della mail"
                                id="input-oggetto"
                                name="oggetto"
                                value={formik.values.oggetto}
                                onChange={formik.handleChange}
                                wrapperClassName="form-group required col-md-12"
                                invalid={!!getFieldError(formik, "oggetto")}
                                infoText={t(getFieldError(formik, "oggetto")) || ""}
                            />
                        </Col>
                    </div>

                    {getPlaceholderResult && getPlaceholderResult?.length > 0 &&
                        <div className="mb-5">
                            <label>Placeholder utilizzabili:</label><br />
                            {getPlaceholderResult?.map((p, i) => <Badge style={{ marginLeft: '5px', padding: '10px' }} key={i}>{p.placeholder!}</Badge>)}
                        </div>
                    }

                    <div className="form-row mb-5">
                        <TextArea
                            label="Testo email"
                            placeholder="Inserisci il testo"
                            id="input-testo"
                            wrapperClassName="form-group required col-md-12"
                            name="testo"
                            value={formik.values.testo}
                            onChange={formik.handleChange}
                            invalid={!!getFieldError(formik, "testo")}
                            infoText={t(getFieldError(formik, "testo")) || ""}
                        />
                    </div>
                    <div className="form-row mb-5">
                        <Col md>
                            <Toggle
                                label="Invia notifica ai soli dipendenti della struttura organizzativa di riferimento"
                                defaultChecked={filtro}
                                disabled={false}
                                onClick={toggleFiltroNuovaConfigurazioneNotifica}
                            />
                            <small className="text-description" color='muted'>
                                Se non abilitato, la notifica verrà inviata a tutti i dipendenti.
                            </small>
                        </Col>
                    </div>
                    <div className=" position-relative py-5">
                        <div className="d-flex flex-row-reverse">
                            <Button color='primary' className="mt-2 mr-2" onClick={formik.submitForm}>{t('Conferma')}</Button>
                            <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(HREF_GESTIONE_NOTIFICHE) }} >Indietro</Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}


export default NuovaConfigurazioneNotifica;
import { useTranslation } from 'react-i18next'
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from '../../components/sidemenu/sidemenuConstants'
import PageHeader from '../../components/page-header/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getContestoProposteIncarichi, saveStruttureOrganizzativeSelezionabili, saveDipendentiSelezionabili, getPropostaIncarico, reset, updatePropostaIncarico } from '../../store/proposteIncarichiSlice'
import { z } from 'zod'
import { DipendenteRegionaleEssenzialeDto, FormsControllerApi, InsertUpdateIncarichiDirigenzialiRequestDto, InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum } from '../../services/ms-anagrafica-unica'
import { debounce } from 'lodash'
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useFormik } from 'formik'
import PageLoader from '../../components/page-loader/PageLoader'
import { Col, Input, TextArea, Toggle } from 'design-react-kit'
import CustomAutocomplete from '../../components/custom-autocomplete/CustomAutocomplete'
import { Button } from 'reactstrap'
import DocumentUpload from '../../components/document-upload/DocumentUpload'
import CustomSelect, { CustomSelectItem } from '../../components/custom-select/CustomSelect'
import NotificationManagerComponent from '../../components/notificationComponent/NotificationManagerComponent'
import './style.scss';
import { hideLoader, showLoader } from '../../store/loaderSlice'

const initialValues = {
    codiceIncaricoSo: '',
    candidatoInterno: true,
    inComando: false,
    tipoIncarico: '',
    note: '',
    nomeCognomeEsterno: '',
    codiceFiscaleEsterno: '',
    idDipendente: 0,
    idDocumento: 0,
};

const schemaConferma = z.object({
    codiceIncaricoSo: z.string(),
    candidatoInterno: z.boolean(),
    inComando: z.boolean(),
    tipoIncarico: z.string(),
    note: z.string().max(200).optional(),
    nomeCognomeEsterno: z.string().max(50).optional(),
    codiceFiscaleEsterno: z.string().max(16).optional(),
    idDipendente: z.number().gt(0).optional(),
    idDocumento: z.number().gt(0),
});

const schemaSalva = z.object({
    codiceIncaricoSo: z.string().optional(),
    candidatoInterno: z.boolean().optional(),
    inComando: z.boolean().optional(),
    tipoIncarico: z.string().optional(),
    note: z.string().optional(),
    nomeCognomeEsterno: z.string().max(50).optional(),
    codiceFiscaleEsterno: z.string().max(16).optional(),
    idDipendente: z.number().optional(),
    idDocumento: z.number().optional()
});

function ModificaPropostaIncarico() {
    const { t } = useTranslation()
    const { id } = useParams();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [tipoSalvataggio, setTipoSalvataggio] = useState('');
    const [tipoStruttura, setTipoStruttura] = useState('');
    const [codiceIncaricoSo, setCodiceIncaricoSo] = useState('');
    const [candidatoInterno, setCandidatoInterno] = useState<boolean>(false);
    const [inComando, setInComando] = useState<boolean>(false);
    const [tipoIncarico, setTipoIncarico] = useState('');
    const [protocolloUscita, setProtocolloUscita] = useState<string | undefined>('');
    const [note, setNote] = useState('');
    const [tipoIncaricoSelect, setTipoIncaricoSelect] = useState<CustomSelectItem[]>([]);
    const [dipRegionale, setDipRegionale] = useState<DipendenteRegionaleEssenzialeDto | undefined>()
    const [modificaStruttura, toggleModificaStruttura] = useState<boolean>(false);
    const [modificaCandidato, toggleModificaCandidato] = useState<boolean>(false);
    const { contestoStatus, soSelezionabili, propostaSelezionataStatus, updateStatus } = useAppSelector((state) => state.proposteIncarichi);
    const { loaderActive } = useAppSelector((state) => state.loader);

    useEffect( () => {
        dispatch(reset())
        dispatch(getPropostaIncarico({id: Number(id)})).unwrap().then(resp => {
            console.log('dettaglio proposta >>> ', resp);
            setCodiceIncaricoSo(resp.codiceIncaricoSo!)
            setTipoStruttura(resp.tipoStrutturaOrganizzativa!)
            setCandidatoInterno(resp.candidatoInterno!)
            setInComando(resp.inComando!)
            setTipoIncarico(resp.tipoIncarico!)
            setProtocolloUscita(resp.nprotocolloUscita!)
            setNote(resp.note!)
            setDipRegionale(resp.dipendenteRegionale)

            propostaForm.setValues({
                codiceIncaricoSo: resp.codiceIncaricoSo!,
                candidatoInterno: resp.candidatoInterno!,
                inComando: resp.inComando!,
                tipoIncarico: resp.tipoIncarico! || '',
                note:resp.note! || '',
                nomeCognomeEsterno: resp.nomeCognomeEsterno || '',
                codiceFiscaleEsterno: resp.codiceFiscaleEsterno || '',
                idDipendente: resp.dipendenteRegionale?.id || 0,
                idDocumento: resp.documenti?.at(0)?.documento?.id || 0
            })
        });
        dispatch(getContestoProposteIncarichi()).unwrap().then(resp => {
            if (resp) {
                const selectItems: Array<CustomSelectItem> = [];
                resp.tipiIncaricoDirigenziale && resp.tipiIncaricoDirigenziale.forEach((f: any) => {
                    selectItems.push({
                        value: f.valore,
                        label: f.label
                    })
                })
                setTipoIncaricoSelect(selectItems)
            }
        });
    }, [])

    const validationSchema = tipoSalvataggio === "CONFERMA" ? toFormikValidationSchema(schemaConferma) : toFormikValidationSchema(schemaSalva);

    const propostaForm = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            const tipoIncarico = values.tipoIncarico.charAt(0).toUpperCase() + values.tipoIncarico.slice(1).toLowerCase()
            const formInserimento: InsertUpdateIncarichiDirigenzialiRequestDto = {
                ...values,
                tipoIncarico: InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum[tipoIncarico as keyof typeof InsertUpdateIncarichiDirigenzialiRequestDtoTipoIncaricoEnum],
                idDipendente:  values.idDipendente ? values.idDipendente : undefined,
                idDocumento:  values ? values.idDocumento : undefined,
            }
            console.log('Invio form inserimento >>> ', formInserimento);
            dispatch(updatePropostaIncarico({
                id: Number(id),
                insertUpdateIncarichiDirigenzialiRequestDto: formInserimento
            }))
        },
    });

    const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoSO({
            descrizioneBreve: inputValue,
            tipoRichiesta: 'INSERIMENTO'
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (response.data.struttureOrganizzative) {
            dispatch(saveStruttureOrganizzativeSelezionabili(response.data))
            response.data.struttureOrganizzative.forEach(d => {
                options.push({ value: d.value, label: d.label });
            });
        }
        callback(options);
    }, 500)

    const autocompleteCandidatoInterno = debounce(async (inputValue: string, callback: any) => {
        dispatch(showLoader());
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoDipendenti({
            categorie: candidatoInterno ? ['CD','SZ','SR'] : [],
            nomeCompleto: inputValue
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (response.data.dipendenti) {
            dispatch(saveDipendentiSelezionabili(response.data))
            response.data.dipendenti.forEach(d => {
                options.push({ value: String(d.value), label: d.label });
            });
            dispatch(hideLoader());
        }
        callback(options);
    }, 500)

    const handleStrutturaOrganizzativaSelect = (value: string) => {
        console.log('handleStrutturaOrganizzativaSelect', value);
        propostaForm.setFieldValue('codiceIncaricoSo', value, false)
        const soSelezionata = soSelezionabili?.struttureOrganizzative && soSelezionabili?.struttureOrganizzative.filter( so => so.valore === value).at(0)
        const tipo = soSelezionata?.label.slice(0, soSelezionata?.label.indexOf(' - '))
        !!tipo && setTipoStruttura(tipo);
        setCodiceIncaricoSo(value)
    }

    const impostaCandidatoInterno = () => {
        const candidatoInternoChanged = !candidatoInterno;
        setCandidatoInterno(candidatoInternoChanged);
        propostaForm.setFieldValue('candidatoInterno', candidatoInternoChanged, false)
        toggleModificaCandidato(!modificaCandidato)
        propostaForm.setFieldValue('candidato', '', false)
    }
    
    const impostaInComando = () => {
        const inComandoChanged = !inComando;
        setInComando(inComandoChanged);
        propostaForm.setFieldValue('inComando', inComandoChanged, false)
    }

    const setDocumentId = (name: string, id: number | undefined) => {
        if (id) {
            propostaForm.setFieldValue(name, id, false);
        } else {
            console.error('errore durante la chiamata di caricamento documento');
        }
    }

    const submitConfirm = () => {
        propostaForm.setFieldValue('requestType', 'CONFERMA');
        propostaForm.values.idDipendente === 0 && propostaForm.setFieldValue('idDipendente', undefined, false);
        setTimeout(() => {
            propostaForm.submitForm();
        }, 500);
    }

    const submitSave = () => {
        propostaForm.setFieldValue('requestType', 'SALVA');
        setTimeout(() => {
            propostaForm.submitForm();
        }, 500);
    }

    const impostaTipoIncarico = (e:any) => {
        setTipoIncarico(e)
        propostaForm.setFieldValue('tipoIncarico', e, false)
    }

    const impostaNote = (e:any) => {
        setNote(e.target.value)
        propostaForm.setFieldValue('note', e.target.value, false)
    }

    const loadOptions = (soSelezionabili: any) => {
        if (!soSelezionabili || !soSelezionabili.struttureOrganizzative) return [];
        return soSelezionabili.struttureOrganizzative.map( (so:any) => ({
            value: so.valore,
            label: so.label
        }));
    }

    return (
        <div>
            <PageHeader showIcon={true}  urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI} title={t("modifica incarichi dirigenziali")!} />
            <div className="form-row">
                {!!modificaStruttura ? 
                <Col>
                    <div className='au-autocomplete'>
                        <CustomAutocomplete
                            required={true}
                            label="Denominazione SO"
                            placeholder="Seleziona un valore"
                            id="select-struttura-organizzativa"
                            invalid={!!propostaForm.errors.codiceIncaricoSo}
                            infoText={t(propostaForm.errors.codiceIncaricoSo || "") || ""}
                            loadOptionsFn={autocompleteStruttureOrganizzative}
                            handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelect(e)}
                        />
                        <Button className='form-group' type='button' onClick={() => toggleModificaStruttura(!modificaStruttura)}>
                            Annulla
                        </Button>
                    </div>
                </Col>
                : 
                <>
                <Col>
                    <CustomSelect label="Denominazione SO"
                        placeholder="Seleziona tipologia"
                        wrapperClass="required"
                        options={loadOptions(soSelezionabili)}
                        name="tipologia-incarico"
                        disabled={true}
                        value={codiceIncaricoSo}
                        onChange={(e) => false} />
                </Col>
                <Button className='form-group' type='button' onClick={() => toggleModificaStruttura(!modificaStruttura)}>
                    Modifica struttura
                </Button>
                </> }
            </div>
            <div className="form-row">
                <Col md="6">
                    <Input
                        type="text"
                        label="Codice struttura"
                        name="codice-struttura"
                        value={codiceIncaricoSo}
                        readOnly={true}
                        />
                </Col>
                <Col md="6">
                    <Input
                        type="text"
                        label="Tipo struttura"
                        name="tipo-struttura"
                        value={tipoStruttura}
                        readOnly={true}
                        />
                </Col>
            </div>
            <div className="form-row">
                <Col md="4">
                    {candidatoInterno ? <Toggle
                        label="Candidato interno"
                        disabled={false}
                        defaultChecked={candidatoInterno}
                        onClick={() => impostaCandidatoInterno()}
                    /> : <Toggle
                    label="Candidato interno"
                    disabled={false}
                    onClick={() => impostaCandidatoInterno()}
                />}
                </Col>
            </div>
            <div className="form-row candidato">
                {!!candidatoInterno ? modificaCandidato ? 
                <Col>
                    <div className='au-autocomplete'>
                        <CustomAutocomplete
                            label="Candidato"
                            placeholder="Seleziona un valore"
                            id="select-candidato"
                            invalid={!!propostaForm.errors.idDipendente}
                            infoText={t(propostaForm.errors.idDipendente || "") || ""}
                            loadOptionsFn={autocompleteCandidatoInterno}
                            handleOptionSelect={(e: string) => propostaForm.setFieldValue('idDipendente', Number(e), false)}
                            required={true}
                        />
                        <Button className='form-group' type='button' onClick={() => toggleModificaCandidato(!modificaCandidato)}>
                            Annulla
                        </Button>
                    </div>
                </Col> : <>
                <Input
                    type="text"
                    label="Candidato"
                    name="candidato"
                    wrapperClassName="col col-md input-group required mt-5"
                    value={dipRegionale?.nomeCompleto}
                    readOnly={true}
                />
                <Button className='form-group' type='button' onClick={() => toggleModificaCandidato(!modificaCandidato)}>
                    Modifica candidato
                </Button>
                </> : !!modificaCandidato ?
                <>
                <Input type="text"
                    label="Candidato"
                    id="nomeCognomeEsterno"
                    placeholder="Nome e cognome del candidato"
                    wrapperClassName="col mt-5 required"
                    name="nomeCognomeEsterno"
                    invalid={!!propostaForm.errors.nomeCognomeEsterno}
                    infoText={t(propostaForm.errors.nomeCognomeEsterno || "") || ""}
                    value={propostaForm.values.nomeCognomeEsterno}
                    onChange={(e) => propostaForm.setFieldValue('nomeCognomeEsterno', e.target.value, false)}
                /> 
                <Input type="text"
                    label="Codice Fiscale"
                    id="codiceFiscaleEsterno"
                    placeholder="Inserisci informazioni del dipendente"
                    wrapperClassName="col mt-5 required"
                    name="codiceFiscaleEsterno"
                    invalid={!!propostaForm.errors.codiceFiscaleEsterno}
                    infoText={t(propostaForm.errors.codiceFiscaleEsterno || "") || ""}
                    value={propostaForm.values.codiceFiscaleEsterno}
                    onChange={(e) => propostaForm.setFieldValue('codiceFiscaleEsterno', e.target.value, false)}
                />
                </> :
                <>
                <Input
                    type="text"
                    label="Candidato"
                    name="nomeCognomeEsterno"
                    wrapperClassName="col mt-5 required"
                    invalid={!!propostaForm.errors.nomeCognomeEsterno}
                    infoText={t(propostaForm.errors.nomeCognomeEsterno || "") || ""}
                    value={propostaForm.values.nomeCognomeEsterno}
                    readOnly={true}
                />
                <Input
                    type="text"
                    label="Candidato"
                    name="codiceFiscaleEsterno"
                    wrapperClassName="col mt-5 required"
                    invalid={!!propostaForm.errors.codiceFiscaleEsterno}
                    infoText={t(propostaForm.errors.codiceFiscaleEsterno || "") || ""}
                    value={propostaForm.values.codiceFiscaleEsterno}
                    readOnly={true}
                />
                <Button className='form-group' type='button' onClick={() => toggleModificaCandidato(!modificaCandidato)}>
                    Modifica candidato
                </Button>
                </>}
            </div>
            <div className="form-row">
                <Col md="3">
                    {propostaForm.values.inComando ? <Toggle
                        label="In comando"
                        disabled={false}
                        defaultChecked  
                        onClick={impostaInComando} 
                    /> : <Toggle
                    label="In comando"
                    disabled={false} 
                    onClick={impostaInComando} 
                />}  
                </Col>
            </div>
            <div className="form-row mt-5">
                <Col>
                    <CustomSelect label="Tipologia incarico"
                        placeholder="Seleziona tipologia"
                        wrapperClass="required"
                        options={tipoIncaricoSelect}
                        name="tipologia-incarico"
                        invalid={!!propostaForm.errors.tipoIncarico}
                        infoText={t(propostaForm.errors.tipoIncarico || "") || ""}
                        value={tipoIncarico}
                        onChange={(e) => impostaTipoIncarico(e)} />
                </Col>
            </div>
            <TextArea
                label="Note personale"
                placeholder="Inserisci le note personali "
                id="input-note"
                wrapperClassName="form-group  col-md-12"
                name="note"
                value={note}
                onChange={(e) => impostaNote(e)}
                invalid={!!propostaForm.errors.note}
                infoText={t(propostaForm.errors.note || "") || ""}
            />
            {!!tipoStruttura && <div className="form-row">
                <DocumentUpload
                    documentName={tipoStruttura === 'DIPARTIMENTO' ? t('dgr approvata') : t('proposta di incarico')}
                    className="col col-md-8 required my-3"
                    invalid={!!propostaForm.errors.idDocumento}
                    infoText={t(propostaForm.errors.idDocumento || "") || ""}
                    idDocumento={propostaForm.values.idDocumento}
                    setDocumentId={(idDocumento) => setDocumentId('idDocumento', idDocumento)}
                />
            </div>}

            {protocolloUscita && (
                <Input
                    type="text"
                    label="Numero protocollo"
                    wrapperClassName="col mt-5"
                    value={protocolloUscita}
                    readOnly={true}
                />
            )}

            <div className="d-flex flex-row-reverse">
                <Button color='primary' className="mt-2 mr-2" onClick={() => { submitConfirm(); setTipoSalvataggio('CONFERMA') }}>Conferma</Button>
                <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSave(); setTipoSalvataggio('SALVA') }}>Salva</Button>
                <Button color='secondary' className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`) }} >{t('annulla')} </Button>
            </div>
        </div>
    )
}

export default ModificaPropostaIncarico
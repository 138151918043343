import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Accordion, AccordionBody, AccordionHeader, Badge, Button, Col, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown } from 'design-react-kit';
import { MS_AU_CONFIG, STATUS_FULLFILLED } from '../../store/store-constants';
import { resetEditStatus, resetInfoDecesso, resetInfoDettaglio, searchDipendentiRegionali } from '../../store/dipendentiRegionaliSlice';
import { z } from 'zod';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useNavigate } from 'react-router-dom';
import CustomSelect, { CustomSelectItem } from '../../components/custom-select/CustomSelect';
import { loadSOFormSearch } from '../../store/strutturaOrganizzativaSlice';
import { t } from 'i18next';
import Pagination from '../../components/pager/Pagination';
import { CandidaturaBandoPoEssenzialeDto, DipendenteRegionaleDto, DipendenteRegionaleDtoStatoEnum, IncaricoEssenzialeDto, StrutturaOrganizzativaControllerApi, StrutturaOrganizzativaDto } from '../../services/ms-anagrafica-unica';
import { debounce } from 'lodash';
import CustomAutocomplete from '../../components/custom-autocomplete/CustomAutocomplete';
import { HREF_CESSAZIONE_DIPENDENTE, HREF_VISUALIZZA_CESSAZIONE_DIPENDENTE } from '../../components/sidemenu/sidemenuConstants';

import CustomAlert from '../../components/custom-alert/CustomAlert';
import PageHeader from '../../components/page-header/PageHeader';
import { cvDownload } from '../../store/curriculumVitaeSlice';

const schema = z.object({
    id: z.number().optional(),
    nome: z.string().max(200, { message: "lenghtnome" }).optional(),
    cognome: z.string().max(200, { message: "lenghtcognome" }).optional(),
    strutturaOrganizzativa: z.string().optional(),
    stato: z.enum(['CENSITO','TERMINATO']).optional(),
    tipoStrutturaOrganizzativa: z.string().optional()
});

const initialValues = {
    pageNum: 0,
    pageSize: 10,
    direction: 'ASC',
    sort: 'nomeCompleto',
    id: 0,
    nome: '',
    cognome: '',
    strutturaOrganizzativa: '',
    statoDipendente:'',
    tipoStrutturaOrganizzativa: ''
};

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

const ElencoDipendentiRegionali = () => {

    const searchFormDipeRegi = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    const formSearchComponents = useAppSelector((state) => state.strutturaOrganizzativa.formSearchComponents);

    const {
        searchResult,
        searchStatus,
        searchInput,
    } = useAppSelector((state) => state.dipendentiRegionali);
    const {userInfoResult} = useAppSelector((state) => state.user);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [formElencoDipeRegiCollapseOpen, setFormElencoDipeRegiCollapseOpen] = useState(false);
    const [isOpenAltreCandidature, toggleModalAltreCandidature] = useState(false);
    const [elencoAltreCandidatureDipRegi, setElencoAltreCandidatureDipRegi] = useState<Array<CandidaturaBandoPoEssenzialeDto>>([]);

    const [isOpenListDetails, toggleModalListDetails] = useState(false);
    const [elencoDetails, setElencoDetails] = useState<IncaricoEssenzialeDto[] | undefined>([]);

    const canDownloadCV = () => {
        return userInfoResult?.elencoFunzionalitaAttive?.includes('CV_DOWNLOAD_TUTTI');
    }

    const canCessazione = () => {
        return userInfoResult?.elencoFunzionalitaAttive?.includes('DIPENDENTE_PATCH');
    }

    const canDettaglio = () => {
        return userInfoResult?.elencoFunzionalitaAttive?.includes('DIPENDENTE_DETTAGLIO');
    }

    const callCvDownload = (id: number, nome: string) => {
        dispatch(cvDownload({
            idCid: Number(id)
        })).unwrap().then((resp) => {
            if (!!resp) {
                const blob = new Blob([resp], { type: 'application/pdf' })
                let url;
                const link = document.createElement('a');
                url = URL.createObjectURL(blob);
                link.href = url;
                link.download = `CV_${nome.replace(' ','_').replace('\'','')}`;
                link.click()
            }
        });
    };

    const modalViewDetails = (list: any) => {
        console.log(list);

        if (list.length > 1) {
            toggleModalListDetails(!isOpenListDetails);
            setElencoDetails(list);
        }
    }

    useEffect(() => {
        dispatch(resetEditStatus());
        dispatch(resetInfoDettaglio());
        dispatch(resetInfoDecesso());
        dispatch(loadSOFormSearch());
        if (searchInput == undefined || (searchInput.tipoStrutturaOrganizzativa == '' && searchInput?.statoDipendente == '' && searchInput?.id == 0 && searchInput?.nome == '' && searchInput?.cognome == '' && searchInput?.strutturaOrganizzativa == '' && searchInput?.sort == '' && searchInput?.direction == '')) {
            doSearch();
        }
    }, [0])

    useEffect(() => {
        searchFormDipeRegi.setValues({
            id: searchInput?.id || 0,
            nome: searchInput?.nome || '',
            cognome: searchInput?.cognome || '',
            strutturaOrganizzativa: searchInput?.strutturaOrganizzativa || '',
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            sort: searchInput?.sort || '',
            direction: searchInput?.direction || '',
            statoDipendente: searchInput?.statoDipendente || '',
            tipoStrutturaOrganizzativa: searchInput?.tipoStrutturaOrganizzativa || ''
        })

        if (searchInput != undefined && (searchInput.tipoStrutturaOrganizzativa != '' || searchInput.statoDipendente != '' ||searchInput.id != 0 || searchInput.nome != '' || searchInput.cognome != '' || searchInput.strutturaOrganizzativa != '')) {
            if (searchInput?.sort == '' && searchInput?.direction == '') {
                setFormElencoDipeRegiCollapseOpen(true);
            }
            dispatch(searchDipendentiRegionali(searchInput))
        }

    }, [searchInput])

    let hasResults =
        searchResult && searchResult.totalElements
            ? searchResult.totalElements > 0
            : false;

    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    let tipoStrutturaOrganizzativaOptions: CustomSelectItem[] = [];
    formSearchComponents?.tipo?.forEach((s) => {
        tipoStrutturaOrganizzativaOptions.push({ label: s.label, value: s.valore });
    });


    let statoDipendenteOptions: CustomSelectItem[] = [{label: 'CENSITO', value: 'CENSITO'}, {label: 'TERMINATO', value: 'TERMINATO'}];


    const doSearch = () => {
        dispatch(searchDipendentiRegionali(searchFormDipeRegi.values));
    };

    const searchStartKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            searchFormDipeRegi.setFieldValue("pageNum", 0);
            searchFormDipeRegi.handleSubmit();
        }
    }

    const ordinamentoDipRegi = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchFormDipeRegi.setFieldValue("sort", columnName);
        searchFormDipeRegi.setFieldValue("direction", sorting.direction);
        searchFormDipeRegi.handleSubmit();
    };


    const handlePageChange = (pageNum: number) => {
        console.log("Handle page change", pageNum - 1);
        searchFormDipeRegi.setFieldValue("pageNum", pageNum - 1);
        searchFormDipeRegi.handleSubmit();
    };

    const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
        const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
        const response = await api.searchStrutturaOrganizzativa({
            denominazione: inputValue
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (response.data?.data) {
            response.data.data.forEach(d => {
                options.push({ value: d.id, label: d.descrizione });
            });
        }
        callback(options);
    }, 500)

    const handleStrutturaOrganizzativaSelect = (value: string) => {
        searchFormDipeRegi.setFieldValue('strutturaOrganizzativa', value);
        searchFormDipeRegi.handleChange(value);
    }

    const returnStruttureAssociate = (elencoStruttureOrganizzative: StrutturaOrganizzativaDto[]) => {

        if (elencoStruttureOrganizzative.length === 0) {
            return (
                'Nessuna struttura organizzativa'
            )
        } else if (elencoStruttureOrganizzative.length === 1) {
            return (
                elencoStruttureOrganizzative[0].descrizione
            )
        } else if (elencoStruttureOrganizzative.length > 1) {
            return (
                elencoStruttureOrganizzative?.length + ' strutture organizzativa/e'
            )
        }
    }

    const handleChangeOptions = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        searchFormDipeRegi.setFieldValue(name, selectedOption);
    };

    const canShowElencoCandidature = (x: DipendenteRegionaleDto) => {
        return userInfoResult?.elencoFunzionalitaAttive?.includes('BANDO_PO_ACCESSO_COMPLETO') 
                && x.stato === 'CENSITO'
                && x.elencoCandidatureBandi
                && x.elencoCandidatureBandi?.length > 0
    }

    const loadCandidature = (x: DipendenteRegionaleDto) => {
        x.elencoCandidatureBandi && x.elencoCandidatureBandi.length > 0 && setElencoAltreCandidatureDipRegi(x.elencoCandidatureBandi)
        toggleModalAltreCandidature(!isOpenAltreCandidature)
    }

    const printStrutturaOrganizzative = (dip: DipendenteRegionaleDto) => {
        if (!dip.elencoStruttureOrganizzative) {
            return ' - '
        } else if (dip.elencoStruttureOrganizzative.length > 1) {
            return <Badge
                    color='primary'
                    className=" col-sm-12"
                    style={{ cursor: 'pointer', padding: '10px', fontSize: '15px' }}
                    onClick={() => modalViewDetails(dip.elencoStruttureOrganizzative)}>
                    {`${returnStruttureAssociate(dip.elencoStruttureOrganizzative!)}`}
                </Badge>
        } else {
            return `${returnStruttureAssociate(dip.elencoStruttureOrganizzative!)}`
        }
    }

    return (
        <div>
            <PageHeader showIcon={false} title='Elenco dipendenti'
                buttons={[
                    {
                        buttonProperties: { outline: true },
                        title: 'Ricerca',
                        buttonColor: 'primary',
                        showButtonIcon: true,
                        icon: 'it-search',
                        iconColor: 'primary',
                        action: () => { formElencoDipeRegiCollapseOpen ? setFormElencoDipeRegiCollapseOpen(false) : setFormElencoDipeRegiCollapseOpen(true) },
                    }
                ]}
            />
            <Accordion background="active">
                <AccordionHeader
                    active={formElencoDipeRegiCollapseOpen}
                    onToggle={() => setFormElencoDipeRegiCollapseOpen(!formElencoDipeRegiCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formElencoDipeRegiCollapseOpen}>
                    <div className="form-row mt-5">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchFormDipeRegi.values.pageNum}
                            onChange={searchFormDipeRegi.handleChange}
                        />
                        <Input
                            type="number"
                            label="Codice"
                            id="input-codice"
                            placeholder="Inserisci un codice"
                            wrapperClassName="col col-md-6"
                            name="id"
                            invalid={!!searchFormDipeRegi.errors.id}
                            infoText={t(searchFormDipeRegi.errors.id || "") || ""}
                            value={searchFormDipeRegi.values.id == 0 ? '' : searchFormDipeRegi.values.id}
                            onChange={searchFormDipeRegi.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Nome"
                            id="input-nome"
                            placeholder="Inserisci un nome"
                            wrapperClassName="col col-md-6"
                            name="nome"
                            invalid={!!searchFormDipeRegi.errors.nome}
                            infoText={t(searchFormDipeRegi.errors.nome || "") || ""}
                            value={searchFormDipeRegi.values.nome}
                            onChange={searchFormDipeRegi.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Cognome"
                            id="input-cognome"
                            placeholder="Inserisci un cognome"
                            wrapperClassName="col col-md-6"
                            name="cognome"
                            invalid={!!searchFormDipeRegi.errors.cognome}
                            infoText={t(searchFormDipeRegi.errors.cognome || "") || ""}
                            value={searchFormDipeRegi.values.cognome}
                            onChange={searchFormDipeRegi.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />
                        <CustomSelect
                            label="Stato"
                            options={statoDipendenteOptions}
                            value={searchFormDipeRegi.values.statoDipendente}
                            placeholder="Seleziona stato"
                            invalid={!!searchFormDipeRegi.errors.statoDipendente}
                            infoText={t(searchFormDipeRegi.errors.statoDipendente || "") || ""}
                            onChange={(e) => handleChangeOptions('statoDipendente', e)}
                            name="statoDipendente"
                            wrapperClass="col-md"
                        />
                    </div>
                    <div className="form-row">
                        <Col md="6" style={{marginTop: '-44px'}}>
                            <CustomAutocomplete
                                label="Struttura organizzativa"
                                placeholder="Seleziona un valore"
                                id="select-struttura-organizzativa"
                                value={searchFormDipeRegi.values.strutturaOrganizzativa}
                                loadOptionsFn={autocompleteStruttureOrganizzative}
                                handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelect(e)}
                            />
                        </Col>
                        <Col md="6">
                            <CustomSelect
                                label="Tipo Struttura Organizzativa"
                                options={tipoStrutturaOrganizzativaOptions}
                                value={searchFormDipeRegi.values.tipoStrutturaOrganizzativa}
                                placeholder="Seleziona tipo struttura"
                                invalid={!!searchFormDipeRegi.errors.tipoStrutturaOrganizzativa}
                                infoText={t(searchFormDipeRegi.errors.tipoStrutturaOrganizzativa || "") || ""}
                                onChange={(e) => handleChangeOptions('tipoStrutturaOrganizzativa', e)}
                                name="statoDipendente"
                            />
                        </Col>

                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchFormDipeRegi.setFieldValue("pageNum", 0);
                                searchFormDipeRegi.handleSubmit();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchFormDipeRegi.handleReset}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>
            <div className="mt-5 position-relative">
                <div>
                    {searchStatus === STATUS_FULLFILLED && searchResult?.totalElements === 0 && <CustomAlert color='info' message='Nessun risultato disponibile' showButton={false} />}
                    {searchStatus === STATUS_FULLFILLED && hasResults && (
                        <>
                            <div className={searchResult.totalPages == 1 ? 'mb-5 table-responsive' : 'table-responsive'}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th scope="col" onClick={() => ordinamentoDipRegi("id")} style={{ minWidth: '20%', maxWidth: '20%' }}>
                                                <div className="table-order">
                                                    Codice CID
                                                    <div className="icon-direction">
                                                        <Icon
                                                            icon="it-collapse"
                                                            size="xs"
                                                        />
                                                        <Icon
                                                            icon="it-expand"
                                                            size="xs"
                                                        />
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" onClick={() => ordinamentoDipRegi("nomeCompleto")} style={{ minWidth: '20%', maxWidth: '20%' }}>
                                                <div className="table-order">
                                                    Cognome e Nome
                                                    <div className="icon-direction">
                                                        <Icon
                                                            icon="it-collapse"
                                                            size="xs"
                                                        />
                                                        <Icon
                                                            icon="it-expand"
                                                            size="xs"
                                                        />
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: '25%' }}>
                                                Struttura organizzativa
                                            </th>
                                            <th scope="col">
                                                Stato
                                            </th>
                                            <th scope="col">
                                                Azioni
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(searchResult.data || []).map((x, i) =>
                                            <tr key={x.id}>
                                                <td>{x.id}</td>
                                                <td>{x.cognome} {x.nome}</td>
                                                <td>{printStrutturaOrganizzative(x)}</td>

                                                <td title={x.stato} >
                                                    {x.stato}
                                                </td>
                                                <td>
                                                    {(canCessazione() || (x.stato === DipendenteRegionaleDtoStatoEnum.Terminato && canDettaglio()) || (canDownloadCV() && x.isCvPubblicato)) && <UncontrolledDropdown direction="left">
                                                        <DropdownToggle nav>
                                                            <Badge color='primary'><Icon color="white" icon="it-more-actions" /></Badge>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="no-arrow">
                                                            <LinkList>
                                                                {x.stato === DipendenteRegionaleDtoStatoEnum.Terminato && canDettaglio() && (
                                                                    <LinkListItem size="medium" onClick={() => { navigate(`${HREF_VISUALIZZA_CESSAZIONE_DIPENDENTE}/${x.id}`) }}>
                                                                        <span>Visualizza cessazione dipendente</span>
                                                                    </LinkListItem>
                                                                )
                                                                }
                                                                {x.stato === DipendenteRegionaleDtoStatoEnum.Censito && canCessazione() && (
                                                                    <LinkListItem size="medium" onClick={() => { navigate(`${HREF_CESSAZIONE_DIPENDENTE}/${x.id}`) }}>
                                                                        <span>Gestione cessazione dipendente</span>
                                                                    </LinkListItem>
                                                                )
                                                                }
                                                                {canDownloadCV() && x.isCvPubblicato && (
                                                                    <LinkListItem size="medium" onClick={() => callCvDownload(x.id!, x.nomeCompleto!)}>
                                                                        <span>Scarica CV</span>
                                                                    </LinkListItem>
                                                                )
                                                                }
                                                                {canShowElencoCandidature(x) && (
                                                                    <LinkListItem size="medium" onClick={() => loadCandidature(x)}>
                                                                        <span>Elenco candidature del dipendente</span>
                                                                    </LinkListItem>
                                                                )}


                                                            </LinkList>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <Modal
                                    isOpen={isOpenListDetails}
                                    toggle={() => toggleModalListDetails(!isOpenListDetails)}
                                    labelledBy='listDetails'
                                    centered
                                >
                                    <ModalHeader toggle={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }} id='listDetails'>
                                        Elenco
                                    </ModalHeader>
                                    <ModalBody>
                                        {elencoDetails?.map((value) => (
                                            <ul key={value.id}>
                                                <li>[{value.id}] {value.descrizione || ''}</li>
                                            </ul>
                                        ))}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color='secondary' onClick={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }}>
                                            Annulla
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Modal
                                    isOpen={isOpenAltreCandidature}
                                    toggle={() => toggleModalAltreCandidature(!isOpenAltreCandidature)}
                                    labelledBy='altreCandidature'
                                    centered
                                    size="lg"
                                >
                                    <ModalHeader toggle={() => toggleModalAltreCandidature(!isOpenAltreCandidature)} id='altreCandidature'>
                                        Elenco candidature
                                    </ModalHeader>
                                    <ModalBody>
                                        {elencoAltreCandidatureDipRegi.length > 0 &&
                                            <Table>
                                                <thead>
                                                    <th>Nome EQ</th>
                                                    <th>Struttura organizzativa</th>
                                                    <th>Dirigente</th>
                                                </thead>
                                                <tbody>
                                                    {elencoAltreCandidatureDipRegi.map( (cand, i) =>
                                                        <tr key={cand.id}>
                                                            <td>{cand.assegnazioneBandoPo?.posizioneOrganizzativa?.denominazione}</td>
                                                            <td>{cand.assegnazioneBandoPo?.posizioneOrganizzativa?.nomeStrutturaOrganizzativa}</td>
                                                            <td>{cand.assegnazioneBandoPo?.posizioneOrganizzativa?.dirigenteStrutturaOrganizzativa}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        }
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color='secondary' onClick={() => toggleModalAltreCandidature(!isOpenAltreCandidature)}>
                                            Annulla
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                            <Pagination
                                totalCount={searchResult.totalElements || 0}
                                siblingCount={1}
                                currentPage={searchFormDipeRegi.values.pageNum + 1}
                                pageSize={searchResult.pageSize || 10}
                                onPageChange={(page: number) => {
                                    handlePageChange(page);
                                }}
                                className="justify-content-center"
                            />
                        </>
                    )
                    }


                </div>
            </div>
        </div>
    )
}

export default ElencoDipendentiRegionali;


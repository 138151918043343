import { DipendentiRegionaliControllerApiInfoDecessoRequest } from './../services/ms-anagrafica-unica/api/dipendenti-regionali-controller-api';
import { PagedDtoDipendenteRegionaleDto } from './../services/ms-anagrafica-unica/model/paged-dto-dipendente-regionale-dto';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CessaDipendentiRegionaliContestoForm, DipendenteRegionaleDto, DipendentiRegionaliControllerApi, DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest, FormsControllerApi, InfoDecessoDipendenteDto } from '../services/ms-anagrafica-unica';
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from './store-constants';

export interface DipendentiRegionaliState {
    searchResult: PagedDtoDipendenteRegionaleDto,
    searchStatus: string,
    searchInput: SearchDipendentiRequest | null,
    detailsResult: DipendenteRegionaleDto | null,
    detailsStatus: string,
    editStatus: string,
    infoDecessoResult: InfoDecessoDipendenteDto | null,
    infoDecessoStatus: string, 
    formSearchComponents: CessaDipendentiRegionaliContestoForm | null,
}

const initialState: DipendentiRegionaliState = {
    searchResult: {
        pageNum: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        data: []
    },
    searchStatus: '',
    searchInput: null,
    detailsResult: null,
    detailsStatus: '',
    editStatus: '',
    infoDecessoResult: null,
    infoDecessoStatus: '',
    formSearchComponents: null
}

export interface SearchDipendentiRequest {
    id?: number | undefined,
    nome?: string,
    cognome?: string,
    codiceFiscale?: string,
    strutturaOrganizzativa?: string,
    statoDipendente?: any,
    tipoStrutturaOrganizzativa?: any,
    pageSize?: number,
    pageNum?: number,
    sort?: string,
    direction?: any,
    q?: string
}

export interface DetailParam {
    idDipendente: number
}

export const searchDipendentiRegionali = createAsyncThunk(
    'dipendenti-regionali/search',
    async (args: SearchDipendentiRequest) => {
        const api = new DipendentiRegionaliControllerApi(MS_AU_CONFIG);
        const response = await api.searchDipendenteRegionale({
            pageNum: args.pageNum,
            pageSize: args.pageSize,
            direction: args.direction,
            sort: args.sort,
            id: args.id == 0 ? undefined : args.id,
            nomeCompleto: args.cognome !== '' || args.nome !== '' ? args.cognome + " " + args.nome : '',
            codiceFiscale: args.codiceFiscale,
            strutturaOrganizzativa: args.strutturaOrganizzativa,
            statoDipendente: args.statoDipendente,
            tipoStrutturaOrganizzativa: args.tipoStrutturaOrganizzativa
        });
        return response.data;
    }
)

export const detailsCesszioneDipendente = createAsyncThunk(
    'dipendenti-regionali/details',
    async (detailsParam: DetailParam) => {
        const api = new DipendentiRegionaliControllerApi(MS_AU_CONFIG);
        const response = await api.detailDipendenteRegionale({
            idDipendente: detailsParam.idDipendente,
        })
        return response.data;
    }
)

export const editCesszioneDipendente = createAsyncThunk(
    'dipendenti-regionali/edit',
    async (args: DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest) => {
        const api = new DipendentiRegionaliControllerApi(MS_AU_CONFIG);
        const response = await api.patchDipendenteRegionale({
            idDipendente: args.idDipendente,
            patchDipendenteRegionaleRequest: args.patchDipendenteRegionaleRequest
        })
        return response.data;
    }
)

export const infoDecessoDipendente = createAsyncThunk(
    'dipendenti-regionali/info-decesso',
    async (args: DipendentiRegionaliControllerApiInfoDecessoRequest) => {
        const api = new DipendentiRegionaliControllerApi(MS_AU_CONFIG);
        const response = await api.infoDecesso({
            idDipendente: args.idDipendente,
        })
        return response.data;
    }
)

export const loadMotiviCessazzioneSearch = createAsyncThunk(
    'dipendenti-regionali/load-motivi-cessazzione',
    async () => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getCessaDipendenteContestoForm();
        return response.data;
    }
);

export const dipendentiRegionaliSlice = createSlice({
    name: 'dipendentiRegionaliSlice',
    initialState,
    reducers: {
        resetInfoDecesso: (state) => {
            state.infoDecessoResult = null;
            state.infoDecessoStatus = ''
        },
        resetInfoDettaglio: (state) => {
            state.detailsResult = null;
            state.detailsStatus = ''
        },
        resetEditStatus: (state) => {
            state.editStatus = '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(searchDipendentiRegionali.pending, (state: any, action: any) => {
            state.searchStatus = STATUS_PENDING;
        });
        builder.addCase(searchDipendentiRegionali.fulfilled, (state: DipendentiRegionaliState, action: any) => {
            state.searchResult = action.payload;
            state.searchStatus = STATUS_FULLFILLED;
            state.searchInput = action.meta.arg;
            
        });
        builder.addCase(searchDipendentiRegionali.rejected, (state: any, action: any) => {
            state.searchStatus = STATUS_REJECTED;
        });

        builder.addCase(detailsCesszioneDipendente.pending, (state: DipendentiRegionaliState, action) => {
            state.detailsStatus = STATUS_PENDING
        })
        builder.addCase(detailsCesszioneDipendente.fulfilled, (state: DipendentiRegionaliState, action) => {
            state.detailsResult = action.payload;
            state.detailsStatus = STATUS_FULLFILLED
        })
        builder.addCase(detailsCesszioneDipendente.rejected, (state: DipendentiRegionaliState, action) => {
            state.detailsStatus = STATUS_REJECTED
        })
        builder.addCase(editCesszioneDipendente.pending, (state: DipendentiRegionaliState, action) => {
            state.editStatus = STATUS_PENDING
        })
        builder.addCase(editCesszioneDipendente.fulfilled, (state: DipendentiRegionaliState, action) => {
            state.editStatus = STATUS_FULLFILLED
        })
        builder.addCase(editCesszioneDipendente.rejected, (state: DipendentiRegionaliState, action) => {
            state.editStatus = STATUS_REJECTED
        })
        builder.addCase(infoDecessoDipendente.pending, (state: DipendentiRegionaliState, action) => {
            state.infoDecessoStatus = STATUS_PENDING
        })
        builder.addCase(infoDecessoDipendente.fulfilled, (state: DipendentiRegionaliState, action) => {
            state.infoDecessoResult = action.payload;
            state.infoDecessoStatus = STATUS_FULLFILLED
        })
        builder.addCase(infoDecessoDipendente.rejected, (state: DipendentiRegionaliState, action) => {
            state.infoDecessoStatus = STATUS_REJECTED
        })

        builder.addCase(loadMotiviCessazzioneSearch.pending, (state: DipendentiRegionaliState, action: any) => {
        });
        builder.addCase(loadMotiviCessazzioneSearch.fulfilled, (state: DipendentiRegionaliState, action: any) => {
            state.formSearchComponents = action.payload;
        });
        builder.addCase(loadMotiviCessazzioneSearch.rejected, (state: DipendentiRegionaliState, action: any) => {

        });
    }
});

export const {resetInfoDecesso, resetInfoDettaglio, resetEditStatus} = dipendentiRegionaliSlice.actions;

export default dipendentiRegionaliSlice.reducer;
import { useTranslation } from "react-i18next";
import { Table } from "design-react-kit";
import PageHeader from "../../components/page-header/PageHeader";

const PraticheCittadino = () => {
    const { t } = useTranslation();

    return (
        <div>
            <PageHeader showIcon={false} title={t("Pratiche")!} buttons={[]}/>
            <div className="mt-2 position-relative">
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Titolo</th>
                            <th>Stato</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default PraticheCittadino;
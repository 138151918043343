import React from 'react'
import { AnagraficaRuoloDto } from '../../services/ms-anagrafica-unica'
import './style.scss'

export interface RuoloDetailsProps {
    detailsResult: AnagraficaRuoloDto;
}

const RuoloDetailsComponent = (props: RuoloDetailsProps) => {
    return (
        <div className='col-sm-12'>
            {(props.detailsResult?.codice || props.detailsResult?.nome || props.detailsResult?.descrizione || props.detailsResult?.stato || props.detailsResult?.dataInserimento || props.detailsResult?.dataModifica) &&
                <div className="card-div row">
                    <div className="col-sm-12">
                        <div className="info-box-light">
                            <h6 className="mb-3">
                                Riepilogo
                            </h6>
                            <hr />
                            <div className="row">
                                <dl className='col-sm-12'>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.codice &&
                                                <>
                                                    <dt className='col-sm-12'>Codice</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.codice}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-12'>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            {props.detailsResult?.stato &&
                                                <>
                                                    <dt className='col-sm-6'>Stato</dt>
                                                    <dd className='col-sm-6'>{props.detailsResult?.stato}</dd>
                                                </>
                                            }
                                        </div>
                                        <div className="col-sm-4">
                                            {props.detailsResult?.dataInserimento &&
                                                <>
                                                    <dt className='col-sm-12'>Data Inserimento</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.dataInserimento}</dd>
                                                </>
                                            }
                                        </div>
                                        <div className="col-sm-4">
                                            {
                                                <>
                                                    <dt className='col-sm-12'>Data Modifica</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.dataModifica ? props.detailsResult?.dataModifica : '----'}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-12'>
                                    <div className="row">

                                        {props.detailsResult?.descrizione &&
                                            <>
                                                <dt className='col-sm-12'>Descrizione</dt>
                                                <dd className='col-sm-12'>{props.detailsResult?.descrizione}</dd>
                                            </>
                                        }
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default RuoloDetailsComponent
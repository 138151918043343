import { Breadcrumb, BreadcrumbItem } from "design-react-kit"
import { BreadcrumbItemModel } from "./BreacrumbModel";
import { useSearchParams } from "react-router-dom";
import './style.scss'

interface BreadcrumbProps {
    items: Array<BreadcrumbItemModel>,
    openSo: Function
}

const CustomBreacrumb = (props:BreadcrumbProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const openSo = (soDestinazione: any) => {
        soDestinazione.id && soDestinazione.tipo && setSearchParams({
            so: soDestinazione.id,
            tipo: soDestinazione.tipo
        }, {replace: true});
        props.openSo(soDestinazione)
    }

    return (
        <section>
            <Breadcrumb>
                <>
                { props.items.map( (page,index,pages) =>
                    (pages.length === index +1) ? (
                        <BreadcrumbItem key={page.nome}>
                            <span>{page.nome}</span>
                        </BreadcrumbItem>
                    ) : (
                        <BreadcrumbItem key={page.nome} onClick={() => openSo(page)}>
                            <span className="link">{page.nome}</span>
                            <span className='separator'>/</span>
                        </BreadcrumbItem>
                    ))
                }
                </>
            </Breadcrumb>
        </section>
    )
}

export default CustomBreacrumb;
/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AssegnazioneIncarico } from './assegnazione-incarico';
// May contain unused imports in some cases
// @ts-ignore
import { IncaricoDocumento } from './incarico-documento';

/**
 * 
 * @export
 * @interface StrutturaOrganizzativa
 */
export interface StrutturaOrganizzativa {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'dataInserimento'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'dataModifica'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'dataCancellazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StrutturaOrganizzativa
     */
    'equiparata'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'descrizioneBreve'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'descrizione'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'codiceUfficioDetermine'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'codiceUfficioDelibere'?: string;
    /**
     * 
     * @type {number}
     * @memberof StrutturaOrganizzativa
     */
    'codiceDipartimentoRagioneria'?: number;
    /**
     * 
     * @type {number}
     * @memberof StrutturaOrganizzativa
     */
    'codiceSezioneRagioneria'?: number;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'codiceIpa'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'codiceBilancio'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'responsabile'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'pec'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'telefono'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'indirizzo'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'cap'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'citta'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'dataInizio'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'dataFine'?: string;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'codiceIncaricoPadre'?: string;
    /**
     * 
     * @type {StrutturaOrganizzativa}
     * @memberof StrutturaOrganizzativa
     */
    'incaricoPadre'?: StrutturaOrganizzativa;
    /**
     * 
     * @type {Array<AssegnazioneIncarico>}
     * @memberof StrutturaOrganizzativa
     */
    'assegnazioneIncarico'?: Array<AssegnazioneIncarico>;
    /**
     * 
     * @type {Array<IncaricoDocumento>}
     * @memberof StrutturaOrganizzativa
     */
    'elencoIncaricoDocumenti'?: Array<IncaricoDocumento>;
    /**
     * 
     * @type {AssegnazioneIncarico}
     * @memberof StrutturaOrganizzativa
     */
    'ultimaAssegnazioneIncarico'?: AssegnazioneIncarico;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'tipo'?: StrutturaOrganizzativaTipoEnum;
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativa
     */
    'stato'?: StrutturaOrganizzativaStatoEnum;
}

export const StrutturaOrganizzativaTipoEnum = {
    Dipartimento: 'DIPARTIMENTO',
    Sezione: 'SEZIONE',
    Servizio: 'SERVIZIO',
    StrutturaEquiparata: 'STRUTTURA_EQUIPARATA'
} as const;

export type StrutturaOrganizzativaTipoEnum = typeof StrutturaOrganizzativaTipoEnum[keyof typeof StrutturaOrganizzativaTipoEnum];
export const StrutturaOrganizzativaStatoEnum = {
    Compilata: 'COMPILATA',
    Registrata: 'REGISTRATA',
    Censita: 'CENSITA',
    Cessata: 'CESSATA'
} as const;

export type StrutturaOrganizzativaStatoEnum = typeof StrutturaOrganizzativaStatoEnum[keyof typeof StrutturaOrganizzativaStatoEnum];



/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FunzionalitaRichiestaRequestDto
 */
export interface FunzionalitaRichiestaRequestDto {
    /**
     * 
     * @type {number}
     * @memberof FunzionalitaRichiestaRequestDto
     */
    'idFunzionalita'?: number;
    /**
     * 
     * @type {string}
     * @memberof FunzionalitaRichiestaRequestDto
     */
    'tipoAbilitazione'?: FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum;
    /**
     * 
     * @type {string}
     * @memberof FunzionalitaRichiestaRequestDto
     */
    'noteDirigente'?: string;
}

export const FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum = typeof FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum[keyof typeof FunzionalitaRichiestaRequestDtoTipoAbilitazioneEnum];



import { Alert, Button, Input } from 'design-react-kit'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { assegnaCodiceCIFRA, detailsStruttureOrganizzative, resetUpdateForm } from '../../store/strutturaOrganizzativaSlice';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/sidemenu/sidemenuConstants';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail-form/StruttureOrganizzativeDetailsForm';
import { CITTA, CODICE, DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, DIPARTIMENTO, EMAIL, FAX, INDIRIZZO, PEC, TELEFONO, TIPOLOGIA_STRUTTURA } from './struttureOrganizzativeConstants';
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import PageLoader from '../../components/page-loader/PageLoader';
import { z } from 'zod'
import { SOCodiceCifraRequestDto } from '../../services/ms-anagrafica-unica';
import { toFormikValidationSchema } from "zod-formik-adapter";
import NotificationManagerComponent from '../../components/notificationComponent/NotificationManagerComponent';
import PageHeader from '../../components/page-header/PageHeader';

const schemaConfirm = z
    .object({
        codiceCifraDetermina: z.string({ required_error: 'required' }).regex(new RegExp(/[0-9]/), 'codiceCifraDetermina').trim().min(1).max(3, 'lenghtcodiceCifra'),
        codiceCifraDelibera: z.string({ required_error: 'required' }).trim().min(1).max(3, 'lenghtcodiceCifra'),
    })

const schemaSave = z
    .object({
        codiceCifraDetermina: z.string().regex(new RegExp(/[0-9]/), 'codiceCifraDetermina').max(3, 'lenghtcodiceCifra').optional(),
        codiceCifraDelibera: z.string().max(3, 'lenghtcodiceCifra').optional(),
    })

const initialValues: SOCodiceCifraRequestDto = {
    codiceCifraDetermina: undefined,
    codiceCifraDelibera: undefined,
    requestType: 'CodiceCifra'
}


const AssegnazioneCodiceCifra = () => {

    const { t } = useTranslation()
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fieldUsed: Array<string> = [CODICE, DENOMINAZIONE, DECLARATORIA, DATA_INIZIO_VALIDITA, TIPOLOGIA_STRUTTURA, DIPARTIMENTO, EMAIL, PEC, TELEFONO, FAX, INDIRIZZO, CITTA];
    const updateStatus = useAppSelector((state) => state.strutturaOrganizzativa.updateStatus)
    const [button, setButtonSubmit] = useState('')

    useEffect(() => {
        dispatch(resetUpdateForm())
        getDetailsFromApi(id!)
    }, [0])

    const strutturaOrganizzativa = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult);
    const strutturaOrganizzativaResult = useAppSelector((state) => state.strutturaOrganizzativa.detailsStatus);

    const getDetailsFromApi = (id: string) => {
        dispatch(detailsStruttureOrganizzative(id));
    }

    const validationSchema = button === "CONFIRM" ? toFormikValidationSchema(schemaConfirm) : toFormikValidationSchema(schemaSave)

    const formData: FormikProps<SOCodiceCifraRequestDto> = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            addCodiceCifra()
        },
    })

    const addCodiceCifra = () => {
        const body = {
            idStrutturaOrganizzativa: strutturaOrganizzativa?.id,
            bodyRequest: formData.values
        }

        dispatch(assegnaCodiceCIFRA(body));
    }

    useEffect(() => {
        formData.setValues({
            codiceCifraDetermina: strutturaOrganizzativa?.codiceUfficioDetermine || '',
            codiceCifraDelibera: strutturaOrganizzativa?.codiceUfficioDelibere || '',
            requestType: 'CodiceCifra'
        }
        )
    }, [strutturaOrganizzativa])

    const getFieldError = (
        form: FormikProps<SOCodiceCifraRequestDto>,
        fieldName: keyof SOCodiceCifraRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const submitConfirm = () => {
        formData.setFieldValue('stato', 'CONFIRM');
        setTimeout(() => {
            formData.submitForm();
        }, 500);

    }

    const submitSave = () => {
        formData.setFieldValue('stato', 'SAVE');
        setTimeout(() => {
            formData.submitForm();
        }, 500);
    }

    const handleInputChange = (event: any) => {
        formData.setFieldValue('codiceCifraDelibera', event.target.value.toUpperCase());
    };

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} title={t("assegnazione codice CIFRA")!}></PageHeader>
            {strutturaOrganizzativaResult === STATUS_FULLFILLED && <div>
                <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
                <div className="postion-relative py-5">

                    <div className="form-row">
                        <Input
                            label='Codice CIFRA Determina'
                            type='text'
                            placeholder='Inserisci il Codice CIFRA Determina'
                            id='input-codiceCifraDetermina'
                            wrapperClassName="form-group required col-md-12"
                            name="codiceCifraDetermina"
                            invalid={!!getFieldError(formData, "codiceCifraDetermina")}
                            infoText={t(getFieldError(formData, "codiceCifraDetermina")) || ""}
                            value={formData.values.codiceCifraDetermina}
                            onChange={formData.handleChange}
                        />
                        <Input
                            label='Codice CIFRA Delibera'
                            type='text'
                            placeholder='Inserisci il Codice CIFRA Delibera'
                            id='input-codiceCifraDelibera'
                            wrapperClassName="form-group required col-md-12"
                            name="codiceCifraDelibera"
                            invalid={!!getFieldError(formData, "codiceCifraDelibera")}
                            infoText={t(getFieldError(formData, "codiceCifraDelibera")) || ""}
                            value={formData.values.codiceCifraDelibera}
                            onChange={handleInputChange}

                        />
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2" onClick={() => { submitConfirm(); setButtonSubmit('CONFIRM') }}> {t('conferma')}</Button>
                        <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSave(); setButtonSubmit('SAVE') }}>{t('salva')}</Button>
                        <Button color='secondary' outline className="mt-2 mr-2" onClick={() => navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`)} >{t('annulla')}</Button>
                    </div>
                </div>
            </div>}
        </div >
    )
}

export default AssegnazioneCodiceCifra
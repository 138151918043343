/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DipendenteRegionaleDto } from '../model';
// @ts-ignore
import { InsertConsulenteEsternoRequestDto } from '../model';
// @ts-ignore
import { PagedDtoDipendenteRegionaleDto } from '../model';
// @ts-ignore
import { PatchConsulenteEsternoRequestDto } from '../model';
/**
 * ConsulenteEsternoControllerApi - axios parameter creator
 * @export
 */
export const ConsulenteEsternoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per la cancellazione di un consulente
         * @summary Delete consulente esterno
         * @param {number} idConsulente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsulenteEsterno: async (idConsulente: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idConsulente' is not null or undefined
            assertParamExists('deleteConsulenteEsterno', 'idConsulente', idConsulente)
            const localVarPath = `/v1/consulenti/{idConsulente}`
                .replace(`{${"idConsulente"}}`, encodeURIComponent(String(idConsulente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il dettaglio di un consulente esterno.
         * @summary Dettaglio consulente esterno
         * @param {number} idConsulente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailConsulenteEsterno: async (idConsulente: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idConsulente' is not null or undefined
            assertParamExists('detailConsulenteEsterno', 'idConsulente', idConsulente)
            const localVarPath = `/v1/consulenti/{idConsulente}`
                .replace(`{${"idConsulente"}}`, encodeURIComponent(String(idConsulente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento di un consulente esterno\'
         * @summary Inserimento di un consulente esterno
         * @param {InsertConsulenteEsternoRequestDto} insertConsulenteEsternoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertConsulenteEsterno: async (insertConsulenteEsternoRequestDto: InsertConsulenteEsternoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertConsulenteEsternoRequestDto' is not null or undefined
            assertParamExists('insertConsulenteEsterno', 'insertConsulenteEsternoRequestDto', insertConsulenteEsternoRequestDto)
            const localVarPath = `/v1/consulenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertConsulenteEsternoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la modifica di un consulente
         * @summary Patch consulente esterno
         * @param {number} idConsulente 
         * @param {PatchConsulenteEsternoRequestDto} patchConsulenteEsternoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConsulenteEsterno: async (idConsulente: number, patchConsulenteEsternoRequestDto: PatchConsulenteEsternoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idConsulente' is not null or undefined
            assertParamExists('patchConsulenteEsterno', 'idConsulente', idConsulente)
            // verify required parameter 'patchConsulenteEsternoRequestDto' is not null or undefined
            assertParamExists('patchConsulenteEsterno', 'patchConsulenteEsternoRequestDto', patchConsulenteEsternoRequestDto)
            const localVarPath = `/v1/consulenti/{idConsulente}`
                .replace(`{${"idConsulente"}}`, encodeURIComponent(String(idConsulente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchConsulenteEsternoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca dei consulenti esterni.
         * @summary Ricerca consulenti esterni
         * @param {number} [id] 
         * @param {string} [nomeCompleto] 
         * @param {string} [codiceFiscale] 
         * @param {string} [strutturaOrganizzativa] 
         * @param {'CENSITO' | 'TERMINATO'} [statoDipendente] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipoStrutturaOrganizzativa] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsulenteEsterno: async (id?: number, nomeCompleto?: string, codiceFiscale?: string, strutturaOrganizzativa?: string, statoDipendente?: 'CENSITO' | 'TERMINATO', tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/consulenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (nomeCompleto !== undefined) {
                localVarQueryParameter['nomeCompleto'] = nomeCompleto;
            }

            if (codiceFiscale !== undefined) {
                localVarQueryParameter['codiceFiscale'] = codiceFiscale;
            }

            if (strutturaOrganizzativa !== undefined) {
                localVarQueryParameter['strutturaOrganizzativa'] = strutturaOrganizzativa;
            }

            if (statoDipendente !== undefined) {
                localVarQueryParameter['statoDipendente'] = statoDipendente;
            }

            if (tipoStrutturaOrganizzativa !== undefined) {
                localVarQueryParameter['tipoStrutturaOrganizzativa'] = tipoStrutturaOrganizzativa;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsulenteEsternoControllerApi - functional programming interface
 * @export
 */
export const ConsulenteEsternoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsulenteEsternoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per la cancellazione di un consulente
         * @summary Delete consulente esterno
         * @param {number} idConsulente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConsulenteEsterno(idConsulente: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsulenteEsterno(idConsulente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il dettaglio di un consulente esterno.
         * @summary Dettaglio consulente esterno
         * @param {number} idConsulente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailConsulenteEsterno(idConsulente: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailConsulenteEsterno(idConsulente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento di un consulente esterno\'
         * @summary Inserimento di un consulente esterno
         * @param {InsertConsulenteEsternoRequestDto} insertConsulenteEsternoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertConsulenteEsterno(insertConsulenteEsternoRequestDto: InsertConsulenteEsternoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertConsulenteEsterno(insertConsulenteEsternoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la modifica di un consulente
         * @summary Patch consulente esterno
         * @param {number} idConsulente 
         * @param {PatchConsulenteEsternoRequestDto} patchConsulenteEsternoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchConsulenteEsterno(idConsulente: number, patchConsulenteEsternoRequestDto: PatchConsulenteEsternoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchConsulenteEsterno(idConsulente, patchConsulenteEsternoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca dei consulenti esterni.
         * @summary Ricerca consulenti esterni
         * @param {number} [id] 
         * @param {string} [nomeCompleto] 
         * @param {string} [codiceFiscale] 
         * @param {string} [strutturaOrganizzativa] 
         * @param {'CENSITO' | 'TERMINATO'} [statoDipendente] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipoStrutturaOrganizzativa] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConsulenteEsterno(id?: number, nomeCompleto?: string, codiceFiscale?: string, strutturaOrganizzativa?: string, statoDipendente?: 'CENSITO' | 'TERMINATO', tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoDipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchConsulenteEsterno(id, nomeCompleto, codiceFiscale, strutturaOrganizzativa, statoDipendente, tipoStrutturaOrganizzativa, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsulenteEsternoControllerApi - factory interface
 * @export
 */
export const ConsulenteEsternoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsulenteEsternoControllerApiFp(configuration)
    return {
        /**
         * API Rest per la cancellazione di un consulente
         * @summary Delete consulente esterno
         * @param {ConsulenteEsternoControllerApiDeleteConsulenteEsternoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiDeleteConsulenteEsternoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteConsulenteEsterno(requestParameters.idConsulente, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il dettaglio di un consulente esterno.
         * @summary Dettaglio consulente esterno
         * @param {ConsulenteEsternoControllerApiDetailConsulenteEsternoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiDetailConsulenteEsternoRequest, options?: AxiosRequestConfig): AxiosPromise<DipendenteRegionaleDto> {
            return localVarFp.detailConsulenteEsterno(requestParameters.idConsulente, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento di un consulente esterno\'
         * @summary Inserimento di un consulente esterno
         * @param {ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest, options?: AxiosRequestConfig): AxiosPromise<DipendenteRegionaleDto> {
            return localVarFp.insertConsulenteEsterno(requestParameters.insertConsulenteEsternoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la modifica di un consulente
         * @summary Patch consulente esterno
         * @param {ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest, options?: AxiosRequestConfig): AxiosPromise<DipendenteRegionaleDto> {
            return localVarFp.patchConsulenteEsterno(requestParameters.idConsulente, requestParameters.patchConsulenteEsternoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca dei consulenti esterni.
         * @summary Ricerca consulenti esterni
         * @param {ConsulenteEsternoControllerApiSearchConsulenteEsternoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiSearchConsulenteEsternoRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoDipendenteRegionaleDto> {
            return localVarFp.searchConsulenteEsterno(requestParameters.id, requestParameters.nomeCompleto, requestParameters.codiceFiscale, requestParameters.strutturaOrganizzativa, requestParameters.statoDipendente, requestParameters.tipoStrutturaOrganizzativa, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteConsulenteEsterno operation in ConsulenteEsternoControllerApi.
 * @export
 * @interface ConsulenteEsternoControllerApiDeleteConsulenteEsternoRequest
 */
export interface ConsulenteEsternoControllerApiDeleteConsulenteEsternoRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsulenteEsternoControllerApiDeleteConsulenteEsterno
     */
    readonly idConsulente: number
}

/**
 * Request parameters for detailConsulenteEsterno operation in ConsulenteEsternoControllerApi.
 * @export
 * @interface ConsulenteEsternoControllerApiDetailConsulenteEsternoRequest
 */
export interface ConsulenteEsternoControllerApiDetailConsulenteEsternoRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsulenteEsternoControllerApiDetailConsulenteEsterno
     */
    readonly idConsulente: number
}

/**
 * Request parameters for insertConsulenteEsterno operation in ConsulenteEsternoControllerApi.
 * @export
 * @interface ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest
 */
export interface ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest {
    /**
     * 
     * @type {InsertConsulenteEsternoRequestDto}
     * @memberof ConsulenteEsternoControllerApiInsertConsulenteEsterno
     */
    readonly insertConsulenteEsternoRequestDto: InsertConsulenteEsternoRequestDto
}

/**
 * Request parameters for patchConsulenteEsterno operation in ConsulenteEsternoControllerApi.
 * @export
 * @interface ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest
 */
export interface ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsulenteEsternoControllerApiPatchConsulenteEsterno
     */
    readonly idConsulente: number

    /**
     * 
     * @type {PatchConsulenteEsternoRequestDto}
     * @memberof ConsulenteEsternoControllerApiPatchConsulenteEsterno
     */
    readonly patchConsulenteEsternoRequestDto: PatchConsulenteEsternoRequestDto
}

/**
 * Request parameters for searchConsulenteEsterno operation in ConsulenteEsternoControllerApi.
 * @export
 * @interface ConsulenteEsternoControllerApiSearchConsulenteEsternoRequest
 */
export interface ConsulenteEsternoControllerApiSearchConsulenteEsternoRequest {
    /**
     * 
     * @type {number}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly nomeCompleto?: string

    /**
     * 
     * @type {string}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly codiceFiscale?: string

    /**
     * 
     * @type {string}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly strutturaOrganizzativa?: string

    /**
     * 
     * @type {'CENSITO' | 'TERMINATO'}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly statoDipendente?: 'CENSITO' | 'TERMINATO'

    /**
     * 
     * @type {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'

    /**
     * 
     * @type {number}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof ConsulenteEsternoControllerApiSearchConsulenteEsterno
     */
    readonly q?: string
}

/**
 * ConsulenteEsternoControllerApi - object-oriented interface
 * @export
 * @class ConsulenteEsternoControllerApi
 * @extends {BaseAPI}
 */
export class ConsulenteEsternoControllerApi extends BaseAPI {
    /**
     * API Rest per la cancellazione di un consulente
     * @summary Delete consulente esterno
     * @param {ConsulenteEsternoControllerApiDeleteConsulenteEsternoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsulenteEsternoControllerApi
     */
    public deleteConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiDeleteConsulenteEsternoRequest, options?: AxiosRequestConfig) {
        return ConsulenteEsternoControllerApiFp(this.configuration).deleteConsulenteEsterno(requestParameters.idConsulente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il dettaglio di un consulente esterno.
     * @summary Dettaglio consulente esterno
     * @param {ConsulenteEsternoControllerApiDetailConsulenteEsternoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsulenteEsternoControllerApi
     */
    public detailConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiDetailConsulenteEsternoRequest, options?: AxiosRequestConfig) {
        return ConsulenteEsternoControllerApiFp(this.configuration).detailConsulenteEsterno(requestParameters.idConsulente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento di un consulente esterno\'
     * @summary Inserimento di un consulente esterno
     * @param {ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsulenteEsternoControllerApi
     */
    public insertConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest, options?: AxiosRequestConfig) {
        return ConsulenteEsternoControllerApiFp(this.configuration).insertConsulenteEsterno(requestParameters.insertConsulenteEsternoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la modifica di un consulente
     * @summary Patch consulente esterno
     * @param {ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsulenteEsternoControllerApi
     */
    public patchConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest, options?: AxiosRequestConfig) {
        return ConsulenteEsternoControllerApiFp(this.configuration).patchConsulenteEsterno(requestParameters.idConsulente, requestParameters.patchConsulenteEsternoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca dei consulenti esterni.
     * @summary Ricerca consulenti esterni
     * @param {ConsulenteEsternoControllerApiSearchConsulenteEsternoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsulenteEsternoControllerApi
     */
    public searchConsulenteEsterno(requestParameters: ConsulenteEsternoControllerApiSearchConsulenteEsternoRequest = {}, options?: AxiosRequestConfig) {
        return ConsulenteEsternoControllerApiFp(this.configuration).searchConsulenteEsterno(requestParameters.id, requestParameters.nomeCompleto, requestParameters.codiceFiscale, requestParameters.strutturaOrganizzativa, requestParameters.statoDipendente, requestParameters.tipoStrutturaOrganizzativa, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}

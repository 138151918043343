import { useTranslation } from "react-i18next";
import {  getElencoAssegnazioniBandiPoContestoForm, annullaCandidatura, getElencoEQ } from "../../../store/bandoSlice";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { AssegnazioneBandiPoDto, BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest, CandidaturaBandoPoDtoStatoEnum, DocumentoDto, RuoloUtenteAutenticatoDto } from "../../../services/ms-anagrafica-unica";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Fragment, useEffect, useState } from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import { Accordion, AccordionBody, AccordionHeader, Alert, Button, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown } from "design-react-kit";
import CustomSelect, { CustomSelectItem } from "../../../components/custom-select/CustomSelect";
import { STATUS_FULLFILLED } from "../../../store/store-constants";
import Pagination from "../../../components/pager/Pagination";
import { useNavigate } from "react-router-dom";
import { HREF_CANDIDATURA_VISUALIZZA_BANDO } from "../../../components/sidemenu/sidemenuConstants";
import { formatDate } from "../../../utility/formatDate";
import { dettaglioDocumento, downloadDocument } from "../../../store/DocumentiSlice";

const ElencoBandiEQ = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {searchElencoEQResult, searchElencoEQStatus} = useAppSelector(state => state.bando)

    const [formBandiCollapseOpen, setFormBandiCollapseOpen] = useState<boolean>(false);
    const [modalAnnullaOpen, setModalAnnullaOpen] = useState<boolean>(false);
    const [idAssegnazioneSelected, setIdAssegnazioneSelected] = useState<any>(undefined);
    const [statiOptions, setStatiOptions] = useState<CustomSelectItem[]>([]);
    const [struttureOrganizzative, setStruttureOrganizzative] = useState<CustomSelectItem[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    let tipoEqOptions: CustomSelectItem[] = [
        {label: t("tipoEQ_PO"), value: "PO"}, 
        {label: t("tipoEQ_PO_EQUIPARATA"), value: "PO_EQUIPARATA"}
    ];

    const initialValues:BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest = {
        dataApertura: '',
        dataChiusura: '',
        idSo: '',
        denominazionePo: '',
        pageNum: 0,
        pageSize: 10,
        sort: '',
        direction: 'ASC',
        q: '',
        codiceCifra: ''
    };

    type Direction = 'ASC' | 'DESC';
    interface SortingState {
        direction: Direction;
    }

    useEffect( () => {
        dispatch(getElencoAssegnazioniBandiPoContestoForm()).unwrap().then((resp) => {
            if (resp) {
                const selectItemsStruttureOrganizzative: Array<CustomSelectItem> = [];
                resp.struttureOrganizzative &&
                    resp.struttureOrganizzative.forEach((f: any) => {
                        selectItemsStruttureOrganizzative.push({
                            value: f.valore,
                            label: f.label,
                        });
                    });
                setStruttureOrganizzative(selectItemsStruttureOrganizzative);
                const selectItemsStato: Array<CustomSelectItem> = [];
                resp.stato &&
                    resp.stato.forEach((f: any) => {
                        selectItemsStato.push({
                        value: f.valore,
                        label: f.label,
                    });
                });
                setStatiOptions(selectItemsStato);
            }
        });
        doSearch()
    }, []);

    const schema = z.object({
        dataApertura: z.coerce.date().optional(),
        dataChiusura: z.coerce.date().optional(),
        stato: z.string().optional(),
        idSo: z.string().optional(),
        tipoPosizioneOrganizzativa: z.string().optional(),
        denominazionePo: z.string().optional(),
        codiceCifra: z.string().optional()
    }).refine((data) => {
        if (data.dataApertura! > data.dataChiusura!) {
          const error =
            "La data di apertura deve essere precedente alla data di chiusura";
          setErrorMessage(error);
          return {
            message: error,
            path: ["dataChiusura"],
          };
        } else {
          setErrorMessage("");
          return true;
        }
      });

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    const doSearch = () => {
        dispatch(getElencoEQ(searchForm.values));
    };

    const handleChangeStato = (selectedOption: any) => {
        searchForm.setFieldValue('stato', selectedOption);
        searchForm.handleChange(selectedOption);
    };
    
    const handleChangeTipoEq = (selectedOption: any) => {
        searchForm.setFieldValue('tipoPosizioneOrganizzativa', selectedOption);
        searchForm.handleChange(selectedOption);
    };

    const ordinamentoBandi = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };

    const handlePageChangeBandi = (pageNum: number) => {
        console.log("Handle page change", pageNum - 1);
        searchForm.setFieldValue("pageNum", pageNum - 1);
        searchForm.handleSubmit();
    };

    const showScaricaRiepilogo = (candidatura: AssegnazioneBandiPoDto) => 
        props.operations.elencoFunzionalita?.includes('BANDO_PO_ELENCO_ASSEGNAZIONI') &&
        candidatura.elencoCandidature?.at(0)?.idRiepilogoFirmato

    const showAnnullaCandidatura = (assegnazione: AssegnazioneBandiPoDto) => 
        props.operations.elencoFunzionalita?.includes('BANDO_PO_CANDIDATURA_ELIMINA') && assegnazione.statoBando === 'APERTO' &&
            assegnazione.elencoCandidature?.at(0) && assegnazione.elencoCandidature?.at(0)?.stato === 'CONFERMATA'

    const scaricaRiepologo = (riepilogoFirmato: DocumentoDto) => {
        let filename = '';
        let contentType = '';
        dispatch(dettaglioDocumento(riepilogoFirmato.id!)).unwrap().then((resp) => {
            if (resp && resp.id) {
                filename = resp.filename || resp.nomeDocumento || 'RiepilogoCandidatura';
                contentType = resp.contentType || ''
                dispatch(downloadDocument({
                  idDocumento: resp.id
                })).unwrap().then(resp => {
                    if (!!resp) {
                        const blob = new Blob([resp], { type: contentType })
                        let url;
                        const link = document.createElement('a');
                        url = URL.createObjectURL(blob);
                        link.href = url;
                        link.download = filename;
                        link.click()
                    }
                });
            }
        })
    };

    const faiAnnullaCandidatura = () => {
        dispatch(annullaCandidatura({id: idAssegnazioneSelected}))
          .unwrap()
          .then((resp) => {
            resp === 200 && doSearch();
          });
    };

    const printClassName = (assegnazione:AssegnazioneBandiPoDto) => {
        if (
            assegnazione.elencoCandidature?.at(0)?.stato !== CandidaturaBandoPoDtoStatoEnum.InComp 
            && assegnazione.elencoCandidature?.at(0)?.dataInserimento
        ) {
            return 'candidato'
        }
        return '';
    }

    const printDataInserimentoCandidatura = (assegnazione:AssegnazioneBandiPoDto) => {
        if (
            assegnazione.elencoCandidature?.at(0)?.stato !== CandidaturaBandoPoDtoStatoEnum.InComp 
            && assegnazione.elencoCandidature?.at(0)?.dataInserimento
        ) {
            return formatDate(assegnazione.elencoCandidature?.at(0)?.dataInserimento!, true)
        }
        return ' - '
    }

    return (
        <div className="wrapper-elenco-bandi">
            <PageHeader
                showIcon={false}
                title={t("Elenco EQ")!}
                buttons={[]}
            />
          {/*}  {searchElencoEQStatus === STATUS_FULLFILLED && searchElencoEQResult?.numeroCandidature! > 0 && (
                <div className="candidato px-3 mb-2 py-2 float-right border">Numero di candidature inviate: {searchElencoEQResult?.numeroCandidature}</div>
            )}
          */}
            <Accordion background="active">
                <AccordionHeader
                    active={formBandiCollapseOpen}
                    onToggle={() => setFormBandiCollapseOpen(!formBandiCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formBandiCollapseOpen}>
                    <div className="form-row mt-5">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchForm.values.pageNum}
                            onChange={searchForm.handleChange}
                        />
                        <Input
                            type="text"
                            label="Denominazione EQ"
                            id="input-denominazione-po"
                            placeholder="Denominazione EQ"
                            wrapperClassName="col col-md-6"
                            name="denominazionePo"
                            invalid={!!searchForm.errors.denominazionePo}
                            infoText={t(searchForm.errors.denominazionePo || "") || ""}
                            value={searchForm.values.denominazionePo}
                            onChange={searchForm.handleChange}
                        />
                        <CustomSelect
                            label="Struttura Organizzativa"
                            options={struttureOrganizzative}
                            value={searchForm.values.idSo}
                            placeholder="Seleziona la struttura organizzativa"
                            invalid={!!searchForm.errors.idSo}
                            infoText={""}
                            disabled={struttureOrganizzative.length === 0}
                            onChange={(e) => searchForm.setFieldValue("idSo", e, false)}
                            name="idSo"
                            wrapperClass="col col-md-6"
                        />
                        <Input
                            label="Data apertura"
                            type="date"
                            placeholder="Inserisci la data di apertura"
                            id="input-dataApertura"
                            wrapperClassName="form-group col-md-4"
                            name="dataApertura"
                            value={searchForm.values.dataApertura}
                            onChange={searchForm.handleChange}
                            invalid={!!errorMessage}
                        />
                        <Input
                            label="Data chiusura"
                            type="date"
                            placeholder="Inserisci la data di chiusura"
                            id="input-dataChiusura"
                            wrapperClassName="form-group col-md-4"
                            name="dataChiusura"
                            value={searchForm.values.dataChiusura}
                            onChange={searchForm.handleChange}
                            invalid={!!errorMessage}
                            infoText={errorMessage}
                        />
                        <CustomSelect
                            label="Stato"
                            options={statiOptions}
                            value={searchForm.values.stato}
                            placeholder="Seleziona stato"
                            onChange={handleChangeStato}
                            name="stato"
                            wrapperClass="form-group col-md-4"
                        />
                        <CustomSelect
                            label="Tipo EQ"
                            name="tipoEQ"
                            placeholder="Seleziona un tipo EQ"
                            options={tipoEqOptions}
                            value={searchForm.values.tipoPosizioneOrganizzativa}
                            onChange={handleChangeTipoEq}
                            wrapperClass="form-group col-md-4"
                        />
                         <Input
                            label="Codice Cifra"
                            type="text"
                            placeholder="Inserisci il codice cifra"
                            id="input-codiceCifra"
                            wrapperClassName="form-group col-md-4"
                            name="codiceCifra"
                            value={searchForm.values.codiceCifra}
                            onChange={searchForm.handleChange}
                            invalid={!!errorMessage}
                            infoText={errorMessage}
                        />
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={searchForm.submitForm}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchForm.handleReset}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>
            <div className="mt-2 position-relative">
                {searchElencoEQStatus === STATUS_FULLFILLED &&
                    searchElencoEQResult?.totalElements === 0 && (
                        <Alert color="info">Nessun risultato disponibile</Alert>
                    )
                }
                {searchElencoEQStatus === STATUS_FULLFILLED && searchElencoEQResult?.totalElements! > 0 && (
                    <div className={searchElencoEQResult?.totalPages === 1 ? 'mb-5' : ''}>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th scope='col' onClick={() => ordinamentoBandi("posizioneOrganizzativa")}>
                                        <div className="table-order">
                                            Denominazione EQ
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    {/*strutturaOrganizzativaBando*/}
                                    <th scope='col' onClick={() => ordinamentoBandi("strutturaOrganizzativaBando")}>
                                        <div className="table-order">
                                            Struttura organizzativa
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamentoBandi("dataApertura")}>
                                        <div className="table-order">
                                            Data apertura
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamentoBandi("dataChiusura")}>
                                        <div className="table-order">
                                            Data chiusura
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamentoBandi("stato")}>
                                        <div className="table-order">
                                            Stato
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamentoBandi("dataCandidatura")}>
                                        <div className="table-order">
                                            Data invio candidatura
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope='col'>
                                        <div className="table-order">
                                            Tipo EQ
                                        </div>
                                    </th>
                                    <th scope='col'>
                                        <div className="table-order">
                                            Codice Cifra
                                        </div>
                                    </th>
                                    <th scope='col'>
                                        Azioni
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(searchElencoEQResult?.data || []).map((x, i) => (
                                <Fragment key={x.codiceUnivoco} >
                                    <tr className={printClassName(x)}>
                                        <td>{x.posizioneOrganizzativa?.denominazione}</td>
                                        <td>{x.posizioneOrganizzativa?.nomeStrutturaOrganizzativa}</td>
                                        <td>{formatDate(x.dataApertura!, true)}</td>
                                        <td>{formatDate(x.dataChiusura!, true)}</td>
                                        <td>{x.statoBando}</td>
                                        <td>{printDataInserimentoCandidatura(x)}</td>
                                        <td>{x.posizioneOrganizzativa?.tipoLabel || ' - '}</td>
                                        <td>{x.codiceCifra}</td>
                                        <td>
                                            <UncontrolledDropdown direction="left">
                                                <DropdownToggle nav>
                                                    <Icon icon="it-more-actions" />
                                                </DropdownToggle>
                                                <DropdownMenu className="no-arrow">
                                                    <LinkList>
                                                        <LinkListItem
                                                            size="medium"
                                                            onClick={() => navigate(`${HREF_CANDIDATURA_VISUALIZZA_BANDO}/${x.codiceUnivoco}`)}
                                                        >
                                                            <span>{t('Visualizza bando')}</span>
                                                        </LinkListItem>
                                                        {showScaricaRiepilogo(x) && <LinkListItem
                                                            size="medium"
                                                            onClick={() => scaricaRiepologo(x.elencoCandidature?.at(0)?.idRiepilogoFirmato!)}
                                                        >
                                                            <span>{t('Scarica riepilogo')}</span>
                                                        </LinkListItem>}
                                                        {showAnnullaCandidatura(x) && <LinkListItem
                                                            size="medium"
                                                            onClick={() => {setModalAnnullaOpen(true); setIdAssegnazioneSelected(x.codiceUnivoco); }}
                                                        >
                                                            <span>{t('Annulla candidatura')}</span>
                                                        </LinkListItem>}
                                                    </LinkList>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                    <Modal
                                        isOpen={modalAnnullaOpen}
                                        toggle={() => setModalAnnullaOpen(!modalAnnullaOpen)}
                                        labelledBy='modalAnnulla'
                                        centered
                                    >
                                            <ModalHeader toggle={() => { setModalAnnullaOpen(!modalAnnullaOpen)} } id='modalAnnulla'>
                                                {t('Annulla candidatura')}
                                            </ModalHeader>
                                            <ModalBody>
                                                {t('Conferma annulla candidatura')}
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color='secondary' onClick={() => { setModalAnnullaOpen(!modalAnnullaOpen)} }>
                                                    {t('Annulla')}
                                                </Button>
                                                <Button color='primary' onClick={() => { setModalAnnullaOpen(!modalAnnullaOpen); faiAnnullaCandidatura(); } }>
                                                    {t('Conferma')}
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                </Fragment>
                                ))}
                            </tbody>
                        </Table>
                        <Pagination
                            totalCount={searchElencoEQResult?.totalElements || 0}
                            siblingCount={1}
                            currentPage={searchForm.values.pageNum! + 1}
                            pageSize={searchElencoEQResult?.pageSize || 10}
                            onPageChange={(page: number) => {
                                handlePageChangeBandi(page);
                            }}
                            className="justify-content-center"
                        />
                    </div>
                )}
            </div>
        </div>

    );
}

export default ElencoBandiEQ;
import { Accordion, AccordionBody, AccordionHeader, Alert, Button, Col, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Table, UncontrolledDropdown } from "design-react-kit";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import CustomAutocomplete, { CustomAutocompleteRef } from "../../components/custom-autocomplete/CustomAutocomplete";
import { debounce } from "lodash";
import { FormsControllerApi, FormsControllerApiGetElencoSORequest, GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest, IncaricoDirigenzialeDto, OptionDtoStatoPropostaIncaricoValoreEnum, RuoloUtenteAutenticatoDto, StrutturaOrganizzativaDtoTipoEnum } from "../../services/ms-anagrafica-unica";
import { MS_AU_CONFIG, STATUS_FULLFILLED } from "../../store/store-constants";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getContestoProposteIncarichi, /*getElencoSo, */reset, searchProposteIncarichi } from '../../store/proposteIncarichiSlice'
import { HREF_ASSEGNA_INCARICO, HREF_INSERIMENTO_PARERE_GIUNTA, HREF_MODIFICA_INCARICO, HREF_MODIFICA_PROPOSTA_INCARICO, HREF_NUOVA_PROPOSTA_INCARICO, HREF_VALUTA_PROPOSTA_INCARICO, HREF_VISUALIZZA_INCARICO } from "../../components/sidemenu/sidemenuConstants";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pager/Pagination";
import PageHeader from "../../components/page-header/PageHeader";
import { isBefore } from "date-fns";
import CustomSelect, { CustomSelectItem } from "../../components/custom-select/CustomSelect";

const schema = z.object({
    candidato: z.string().optional(),
    denominazioneSo: z.string().optional(),
    stato: z.string().optional()
});

const initialValues: GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest = {
    pageNum: 0,
    pageSize: 10,
    direction: undefined,
    sort: '',
    denominazioneSo: '',
    candidato: ''
};

// TODO: Request da modificare in base al ruolo - vedere docs
const getElencoSOParamsInitialValues: FormsControllerApiGetElencoSORequest = {
    codIncaPadre: undefined,
    stato: "CENSITA",
    tipi: undefined,
    // denominazione: ''
}

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

function ElencoIncarichiDirigenziali(props: { operations: RuoloUtenteAutenticatoDto }) {
    const ref = useRef<CustomAutocompleteRef>(null);

    const [formCollapseOpen, setFormCollapseOpen] = useState(false);
    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
    const dispatch = useAppDispatch()
    const { searchResult, searchStatus, contestoResult, contestoStatus } = useAppSelector(
        (state) => state.proposteIncarichi
    )

    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(reset());
        dispatch(getContestoProposteIncarichi())
        doSearch(initialValues);
    }, [])

    let statoOptions: CustomSelectItem[] = [];

    contestoResult?.statoPropostaIncarico?.forEach(s => {
        statoOptions.push({ label: s.label, value: s.valore });
    });

    const doSearch = (args: GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest) => {
        dispatch(searchProposteIncarichi(args));
    };

    const searchForm = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            doSearch(values)
        },
    });

    const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoSO({
            ...getElencoSOParamsInitialValues,
            descrizioneBreve: inputValue,
            tipoRichiesta: 'VISUALIZZA'
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (response.data.struttureOrganizzative) {
            response.data.struttureOrganizzative.forEach(d => {
                options.push({ value: d.value, label: d.label });
            });
        }
        callback(options);
    }, 500)

    const handleStrutturaOrganizzativaSelect = (value: string) => {
        console.log('handleStrutturaOrganizzativaSelect', value);
        if (value == '') {
            console.log('intercettato');
            
        }
        searchForm.setFieldValue('denominazioneSo', value);
        // searchForm.handleChange(value);
    }

    const searchStartKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            searchForm.setFieldValue("pageNum", 0);
            searchForm.handleSubmit();
        }
    }

    const stampaTipoIncarico = (tipoIncarico: string) => {
        return contestoResult && contestoResult.tipiIncaricoDirigenziale?.filter(tipo => tipo.valore === tipoIncarico).at(0)?.label
    }

    const handlePageChange = (pageNum: number) => {
        console.log("Handle page change", pageNum - 1);
        // setCurrentPage(pageNum);
        searchForm.setFieldValue("pageNum", pageNum - 1);
        // doSearch();
        searchForm.handleSubmit();
    };

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };

    const showVisualizzazioneIncaricoOperations = () =>
        props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_MODIFICA_ASSEGNAZIONE')

    const nuovaProposta = () => {
        navigate(HREF_NUOVA_PROPOSTA_INCARICO);
    }

    const stampaCandidato = (proposta: IncaricoDirigenzialeDto) => {
        if (proposta.candidatoInterno) {
            return `${proposta.dipendenteRegionale?.nome || ''} ${proposta.dipendenteRegionale?.cognome || ''}`
        } else {
            return `${proposta.nomeCognomeEsterno || ''} ${proposta.codiceFiscaleEsterno ? `(${proposta.codiceFiscaleEsterno})` : ''}`
        }
    }

    const showInserisciPropostaOperations = () =>
        props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_DIP')
        || props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_SEZ')
        || props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_SER')

    const showModificaPropostaOperations = (stato: string) =>
        stato === OptionDtoStatoPropostaIncaricoValoreEnum.InCompilazione
        && (props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_MOD_DIP')
        || props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_MOD_SEZ')
        || props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_MOD_SER'))

    const showValutazionePropostaOperations = (stato: string) => 
        stato === OptionDtoStatoPropostaIncaricoValoreEnum.Inserita
        && props.operations.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_VALPERSONALE')

    const showParereOperations = (proposta: IncaricoDirigenzialeDto) =>
        props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_PATCH_VALGIUNTA')
        && proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaDtoTipoEnum.Sezione
        && proposta.stato === OptionDtoStatoPropostaIncaricoValoreEnum.Accettata

    const showAssegnazioneOperations = (proposta: IncaricoDirigenzialeDto) =>
        proposta.assegnabile && props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_INSERT_ASSEGNAZIONE')
        && (
            (proposta.stato === OptionDtoStatoPropostaIncaricoValoreEnum.Approvata && (
                proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaDtoTipoEnum.Dipartimento
                || proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaDtoTipoEnum.Sezione
            ))
            ||
            (proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaDtoTipoEnum.Servizio 
            && proposta.stato === OptionDtoStatoPropostaIncaricoValoreEnum.Accettata)
        )

    const showModificaAssegnazioneOperations = (proposta: IncaricoDirigenzialeDto) => {
        if (!!proposta.assegnazioneIncarico?.dataFine) {
            const dataFine = new Date(proposta.assegnazioneIncarico.dataFine);
            const today = new Date();
            return (
                props.operations?.elencoFunzionalita?.includes('INCDIRIGENZIALE_MODIFICA_ASSEGNAZIONE')
                && (proposta.stato === OptionDtoStatoPropostaIncaricoValoreEnum.Assegnata))
                && (isBefore(today, dataFine))
        }
        return false;
    }

    const newButton = {
        buttonProperties: { outline: true },
        title: 'Nuova proposta',
        buttonColor: 'primary',
        action: nuovaProposta,
        showButtonIcon: true,
        icon: 'it-plus',
        iconColor: 'primary',
    }

    const expandButton = {
        buttonProperties: { outline: true },
        title: 'Ricerca',
        buttonColor: 'primary',
        action: () => { formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true) },
        showButtonIcon: true,
        icon: 'it-search',
        iconColor: 'primary',
    }

    const resetForm = () => {
        searchForm.resetForm()
        ref.current?.resetAutocompleteForm();
    }

    const handleChange = (selectedOption: any) => {
        searchForm.setFieldValue('stato', selectedOption);
        searchForm.handleChange(selectedOption);
    };

    return (
        <div>
            <PageHeader showIcon={false} title={t("elenco incarichi dirigenziali")!}
                buttons={showInserisciPropostaOperations() ? [newButton, expandButton] : [expandButton]}
            />
            {contestoStatus === STATUS_FULLFILLED && (<>
            <Accordion background="active">
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-5">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchForm.values.pageNum}
                            onChange={searchForm.handleChange}
                        />
                        <Col md>
                            <Input
                                type="text"
                                label="Candidato"
                                id="candidato"
                                placeholder="Inserisci candidato"
                                name="candidato"
                                invalid={!!searchForm.errors.candidato}
                                infoText={t(searchForm.errors.candidato || "") || ""}
                                value={searchForm.values.candidato || ''}
                                onChange={searchForm.handleChange}
                                onKeyUp={searchStartKeyPress}
                            />
                        </Col>
                        <Col md="6">
                            <CustomSelect label="Stato"
                                name="stato"
                                placeholder="Seleziona stato"
                                options={statoOptions}
                                value={searchForm.values.stato}
                                onChange={handleChange} />
                        </Col>
                    </div>
                    <div className="form-row">
                        <Col md>
                            <CustomAutocomplete
                                ref={ref}
                                label="Denominazione SO"
                                placeholder="Seleziona un valore"
                                id="select-struttura-organizzativa"
                                value={searchForm.values.denominazioneSo}
                                loadOptionsFn={autocompleteStruttureOrganizzative}
                                handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelect(e)}
                            />
                        </Col>
                    </div>

                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchForm.setFieldValue("pageNum", 0);
                                searchForm.handleSubmit();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={() => resetForm()}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>

            <div className="mt-3 position-relative">
                <div>
                    {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && (
                    <Alert color="info">Nessun risultato disponibile</Alert>
                    )}
                    {searchStatus === STATUS_FULLFILLED && !!searchResult.totalElements && (<>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th scope="col" onClick={() => ordinamento("denominazioneSo")}>
                                    <div className="table-order">
                                        {t("denominazione so")}
                                        <div className="icon-direction">
                                            <Icon
                                                icon="it-collapse"
                                                size="xs"
                                            />
                                            <Icon
                                                icon="it-expand"
                                                size="xs"
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope="col-2" onClick={() => ordinamento("tipoIncarico")}>
                                    <div className="table-order">
                                        {t("tipologia")}
                                        <div className="icon-direction">
                                            <Icon
                                                icon="it-collapse"
                                                size="xs"
                                            />
                                            <Icon
                                                icon="it-expand"
                                                size="xs"
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div className="table-order">
                                        {t("candidato")}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => ordinamento("stato")}>
                                    <div className="table-order">
                                        {t("stato")}
                                        <div className="icon-direction">
                                            <Icon
                                                icon="it-collapse"
                                                size="xs"
                                            />
                                            <Icon
                                                icon="it-expand"
                                                size="xs"
                                            />
                                        </div>
                                    </div> </th>
                                <th scope="col">
                                    <div className="table-order">
                                        {t("azioni")}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResult.data?.map((x, i) => (
                                <tr key={i}>
                                    <td>{x.denominazioneSo}</td>
                                    <td>{stampaTipoIncarico(x.tipoIncarico || '')}</td>
                                    <td>{stampaCandidato(x)}</td>
                                    <td>{t(`stato_${x.stato}`)}</td>
                                    <td>
                                        {
                                        (showModificaPropostaOperations(x.stato!) ||
                                        showValutazionePropostaOperations(x.stato!) ||
                                        showParereOperations(x) ||
                                        showAssegnazioneOperations(x) ||
                                        showModificaAssegnazioneOperations(x) ||
                                        showVisualizzazioneIncaricoOperations()) &&
                                        <UncontrolledDropdown direction="left">
                                            <DropdownToggle nav>
                                                <Icon icon="it-more-actions" />
                                            </DropdownToggle>
                                            <DropdownMenu className="no-arrow">
                                                <LinkList>
                                                    {showModificaPropostaOperations(x.stato!) && <LinkListItem size="medium" onClick={() => navigate(`${HREF_MODIFICA_PROPOSTA_INCARICO}/${x.codiceUnivoco}`)}>
                                                            <span>Modifica proposta</span>
                                                        </LinkListItem>}
                                                    {showValutazionePropostaOperations(x.stato!) &&
                                                        <LinkListItem size="medium" onClick={() => navigate(`${HREF_VALUTA_PROPOSTA_INCARICO}/${x.codiceUnivoco}`)}>
                                                            <span>Valutazione proposta</span>
                                                        </LinkListItem>}
                                                    {showParereOperations(x) && <LinkListItem size="medium" onClick={() => navigate(`${HREF_INSERIMENTO_PARERE_GIUNTA}/${x.codiceUnivoco}`)}>
                                                        <span>Inserimento parere</span>
                                                    </LinkListItem>}
                                                    {showAssegnazioneOperations(x) &&
                                                        <LinkListItem size="medium" onClick={() => navigate(`${HREF_ASSEGNA_INCARICO}/${x.codiceUnivoco}`)}>
                                                            <span>Assegnazione incarico</span>
                                                        </LinkListItem>
                                                    }
                                                    {showModificaAssegnazioneOperations(x) &&
                                                    <LinkListItem size="medium" onClick={() => navigate(`${HREF_MODIFICA_INCARICO}/${x.codiceUnivoco}`)}>
                                                        <span>Modifica incarico</span>
                                                    </LinkListItem>
                                                    }
                                                    {showVisualizzazioneIncaricoOperations() && 
                                                    <LinkListItem size="medium" onClick={() => navigate(`${HREF_VISUALIZZA_INCARICO}/${x.codiceUnivoco}`)}>
                                                        <span>Visualizza incarico</span>
                                                    </LinkListItem>}
                                                </LinkList>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        totalCount={searchResult.totalElements || 0}
                        siblingCount={0}
                        currentPage={searchForm.values.pageNum! + 1}
                        pageSize={searchResult.pageSize || 10}
                        onPageChange={(page: number) => {
                            handlePageChange(page);
                        }}
                        className="justify-content-center"
                    />
                    </>)}
                </div>
            </div>
            </>)}
        </div>
    )
}

export default ElencoIncarichiDirigenziali;
import { Callout, CalloutTitle } from "design-react-kit";
import { EsperienzeProfessionaliDto } from "../../../services/ms-anagrafica-unica";
import { formatDate } from "../../../utility/formatDate";

const EsperienzeProfessionaliDetail = (props: {dati: EsperienzeProfessionaliDto[]}) => {
    const {dati} = props;
    return (
        <div className="row">
            <dl className="col-sm-12">
                {dati.length > 0 && dati.map ( esperienza => (
                    <Callout className="wrap-text au-callout">
                        <CalloutTitle style={{marginBottom: '5rem'}}>{`${esperienza.lavoroPosizioneRicoperta} dal ${formatDate(esperienza.da!, true)} ${esperienza.al ? 'al ' + formatDate(esperienza.al!, true) : ''}`}</CalloutTitle>
                        <div className="row mt-5" >
                            {esperienza.lavoroPosizioneRicoperta && <div className="col-sm-4 mt-5">
                                <dt className='col-sm-12'>Posizione ricoperta</dt>
                                <dd className='col-sm-12'>{esperienza.lavoroPosizioneRicoperta}</dd>
                            </div>}
                            {esperienza.tipoAttivitaSettore && <div className="col-sm-4 mt-5">
                                <dt className='col-sm-12'>Attività</dt>
                                <dd className='col-sm-12'>{esperienza.tipoAttivitaSettoreLabel}</dd>
                            </div>}
                            {esperienza.nomeIndirizzoLavoro && <div className="col-sm-4 mt-5">
                                <dt className='col-sm-12'>Presso</dt>
                                <dd className='col-sm-12'>{esperienza.nomeIndirizzoLavoro}</dd>
                            </div>}
                            {esperienza.principaliAttivitaResponsabilita && <div className="col-sm-12">
                                <dt className='col-sm-12'>Principali attività</dt>
                                <dd className='col-sm-12'>{esperienza.principaliAttivitaResponsabilita}</dd>
                            </div>}
                            {esperienza.specificare && <div className="col-sm-12">
                                <dt className='col-sm-12'>Dettagli</dt>
                                <dd className='col-sm-12'>{esperienza.specificare}</dd>
                            </div>}
                            {esperienza.ambito && <div className="col-sm-12">
                                <dt className='col-sm-12'>Ambito</dt>
                                <dd className='col-sm-12'>{esperienza.ambitoLabel}</dd>
                            </div>}
                            {esperienza.tipologiaContratto && <div className="col-sm-12">
                                <dt className='col-sm-12'>Tipologia Contratto</dt>
                                <dd className='col-sm-12'>{esperienza.tipologiaContrattoLabel}</dd>
                            </div>}
                        </div>
                    </Callout>
                ))}
            </dl>
        </div>
    );
}

export default EsperienzeProfessionaliDetail;
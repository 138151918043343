import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ConfigNotificheInsertRequestDto, ConfigurazioneNotificheDto, InsertConfigNotificaForm, PagedDtoConfigurazioneNotificheDto, PlaceholderNotificheDto } from '../services/ms-anagrafica-unica/model';
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from './store-constants';
import { FormsControllerApi, GestioneConfigurazioniNotificheControllerApi, GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest, GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest, GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest, GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest, GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest } from '../services/ms-anagrafica-unica';

export interface ConfigurazioniNotificheRequest {
    stato?: 'TUTTE' | 'ATTIVE' | 'DISATTIVE'
    ruolo?: string
    funzionalita?: string
    pageNum?: number
    pageSize?: number
    sort?: string
    direction?: 'ASC' | 'DESC'
    q?: string,
    options?: any
}

export interface ConfigurazioneNotificheState {
    searchConfigurazioniResult: PagedDtoConfigurazioneNotificheDto,
    searchConfigurazioniStatus: string,
    searchConfigurazioniInput: GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest | null,
    loadConfigurazioneResult: ConfigurazioneNotificheDto | null,
    loadConfigurazioneStatus: string,
    loadConfigurazioneInput: GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest | null,
    updateConfigurazioneResult: ConfigurazioneNotificheDto | null,
    updateConfigurazioneStatus: string,
    updateConfigurazioneInput: ConfigNotificheInsertRequestDto | null
    insertStatus: string,
    insertInput: ConfigNotificheInsertRequestDto | null,
    deleteStatus: string,
    contestoResult: InsertConfigNotificaForm | null
    contestoStatus: string,
    getPlaceholderResult: Array<PlaceholderNotificheDto> | null,
    getPlaceholderStatus: string
}

const initialState: ConfigurazioneNotificheState = {
    searchConfigurazioniResult: {
        pageNum: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        data: []
    },
    searchConfigurazioniStatus: '',
    searchConfigurazioniInput: null,
    loadConfigurazioneResult: null,
    loadConfigurazioneStatus: '',
    loadConfigurazioneInput: null,
    updateConfigurazioneResult: null,
    updateConfigurazioneStatus: '',
    updateConfigurazioneInput: null,
    insertStatus: '',
    insertInput: null,
    deleteStatus: '',
    contestoResult: null,
    contestoStatus: '',
    getPlaceholderResult: null, 
    getPlaceholderStatus: ''
}

export const loadConfigurazioniNotifiche = createAsyncThunk(
    'configurazioniNotifiche/elencoConfigurazioni',
    async (args: GestioneConfigurazioniNotificheControllerApiSearchConfigurazioniNotificheRequest) => {
        const api = new GestioneConfigurazioniNotificheControllerApi(MS_AU_CONFIG);
        const response = await api.searchConfigurazioniNotifiche(args);
        return response.data;
    }
);

export const loadConfigurazione = createAsyncThunk(
    'configurazioniNotifiche/getConfigurazione',
    async (args: GestioneConfigurazioniNotificheControllerApiSearchByIdConfigurazioniNotificheRequest) => {
            const api = new GestioneConfigurazioniNotificheControllerApi(MS_AU_CONFIG);
            const response = await api.searchByIdConfigurazioniNotifiche(args);
            return response.data;
    }
);

export const getContestoNotifiche = createAsyncThunk(
    'configurazioniNotifiche/getContesto',
    async () => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getInsertConfigNotificheContestoForm();
        return response.data;
    }
);

export const getPlaceholder = createAsyncThunk(
    'configurazioniNotifiche/getPlaceholder',
    async (args: GestioneConfigurazioniNotificheControllerApiSearchPlaceholderByIdRequest) => {
        const api = new GestioneConfigurazioniNotificheControllerApi(MS_AU_CONFIG);
        const response = await api.searchPlaceholderById(args)
        return response.data;
    }
);

export const insertConfigurazione = createAsyncThunk(
    'configurazioniNotifiche/insert',
    async (body: ConfigNotificheInsertRequestDto) => {
        const api = new GestioneConfigurazioniNotificheControllerApi(MS_AU_CONFIG);
        const response = await api.insertConfigurazioniNotifiche({
            configNotificheInsertRequestDto: body,
        })
        return response.data;
    }
)

export const updateConfigurazione = createAsyncThunk(
    'configurazioniNotifiche/update',
    async (args: GestioneConfigurazioniNotificheControllerApiUpdateConfigurazioniNotificheRequest) => {
        const api = new GestioneConfigurazioniNotificheControllerApi(MS_AU_CONFIG);
        const response = await api.updateConfigurazioniNotifiche({
            idConfigurazioneNotifiche: args.idConfigurazioneNotifiche,
            configNotificheInsertRequestDto: args.configNotificheInsertRequestDto
        })
        return response.data
    }
)

export const deleteConfigurazione = createAsyncThunk(
    'configurazioniNotifiche/delete',
    async (args: GestioneConfigurazioniNotificheControllerApiPatchConfigurazioniNotificheRequest) => {
        const api = new GestioneConfigurazioniNotificheControllerApi(MS_AU_CONFIG);
        const response = await api.patchConfigurazioniNotifiche({
            idConfigurazioneNotifiche: args.idConfigurazioneNotifiche,
            configNotifichePatchRequestDto: args.configNotifichePatchRequestDto
        })
        return response.data
    }
)

export const configurazioniNotificheSlice = createSlice({
    name: 'configurazioniNotificheSlice',
    initialState,
    reducers: {
        resetSearchForm: (state) => {
            state.searchConfigurazioniInput = initialState.searchConfigurazioniInput;
            state.searchConfigurazioniResult = initialState.searchConfigurazioniResult;
            state.searchConfigurazioniStatus = initialState.searchConfigurazioniStatus;
        },
        resetPlaceholder: (state) => {
            state.getPlaceholderResult = initialState.getPlaceholderResult
            state.getPlaceholderStatus = initialState.getPlaceholderStatus
        }
    },
    extraReducers: (builder) => {
        // Carico il contesto delle configurazioni
        builder.addCase(getContestoNotifiche.pending, (state: ConfigurazioneNotificheState, action: any) => {
            state.contestoStatus = STATUS_PENDING;
        });
        builder.addCase(getContestoNotifiche.fulfilled, (state: ConfigurazioneNotificheState, action: any) => {
            state.contestoResult = action.payload;
            state.contestoStatus = STATUS_FULLFILLED;
        });
        builder.addCase(getContestoNotifiche.rejected, (state: ConfigurazioneNotificheState, action: any) => {
            state.contestoStatus = STATUS_REJECTED;
        });

        // Carico le configurazioni
        builder.addCase(loadConfigurazioniNotifiche.pending, (state: ConfigurazioneNotificheState, action: any) => {
            state.searchConfigurazioniStatus = STATUS_PENDING;
        });
        builder.addCase(loadConfigurazioniNotifiche.fulfilled, (state: ConfigurazioneNotificheState, action: any) => {
            state.searchConfigurazioniResult = action.payload;
            state.searchConfigurazioniStatus = STATUS_FULLFILLED;
            state.searchConfigurazioniInput = action.meta.arg;
        });
        builder.addCase(loadConfigurazioniNotifiche.rejected, (state: ConfigurazioneNotificheState, action: any) => {
            state.searchConfigurazioniStatus = STATUS_REJECTED;
        });

        // Recupero configurazione per id
        builder.addCase(loadConfigurazione.pending, (state: ConfigurazioneNotificheState, action: any) => {
            state.loadConfigurazioneStatus = STATUS_PENDING;
        });
        builder.addCase(loadConfigurazione.fulfilled, (state: ConfigurazioneNotificheState, action: any) => {
            state.loadConfigurazioneResult = action.payload;
            state.loadConfigurazioneStatus = STATUS_FULLFILLED;
            state.loadConfigurazioneInput = action.meta.arg;
        });
        builder.addCase(loadConfigurazione.rejected, (state: ConfigurazioneNotificheState, action: any) => {
            state.loadConfigurazioneStatus = STATUS_REJECTED;
        });

        // Aggiornamento configurazione
        builder.addCase(updateConfigurazione.pending, (state: ConfigurazioneNotificheState, action: any) => {
            state.updateConfigurazioneStatus = STATUS_PENDING;
        });
        builder.addCase(updateConfigurazione.fulfilled, (state: ConfigurazioneNotificheState, action: any) => {
            state.updateConfigurazioneResult = action.payload;
            state.updateConfigurazioneStatus = STATUS_FULLFILLED;
            state.updateConfigurazioneInput = action.meta.arg;
        });
        builder.addCase(updateConfigurazione.rejected, (state: ConfigurazioneNotificheState, action: any) => {
            state.updateConfigurazioneStatus = STATUS_REJECTED;
        });

        // Insert nuova configurazione
        builder.addCase(insertConfigurazione.pending, (state: ConfigurazioneNotificheState, action: any) => {
            state.insertStatus = STATUS_PENDING;
            state.insertInput = action.meta.arg;
        });
        builder.addCase(insertConfigurazione.fulfilled, (state: ConfigurazioneNotificheState, action: any) => {
            state.insertStatus = STATUS_FULLFILLED;
        });
        builder.addCase(insertConfigurazione.rejected, (state: ConfigurazioneNotificheState, action: any) => {
            state.insertStatus = STATUS_REJECTED;
        });

        //delete ruolo
        builder.addCase(deleteConfigurazione.pending, (state: ConfigurazioneNotificheState, action: any) => {
            state.deleteStatus = STATUS_PENDING
        });
        builder.addCase(deleteConfigurazione.fulfilled, (state: ConfigurazioneNotificheState, action: any) => {
            state.deleteStatus = STATUS_FULLFILLED
        });
        builder.addCase(deleteConfigurazione.rejected, (state: ConfigurazioneNotificheState, action: any) => {
            state.deleteStatus = STATUS_REJECTED
        });

        // Recupero placeholder
        builder.addCase(getPlaceholder.pending, (state: ConfigurazioneNotificheState, action: any) => {
            state.getPlaceholderStatus = STATUS_PENDING;
        });
        builder.addCase(getPlaceholder.fulfilled, (state: ConfigurazioneNotificheState, action: any) => {
            state.getPlaceholderResult = action.payload;
            state.getPlaceholderStatus = STATUS_FULLFILLED;
        });
        builder.addCase(getPlaceholder.rejected, (state: ConfigurazioneNotificheState, action: any) => {
            state.getPlaceholderStatus = STATUS_REJECTED;
        });
    }
});

export const {resetSearchForm, resetPlaceholder} = configurazioniNotificheSlice.actions;

export default configurazioniNotificheSlice.reducer;
import { Callout, CalloutTitle } from "design-react-kit";
import { IstruzioneFormazioneDto } from "../../../services/ms-anagrafica-unica";
import { formatDate } from "../../../utility/formatDate";

const IstruzioneFormazioneDetail = (props: {dati: IstruzioneFormazioneDto[]}) => {
    const {dati} = props;
    return (
        <div className="row">
            <dl className="col-sm-12">
                {dati.length > 0 && dati.map ( titolo => (
                    <Callout className="wrap-text au-callout">
                        {titolo.altriPercorsiFormativi? <><CalloutTitle>{"Altri percorsi formativi"}</CalloutTitle><div className="row">
                            {<div className="col-sm-12">
                                <dd className='col-sm-12'>{titolo.altriPercorsiFormativi}</dd>
                            </div>}
                        </div></> : 
                        <><CalloutTitle>{`${titolo.macroCategoria?.descrizione}`}</CalloutTitle><div className="row">
                                {titolo.dettaglio?.descrizione && <div className="col-sm-4">
                                    <dt className='col-sm-12'>Titolo di studio</dt>
                                    <dd className='col-sm-12'>{titolo.dettaglio?.descrizione}</dd>
                                </div>}
                                {titolo.specificare && <div className="col-sm-4">
                                    <dt className='col-sm-12'>Specifica titolo di studio</dt>
                                    <dd className='col-sm-12'>{titolo.specificare}</dd>
                                </div>}
                                {(titolo.macroCategoria?.classe === 1 || titolo.macroCategoria?.classe === 2) && <div className="col-sm-4">
                                    <dt className='col-sm-12'>Anno di conseguimento</dt>
                                    <dd className='col-sm-12'>{titolo.dataConseguimento!.substring(0, 4)}</dd>
                                </div>}
                                {(titolo.macroCategoria?.classe !== 1 && titolo.macroCategoria?.classe !== 2) && <div className="col-sm-4">
                                    <dt className='col-sm-12'>Data di conseguimento</dt>
                                    <dd className='col-sm-12'>{formatDate(titolo.dataConseguimento!, true)}</dd>
                                </div>}
                                {titolo.titoloAccessoAreaGiuridica && <div className="col-sm-4">
                                    <dt className='col-sm-12'>Titolo di accesso all'attuale area di inquadramento giuridico</dt>
                                </div>}
                            </div></>
                        }
                    </Callout>
                ))}
            </dl>
        </div>
    );
}

export default IstruzioneFormazioneDetail;
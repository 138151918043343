import { Alert, Button } from 'design-react-kit'
import React from 'react'

export interface CustomAlertInterface {
    color: string,
    message: string,
    showButton: boolean,
    callback?: Function,
    btnText?: string
}

const CustomAlert = (props: CustomAlertInterface) => {
    const callback = () => {
        props.callback!();
    }

    return (
        <Alert color={props.color}>{props.message}
            {props.showButton && (

                <>
                    <hr />
                    
                        <Button color='link' className="mt-2 mr-2 p-0"
                            onClick={() => callback()}>{props.btnText || 'Riprova'}</Button>
                    
                </>
            )}
        </Alert>
    )
}

export default CustomAlert
import React from 'react'
import { CODICE_CID, CODICE_FISCALE, COGNOME, COLLABORATORE, DATA_FINE_RAPPORTO, DATA_INIZIO_RAPPORTO, INCARICO_IN_CORSO, MOTIVO_CESSAZIONE, NOME, NUMERO_ATTO_CESSAZIONE, STRUTTURA_ORGANIZZATIVA, TIPOLOGIA_CONTRATTUALE } from '../../pages/gestione-dipendenti-regionali/GestioneCessazioneDipendentiConstansts';
import { DipendenteRegionaleDto } from '../../services/ms-anagrafica-unica';
import DettaglioAtto from '../dettaglio-atto-cifra/DettaglioAtto';

export interface CessazioneDipendenteDetailsComponent {
  detailsResult: DipendenteRegionaleDto,
  showInfoDecesso?: boolean
}

const CessazioneDipendenteDetailsComponent = (props: CessazioneDipendenteDetailsComponent) => {

  return (
    <div className='col-sm-12'>
      {(props.detailsResult?.id || props.detailsResult?.nome || props.detailsResult?.cognome || props.detailsResult?.codiceFiscale || props.detailsResult?.elencoStruttureOrganizzative || props.detailsResult?.tipoContratto || props.detailsResult?.elencoIncarichiInCorso || props.detailsResult?.dataInizioRapporto || props.detailsResult?.cessazione?.dataFineRapporto || props.detailsResult?.cessazione?.collaboratore || props.detailsResult?.cessazione?.numeroAttoCessazione) &&
        <div className="card-div col-sm-12">
          <div className="col-sm-12">
            <div className="info-box-light">
              <h6 className="mb-3">
                Riepilogo
              </h6>
              <hr />
              <div className="row">
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.id &&
                        <>
                          <dt className='col-sm-12'>Codice CID</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.id}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.nome &&
                        <>
                          <dt className='col-sm-12'>Nome</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.nome}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.cognome &&
                        <>
                          <dt className='col-sm-12'>Cognome</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.cognome}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.codiceFiscale &&
                        <>
                          <dt className='col-sm-12'>Codice fiscale</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.codiceFiscale}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.elencoStruttureOrganizzative &&
                        <>
                          <dt className='col-sm-12'>Strutture organizzative di appartenenza</dt>
                          <dd className='col-sm-12'>{props.detailsResult.numeroSO == 1 ? `${props.detailsResult.elencoStruttureOrganizzative![0].descrizione}` : <ul key={props.detailsResult.id}>{props.detailsResult?.elencoStruttureOrganizzative!.map(data => <li>{data.descrizione}</li>)} </ul>}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.tipoContratto &&
                        <>
                          <dt className='col-sm-12'>Tipologia contrattuale</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.tipoContratto}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.elencoIncarichiInCorso &&
                        <>
                          <dt className='col-sm-12'>Incarichi in corso</dt>
                          <dd className='col-sm-12'>{props.detailsResult.numeroIncarichiInCorso == 1 ? `${props.detailsResult.elencoIncarichiInCorso[0]}` : <ul key={props.detailsResult.id}>{props.detailsResult?.elencoIncarichiInCorso!.map(data => <li>{data}</li>)} </ul>}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.dataInizioRapporto &&
                        <>
                          <dt className='col-sm-12'>Data inizio rapporto</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.dataInizioRapporto}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.cessazione?.dataFineRapporto &&
                        <>
                          <dt className='col-sm-12'>Data fine rapporto</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.cessazione.dataFineRapporto}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.cessazione?.collaboratore &&
                        <>
                          <dt className='col-sm-12'>Collaboratore</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.cessazione.collaboratore ? 'SI' : 'NO'}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.cessazione?.motivoCessazione &&
                        <>
                          <dt className='col-sm-12'>Motivo cessazione</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.cessazione.motivoCessazione}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.cessazione?.numeroAttoCessazione &&
                        <>
                          <dt className='col-sm-12'>Numero atto cessazione</dt>
                          <dd className='col-sm-12'>{props.detailsResult?.cessazione.numeroAttoCessazione}</dd>
                        </>
                      }
                    </div>
                  </div>
                </dl>
                <dl className='col-sm-12 mb-1'>
                  <div className="row">
                    <div className="col-sm-12">
                      {props.detailsResult?.cessazione?.datiAtto &&
                        <DettaglioAtto datiAtto={props.detailsResult?.cessazione?.datiAtto} titolo='Dati Atto Cessazione'></DettaglioAtto>
                      }
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CessazioneDipendenteDetailsComponent;
import {ClassificazioniAtecoDto} from "../../../services/ms-anagrafica-unica";
import React from "react";
import {Table} from "design-react-kit";

const RIAtecoComponent = (dati: { value: ClassificazioniAtecoDto, inner?: boolean }) => {
    let css: string = "primary-bg text-white";
    if (dati.inner) {
        css = "";
    }
    return (<div className={"col-12 text-center"}>
            <h5><b>Classificazione ATECORI 2007-2022</b></h5>
            <Table striped responsive>
                <thead className={css}>
                <tr>
                    <th>Codice</th>
                    <th>Attivita'</th>
                    <th>Codice importanza</th>
                    <th>Importanza</th>
                </tr>
                </thead>
                <tbody>
                {(dati.value["classificazione-ateco"] ?? []).map((x) =>
                    <tr key={x.value}>
                        <td>{dati.value.codifica ?? "-"}</td>
                        <td>{x.attivita ?? "-"}</td>
                        <td>{x["c-importanza"] ?? "-"}</td>
                        <td>{x.importanza ?? "-"}</td>
                    </tr>)}
                </tbody>
            </Table>

        </div>
    );
}

export default RIAtecoComponent;
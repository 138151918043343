/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NullaOstaDto } from '../model';
// @ts-ignore
import { NullaOstaRichiedibileDto } from '../model';
// @ts-ignore
import { PagedDtoNullaOstaDto } from '../model';
// @ts-ignore
import { ValutaRichiestaNullaOstaRequestDto } from '../model';
/**
 * NullaOstaControllerApi - axios parameter creator
 * @export
 */
export const NullaOstaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per il dettaglio di un nulla osta
         * @summary Dettaglio nulla osta
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettagliNullaOsta: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dettagliNullaOsta', 'id', id)
            const localVarPath = `/v1/nulla-osta/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento di una nuova richiesta di nulla osta
         * @summary Inserimento richiesta nulla osta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertNullaOsta: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nulla-osta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero del flag nulla osta richiedibile
         * @summary Flag nulla osta richiedibile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nullaOstaRichiedibile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nulla-osta/richiedibile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca delle richieste di nulla osta
         * @summary Ricerca nulla osta
         * @param {string} [idSo] 
         * @param {string} [nomeCognomeDipendente] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNullaOsta: async (idSo?: string, nomeCognomeDipendente?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nulla-osta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idSo !== undefined) {
                localVarQueryParameter['idSo'] = idSo;
            }

            if (nomeCognomeDipendente !== undefined) {
                localVarQueryParameter['nomeCognomeDipendente'] = nomeCognomeDipendente;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API REST per la valutazione da parte del direttore di una richiesta nulla osta
         * @summary Patch valutazione richiesta nulla osta
         * @param {number} id 
         * @param {ValutaRichiestaNullaOstaRequestDto} valutaRichiestaNullaOstaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneDirettoreNullaOsta: async (id: number, valutaRichiestaNullaOstaRequestDto: ValutaRichiestaNullaOstaRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('valutazioneDirettoreNullaOsta', 'id', id)
            // verify required parameter 'valutaRichiestaNullaOstaRequestDto' is not null or undefined
            assertParamExists('valutazioneDirettoreNullaOsta', 'valutaRichiestaNullaOstaRequestDto', valutaRichiestaNullaOstaRequestDto)
            const localVarPath = `/v1/nulla-osta/{id}/valutazione-direttore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valutaRichiestaNullaOstaRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API REST per la valutazione da parte del dirigente di sezione di una richiesta nulla osta
         * @summary Patch valutazione richiesta dirigente nulla osta
         * @param {number} id 
         * @param {ValutaRichiestaNullaOstaRequestDto} valutaRichiestaNullaOstaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneDirigenteNullaOsta: async (id: number, valutaRichiestaNullaOstaRequestDto: ValutaRichiestaNullaOstaRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('valutazioneDirigenteNullaOsta', 'id', id)
            // verify required parameter 'valutaRichiestaNullaOstaRequestDto' is not null or undefined
            assertParamExists('valutazioneDirigenteNullaOsta', 'valutaRichiestaNullaOstaRequestDto', valutaRichiestaNullaOstaRequestDto)
            const localVarPath = `/v1/nulla-osta/{id}/valutazione-dirigente`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valutaRichiestaNullaOstaRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NullaOstaControllerApi - functional programming interface
 * @export
 */
export const NullaOstaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NullaOstaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per il dettaglio di un nulla osta
         * @summary Dettaglio nulla osta
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dettagliNullaOsta(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NullaOstaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dettagliNullaOsta(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento di una nuova richiesta di nulla osta
         * @summary Inserimento richiesta nulla osta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertNullaOsta(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NullaOstaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertNullaOsta(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero del flag nulla osta richiedibile
         * @summary Flag nulla osta richiedibile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nullaOstaRichiedibile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NullaOstaRichiedibileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nullaOstaRichiedibile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca delle richieste di nulla osta
         * @summary Ricerca nulla osta
         * @param {string} [idSo] 
         * @param {string} [nomeCognomeDipendente] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchNullaOsta(idSo?: string, nomeCognomeDipendente?: string, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoNullaOstaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchNullaOsta(idSo, nomeCognomeDipendente, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API REST per la valutazione da parte del direttore di una richiesta nulla osta
         * @summary Patch valutazione richiesta nulla osta
         * @param {number} id 
         * @param {ValutaRichiestaNullaOstaRequestDto} valutaRichiestaNullaOstaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valutazioneDirettoreNullaOsta(id: number, valutaRichiestaNullaOstaRequestDto: ValutaRichiestaNullaOstaRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NullaOstaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valutazioneDirettoreNullaOsta(id, valutaRichiestaNullaOstaRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API REST per la valutazione da parte del dirigente di sezione di una richiesta nulla osta
         * @summary Patch valutazione richiesta dirigente nulla osta
         * @param {number} id 
         * @param {ValutaRichiestaNullaOstaRequestDto} valutaRichiestaNullaOstaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valutazioneDirigenteNullaOsta(id: number, valutaRichiestaNullaOstaRequestDto: ValutaRichiestaNullaOstaRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NullaOstaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valutazioneDirigenteNullaOsta(id, valutaRichiestaNullaOstaRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NullaOstaControllerApi - factory interface
 * @export
 */
export const NullaOstaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NullaOstaControllerApiFp(configuration)
    return {
        /**
         * API Rest per il dettaglio di un nulla osta
         * @summary Dettaglio nulla osta
         * @param {NullaOstaControllerApiDettagliNullaOstaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettagliNullaOsta(requestParameters: NullaOstaControllerApiDettagliNullaOstaRequest, options?: AxiosRequestConfig): AxiosPromise<NullaOstaDto> {
            return localVarFp.dettagliNullaOsta(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento di una nuova richiesta di nulla osta
         * @summary Inserimento richiesta nulla osta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertNullaOsta(options?: AxiosRequestConfig): AxiosPromise<NullaOstaDto> {
            return localVarFp.insertNullaOsta(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero del flag nulla osta richiedibile
         * @summary Flag nulla osta richiedibile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nullaOstaRichiedibile(options?: AxiosRequestConfig): AxiosPromise<NullaOstaRichiedibileDto> {
            return localVarFp.nullaOstaRichiedibile(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca delle richieste di nulla osta
         * @summary Ricerca nulla osta
         * @param {NullaOstaControllerApiSearchNullaOstaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNullaOsta(requestParameters: NullaOstaControllerApiSearchNullaOstaRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoNullaOstaDto> {
            return localVarFp.searchNullaOsta(requestParameters.idSo, requestParameters.nomeCognomeDipendente, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API REST per la valutazione da parte del direttore di una richiesta nulla osta
         * @summary Patch valutazione richiesta nulla osta
         * @param {NullaOstaControllerApiValutazioneDirettoreNullaOstaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneDirettoreNullaOsta(requestParameters: NullaOstaControllerApiValutazioneDirettoreNullaOstaRequest, options?: AxiosRequestConfig): AxiosPromise<NullaOstaDto> {
            return localVarFp.valutazioneDirettoreNullaOsta(requestParameters.id, requestParameters.valutaRichiestaNullaOstaRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API REST per la valutazione da parte del dirigente di sezione di una richiesta nulla osta
         * @summary Patch valutazione richiesta dirigente nulla osta
         * @param {NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneDirigenteNullaOsta(requestParameters: NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest, options?: AxiosRequestConfig): AxiosPromise<NullaOstaDto> {
            return localVarFp.valutazioneDirigenteNullaOsta(requestParameters.id, requestParameters.valutaRichiestaNullaOstaRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dettagliNullaOsta operation in NullaOstaControllerApi.
 * @export
 * @interface NullaOstaControllerApiDettagliNullaOstaRequest
 */
export interface NullaOstaControllerApiDettagliNullaOstaRequest {
    /**
     * 
     * @type {number}
     * @memberof NullaOstaControllerApiDettagliNullaOsta
     */
    readonly id: number
}

/**
 * Request parameters for searchNullaOsta operation in NullaOstaControllerApi.
 * @export
 * @interface NullaOstaControllerApiSearchNullaOstaRequest
 */
export interface NullaOstaControllerApiSearchNullaOstaRequest {
    /**
     * 
     * @type {string}
     * @memberof NullaOstaControllerApiSearchNullaOsta
     */
    readonly idSo?: string

    /**
     * 
     * @type {string}
     * @memberof NullaOstaControllerApiSearchNullaOsta
     */
    readonly nomeCognomeDipendente?: string

    /**
     * 
     * @type {number}
     * @memberof NullaOstaControllerApiSearchNullaOsta
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof NullaOstaControllerApiSearchNullaOsta
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof NullaOstaControllerApiSearchNullaOsta
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof NullaOstaControllerApiSearchNullaOsta
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof NullaOstaControllerApiSearchNullaOsta
     */
    readonly q?: string
}

/**
 * Request parameters for valutazioneDirettoreNullaOsta operation in NullaOstaControllerApi.
 * @export
 * @interface NullaOstaControllerApiValutazioneDirettoreNullaOstaRequest
 */
export interface NullaOstaControllerApiValutazioneDirettoreNullaOstaRequest {
    /**
     * 
     * @type {number}
     * @memberof NullaOstaControllerApiValutazioneDirettoreNullaOsta
     */
    readonly id: number

    /**
     * 
     * @type {ValutaRichiestaNullaOstaRequestDto}
     * @memberof NullaOstaControllerApiValutazioneDirettoreNullaOsta
     */
    readonly valutaRichiestaNullaOstaRequestDto: ValutaRichiestaNullaOstaRequestDto
}

/**
 * Request parameters for valutazioneDirigenteNullaOsta operation in NullaOstaControllerApi.
 * @export
 * @interface NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest
 */
export interface NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest {
    /**
     * 
     * @type {number}
     * @memberof NullaOstaControllerApiValutazioneDirigenteNullaOsta
     */
    readonly id: number

    /**
     * 
     * @type {ValutaRichiestaNullaOstaRequestDto}
     * @memberof NullaOstaControllerApiValutazioneDirigenteNullaOsta
     */
    readonly valutaRichiestaNullaOstaRequestDto: ValutaRichiestaNullaOstaRequestDto
}

/**
 * NullaOstaControllerApi - object-oriented interface
 * @export
 * @class NullaOstaControllerApi
 * @extends {BaseAPI}
 */
export class NullaOstaControllerApi extends BaseAPI {
    /**
     * API Rest per il dettaglio di un nulla osta
     * @summary Dettaglio nulla osta
     * @param {NullaOstaControllerApiDettagliNullaOstaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NullaOstaControllerApi
     */
    public dettagliNullaOsta(requestParameters: NullaOstaControllerApiDettagliNullaOstaRequest, options?: AxiosRequestConfig) {
        return NullaOstaControllerApiFp(this.configuration).dettagliNullaOsta(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento di una nuova richiesta di nulla osta
     * @summary Inserimento richiesta nulla osta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NullaOstaControllerApi
     */
    public insertNullaOsta(options?: AxiosRequestConfig) {
        return NullaOstaControllerApiFp(this.configuration).insertNullaOsta(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero del flag nulla osta richiedibile
     * @summary Flag nulla osta richiedibile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NullaOstaControllerApi
     */
    public nullaOstaRichiedibile(options?: AxiosRequestConfig) {
        return NullaOstaControllerApiFp(this.configuration).nullaOstaRichiedibile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca delle richieste di nulla osta
     * @summary Ricerca nulla osta
     * @param {NullaOstaControllerApiSearchNullaOstaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NullaOstaControllerApi
     */
    public searchNullaOsta(requestParameters: NullaOstaControllerApiSearchNullaOstaRequest = {}, options?: AxiosRequestConfig) {
        return NullaOstaControllerApiFp(this.configuration).searchNullaOsta(requestParameters.idSo, requestParameters.nomeCognomeDipendente, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API REST per la valutazione da parte del direttore di una richiesta nulla osta
     * @summary Patch valutazione richiesta nulla osta
     * @param {NullaOstaControllerApiValutazioneDirettoreNullaOstaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NullaOstaControllerApi
     */
    public valutazioneDirettoreNullaOsta(requestParameters: NullaOstaControllerApiValutazioneDirettoreNullaOstaRequest, options?: AxiosRequestConfig) {
        return NullaOstaControllerApiFp(this.configuration).valutazioneDirettoreNullaOsta(requestParameters.id, requestParameters.valutaRichiestaNullaOstaRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API REST per la valutazione da parte del dirigente di sezione di una richiesta nulla osta
     * @summary Patch valutazione richiesta dirigente nulla osta
     * @param {NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NullaOstaControllerApi
     */
    public valutazioneDirigenteNullaOsta(requestParameters: NullaOstaControllerApiValutazioneDirigenteNullaOstaRequest, options?: AxiosRequestConfig) {
        return NullaOstaControllerApiFp(this.configuration).valutazioneDirigenteNullaOsta(requestParameters.id, requestParameters.valutaRichiestaNullaOstaRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

import {LocalizzazioneDto} from "../../../services/ms-anagrafica-unica";
import React, {useState} from "react";
import {Table} from "design-react-kit";
import {Button} from "reactstrap";
import RIAtecoComponent from "../ri-atecori-component/RIAtecoComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";


const RISediComponent = (dati: { value: Array<LocalizzazioneDto> }) => {

    const initialState = dati.value.map(() => false);
    const [rowsVisibility, setRowsVisibility] = useState<boolean[]>(initialState);

    function handleClick(index: number) {
        let rows = !rowsVisibility[index] ? [...initialState] : [...rowsVisibility];
        rows[index] = !rowsVisibility[index];
        setRowsVisibility([...rows])
    }

    return (
        <div className={"col-12 pt-5"}>
            <Table striped responsive key={"table"}>
                <thead className={"primary-bg text-white"}>
                <tr>
                    <td></td>
                    <th>Via</th>
                    <th>Comune</th>
                    <th>Provincia</th>
                    <th>CAP</th>
                    <th>Data apertura</th>
                    <th>Tipo sede</th>
                </tr>
                </thead>
                <tbody>
                {(dati.value || []).map((x, index) =>
                    <React.Fragment key={x["indirizzo-localizzazione"]?.via + ", " + x["indirizzo-localizzazione"]?.["n-civico"] || "-"}>
                        <tr>
                            <td>
                                <Button outline color='primary' onClick={() => handleClick(index)}>
                                    {rowsVisibility[index]
                                        ? <FontAwesomeIcon icon={faCaretUp}/>
                                        : <FontAwesomeIcon icon={faCaretDown}/>}</Button>
                            </td>
                            <td>{x["indirizzo-localizzazione"]?.via + ", " + x["indirizzo-localizzazione"]?.["n-civico"] || "-"}</td>
                            <td>{x["indirizzo-localizzazione"]?.comune ?? "-"}</td>
                            <td>{x["indirizzo-localizzazione"]?.provincia ?? "-"}</td>
                            <td>{x["indirizzo-localizzazione"]?.cap ?? "-"}</td>
                            <td>{x["dt-apertura"] ?? "-"}</td>
                            <td>{x.tipo}</td>
                        </tr>

                        {rowsVisibility[index] && <tr>
                            <td colSpan={7}><RIAtecoComponent inner value={x["classificazione-ateco"]!}/></td>
                        </tr>}

                    </React.Fragment>)}
                </tbody>
            </Table>
        </div>
    );
}

export default RISediComponent;
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { z } from 'zod'
import { detailsRuolo, resetUpdateForm, updateRuolo } from '../../store/ruoloSlice';
import { FormikProps, useFormik } from 'formik';
import { AnagraficaRuoloModificaRequestDto, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Button, Input, TextArea } from 'design-react-kit';
import { HREF_ELENCO_RUOLI } from '../../components/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import PageHeader from '../../components/page-header/PageHeader';

const schema = z
    .object({
        nome: z.string().max(50, 'lenghtnomeruolo').optional(),
        descrizione: z.string().max(150, 'lenghtdescrizioneruolo'),
    })

const initialValues: AnagraficaRuoloModificaRequestDto = {
    nome: '',
    descrizione: '',
    requestType: 'Modifica',
}

const ModificaRuolo = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { detailsResult, detailsStatus, updateStatus } = useAppSelector((state) => state.ruolo)

    useEffect(() => {
        dispatch(resetUpdateForm())
        getDetailsFromApi(id!)
    }, [0])

    const getDetailsFromApi = (id: string) => {
        dispatch(detailsRuolo(Number.parseInt(id)));
    }

    const updateValue: FormikProps<AnagraficaRuoloModificaRequestDto> = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            doUpdate();
        },
    });

    const doUpdate = () => {
        const body = {
            idRuolo: detailsResult?.id,
            bodyRequest: updateValue.values
        }
        dispatch(updateRuolo(body))
    }

    const showModificaRuoloOperations = () => props.operations?.elencoFunzionalita?.includes('RUOLO_PATCH')

    useEffect(() => {
        updateValue.setValues({
            nome: detailsResult?.nome || '',
            descrizione: detailsResult?.descrizione || '',
            requestType: 'Modifica'
        })
    }, [detailsResult])

    const getFieldError = (
        form: FormikProps<AnagraficaRuoloModificaRequestDto>,
        fieldName: keyof AnagraficaRuoloModificaRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_RUOLI} title={'Modifica Ruolo'} />
            {detailsStatus === STATUS_FULLFILLED &&
                <div className="mt-5 position-relative">
                    <Input
                        type="text"
                        label="Codice"
                        id="input-codice"
                        name="nome"
                        value={detailsResult?.codice != undefined ? detailsResult?.codice : ''}
                        onChange={updateValue.handleChange}
                        wrapperClassName="form-group  col-md-12"
                        disabled={true}
                    />
                    <Input
                        type="text"
                        label="Ruolo"
                        placeholder="Inserisci il ruolo"
                        id="input-nome"
                        name="nome"
                        disabled={true}                        
                        value={updateValue.values.nome}
                        onChange={updateValue.handleChange}
                        wrapperClassName="form-group  col-md-12"
                        invalid={!!getFieldError(updateValue, "nome")}
                        infoText={t(getFieldError(updateValue, "nome")) || ""}
                    />

                    <TextArea
                       
                        label="Descrizione"
                        placeholder="Inserisci la descrizione"
                        id="input-descrizione"
                        name="descrizione"
                        value={updateValue.values.descrizione}
                        onChange={updateValue.handleChange}
                        wrapperClassName="form-group  col-md-12"
                        invalid={!!getFieldError(updateValue, "descrizione")}
                        infoText={t(getFieldError(updateValue, "descrizione")) || ""}
                    />
                    <div className="d-flex flex-row-reverse">
                        {<Button color='primary' className="mt-2 mr-2" disabled={!showModificaRuoloOperations()} onClick={() => { updateValue.submitForm() }}>Salva</Button>}
                        <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_RUOLI}`) }} >{t('annulla')} </Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default ModificaRuolo
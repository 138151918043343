import { Badge, DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Table, UncontrolledDropdown } from "design-react-kit";
import format from "date-fns/format";
import { ModalType, ScrivaniaVirtualeModale } from "../scrivania-virtuale-modal/ScrivaniaVirtualeModal";
import { useState } from "react";
import { parseISO } from "date-fns";
import { NotificaDtoStatoEnum, PagedDtoNotificaDto } from "../../services/ms-anagrafica-unica";
import { BsEnvelope, BsEnvelopeOpen, BsArchive} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { HREF_FEEDBACK_NOTIFICA } from "../sidemenu/sidemenuConstants";
import { useAppDispatch } from "../../hooks";
import { resetInputForm, searchNotifiche } from "../../store/notificheSlice";
import DocumentView from "../document-view/DocumentView";

export interface NotificationTableComponentProps {
    searchResult: PagedDtoNotificaDto
    showAggiornaStatoNotifica: boolean,
    showEliminaNotifica: boolean,
    showElencoFeedback: boolean,
    showFornisciFeedback: boolean,
}

const NotificationTableComponent = ({ searchResult, showAggiornaStatoNotifica, showEliminaNotifica, showElencoFeedback, showFornisciFeedback }: NotificationTableComponentProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [id, setId] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [body, setBody] = useState<string>();
    const [actionType, setActionType] = useState<ModalType>();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const inoltra = (id?: number, actionType?: ModalType) => {
        console.log(id);

        setId(String(id));
        setIsOpen(true);
        switch (actionType) {
            case ModalType.SegnaDaLeggere:
                setTitle('Conferma Segna da leggere');
                setBody('Sei sicuro di voler cambiare lo stato della notifica in: Segna da leggere?');
                setActionType(actionType)

                break;
            case ModalType.SegnaComeLetto:
                setTitle('Conferma Segna come letto');
                setBody('Sei sicuro di voler cambiare lo stato della notifica in: Segna come letto?');
                setActionType(actionType)
                //dispatch(searchNotifiche({}));
                break;
            case ModalType.Archivia:
                setTitle('Conferma archiviazione');
                setBody('Sei sicuro di voler archiviare la notifica?');
                setActionType(actionType)
                break;
            case ModalType.Elimina:
                setTitle('Conferma eliminazione');
                setBody('Sei sicuro di voler eliminare la notifica?');
                setActionType(actionType)
                break;
            default:
                break;
        }

    }

    const resetModalProps = () => {
        setId('');
        setIsOpen(false);
        setTitle('');
        setBody('');
        setActionType(undefined);
    }

    function replaceUrisInHyperlinks(inputText: string): string {
        const uriPattern = /\b((?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|])/g;
    
        const replacedText = inputText.replace(uriPattern, (match, uri) => {
            return `<a href="${uri}" target="_blank">${uri}</a>`;
        });
    
        return replacedText;
    }

    const returnListLinkItem = (id?: number, stato?: NotificaDtoStatoEnum) => {
        switch (stato) {
            case NotificaDtoStatoEnum.NonLetta:
                return (
                    <LinkListItem size="medium" onClick={() => inoltra(id, ModalType.SegnaComeLetto)}>
                        <span>Segna come letto</span>
                    </LinkListItem>
                )
            case NotificaDtoStatoEnum.Letta:
                return (
                    <LinkListItem size="medium" onClick={() => inoltra(id, ModalType.SegnaDaLeggere)}>
                        <span>Segna da leggere</span>
                    </LinkListItem>
                )
            case NotificaDtoStatoEnum.Archiviata:
                break;
            default:
                break;
        }

    }

    const returnStatusIcon = (stato?: NotificaDtoStatoEnum) => {
        switch (stato) {
            case NotificaDtoStatoEnum.NonLetta:
                return (
                    <div style={{fontSize: '24px'}}><BsEnvelope title={"Notifica non letta"}/></div>
                    )
            case NotificaDtoStatoEnum.Letta:
                return (
                    <div style={{fontSize: '24px'}}><BsEnvelopeOpen title={"Notifica letta"} /></div>
                )
            case NotificaDtoStatoEnum.Archiviata:
                return (
                    <div style={{fontSize: '24px'}}><BsArchive title={"Notifica archiviata"} /></div>
                )
            default:
                break;
        }
    }

    return (
        <>
            <Table striped responsive>
                <thead>
                    <tr>
                        <th scope="col">
                            Titolo
                        </th>
                        <th scope="col">
                            Testo
                        </th>
                        <th scope="col">
                            Data
                        </th>
                        <th scope="col">
                            Feedback da
                        </th>
                        <th scope="col">
                            Data feedback
                        </th>
                        <th scope="col">
                            Allegati
                        </th>
                        <th scope="col">
                            Stato
                        </th>
                        <th scope="col">
                            Azioni
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(searchResult.data || []).map((x, i) =>
                        <tr key={x?.id}>
                            <td>{x?.oggetto}</td>
                            <td dangerouslySetInnerHTML={{ __html: replaceUrisInHyperlinks(x?.testo!)} } />
                            <td>{x.dataInserimento != null ? format(parseISO(x.dataInserimento!), 'dd/MM/yyyy HH:mm') : ''}</td>
                            <td>{x?.ultimoFeedback != null ? x.ultimoFeedback.fattoDa : '-'}</td>
                            <td>
                                {x?.ultimoFeedback != null ? format(parseISO(x.ultimoFeedback?.dataInserimento!), 'dd/MM/yyyy HH:mm') : ''}
                            </td>
                            <td>
                                {x.allegati && x.allegati.length > 0 &&
                                <LinkList>
                                    {x.allegati.map( (a, i) => <DocumentView key={i} documentName={a.tipo!} idDocumento={a.documentoDto?.id} compact></DocumentView>)}
                                </LinkList>
                                }
                            </td>
                            <td title={x.stato} className="text-center" >
                                {returnStatusIcon(x.stato)}
                            </td>
                            <td>
                                {(showAggiornaStatoNotifica || showEliminaNotifica || showFornisciFeedback || showElencoFeedback) &&
                                <UncontrolledDropdown direction="left">
                                    <DropdownToggle title="Apri menu azioni" nav>
                                        <Badge color='primary'><Icon icon="it-more-actions" aria-label={'Apri menu azioni'} color="white"/></Badge>
                                    </DropdownToggle>
                                    <DropdownMenu className="no-arrow">
                                        {showAggiornaStatoNotifica && <>
                                        <LinkList>
                                            {returnListLinkItem(x?.id, x.stato)}
                                        </LinkList>
                                        <LinkList>
                                            {x.stato != null && x.stato === NotificaDtoStatoEnum.Archiviata ?
                                                ''
                                                :
                                                <LinkListItem size="medium" onClick={() => inoltra(x.id!, ModalType.Archivia)}>
                                                    <span>Archivia</span>
                                                </LinkListItem>
                                            }

                                        </LinkList>
                                        </>}
                                        {showEliminaNotifica && <LinkList>
                                            <LinkListItem size="medium" onClick={() => inoltra(x.id!, ModalType.Elimina)}>
                                                <span>Elimina</span>
                                            </LinkListItem>
                                        </LinkList>}
                                        {showFornisciFeedback && x.feedbackInseribile &&(
                                            <LinkList>
                                                <LinkListItem size="medium" onClick={() => {
                                                    dispatch(resetInputForm());
                                                    console.log('feedback');
                                                    navigate(`${HREF_FEEDBACK_NOTIFICA}/${x.id}`)
                                                }}>
                                                    <span>Fornisci feedback</span>
                                                </LinkListItem>
                                            </LinkList>
                                        )}
                                        {showElencoFeedback && x.ultimoFeedback && (
                                            <LinkList>
                                                <LinkListItem size="medium" onClick={() => {
                                                    setTitle('Testo feedback');
                                                    setBody(x.ultimoFeedback?.testo);
                                                    setActionType(undefined);
                                                    setIsOpen(true);
                                                }}>
                                                    <span>Visualizza feedback</span>
                                                </LinkListItem>
                                            </LinkList>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <ScrivaniaVirtualeModale open={isOpen} resetModalProps={resetModalProps} id={id} title={title} body={body} action={actionType} />
        </>)
}

export default NotificationTableComponent;
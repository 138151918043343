import { Alert, Col, Input, Table, TextArea } from 'design-react-kit'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/sidemenu/sidemenuConstants';
import { FormsControllerApi, SODisassociaRequestDto } from '../../services/ms-anagrafica-unica';
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { associazioneSoDipentente, detailsAssociazioneSoDipendente, detailsStruttureOrganizzative, disassociaStrutturaDipendente, resetDetailsAssocazioneSoDipendente, resetUpdateForm } from '../../store/strutturaOrganizzativaSlice';
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import CustomAutocomplete from '../../components/custom-autocomplete/CustomAutocomplete';
import {debounce} from 'lodash'
import PageHeader from '../../components/page-header/PageHeader';
import { CITTA, CODICE, DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, DIPARTIMENTO, EMAIL, FAX, INDIRIZZO, PEC, TELEFONO, TIPOLOGIA_STRUTTURA } from './struttureOrganizzativeConstants';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail-form/StruttureOrganizzativeDetailsForm';


const initialValues: SODisassociaRequestDto = {
  idDipendente: undefined,
  dataFineValidita: '',
  attoAutorizzativo: undefined,
  requestType: 'Disassocia'
}

const schema = z
  .object({
    idDipendente: z.number({ required_error: 'required' }),
    dataFineValidita: z.coerce.date(),
    attoAutorizzativo: z.string({ required_error: 'required' })
  })

const DisassociazioneStrutturaOrganizzativa = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const updateStatus = useAppSelector((state) => state.strutturaOrganizzativa.updateStatus)
  const detailsDipendenteAssociato = useAppSelector((state) => state.strutturaOrganizzativa.assegnazioneSoDipendenteResult)
  const fieldUsed: Array<string> = [CODICE, DENOMINAZIONE, DECLARATORIA, DATA_INIZIO_VALIDITA, TIPOLOGIA_STRUTTURA, DIPARTIMENTO, EMAIL, PEC, TELEFONO, FAX, INDIRIZZO, CITTA];
  const strutturaOrganizzativaResult = useAppSelector((state) => state.strutturaOrganizzativa.detailsStatus);
  const strutturaOrganizzativa = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult);

  useEffect(() => {
    dispatch(resetUpdateForm())
    getDetailsFromApi(id!)
  }, [0])

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  }

  const formik: FormikProps<SODisassociaRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: values => {
      console.log('Submit form', JSON.stringify(values, null, 2));
      disassociaSoDipendenti()
    }
  })

  const getFieldError = (
    form: FormikProps<SODisassociaRequestDto>,
    fieldName: keyof SODisassociaRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || "";
    } else return "";
  };

  const submitConfirm = () => {
    setTimeout(() => {
      formik.submitForm();
    }, 500);
  }

  const handleChange = (name: string, selectedOption: any) => {
    formik.setFieldValue(name, selectedOption);
    console.log(name, selectedOption)
    if (selectedOption != '') {
      const body: associazioneSoDipentente = {
        idDipendente: selectedOption,
        idIncarico: id!
      }
      dispatch(detailsAssociazioneSoDipendente(body))
    } else {
      dispatch(resetDetailsAssocazioneSoDipendente())
    }
  };

  const disassociaSoDipendenti = () => {
    const body = {
      idStrutturaOrganizzativa: id,
      bodyRequest: formik.values
    }

    dispatch(disassociaStrutturaDipendente(body));
  }

  const autocompleteDipendenti = debounce(async (inputValue: string, callback: any) => {
    const api = new FormsControllerApi(MS_AU_CONFIG);
    const response = await api.getDisassociaIncarichiContestoFormSuBaseDipendente({
        idIncarico: id!,
        chiave:inputValue
    });
    let options: { value: number | undefined; label: string | undefined }[] = [];
    if (response.data.dipendenti) {
        response.data.dipendenti?.data?.forEach(value => {
            options.push({ value: value.id, label: value.nomeCompleto });
        })
    }
    callback(options)
}, 500)




  useEffect(() => {
    if (updateStatus === STATUS_FULLFILLED) {
      formik.resetForm();
      dispatch(resetDetailsAssocazioneSoDipendente())
      formik.setFieldValue('attoAutorizzativo', '')
    }
  }, [updateStatus])

  useEffect(() => {
    formik.setFieldValue('dataFineValidita', detailsDipendenteAssociato?.dataFine || '')
  }, [detailsDipendenteAssociato])

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} title={t("disassociazione struttura organizzativa")!}></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED && <div>
                <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
        <div className="form-row">
          <Col md="12">
            <CustomAutocomplete
              required={true}
              label="Dipendente"
              placeholder="Cerca un dipendente"
              id="select-dipendente"
              loadOptionsFn={autocompleteDipendenti}
              handleOptionSelect={(e: string) => handleChange("idDipendente", e)}
              invalid={!!getFieldError(formik, "idDipendente")}
              infoText={t(getFieldError(formik, "idDipendente")) || ""}
            />
          </Col>
          <TextArea
            label="Atto Autorizzativo"
            placeholder="Inserisci l' atto autorizzativo"
            id="input-attoAutorizzativo"
            wrapperClassName="form-group required col-md-12"
            name="attoAutorizzativo"
            value={formik.values.attoAutorizzativo}
            onChange={formik.handleChange}
            invalid={!!getFieldError(formik, "attoAutorizzativo")}
            infoText={t(getFieldError(formik, "attoAutorizzativo")) || ""}
          />
          <Input
            type="date"
            label="Data inizio validità"
            placeholder="Inserisci la data di inizio Validità"
            id="input-dataInizioValidita"
            name="dataInizioValidita"
            value={detailsDipendenteAssociato?.dataInizio != null ? detailsDipendenteAssociato?.dataInizio : ''}
            onChange={() => { }}
            disabled={true}
            wrapperClassName="form-group col-md-6"
          />
          <Input
            type="date"
            label="Data fine validità"
            placeholder="Inserisci la data di fine Validità"
            id="input-dataFineValidita"
            name="dataFineValidita"
            value={formik.values.dataFineValidita}
            onChange={formik.handleChange}
            min={detailsDipendenteAssociato?.dataInizio != null ? detailsDipendenteAssociato?.dataInizio : ''}
            wrapperClassName="form-group required col-md-6"
            invalid={!!getFieldError(formik, "dataFineValidita")}
            infoText={t(getFieldError(formik, "dataFineValidita")) || ""}
          />
        </div>
      </div>
      }

      <div className="postion-relative py-5">
        <div className="d-flex flex-row-reverse">
          <Button color='primary' className="mt-2" onClick={() => { submitConfirm() }}>{t('conferma')}</Button>
          <Button color='secondary' outline className="mt-2 mr-2" onClick={() => navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`)} >{t('annulla')} </Button>
        </div>
      </div>
    </div>
  )
}

export default DisassociazioneStrutturaOrganizzativa
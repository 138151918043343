import React, { useEffect } from 'react'
import CandidatoDetailsComponent from '../../components/candidato-detail-form/CandidatoDetailsComponent'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { CODICE_FISCALE, COGNOME, EMAIL, NOME, NOTE_CANDIDATO, PEC } from './candidatiConstants';
import { Alert, Button } from 'design-react-kit';
import { useNavigate, useParams } from 'react-router-dom';
import { cessazioneContratto, detailsCandidato } from '../../store/candidatoSlice';
import PageHeader from '../../components/page-header/PageHeader';
import { HREF_ELENCO_CANDIDATI } from '../../components/sidemenu/sidemenuConstants';
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import NotificationManagerComponent from '../../components/notificationComponent/NotificationManagerComponent';
import PageLoader from '../../components/page-loader/PageLoader';

const GestioneCessazioneContratto = () => {
    const { detailsResult, detailsStatus, updateStatus } = useAppSelector((state) => state.candidato);
    const fieldUsed: Array<string> = [COGNOME, NOME, CODICE_FISCALE, EMAIL, PEC, NOTE_CANDIDATO];
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(detailsCandidato(Number.parseInt(id!)))
    }, [])

    const confermaCessazione = () => {
        dispatch(cessazioneContratto(Number.parseInt(id!)))
    }

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={"Gestione cessazione contratto"}></PageHeader>
            {detailsStatus == STATUS_FULLFILLED && <div>
                <div className='mt-3'>

                    <div className="row">
                        <CandidatoDetailsComponent fileds={fieldUsed} value={detailsResult!} />
                    </div>
                    <div className="d-flex flex-row-reverse mt-5">
                        <Button color='primary' className="mt-2" onClick={() => { confermaCessazione() }}>Conferma</Button>
                        <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(HREF_ELENCO_CANDIDATI) }} >Indietro</Button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default GestioneCessazioneContratto
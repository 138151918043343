import { Button, Col, Input } from 'design-react-kit';
import  { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { HREF_CONSULENTE_ESTERNO_ELENCO } from '../../components/sidemenu/sidemenuConstants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { detailConsulenteEsterno, editConsulenteEsterno, resetEditStatus, resetInfoDettaglio } from '../../store/consulentiEsterniSlice';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { PatchConsulenteEsternoRequestDto, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { t } from 'i18next';
import PageHeader from '../../components/page-header/PageHeader';
import ConsulenteEsternoDetailsComponent from '../../components/consulente-esterno-component/ConsulenteEsternoDetailsComponent';

const ModificaConsulenteEsterno = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {detailResult,detailStatus} = useAppSelector((state) => state.consulentiEsterni);

    useEffect(() => {
        dispatch(resetEditStatus());
        dispatch(resetInfoDettaglio());
        getDetailsFromApi();
    }, [0])

    const getId = () => {
        return Number(id!);
    }

    const handleChange = (name: string, selectedOption: any) => {
        console.log("selected options", selectedOption);
        if (selectedOption != "") {
            formik.setFieldValue(name, selectedOption,false);
        } else {
            formik.setFieldValue(name, undefined,false);
        }
    };

    const getDetailsFromApi = () => {
        let idDipendente = getId();
        dispatch(detailConsulenteEsterno(idDipendente));
    }


    const initialValues: PatchConsulenteEsternoRequestDto = {
        nome: '',
        cognome: '',
        cellulare: '',
        telefono: ''
    };

    const schema = z.object({
        nome: z.string().max(50),
        cognome: z.string().max(200),
        telefono: z.string().max(20).optional(),
        cellulare: z.string().max(30).optional()  
    })

    const formik: FormikProps<PatchConsulenteEsternoRequestDto> = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            dispatch(editConsulenteEsterno({
                idConsulente: detailResult?.id!,
                patchConsulenteEsternoRequestDto:  values 
            })).unwrap().then( resp => navigate(HREF_CONSULENTE_ESTERNO_ELENCO))
        }
    });

    const getFieldError = (
        form: FormikProps<PatchConsulenteEsternoRequestDto>,
        fieldName: keyof PatchConsulenteEsternoRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    useEffect(() => {
        detailResult && formik.setValues({
            nome: detailResult.nome || '',
            cognome: detailResult.cognome || '',
            cellulare: detailResult.cellulare || '',
            telefono: detailResult.telefono || '',
        })
    }, [detailResult]);

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_CONSULENTE_ESTERNO_ELENCO} title='Modifica consulente esterno' />
            {detailStatus === STATUS_FULLFILLED && !!detailResult &&
            <div>
                <div className="row">
                    <ConsulenteEsternoDetailsComponent dipendente={detailResult!} except={[]}></ConsulenteEsternoDetailsComponent>
                    <div className="col">
                        <div className="mt-5 position-relative">
                            <Col>
                                <Input
                                    label="Nome"
                                    type="text"
                                    placeholder="Inserisci nome"
                                    id="input-nome"
                                    wrapperClassName="form-group col-md-12 "
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={(e) => handleChange("nome", e.target.value)}
                                    invalid={!!getFieldError(formik, "nome")}
                                    infoText={t(getFieldError(formik, "nome")) || ""}
                                />
                            </Col>
                            <Col>
                                <Input
                                    label="Cognome"
                                    type="text"
                                    placeholder="Inserisci cognome"
                                    id="input-cognome"
                                    wrapperClassName="form-group col-md-12 "
                                    name="cognome"
                                    value={formik.values.cognome}
                                    onChange={(e) => handleChange("cognome", e.target.value)}
                                    invalid={!!getFieldError(formik, "cognome")}
                                    infoText={t(getFieldError(formik, "cognome")) || ""}
                                />
                            </Col>
                            <Col>
                                <Input
                                    label="Telefono"
                                    type="text"
                                    placeholder="Inserisci telefono"
                                    id="input-telefono"
                                    wrapperClassName="form-group col-md-12 "
                                    name="telefono"
                                    value={formik.values.telefono || ''}
                                    onChange={(e) => handleChange("telefono", e.target.value)}
                                    invalid={!!formik.errors.telefono}
                                    infoText={(t(formik.errors.telefono || "") || "")}
                                />
                            </Col>
                            <Col>
                                <Input
                                    label="Cellulare"
                                    type="text"
                                    placeholder="Inserisci cellulare"
                                    id="input-cellulare"
                                    wrapperClassName="form-group col-md-12 "
                                    name="cellulare"
                                    value={formik.values.cellulare || ''}
                                    onChange={(e) => handleChange("cellulare", e.target.value)}
                                    invalid={!!formik.errors.cellulare}
                                    infoText={(t(formik.errors.cellulare || "") || "")}
                                />
                            </Col>
                        </div>
                    </div>
                </div>
                <div className="postion-relative py-5">
                    <div className="d-flex flex-row-reverse pb-5">
                        <Button color='primary' className="mt-2" onClick={() => formik.submitForm()}>Conferma</Button>
                        <Button outline color='primary' className="mt-2 mr-2"
                            onClick={() => navigate(HREF_CONSULENTE_ESTERNO_ELENCO)}>Annulla</Button>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default ModificaConsulenteEsterno
import React, { useEffect } from 'react'
import { HREF_SCRIVANIA_VIRTUALE } from '../../components/sidemenu/sidemenuConstants'
import { Alert, Input, TextArea, Button } from 'design-react-kit'
import NotificationManagerComponent from '../../components/notificationComponent/NotificationManagerComponent'
import PageLoader from '../../components/page-loader/PageLoader'
import { STATUS_PENDING, STATUS_REJECTED, STATUS_FULLFILLED } from '../../store/store-constants'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { z } from 'zod'
import { toFormikValidationSchema } from "zod-formik-adapter";
import { FeedbackNotificaInsertRequestDto } from '../../services/ms-anagrafica-unica'
import { FormikProps, useFormik } from 'formik'
import { inserisciFeedbackNotifica, resetInputForm } from '../../store/notificheSlice'
import PageHeader from '../../components/page-header/PageHeader'

const initialValues: FeedbackNotificaInsertRequestDto = {
    testo: '',
}


const FeedbackNotifica = () => {

    const { t } = useTranslation()
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { insertStatus } = useAppSelector((state) => state.notifiche)

    useEffect(() => {
        dispatch(resetInputForm())
    }, [0])

    useEffect(() => {
        if (insertStatus == STATUS_FULLFILLED) {
            navigate(`${HREF_SCRIVANIA_VIRTUALE}`);
        }
    }, [insertStatus])

    const validationSchema = z
        .object({
            testo: z.string({ required_error: 'required' }).max(150, 'lenghtdescrizioneruolo')
        })

    const form: FormikProps<FeedbackNotificaInsertRequestDto> = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(validationSchema),
        onSubmit: (values) => {
            console.log("Submit form", JSON.stringify(values, null, 2));
            doInsert();
        },
    })

    const doInsert = () => {
        dispatch(inserisciFeedbackNotifica({
            idNotifica: Number(id!),
            feedbackNotificaInsertRequestDto: form.values
        }));
    };

    const getFieldError = (
        form: FormikProps<FeedbackNotificaInsertRequestDto>,
        fieldName: keyof FeedbackNotificaInsertRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };


    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_SCRIVANIA_VIRTUALE} title='Fornisci feedback'></PageHeader>
            <div className="mt-5 position-relative">
                <TextArea
                    label="Testo feedback"
                    placeholder="Inserisci il testo del feedback"
                    id="input-feedback"
                    name="testo"
                    value={form.values.testo}
                    onChange={form.handleChange}
                    wrapperClassName="form-group required col-md-12"
                    invalid={!!getFieldError(form, "testo")}
                    infoText={t(getFieldError(form, "testo")) || ""}
                />
                <div className="d-flex flex-row-reverse">
                    <Button color='primary' className="mt-2 mr-2" onClick={() => { form.submitForm() }}>Salva</Button>
                    <Button color='primary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_SCRIVANIA_VIRTUALE}`) }} >{t('annulla')} </Button>
                </div>
            </div>

        </div>
    )
}

export default FeedbackNotifica
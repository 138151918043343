/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PagedDtoDipartimentoDto } from '../model';
// @ts-ignore
import { StrutturaOrganizzativaOrganigrammaDto } from '../model';
/**
 * GestioneOrganigrammaControllerApi - axios parameter creator
 * @export
 */
export const GestioneOrganigrammaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per il recupero dell\'elenco dei dipartimenti nello storico
         * @summary Elenco Storico Dipartimenti
         * @param {number} [pageSize] 
         * @param {string} [dataInput] 
         * @param {number} [pageNum] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoStoricoDipartimenti: async (pageSize?: number, dataInput?: string, pageNum?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organigramma/dipartimenti/storico`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (dataInput !== undefined) {
                localVarQueryParameter['dataInput'] = (dataInput as any instanceof Date) ?
                    (dataInput as any).toISOString() :
                    dataInput;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero del dettaglio di una struttura Organizzativa nello Storico
         * @summary Dettaglio struttura Organizzativa Storico
         * @param {string} idStrutturaOrganizzativa 
         * @param {string} dataInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSOStorico: async (idStrutturaOrganizzativa: string, dataInput: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idStrutturaOrganizzativa' is not null or undefined
            assertParamExists('getSOStorico', 'idStrutturaOrganizzativa', idStrutturaOrganizzativa)
            // verify required parameter 'dataInput' is not null or undefined
            assertParamExists('getSOStorico', 'dataInput', dataInput)
            const localVarPath = `/v1/organigramma/struttureOrganizzative/storico/{idStrutturaOrganizzativa}`
                .replace(`{${"idStrutturaOrganizzativa"}}`, encodeURIComponent(String(idStrutturaOrganizzativa)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataInput !== undefined) {
                localVarQueryParameter['dataInput'] = (dataInput as any instanceof Date) ?
                    (dataInput as any).toISOString() :
                    dataInput;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero del dettaglio di una struttura Organizzativa
         * @summary Dettaglio struttura Organizzativa
         * @param {string} idStrutturaOrganizzativa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByIdOrganigramma: async (idStrutturaOrganizzativa: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idStrutturaOrganizzativa' is not null or undefined
            assertParamExists('searchByIdOrganigramma', 'idStrutturaOrganizzativa', idStrutturaOrganizzativa)
            const localVarPath = `/v1/organigramma/struttureOrganizzative/{idStrutturaOrganizzativa}`
                .replace(`{${"idStrutturaOrganizzativa"}}`, encodeURIComponent(String(idStrutturaOrganizzativa)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dell\'elenco dei dipartimenti
         * @summary Elenco Dipartimenti
         * @param {number} [pageSize] 
         * @param {number} [pageNum] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrganigramma: async (pageSize?: number, pageNum?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organigramma/dipartimenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GestioneOrganigrammaControllerApi - functional programming interface
 * @export
 */
export const GestioneOrganigrammaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GestioneOrganigrammaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per il recupero dell\'elenco dei dipartimenti nello storico
         * @summary Elenco Storico Dipartimenti
         * @param {number} [pageSize] 
         * @param {string} [dataInput] 
         * @param {number} [pageNum] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elencoStoricoDipartimenti(pageSize?: number, dataInput?: string, pageNum?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoDipartimentoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elencoStoricoDipartimenti(pageSize, dataInput, pageNum, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero del dettaglio di una struttura Organizzativa nello Storico
         * @summary Dettaglio struttura Organizzativa Storico
         * @param {string} idStrutturaOrganizzativa 
         * @param {string} dataInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSOStorico(idStrutturaOrganizzativa: string, dataInput: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrutturaOrganizzativaOrganigrammaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSOStorico(idStrutturaOrganizzativa, dataInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero del dettaglio di una struttura Organizzativa
         * @summary Dettaglio struttura Organizzativa
         * @param {string} idStrutturaOrganizzativa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByIdOrganigramma(idStrutturaOrganizzativa: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrutturaOrganizzativaOrganigrammaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByIdOrganigramma(idStrutturaOrganizzativa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dell\'elenco dei dipartimenti
         * @summary Elenco Dipartimenti
         * @param {number} [pageSize] 
         * @param {number} [pageNum] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOrganigramma(pageSize?: number, pageNum?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoDipartimentoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOrganigramma(pageSize, pageNum, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GestioneOrganigrammaControllerApi - factory interface
 * @export
 */
export const GestioneOrganigrammaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GestioneOrganigrammaControllerApiFp(configuration)
    return {
        /**
         * API Rest per il recupero dell\'elenco dei dipartimenti nello storico
         * @summary Elenco Storico Dipartimenti
         * @param {GestioneOrganigrammaControllerApiElencoStoricoDipartimentiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoStoricoDipartimenti(requestParameters: GestioneOrganigrammaControllerApiElencoStoricoDipartimentiRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoDipartimentoDto> {
            return localVarFp.elencoStoricoDipartimenti(requestParameters.pageSize, requestParameters.dataInput, requestParameters.pageNum, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero del dettaglio di una struttura Organizzativa nello Storico
         * @summary Dettaglio struttura Organizzativa Storico
         * @param {GestioneOrganigrammaControllerApiGetSOStoricoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSOStorico(requestParameters: GestioneOrganigrammaControllerApiGetSOStoricoRequest, options?: AxiosRequestConfig): AxiosPromise<StrutturaOrganizzativaOrganigrammaDto> {
            return localVarFp.getSOStorico(requestParameters.idStrutturaOrganizzativa, requestParameters.dataInput, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero del dettaglio di una struttura Organizzativa
         * @summary Dettaglio struttura Organizzativa
         * @param {GestioneOrganigrammaControllerApiSearchByIdOrganigrammaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByIdOrganigramma(requestParameters: GestioneOrganigrammaControllerApiSearchByIdOrganigrammaRequest, options?: AxiosRequestConfig): AxiosPromise<StrutturaOrganizzativaOrganigrammaDto> {
            return localVarFp.searchByIdOrganigramma(requestParameters.idStrutturaOrganizzativa, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dell\'elenco dei dipartimenti
         * @summary Elenco Dipartimenti
         * @param {GestioneOrganigrammaControllerApiSearchOrganigrammaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrganigramma(requestParameters: GestioneOrganigrammaControllerApiSearchOrganigrammaRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoDipartimentoDto> {
            return localVarFp.searchOrganigramma(requestParameters.pageSize, requestParameters.pageNum, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for elencoStoricoDipartimenti operation in GestioneOrganigrammaControllerApi.
 * @export
 * @interface GestioneOrganigrammaControllerApiElencoStoricoDipartimentiRequest
 */
export interface GestioneOrganigrammaControllerApiElencoStoricoDipartimentiRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneOrganigrammaControllerApiElencoStoricoDipartimenti
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiElencoStoricoDipartimenti
     */
    readonly dataInput?: string

    /**
     * 
     * @type {number}
     * @memberof GestioneOrganigrammaControllerApiElencoStoricoDipartimenti
     */
    readonly pageNum?: number

    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiElencoStoricoDipartimenti
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof GestioneOrganigrammaControllerApiElencoStoricoDipartimenti
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiElencoStoricoDipartimenti
     */
    readonly q?: string
}

/**
 * Request parameters for getSOStorico operation in GestioneOrganigrammaControllerApi.
 * @export
 * @interface GestioneOrganigrammaControllerApiGetSOStoricoRequest
 */
export interface GestioneOrganigrammaControllerApiGetSOStoricoRequest {
    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiGetSOStorico
     */
    readonly idStrutturaOrganizzativa: string

    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiGetSOStorico
     */
    readonly dataInput: string
}

/**
 * Request parameters for searchByIdOrganigramma operation in GestioneOrganigrammaControllerApi.
 * @export
 * @interface GestioneOrganigrammaControllerApiSearchByIdOrganigrammaRequest
 */
export interface GestioneOrganigrammaControllerApiSearchByIdOrganigrammaRequest {
    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiSearchByIdOrganigramma
     */
    readonly idStrutturaOrganizzativa: string
}

/**
 * Request parameters for searchOrganigramma operation in GestioneOrganigrammaControllerApi.
 * @export
 * @interface GestioneOrganigrammaControllerApiSearchOrganigrammaRequest
 */
export interface GestioneOrganigrammaControllerApiSearchOrganigrammaRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneOrganigrammaControllerApiSearchOrganigramma
     */
    readonly pageSize?: number

    /**
     * 
     * @type {number}
     * @memberof GestioneOrganigrammaControllerApiSearchOrganigramma
     */
    readonly pageNum?: number

    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiSearchOrganigramma
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof GestioneOrganigrammaControllerApiSearchOrganigramma
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof GestioneOrganigrammaControllerApiSearchOrganigramma
     */
    readonly q?: string
}

/**
 * GestioneOrganigrammaControllerApi - object-oriented interface
 * @export
 * @class GestioneOrganigrammaControllerApi
 * @extends {BaseAPI}
 */
export class GestioneOrganigrammaControllerApi extends BaseAPI {
    /**
     * API Rest per il recupero dell\'elenco dei dipartimenti nello storico
     * @summary Elenco Storico Dipartimenti
     * @param {GestioneOrganigrammaControllerApiElencoStoricoDipartimentiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneOrganigrammaControllerApi
     */
    public elencoStoricoDipartimenti(requestParameters: GestioneOrganigrammaControllerApiElencoStoricoDipartimentiRequest = {}, options?: AxiosRequestConfig) {
        return GestioneOrganigrammaControllerApiFp(this.configuration).elencoStoricoDipartimenti(requestParameters.pageSize, requestParameters.dataInput, requestParameters.pageNum, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero del dettaglio di una struttura Organizzativa nello Storico
     * @summary Dettaglio struttura Organizzativa Storico
     * @param {GestioneOrganigrammaControllerApiGetSOStoricoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneOrganigrammaControllerApi
     */
    public getSOStorico(requestParameters: GestioneOrganigrammaControllerApiGetSOStoricoRequest, options?: AxiosRequestConfig) {
        return GestioneOrganigrammaControllerApiFp(this.configuration).getSOStorico(requestParameters.idStrutturaOrganizzativa, requestParameters.dataInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero del dettaglio di una struttura Organizzativa
     * @summary Dettaglio struttura Organizzativa
     * @param {GestioneOrganigrammaControllerApiSearchByIdOrganigrammaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneOrganigrammaControllerApi
     */
    public searchByIdOrganigramma(requestParameters: GestioneOrganigrammaControllerApiSearchByIdOrganigrammaRequest, options?: AxiosRequestConfig) {
        return GestioneOrganigrammaControllerApiFp(this.configuration).searchByIdOrganigramma(requestParameters.idStrutturaOrganizzativa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dell\'elenco dei dipartimenti
     * @summary Elenco Dipartimenti
     * @param {GestioneOrganigrammaControllerApiSearchOrganigrammaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneOrganigrammaControllerApi
     */
    public searchOrganigramma(requestParameters: GestioneOrganigrammaControllerApiSearchOrganigrammaRequest = {}, options?: AxiosRequestConfig) {
        return GestioneOrganigrammaControllerApiFp(this.configuration).searchOrganigramma(requestParameters.pageSize, requestParameters.pageNum, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}

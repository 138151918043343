import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getInfoStakeholder } from "../../store/stakeholderSlice";
import PageHeader from "../../components/page-header/PageHeader";
import { useTranslation } from "react-i18next";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import { DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Table, UncontrolledDropdown } from "design-react-kit";
import { useNavigate } from "react-router-dom";
import { HREF_SH_AREA_PERSONALE, HREF_SH_MODIFICA_INFO } from "../../components/sidemenu/sidemenuConstants";
import { Button } from "reactstrap";
import PageLoader from "../../components/page-loader/PageLoader";
import NotificationManagerComponent from "../../components/notificationComponent/NotificationManagerComponent";

const AreaPersonale = () => {
    const { searchStatus, searchResult } = useAppSelector((state) => state.stakeholder)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getInfoStakeholder())
    }, [])

    return (
        <div>
            <PageHeader showIcon={false} title={t("area personale")!} buttons={[]}/>
            <div className="mt-2 position-relative">
                {searchStatus === STATUS_PENDING && (
                    <PageLoader loadingMessage="Ricerca in corso.." />
                )}
                {searchStatus === STATUS_REJECTED && (
                    <NotificationManagerComponent state='error' title='Errore'
                        content='Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio'/>
                )}
                {searchStatus === STATUS_FULLFILLED && !!searchResult && (
                    <>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th colSpan={2}>Informazioni personali</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>IBAN</td>
                                <td>{searchResult.iban || '-'}</td>
                                {/* <td>
                                    <UncontrolledDropdown direction="left">
                                        <DropdownToggle nav>
                                            <Icon icon="it-more-actions" />
                                        </DropdownToggle>
                                        <DropdownMenu className="no-arrow">
                                            <LinkList>
                                                <LinkListItem
                                                    size="medium"
                                                    onClick={() => navigate(`${HREF_SH_AREA_PERSONALE}`)}
                                                >
                                                    <span>Modifica</span>
                                                </LinkListItem>
                                            </LinkList>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td> */}
                            </tr>
                            <tr>
                                <td>Telefono cellulare</td>
                                <td>{searchResult.cellulare || '-'}</td>
                            </tr>
                            <tr>
                                <td>Telefono abitazione</td>
                                <td>{searchResult.telefono || '-'}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{searchResult.email || '-'}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2 mr-2" onClick={() => navigate(HREF_SH_MODIFICA_INFO)}>Modifica</Button>
                    </div></>
                )}
            </div>
        </div>
    )
}

export default AreaPersonale;
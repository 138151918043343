import { RichiestaAbilitazioneDto } from '../../services/ms-anagrafica-unica'
import './style.scss'

const AbilitazioneDetailsComponent = (props: {abilitazione: RichiestaAbilitazioneDto, except: Array<string>}) => {
    return (
        <div className="col-sm-12">
            {props.abilitazione != null &&
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light-candidato">
                        <h6 className="mb-3">
                            Riepilogo
                        </h6>
                        <hr />
                        <div className="row">
                            <dl className="col-sm-12">
                                <div className="row">
                                {!!props.abilitazione.codiceFiscale && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Codice Fiscale'}</dt>
                                        <dd className='col-sm-12'>{props.abilitazione.codiceFiscale}</dd>
                                    </div>
                                )}
                                {!!props.abilitazione.dipendenteRegionale && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Codice CID'}</dt>
                                        <dd className='col-sm-12'>{props.abilitazione.dipendenteRegionale?.id}</dd>
                                    </div>
                                )}
                                {!!props.abilitazione.cognome && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Cognome'}</dt>
                                        <dd className='col-sm-12'>{props.abilitazione.cognome}</dd>
                                    </div>
                                )}
                                {!!props.abilitazione.nome && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Nome'}</dt>
                                        <dd className='col-sm-12'>{props.abilitazione.nome}</dd>
                                    </div>
                                )}
                                {!!props.abilitazione.applicativo && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Applicativo'}</dt>
                                        <dd className='col-sm-12'>{props.abilitazione.applicativo}</dd>
                                    </div>
                                )}
                                {!!props.abilitazione.stato && (
                                    <div className="col-sm-4">
                                        <dt className='col-sm-12'>{'Stato'}</dt>
                                        <dd className='col-sm-12'>{props.abilitazione.stato}</dd>
                                    </div>
                                )}
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default AbilitazioneDetailsComponent
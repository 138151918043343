/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AreaPersonaleDto } from '../model';
// @ts-ignore
import { BandoDto } from '../model';
// @ts-ignore
import { PagedDtoBandoDto } from '../model';
// @ts-ignore
import { ScriviAreaPersonaleRequestDto } from '../model';
/**
 * AreaPersonaleControllerApi - axios parameter creator
 * @export
 */
export const AreaPersonaleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per la lista dei bandi
         * @summary Elenco bandi
         * @param {number} [categoria] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoBandi: async (categoria?: number, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bandi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoria !== undefined) {
                localVarQueryParameter['categoria'] = categoria;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la lettura delle info stakeolder
         * @summary Leggi info stakeholder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leggi: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/area-personale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la lettura di uno specifico bando
         * @summary Leggi singolo bando
         * @param {number} idBando 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leggiSingoloBando: async (idBando: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idBando' is not null or undefined
            assertParamExists('leggiSingoloBando', 'idBando', idBando)
            const localVarPath = `/v1/bandi/{idBando}`
                .replace(`{${"idBando"}}`, encodeURIComponent(String(idBando)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la scrittura delle info stakeolder
         * @summary Scrivi info stakeholder
         * @param {ScriviAreaPersonaleRequestDto} scriviAreaPersonaleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scrivi: async (scriviAreaPersonaleRequestDto: ScriviAreaPersonaleRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scriviAreaPersonaleRequestDto' is not null or undefined
            assertParamExists('scrivi', 'scriviAreaPersonaleRequestDto', scriviAreaPersonaleRequestDto)
            const localVarPath = `/v1/area-personale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scriviAreaPersonaleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreaPersonaleControllerApi - functional programming interface
 * @export
 */
export const AreaPersonaleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreaPersonaleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per la lista dei bandi
         * @summary Elenco bandi
         * @param {number} [categoria] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elencoBandi(categoria?: number, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoBandoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elencoBandi(categoria, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la lettura delle info stakeolder
         * @summary Leggi info stakeholder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leggi(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaPersonaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leggi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la lettura di uno specifico bando
         * @summary Leggi singolo bando
         * @param {number} idBando 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leggiSingoloBando(idBando: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BandoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leggiSingoloBando(idBando, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la scrittura delle info stakeolder
         * @summary Scrivi info stakeholder
         * @param {ScriviAreaPersonaleRequestDto} scriviAreaPersonaleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scrivi(scriviAreaPersonaleRequestDto: ScriviAreaPersonaleRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaPersonaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scrivi(scriviAreaPersonaleRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreaPersonaleControllerApi - factory interface
 * @export
 */
export const AreaPersonaleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreaPersonaleControllerApiFp(configuration)
    return {
        /**
         * API Rest per la lista dei bandi
         * @summary Elenco bandi
         * @param {AreaPersonaleControllerApiElencoBandiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elencoBandi(requestParameters: AreaPersonaleControllerApiElencoBandiRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoBandoDto> {
            return localVarFp.elencoBandi(requestParameters.categoria, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la lettura delle info stakeolder
         * @summary Leggi info stakeholder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leggi(options?: AxiosRequestConfig): AxiosPromise<AreaPersonaleDto> {
            return localVarFp.leggi(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la lettura di uno specifico bando
         * @summary Leggi singolo bando
         * @param {AreaPersonaleControllerApiLeggiSingoloBandoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leggiSingoloBando(requestParameters: AreaPersonaleControllerApiLeggiSingoloBandoRequest, options?: AxiosRequestConfig): AxiosPromise<BandoDto> {
            return localVarFp.leggiSingoloBando(requestParameters.idBando, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la scrittura delle info stakeolder
         * @summary Scrivi info stakeholder
         * @param {AreaPersonaleControllerApiScriviRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scrivi(requestParameters: AreaPersonaleControllerApiScriviRequest, options?: AxiosRequestConfig): AxiosPromise<AreaPersonaleDto> {
            return localVarFp.scrivi(requestParameters.scriviAreaPersonaleRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for elencoBandi operation in AreaPersonaleControllerApi.
 * @export
 * @interface AreaPersonaleControllerApiElencoBandiRequest
 */
export interface AreaPersonaleControllerApiElencoBandiRequest {
    /**
     * 
     * @type {number}
     * @memberof AreaPersonaleControllerApiElencoBandi
     */
    readonly categoria?: number

    /**
     * 
     * @type {number}
     * @memberof AreaPersonaleControllerApiElencoBandi
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof AreaPersonaleControllerApiElencoBandi
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AreaPersonaleControllerApiElencoBandi
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof AreaPersonaleControllerApiElencoBandi
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof AreaPersonaleControllerApiElencoBandi
     */
    readonly q?: string
}

/**
 * Request parameters for leggiSingoloBando operation in AreaPersonaleControllerApi.
 * @export
 * @interface AreaPersonaleControllerApiLeggiSingoloBandoRequest
 */
export interface AreaPersonaleControllerApiLeggiSingoloBandoRequest {
    /**
     * 
     * @type {number}
     * @memberof AreaPersonaleControllerApiLeggiSingoloBando
     */
    readonly idBando: number
}

/**
 * Request parameters for scrivi operation in AreaPersonaleControllerApi.
 * @export
 * @interface AreaPersonaleControllerApiScriviRequest
 */
export interface AreaPersonaleControllerApiScriviRequest {
    /**
     * 
     * @type {ScriviAreaPersonaleRequestDto}
     * @memberof AreaPersonaleControllerApiScrivi
     */
    readonly scriviAreaPersonaleRequestDto: ScriviAreaPersonaleRequestDto
}

/**
 * AreaPersonaleControllerApi - object-oriented interface
 * @export
 * @class AreaPersonaleControllerApi
 * @extends {BaseAPI}
 */
export class AreaPersonaleControllerApi extends BaseAPI {
    /**
     * API Rest per la lista dei bandi
     * @summary Elenco bandi
     * @param {AreaPersonaleControllerApiElencoBandiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaPersonaleControllerApi
     */
    public elencoBandi(requestParameters: AreaPersonaleControllerApiElencoBandiRequest = {}, options?: AxiosRequestConfig) {
        return AreaPersonaleControllerApiFp(this.configuration).elencoBandi(requestParameters.categoria, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la lettura delle info stakeolder
     * @summary Leggi info stakeholder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaPersonaleControllerApi
     */
    public leggi(options?: AxiosRequestConfig) {
        return AreaPersonaleControllerApiFp(this.configuration).leggi(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la lettura di uno specifico bando
     * @summary Leggi singolo bando
     * @param {AreaPersonaleControllerApiLeggiSingoloBandoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaPersonaleControllerApi
     */
    public leggiSingoloBando(requestParameters: AreaPersonaleControllerApiLeggiSingoloBandoRequest, options?: AxiosRequestConfig) {
        return AreaPersonaleControllerApiFp(this.configuration).leggiSingoloBando(requestParameters.idBando, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la scrittura delle info stakeolder
     * @summary Scrivi info stakeholder
     * @param {AreaPersonaleControllerApiScriviRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaPersonaleControllerApi
     */
    public scrivi(requestParameters: AreaPersonaleControllerApiScriviRequest, options?: AxiosRequestConfig) {
        return AreaPersonaleControllerApiFp(this.configuration).scrivi(requestParameters.scriviAreaPersonaleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

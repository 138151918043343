/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { FunzionalitaRichiestaRequestDto } from './funzionalita-richiesta-request-dto';

/**
 * 
 * @export
 * @interface InsertRichiestaAbilitazioneDto
 */
export interface InsertRichiestaAbilitazioneDto {
    /**
     * 
     * @type {string}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'tipoUtente'?: InsertRichiestaAbilitazioneDtoTipoUtenteEnum;
    /**
     * 
     * @type {string}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'codiceFiscale'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'codiceCid'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'cognome'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'nome'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'applicativo'?: number;
    /**
     * 
     * @type {Array<FunzionalitaRichiestaRequestDto>}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'elencoFunzionalita'?: Array<FunzionalitaRichiestaRequestDto>;
    /**
     * 
     * @type {string}
     * @memberof InsertRichiestaAbilitazioneDto
     */
    'requestType'?: InsertRichiestaAbilitazioneDtoRequestTypeEnum;
}

export const InsertRichiestaAbilitazioneDtoTipoUtenteEnum = {
    Dipendente: 'DIPENDENTE',
    ConsulenteEsterno: 'CONSULENTE_ESTERNO'
} as const;

export type InsertRichiestaAbilitazioneDtoTipoUtenteEnum = typeof InsertRichiestaAbilitazioneDtoTipoUtenteEnum[keyof typeof InsertRichiestaAbilitazioneDtoTipoUtenteEnum];
export const InsertRichiestaAbilitazioneDtoRequestTypeEnum = {
    Salva: 'SALVA',
    Conferma: 'CONFERMA'
} as const;

export type InsertRichiestaAbilitazioneDtoRequestTypeEnum = typeof InsertRichiestaAbilitazioneDtoRequestTypeEnum[keyof typeof InsertRichiestaAbilitazioneDtoRequestTypeEnum];



/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DipendenteRegionaleDto } from '../model';
// @ts-ignore
import { UtenteAutenticatoDto } from '../model';
/**
 * AuthPublicControllerApi - axios parameter creator
 * @export
 */
export const AuthPublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDipendentiRegionaliConRuolo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/getDipendentiRegionaliConRuolo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [idDipendente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goLogin: async (idDipendente?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idDipendente !== undefined) {
                localVarQueryParameter['idDipendente'] = idDipendente;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la lettura dei dati dell\'utente loggato
         * @summary Azione per leggere i dati dell\'utente loggato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leggiUtenteLoggato: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/leggi-utente-loggato`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testDeployScript: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/test-deploy-script`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthPublicControllerApi - functional programming interface
 * @export
 */
export const AuthPublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthPublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDipendentiRegionaliConRuolo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DipendenteRegionaleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDipendentiRegionaliConRuolo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [idDipendente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goLogin(idDipendente?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goLogin(idDipendente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la lettura dei dati dell\'utente loggato
         * @summary Azione per leggere i dati dell\'utente loggato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leggiUtenteLoggato(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtenteAutenticatoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leggiUtenteLoggato(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testDeployScript(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testDeployScript(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthPublicControllerApi - factory interface
 * @export
 */
export const AuthPublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthPublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDipendentiRegionaliConRuolo(options?: AxiosRequestConfig): AxiosPromise<Array<DipendenteRegionaleDto>> {
            return localVarFp.getDipendentiRegionaliConRuolo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthPublicControllerApiGoLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goLogin(requestParameters: AuthPublicControllerApiGoLoginRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.goLogin(requestParameters.idDipendente, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la lettura dei dati dell\'utente loggato
         * @summary Azione per leggere i dati dell\'utente loggato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leggiUtenteLoggato(options?: AxiosRequestConfig): AxiosPromise<UtenteAutenticatoDto> {
            return localVarFp.leggiUtenteLoggato(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testDeployScript(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.testDeployScript(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for goLogin operation in AuthPublicControllerApi.
 * @export
 * @interface AuthPublicControllerApiGoLoginRequest
 */
export interface AuthPublicControllerApiGoLoginRequest {
    /**
     * 
     * @type {number}
     * @memberof AuthPublicControllerApiGoLogin
     */
    readonly idDipendente?: number
}

/**
 * AuthPublicControllerApi - object-oriented interface
 * @export
 * @class AuthPublicControllerApi
 * @extends {BaseAPI}
 */
export class AuthPublicControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthPublicControllerApi
     */
    public getDipendentiRegionaliConRuolo(options?: AxiosRequestConfig) {
        return AuthPublicControllerApiFp(this.configuration).getDipendentiRegionaliConRuolo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthPublicControllerApiGoLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthPublicControllerApi
     */
    public goLogin(requestParameters: AuthPublicControllerApiGoLoginRequest = {}, options?: AxiosRequestConfig) {
        return AuthPublicControllerApiFp(this.configuration).goLogin(requestParameters.idDipendente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la lettura dei dati dell\'utente loggato
     * @summary Azione per leggere i dati dell\'utente loggato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthPublicControllerApi
     */
    public leggiUtenteLoggato(options?: AxiosRequestConfig) {
        return AuthPublicControllerApiFp(this.configuration).leggiUtenteLoggato(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthPublicControllerApi
     */
    public testDeployScript(options?: AxiosRequestConfig) {
        return AuthPublicControllerApiFp(this.configuration).testDeployScript(options).then((request) => request(this.axios, this.basePath));
    }
}

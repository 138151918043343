/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncaricoDirigenzialeDto } from '../model';
// @ts-ignore
import { InsertAssegnazioneIncarichiDirigenzialiRequestDto } from '../model';
// @ts-ignore
import { InsertUpdateIncarichiDirigenzialiRequestDto } from '../model';
// @ts-ignore
import { PagedDtoIncaricoDirigenzialeDto } from '../model';
// @ts-ignore
import { ProtocollaRequest } from '../model';
// @ts-ignore
import { UpdateAssegnazioneIncarichiDirigenzialiRequestDto } from '../model';
// @ts-ignore
import { ValutazioneGiuntaPropostaIncaDirigRequestDto } from '../model';
// @ts-ignore
import { ValutazionePropostaIncaDirigDto } from '../model';
// @ts-ignore
import { ValutazionePropostaIncaDirigRequestDto } from '../model';
/**
 * GestioneIncarichiDirigenzialiControllerApi - axios parameter creator
 * @export
 */
export const GestioneIncarichiDirigenzialiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per l\'inserimento di una nuova assegnazione di incarico dirigenziale
         * @summary Nuova Assegnazione Incarico Dirigenziale
         * @param {number} idProposta 
         * @param {InsertAssegnazioneIncarichiDirigenzialiRequestDto} insertAssegnazioneIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAssegnazioneIncaricoDirigenziale: async (idProposta: number, insertAssegnazioneIncarichiDirigenzialiRequestDto: InsertAssegnazioneIncarichiDirigenzialiRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idProposta' is not null or undefined
            assertParamExists('insertAssegnazioneIncaricoDirigenziale', 'idProposta', idProposta)
            // verify required parameter 'insertAssegnazioneIncarichiDirigenzialiRequestDto' is not null or undefined
            assertParamExists('insertAssegnazioneIncaricoDirigenziale', 'insertAssegnazioneIncarichiDirigenzialiRequestDto', insertAssegnazioneIncarichiDirigenzialiRequestDto)
            const localVarPath = `/v1/incarichi-dirigenziali/{idProposta}`
                .replace(`{${"idProposta"}}`, encodeURIComponent(String(idProposta)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertAssegnazioneIncarichiDirigenzialiRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento di una nuova proposta di incarico dirigenziale
         * @summary Nuova proposta Incarico Dirigenziale
         * @param {InsertUpdateIncarichiDirigenzialiRequestDto} insertUpdateIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertIncaricoDirigenziale: async (insertUpdateIncarichiDirigenzialiRequestDto: InsertUpdateIncarichiDirigenzialiRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertUpdateIncarichiDirigenzialiRequestDto' is not null or undefined
            assertParamExists('insertIncaricoDirigenziale', 'insertUpdateIncarichiDirigenzialiRequestDto', insertUpdateIncarichiDirigenzialiRequestDto)
            const localVarPath = `/v1/incarichi-dirigenziali`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUpdateIncarichiDirigenzialiRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento di una valutazione da parte del personale di una proposta di incarico dirigenziale
         * @summary Nuova valutazione del personale su una proposta di Incarico Dirigenziale
         * @param {number} id 
         * @param {ValutazionePropostaIncaDirigRequestDto} valutazionePropostaIncaDirigRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertValutazionePersonale: async (id: number, valutazionePropostaIncaDirigRequestDto: ValutazionePropostaIncaDirigRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insertValutazionePersonale', 'id', id)
            // verify required parameter 'valutazionePropostaIncaDirigRequestDto' is not null or undefined
            assertParamExists('insertValutazionePersonale', 'valutazionePropostaIncaDirigRequestDto', valutazionePropostaIncaDirigRequestDto)
            const localVarPath = `/v1/incarichi-dirigenziali/{id}/valutazione-proposta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valutazionePropostaIncaDirigRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la modifica di una valutazione da parte del personale di una proposta di incarico dirigenziale
         * @summary Patch per la modifica della valutazione del personale sulla proposta di Incarico Dirigenziale
         * @param {number} id 
         * @param {ValutazionePropostaIncaDirigRequestDto} valutazionePropostaIncaDirigRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchValutazionePersonale: async (id: number, valutazionePropostaIncaDirigRequestDto: ValutazionePropostaIncaDirigRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchValutazionePersonale', 'id', id)
            // verify required parameter 'valutazionePropostaIncaDirigRequestDto' is not null or undefined
            assertParamExists('patchValutazionePersonale', 'valutazionePropostaIncaDirigRequestDto', valutazionePropostaIncaDirigRequestDto)
            const localVarPath = `/v1/incarichi-dirigenziali/{id}/valutazione-proposta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valutazionePropostaIncaDirigRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la protocollazione del documento di una proposta di incarico dirigenziale
         * @summary Protocolla documento proposta Incarico Dirigenziale
         * @param {ProtocollaRequest} protocollaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        protocolla: async (protocollaRequest: ProtocollaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'protocollaRequest' is not null or undefined
            assertParamExists('protocolla', 'protocollaRequest', protocollaRequest)
            const localVarPath = `/v1/incarichi-dirigenziali/protocolla`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(protocollaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la modifica dello stato della proposta che sono agganciate ad assegnazioni scadute.
         * @summary Aggiorna stato delle proposte le cui assegnazioni sono scadute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scadenzaProposta: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/incarichi-dirigenziali/effettiva-scadenza`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il dettaglio di una proposta di incarico dirigenziale
         * @summary Dettaglio proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByIdIncaricoDirigenziale: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchByIdIncaricoDirigenziale', 'id', id)
            const localVarPath = `/v1/incarichi-dirigenziali/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dell\'elenco degli incarichi dirigenziali
         * @summary Elenco Incarichi Dirigenziali
         * @param {string} [denominazioneSo] 
         * @param {string} [candidato] 
         * @param {'IN_COMPILAZIONE' | 'INSERITA' | 'RIGETTATA' | 'ACCETTATA' | 'ASSEGNATA' | 'APPROVATA' | 'NON_APPROVATA' | 'TERMINATA'} [stato] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIncaricoDirigenziale: async (denominazioneSo?: string, candidato?: string, stato?: 'IN_COMPILAZIONE' | 'INSERITA' | 'RIGETTATA' | 'ACCETTATA' | 'ASSEGNATA' | 'APPROVATA' | 'NON_APPROVATA' | 'TERMINATA', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/incarichi-dirigenziali`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (denominazioneSo !== undefined) {
                localVarQueryParameter['denominazioneSo'] = denominazioneSo;
            }

            if (candidato !== undefined) {
                localVarQueryParameter['candidato'] = candidato;
            }

            if (stato !== undefined) {
                localVarQueryParameter['stato'] = stato;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero della valutazione
         * @summary Valutazione proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchValutazioni: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchValutazioni', 'id', id)
            const localVarPath = `/v1/incarichi-dirigenziali/{id}/valutazione-proposta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la modifica dell\'assegnazione.
         * @summary Modifica l\'assegnazione
         * @param {number} idProposta 
         * @param {UpdateAssegnazioneIncarichiDirigenzialiRequestDto} updateAssegnazioneIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssegnazioneIncaricoDirigenziale: async (idProposta: number, updateAssegnazioneIncarichiDirigenzialiRequestDto: UpdateAssegnazioneIncarichiDirigenzialiRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idProposta' is not null or undefined
            assertParamExists('updateAssegnazioneIncaricoDirigenziale', 'idProposta', idProposta)
            // verify required parameter 'updateAssegnazioneIncarichiDirigenzialiRequestDto' is not null or undefined
            assertParamExists('updateAssegnazioneIncaricoDirigenziale', 'updateAssegnazioneIncarichiDirigenzialiRequestDto', updateAssegnazioneIncarichiDirigenzialiRequestDto)
            const localVarPath = `/v1/incarichi-dirigenziali/{idProposta}/modifica`
                .replace(`{${"idProposta"}}`, encodeURIComponent(String(idProposta)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssegnazioneIncarichiDirigenzialiRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'aggiornamento di una proposta di incarico dirigenziale
         * @summary Update proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {InsertUpdateIncarichiDirigenzialiRequestDto} insertUpdateIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncaricoDirigenziale: async (id: number, insertUpdateIncarichiDirigenzialiRequestDto: InsertUpdateIncarichiDirigenzialiRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIncaricoDirigenziale', 'id', id)
            // verify required parameter 'insertUpdateIncarichiDirigenzialiRequestDto' is not null or undefined
            assertParamExists('updateIncaricoDirigenziale', 'insertUpdateIncarichiDirigenzialiRequestDto', insertUpdateIncarichiDirigenzialiRequestDto)
            const localVarPath = `/v1/incarichi-dirigenziali/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUpdateIncarichiDirigenzialiRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento del parere della giunta per una proposta di incarico dirigenziale
         * @summary Aggiungi valutazione da parte della giunta alla proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {ValutazioneGiuntaPropostaIncaDirigRequestDto} valutazioneGiuntaPropostaIncaDirigRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneGiunta: async (id: number, valutazioneGiuntaPropostaIncaDirigRequestDto: ValutazioneGiuntaPropostaIncaDirigRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('valutazioneGiunta', 'id', id)
            // verify required parameter 'valutazioneGiuntaPropostaIncaDirigRequestDto' is not null or undefined
            assertParamExists('valutazioneGiunta', 'valutazioneGiuntaPropostaIncaDirigRequestDto', valutazioneGiuntaPropostaIncaDirigRequestDto)
            const localVarPath = `/v1/incarichi-dirigenziali/{id}/valutazione-proposta/giunta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(valutazioneGiuntaPropostaIncaDirigRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GestioneIncarichiDirigenzialiControllerApi - functional programming interface
 * @export
 */
export const GestioneIncarichiDirigenzialiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GestioneIncarichiDirigenzialiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per l\'inserimento di una nuova assegnazione di incarico dirigenziale
         * @summary Nuova Assegnazione Incarico Dirigenziale
         * @param {number} idProposta 
         * @param {InsertAssegnazioneIncarichiDirigenzialiRequestDto} insertAssegnazioneIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertAssegnazioneIncaricoDirigenziale(idProposta: number, insertAssegnazioneIncarichiDirigenzialiRequestDto: InsertAssegnazioneIncarichiDirigenzialiRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertAssegnazioneIncaricoDirigenziale(idProposta, insertAssegnazioneIncarichiDirigenzialiRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento di una nuova proposta di incarico dirigenziale
         * @summary Nuova proposta Incarico Dirigenziale
         * @param {InsertUpdateIncarichiDirigenzialiRequestDto} insertUpdateIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertIncaricoDirigenziale(insertUpdateIncarichiDirigenzialiRequestDto: InsertUpdateIncarichiDirigenzialiRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncaricoDirigenzialeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertIncaricoDirigenziale(insertUpdateIncarichiDirigenzialiRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento di una valutazione da parte del personale di una proposta di incarico dirigenziale
         * @summary Nuova valutazione del personale su una proposta di Incarico Dirigenziale
         * @param {number} id 
         * @param {ValutazionePropostaIncaDirigRequestDto} valutazionePropostaIncaDirigRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertValutazionePersonale(id: number, valutazionePropostaIncaDirigRequestDto: ValutazionePropostaIncaDirigRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValutazionePropostaIncaDirigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertValutazionePersonale(id, valutazionePropostaIncaDirigRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la modifica di una valutazione da parte del personale di una proposta di incarico dirigenziale
         * @summary Patch per la modifica della valutazione del personale sulla proposta di Incarico Dirigenziale
         * @param {number} id 
         * @param {ValutazionePropostaIncaDirigRequestDto} valutazionePropostaIncaDirigRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchValutazionePersonale(id: number, valutazionePropostaIncaDirigRequestDto: ValutazionePropostaIncaDirigRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValutazionePropostaIncaDirigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchValutazionePersonale(id, valutazionePropostaIncaDirigRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la protocollazione del documento di una proposta di incarico dirigenziale
         * @summary Protocolla documento proposta Incarico Dirigenziale
         * @param {ProtocollaRequest} protocollaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async protocolla(protocollaRequest: ProtocollaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.protocolla(protocollaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la modifica dello stato della proposta che sono agganciate ad assegnazioni scadute.
         * @summary Aggiorna stato delle proposte le cui assegnazioni sono scadute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scadenzaProposta(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scadenzaProposta(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il dettaglio di una proposta di incarico dirigenziale
         * @summary Dettaglio proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByIdIncaricoDirigenziale(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncaricoDirigenzialeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByIdIncaricoDirigenziale(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dell\'elenco degli incarichi dirigenziali
         * @summary Elenco Incarichi Dirigenziali
         * @param {string} [denominazioneSo] 
         * @param {string} [candidato] 
         * @param {'IN_COMPILAZIONE' | 'INSERITA' | 'RIGETTATA' | 'ACCETTATA' | 'ASSEGNATA' | 'APPROVATA' | 'NON_APPROVATA' | 'TERMINATA'} [stato] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIncaricoDirigenziale(denominazioneSo?: string, candidato?: string, stato?: 'IN_COMPILAZIONE' | 'INSERITA' | 'RIGETTATA' | 'ACCETTATA' | 'ASSEGNATA' | 'APPROVATA' | 'NON_APPROVATA' | 'TERMINATA', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoIncaricoDirigenzialeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIncaricoDirigenziale(denominazioneSo, candidato, stato, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero della valutazione
         * @summary Valutazione proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchValutazioni(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValutazionePropostaIncaDirigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchValutazioni(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la modifica dell\'assegnazione.
         * @summary Modifica l\'assegnazione
         * @param {number} idProposta 
         * @param {UpdateAssegnazioneIncarichiDirigenzialiRequestDto} updateAssegnazioneIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssegnazioneIncaricoDirigenziale(idProposta: number, updateAssegnazioneIncarichiDirigenzialiRequestDto: UpdateAssegnazioneIncarichiDirigenzialiRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssegnazioneIncaricoDirigenziale(idProposta, updateAssegnazioneIncarichiDirigenzialiRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'aggiornamento di una proposta di incarico dirigenziale
         * @summary Update proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {InsertUpdateIncarichiDirigenzialiRequestDto} insertUpdateIncarichiDirigenzialiRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIncaricoDirigenziale(id: number, insertUpdateIncarichiDirigenzialiRequestDto: InsertUpdateIncarichiDirigenzialiRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncaricoDirigenzialeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIncaricoDirigenziale(id, insertUpdateIncarichiDirigenzialiRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento del parere della giunta per una proposta di incarico dirigenziale
         * @summary Aggiungi valutazione da parte della giunta alla proposta Incarico Dirigenziale
         * @param {number} id 
         * @param {ValutazioneGiuntaPropostaIncaDirigRequestDto} valutazioneGiuntaPropostaIncaDirigRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valutazioneGiunta(id: number, valutazioneGiuntaPropostaIncaDirigRequestDto: ValutazioneGiuntaPropostaIncaDirigRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValutazionePropostaIncaDirigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valutazioneGiunta(id, valutazioneGiuntaPropostaIncaDirigRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GestioneIncarichiDirigenzialiControllerApi - factory interface
 * @export
 */
export const GestioneIncarichiDirigenzialiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GestioneIncarichiDirigenzialiControllerApiFp(configuration)
    return {
        /**
         * API Rest per l\'inserimento di una nuova assegnazione di incarico dirigenziale
         * @summary Nuova Assegnazione Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAssegnazioneIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.insertAssegnazioneIncaricoDirigenziale(requestParameters.idProposta, requestParameters.insertAssegnazioneIncarichiDirigenzialiRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento di una nuova proposta di incarico dirigenziale
         * @summary Nuova proposta Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest, options?: AxiosRequestConfig): AxiosPromise<IncaricoDirigenzialeDto> {
            return localVarFp.insertIncaricoDirigenziale(requestParameters.insertUpdateIncarichiDirigenzialiRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento di una valutazione da parte del personale di una proposta di incarico dirigenziale
         * @summary Nuova valutazione del personale su una proposta di Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertValutazionePersonale(requestParameters: GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest, options?: AxiosRequestConfig): AxiosPromise<ValutazionePropostaIncaDirigDto> {
            return localVarFp.insertValutazionePersonale(requestParameters.id, requestParameters.valutazionePropostaIncaDirigRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la modifica di una valutazione da parte del personale di una proposta di incarico dirigenziale
         * @summary Patch per la modifica della valutazione del personale sulla proposta di Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchValutazionePersonale(requestParameters: GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest, options?: AxiosRequestConfig): AxiosPromise<ValutazionePropostaIncaDirigDto> {
            return localVarFp.patchValutazionePersonale(requestParameters.id, requestParameters.valutazionePropostaIncaDirigRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la protocollazione del documento di una proposta di incarico dirigenziale
         * @summary Protocolla documento proposta Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiProtocollaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        protocolla(requestParameters: GestioneIncarichiDirigenzialiControllerApiProtocollaRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.protocolla(requestParameters.protocollaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la modifica dello stato della proposta che sono agganciate ad assegnazioni scadute.
         * @summary Aggiorna stato delle proposte le cui assegnazioni sono scadute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scadenzaProposta(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.scadenzaProposta(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il dettaglio di una proposta di incarico dirigenziale
         * @summary Dettaglio proposta Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByIdIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest, options?: AxiosRequestConfig): AxiosPromise<IncaricoDirigenzialeDto> {
            return localVarFp.searchByIdIncaricoDirigenziale(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dell\'elenco degli incarichi dirigenziali
         * @summary Elenco Incarichi Dirigenziali
         * @param {GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoIncaricoDirigenzialeDto> {
            return localVarFp.searchIncaricoDirigenziale(requestParameters.denominazioneSo, requestParameters.candidato, requestParameters.stato, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero della valutazione
         * @summary Valutazione proposta Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchValutazioni(requestParameters: GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest, options?: AxiosRequestConfig): AxiosPromise<ValutazionePropostaIncaDirigDto> {
            return localVarFp.searchValutazioni(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la modifica dell\'assegnazione.
         * @summary Modifica l\'assegnazione
         * @param {GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssegnazioneIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAssegnazioneIncaricoDirigenziale(requestParameters.idProposta, requestParameters.updateAssegnazioneIncarichiDirigenzialiRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'aggiornamento di una proposta di incarico dirigenziale
         * @summary Update proposta Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest, options?: AxiosRequestConfig): AxiosPromise<IncaricoDirigenzialeDto> {
            return localVarFp.updateIncaricoDirigenziale(requestParameters.id, requestParameters.insertUpdateIncarichiDirigenzialiRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento del parere della giunta per una proposta di incarico dirigenziale
         * @summary Aggiungi valutazione da parte della giunta alla proposta Incarico Dirigenziale
         * @param {GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valutazioneGiunta(requestParameters: GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest, options?: AxiosRequestConfig): AxiosPromise<ValutazionePropostaIncaDirigDto> {
            return localVarFp.valutazioneGiunta(requestParameters.id, requestParameters.valutazioneGiuntaPropostaIncaDirigRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for insertAssegnazioneIncaricoDirigenziale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenziale
     */
    readonly idProposta: number

    /**
     * 
     * @type {InsertAssegnazioneIncarichiDirigenzialiRequestDto}
     * @memberof GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenziale
     */
    readonly insertAssegnazioneIncarichiDirigenzialiRequestDto: InsertAssegnazioneIncarichiDirigenzialiRequestDto
}

/**
 * Request parameters for insertIncaricoDirigenziale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest {
    /**
     * 
     * @type {InsertUpdateIncarichiDirigenzialiRequestDto}
     * @memberof GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenziale
     */
    readonly insertUpdateIncarichiDirigenzialiRequestDto: InsertUpdateIncarichiDirigenzialiRequestDto
}

/**
 * Request parameters for insertValutazionePersonale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonale
     */
    readonly id: number

    /**
     * 
     * @type {ValutazionePropostaIncaDirigRequestDto}
     * @memberof GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonale
     */
    readonly valutazionePropostaIncaDirigRequestDto: ValutazionePropostaIncaDirigRequestDto
}

/**
 * Request parameters for patchValutazionePersonale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonale
     */
    readonly id: number

    /**
     * 
     * @type {ValutazionePropostaIncaDirigRequestDto}
     * @memberof GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonale
     */
    readonly valutazionePropostaIncaDirigRequestDto: ValutazionePropostaIncaDirigRequestDto
}

/**
 * Request parameters for protocolla operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiProtocollaRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiProtocollaRequest {
    /**
     * 
     * @type {ProtocollaRequest}
     * @memberof GestioneIncarichiDirigenzialiControllerApiProtocolla
     */
    readonly protocollaRequest: ProtocollaRequest
}

/**
 * Request parameters for searchByIdIncaricoDirigenziale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenziale
     */
    readonly id: number
}

/**
 * Request parameters for searchIncaricoDirigenziale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest {
    /**
     * 
     * @type {string}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly denominazioneSo?: string

    /**
     * 
     * @type {string}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly candidato?: string

    /**
     * 
     * @type {'IN_COMPILAZIONE' | 'INSERITA' | 'RIGETTATA' | 'ACCETTATA' | 'ASSEGNATA' | 'APPROVATA' | 'NON_APPROVATA' | 'TERMINATA'}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly stato?: 'IN_COMPILAZIONE' | 'INSERITA' | 'RIGETTATA' | 'ACCETTATA' | 'ASSEGNATA' | 'APPROVATA' | 'NON_APPROVATA' | 'TERMINATA'

    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenziale
     */
    readonly q?: string
}

/**
 * Request parameters for searchValutazioni operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiSearchValutazioni
     */
    readonly id: number
}

/**
 * Request parameters for updateAssegnazioneIncaricoDirigenziale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenziale
     */
    readonly idProposta: number

    /**
     * 
     * @type {UpdateAssegnazioneIncarichiDirigenzialiRequestDto}
     * @memberof GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenziale
     */
    readonly updateAssegnazioneIncarichiDirigenzialiRequestDto: UpdateAssegnazioneIncarichiDirigenzialiRequestDto
}

/**
 * Request parameters for updateIncaricoDirigenziale operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenziale
     */
    readonly id: number

    /**
     * 
     * @type {InsertUpdateIncarichiDirigenzialiRequestDto}
     * @memberof GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenziale
     */
    readonly insertUpdateIncarichiDirigenzialiRequestDto: InsertUpdateIncarichiDirigenzialiRequestDto
}

/**
 * Request parameters for valutazioneGiunta operation in GestioneIncarichiDirigenzialiControllerApi.
 * @export
 * @interface GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest
 */
export interface GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest {
    /**
     * 
     * @type {number}
     * @memberof GestioneIncarichiDirigenzialiControllerApiValutazioneGiunta
     */
    readonly id: number

    /**
     * 
     * @type {ValutazioneGiuntaPropostaIncaDirigRequestDto}
     * @memberof GestioneIncarichiDirigenzialiControllerApiValutazioneGiunta
     */
    readonly valutazioneGiuntaPropostaIncaDirigRequestDto: ValutazioneGiuntaPropostaIncaDirigRequestDto
}

/**
 * GestioneIncarichiDirigenzialiControllerApi - object-oriented interface
 * @export
 * @class GestioneIncarichiDirigenzialiControllerApi
 * @extends {BaseAPI}
 */
export class GestioneIncarichiDirigenzialiControllerApi extends BaseAPI {
    /**
     * API Rest per l\'inserimento di una nuova assegnazione di incarico dirigenziale
     * @summary Nuova Assegnazione Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public insertAssegnazioneIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).insertAssegnazioneIncaricoDirigenziale(requestParameters.idProposta, requestParameters.insertAssegnazioneIncarichiDirigenzialiRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento di una nuova proposta di incarico dirigenziale
     * @summary Nuova proposta Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public insertIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).insertIncaricoDirigenziale(requestParameters.insertUpdateIncarichiDirigenzialiRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento di una valutazione da parte del personale di una proposta di incarico dirigenziale
     * @summary Nuova valutazione del personale su una proposta di Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public insertValutazionePersonale(requestParameters: GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).insertValutazionePersonale(requestParameters.id, requestParameters.valutazionePropostaIncaDirigRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la modifica di una valutazione da parte del personale di una proposta di incarico dirigenziale
     * @summary Patch per la modifica della valutazione del personale sulla proposta di Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public patchValutazionePersonale(requestParameters: GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).patchValutazionePersonale(requestParameters.id, requestParameters.valutazionePropostaIncaDirigRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la protocollazione del documento di una proposta di incarico dirigenziale
     * @summary Protocolla documento proposta Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiProtocollaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public protocolla(requestParameters: GestioneIncarichiDirigenzialiControllerApiProtocollaRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).protocolla(requestParameters.protocollaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la modifica dello stato della proposta che sono agganciate ad assegnazioni scadute.
     * @summary Aggiorna stato delle proposte le cui assegnazioni sono scadute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public scadenzaProposta(options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).scadenzaProposta(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il dettaglio di una proposta di incarico dirigenziale
     * @summary Dettaglio proposta Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public searchByIdIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).searchByIdIncaricoDirigenziale(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dell\'elenco degli incarichi dirigenziali
     * @summary Elenco Incarichi Dirigenziali
     * @param {GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public searchIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest = {}, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).searchIncaricoDirigenziale(requestParameters.denominazioneSo, requestParameters.candidato, requestParameters.stato, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero della valutazione
     * @summary Valutazione proposta Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public searchValutazioni(requestParameters: GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).searchValutazioni(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la modifica dell\'assegnazione.
     * @summary Modifica l\'assegnazione
     * @param {GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public updateAssegnazioneIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).updateAssegnazioneIncaricoDirigenziale(requestParameters.idProposta, requestParameters.updateAssegnazioneIncarichiDirigenzialiRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'aggiornamento di una proposta di incarico dirigenziale
     * @summary Update proposta Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public updateIncaricoDirigenziale(requestParameters: GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).updateIncaricoDirigenziale(requestParameters.id, requestParameters.insertUpdateIncarichiDirigenzialiRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento del parere della giunta per una proposta di incarico dirigenziale
     * @summary Aggiungi valutazione da parte della giunta alla proposta Incarico Dirigenziale
     * @param {GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestioneIncarichiDirigenzialiControllerApi
     */
    public valutazioneGiunta(requestParameters: GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest, options?: AxiosRequestConfig) {
        return GestioneIncarichiDirigenzialiControllerApiFp(this.configuration).valutazioneGiunta(requestParameters.id, requestParameters.valutazioneGiuntaPropostaIncaDirigRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DipendenteRegionaleEssenzialeDto } from './dipendente-regionale-essenziale-dto';
// May contain unused imports in some cases
// @ts-ignore
import { FunzionalitaRichiesteDto } from './funzionalita-richieste-dto';

/**
 * 
 * @export
 * @interface RichiestaAbilitazioneDto
 */
export interface RichiestaAbilitazioneDto {
    /**
     * 
     * @type {number}
     * @memberof RichiestaAbilitazioneDto
     */
    'codiceUnivoco'?: number;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'codiceFiscale'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'cognome'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'nome'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'noteGestore'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'utenza'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'applicativo'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'dataAttivazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'tipoUtente'?: RichiestaAbilitazioneDtoTipoUtenteEnum;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'stato'?: RichiestaAbilitazioneDtoStatoEnum;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAbilitazioneDto
     */
    'descrizioneStato'?: string;
    /**
     * 
     * @type {DipendenteRegionaleEssenzialeDto}
     * @memberof RichiestaAbilitazioneDto
     */
    'dipendenteRegionale'?: DipendenteRegionaleEssenzialeDto;
    /**
     * 
     * @type {Array<FunzionalitaRichiesteDto>}
     * @memberof RichiestaAbilitazioneDto
     */
    'elencoFunzionalitaRichieste'?: Array<FunzionalitaRichiesteDto>;
}

export const RichiestaAbilitazioneDtoTipoUtenteEnum = {
    Dipendente: 'DIPENDENTE',
    ConsulenteEsterno: 'CONSULENTE_ESTERNO'
} as const;

export type RichiestaAbilitazioneDtoTipoUtenteEnum = typeof RichiestaAbilitazioneDtoTipoUtenteEnum[keyof typeof RichiestaAbilitazioneDtoTipoUtenteEnum];
export const RichiestaAbilitazioneDtoStatoEnum = {
    Bozza: 'BOZZA',
    AttesaApprovazioneAttivazione: 'ATTESA_APPROVAZIONE_ATTIVAZIONE',
    AttesaApprovazioneModifica: 'ATTESA_APPROVAZIONE_MODIFICA',
    AttesaApprovazioneSospensione: 'ATTESA_APPROVAZIONE_SOSPENSIONE',
    AttesaApprovazioneRevoca: 'ATTESA_APPROVAZIONE_REVOCA',
    AttesaApprovazioneRiattivazione: 'ATTESA_APPROVAZIONE_RIATTIVAZIONE',
    Attiva: 'ATTIVA',
    Annullata: 'ANNULLATA',
    Revocata: 'REVOCATA',
    Sospesa: 'SOSPESA'
} as const;

export type RichiestaAbilitazioneDtoStatoEnum = typeof RichiestaAbilitazioneDtoStatoEnum[keyof typeof RichiestaAbilitazioneDtoStatoEnum];



/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssegnaRuoloConDipendenteRequestDto } from '../model';
// @ts-ignore
import { AssegnazioneRuoloConDipendenteDto } from '../model';
// @ts-ignore
import { PagedDtoAssegnazioneRuoloConDipendenteDto } from '../model';
// @ts-ignore
import { PagedDtoDipendenteRegionaleDto } from '../model';
/**
 * AssegnazioneRuoloDipendentiControllerApi - axios parameter creator
 * @export
 */
export const AssegnazioneRuoloDipendentiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per la cancellazione di un\'assegnazione di un ruolo ad un dipendente
         * @summary Cancellazione Assegnazione Ruolo a dipendente
         * @param {number} idRuolo 
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssegnazioneRuoloDipendenti: async (idRuolo: number, idDipendente: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRuolo' is not null or undefined
            assertParamExists('deleteAssegnazioneRuoloDipendenti', 'idRuolo', idRuolo)
            // verify required parameter 'idDipendente' is not null or undefined
            assertParamExists('deleteAssegnazioneRuoloDipendenti', 'idDipendente', idDipendente)
            const localVarPath = `/v1/assegnazione-ruoli-dipendenti/{idRuolo}/dipendente/{idDipendente}`
                .replace(`{${"idRuolo"}}`, encodeURIComponent(String(idRuolo)))
                .replace(`{${"idDipendente"}}`, encodeURIComponent(String(idDipendente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca dei dipendenti associabili
         * @summary Ricerca dipendenti associabili
         * @param {number} idRuolo 
         * @param {boolean} [consulentiEsterni] 
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociabili: async (idRuolo: number, consulentiEsterni?: boolean, chiave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRuolo' is not null or undefined
            assertParamExists('getAssociabili', 'idRuolo', idRuolo)
            const localVarPath = `/v1/assegnazione-ruoli-dipendenti/associabili/{idRuolo}`
                .replace(`{${"idRuolo"}}`, encodeURIComponent(String(idRuolo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (consulentiEsterni !== undefined) {
                localVarQueryParameter['consulentiEsterni'] = consulentiEsterni;
            }

            if (chiave !== undefined) {
                localVarQueryParameter['chiave'] = chiave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento dell\'assegnazione
         * @summary Inserimento assegnazione
         * @param {AssegnaRuoloConDipendenteRequestDto} assegnaRuoloConDipendenteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAssegnazioneRuoloDipendenti: async (assegnaRuoloConDipendenteRequestDto: AssegnaRuoloConDipendenteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assegnaRuoloConDipendenteRequestDto' is not null or undefined
            assertParamExists('insertAssegnazioneRuoloDipendenti', 'assegnaRuoloConDipendenteRequestDto', assegnaRuoloConDipendenteRequestDto)
            const localVarPath = `/v1/assegnazione-ruoli-dipendenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assegnaRuoloConDipendenteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca delle assegnazioni
         * @summary Ricerca assegnazioni
         * @param {number} [idRuolo] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssegnazioneRuoloDipendenti: async (idRuolo?: number, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/assegnazione-ruoli-dipendenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idRuolo !== undefined) {
                localVarQueryParameter['idRuolo'] = idRuolo;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssegnazioneRuoloDipendentiControllerApi - functional programming interface
 * @export
 */
export const AssegnazioneRuoloDipendentiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssegnazioneRuoloDipendentiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per la cancellazione di un\'assegnazione di un ruolo ad un dipendente
         * @summary Cancellazione Assegnazione Ruolo a dipendente
         * @param {number} idRuolo 
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssegnazioneRuoloDipendenti(idRuolo: number, idDipendente: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssegnazioneRuoloDipendenti(idRuolo, idDipendente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca dei dipendenti associabili
         * @summary Ricerca dipendenti associabili
         * @param {number} idRuolo 
         * @param {boolean} [consulentiEsterni] 
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssociabili(idRuolo: number, consulentiEsterni?: boolean, chiave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoDipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociabili(idRuolo, consulentiEsterni, chiave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento dell\'assegnazione
         * @summary Inserimento assegnazione
         * @param {AssegnaRuoloConDipendenteRequestDto} assegnaRuoloConDipendenteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertAssegnazioneRuoloDipendenti(assegnaRuoloConDipendenteRequestDto: AssegnaRuoloConDipendenteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssegnazioneRuoloConDipendenteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertAssegnazioneRuoloDipendenti(assegnaRuoloConDipendenteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca delle assegnazioni
         * @summary Ricerca assegnazioni
         * @param {number} [idRuolo] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAssegnazioneRuoloDipendenti(idRuolo?: number, pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoAssegnazioneRuoloConDipendenteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAssegnazioneRuoloDipendenti(idRuolo, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssegnazioneRuoloDipendentiControllerApi - factory interface
 * @export
 */
export const AssegnazioneRuoloDipendentiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssegnazioneRuoloDipendentiControllerApiFp(configuration)
    return {
        /**
         * API Rest per la cancellazione di un\'assegnazione di un ruolo ad un dipendente
         * @summary Cancellazione Assegnazione Ruolo a dipendente
         * @param {AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendentiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssegnazioneRuoloDipendenti(requestParameters: AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendentiRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.deleteAssegnazioneRuoloDipendenti(requestParameters.idRuolo, requestParameters.idDipendente, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca dei dipendenti associabili
         * @summary Ricerca dipendenti associabili
         * @param {AssegnazioneRuoloDipendentiControllerApiGetAssociabiliRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociabili(requestParameters: AssegnazioneRuoloDipendentiControllerApiGetAssociabiliRequest, options?: AxiosRequestConfig): AxiosPromise<PagedDtoDipendenteRegionaleDto> {
            return localVarFp.getAssociabili(requestParameters.idRuolo, requestParameters.consulentiEsterni, requestParameters.chiave, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento dell\'assegnazione
         * @summary Inserimento assegnazione
         * @param {AssegnazioneRuoloDipendentiControllerApiInsertAssegnazioneRuoloDipendentiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAssegnazioneRuoloDipendenti(requestParameters: AssegnazioneRuoloDipendentiControllerApiInsertAssegnazioneRuoloDipendentiRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AssegnazioneRuoloConDipendenteDto>> {
            return localVarFp.insertAssegnazioneRuoloDipendenti(requestParameters.assegnaRuoloConDipendenteRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca delle assegnazioni
         * @summary Ricerca assegnazioni
         * @param {AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendentiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssegnazioneRuoloDipendenti(requestParameters: AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendentiRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoAssegnazioneRuoloConDipendenteDto> {
            return localVarFp.searchAssegnazioneRuoloDipendenti(requestParameters.idRuolo, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteAssegnazioneRuoloDipendenti operation in AssegnazioneRuoloDipendentiControllerApi.
 * @export
 * @interface AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendentiRequest
 */
export interface AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendentiRequest {
    /**
     * 
     * @type {number}
     * @memberof AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendenti
     */
    readonly idRuolo: number

    /**
     * 
     * @type {number}
     * @memberof AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendenti
     */
    readonly idDipendente: number
}

/**
 * Request parameters for getAssociabili operation in AssegnazioneRuoloDipendentiControllerApi.
 * @export
 * @interface AssegnazioneRuoloDipendentiControllerApiGetAssociabiliRequest
 */
export interface AssegnazioneRuoloDipendentiControllerApiGetAssociabiliRequest {
    /**
     * 
     * @type {number}
     * @memberof AssegnazioneRuoloDipendentiControllerApiGetAssociabili
     */
    readonly idRuolo: number

    /**
     * 
     * @type {boolean}
     * @memberof AssegnazioneRuoloDipendentiControllerApiGetAssociabili
     */
    readonly consulentiEsterni?: boolean

    /**
     * 
     * @type {string}
     * @memberof AssegnazioneRuoloDipendentiControllerApiGetAssociabili
     */
    readonly chiave?: string
}

/**
 * Request parameters for insertAssegnazioneRuoloDipendenti operation in AssegnazioneRuoloDipendentiControllerApi.
 * @export
 * @interface AssegnazioneRuoloDipendentiControllerApiInsertAssegnazioneRuoloDipendentiRequest
 */
export interface AssegnazioneRuoloDipendentiControllerApiInsertAssegnazioneRuoloDipendentiRequest {
    /**
     * 
     * @type {AssegnaRuoloConDipendenteRequestDto}
     * @memberof AssegnazioneRuoloDipendentiControllerApiInsertAssegnazioneRuoloDipendenti
     */
    readonly assegnaRuoloConDipendenteRequestDto: AssegnaRuoloConDipendenteRequestDto
}

/**
 * Request parameters for searchAssegnazioneRuoloDipendenti operation in AssegnazioneRuoloDipendentiControllerApi.
 * @export
 * @interface AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendentiRequest
 */
export interface AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendentiRequest {
    /**
     * 
     * @type {number}
     * @memberof AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendenti
     */
    readonly idRuolo?: number

    /**
     * 
     * @type {number}
     * @memberof AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendenti
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendenti
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendenti
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendenti
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendenti
     */
    readonly q?: string
}

/**
 * AssegnazioneRuoloDipendentiControllerApi - object-oriented interface
 * @export
 * @class AssegnazioneRuoloDipendentiControllerApi
 * @extends {BaseAPI}
 */
export class AssegnazioneRuoloDipendentiControllerApi extends BaseAPI {
    /**
     * API Rest per la cancellazione di un\'assegnazione di un ruolo ad un dipendente
     * @summary Cancellazione Assegnazione Ruolo a dipendente
     * @param {AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendentiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssegnazioneRuoloDipendentiControllerApi
     */
    public deleteAssegnazioneRuoloDipendenti(requestParameters: AssegnazioneRuoloDipendentiControllerApiDeleteAssegnazioneRuoloDipendentiRequest, options?: AxiosRequestConfig) {
        return AssegnazioneRuoloDipendentiControllerApiFp(this.configuration).deleteAssegnazioneRuoloDipendenti(requestParameters.idRuolo, requestParameters.idDipendente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca dei dipendenti associabili
     * @summary Ricerca dipendenti associabili
     * @param {AssegnazioneRuoloDipendentiControllerApiGetAssociabiliRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssegnazioneRuoloDipendentiControllerApi
     */
    public getAssociabili(requestParameters: AssegnazioneRuoloDipendentiControllerApiGetAssociabiliRequest, options?: AxiosRequestConfig) {
        return AssegnazioneRuoloDipendentiControllerApiFp(this.configuration).getAssociabili(requestParameters.idRuolo, requestParameters.consulentiEsterni, requestParameters.chiave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento dell\'assegnazione
     * @summary Inserimento assegnazione
     * @param {AssegnazioneRuoloDipendentiControllerApiInsertAssegnazioneRuoloDipendentiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssegnazioneRuoloDipendentiControllerApi
     */
    public insertAssegnazioneRuoloDipendenti(requestParameters: AssegnazioneRuoloDipendentiControllerApiInsertAssegnazioneRuoloDipendentiRequest, options?: AxiosRequestConfig) {
        return AssegnazioneRuoloDipendentiControllerApiFp(this.configuration).insertAssegnazioneRuoloDipendenti(requestParameters.assegnaRuoloConDipendenteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca delle assegnazioni
     * @summary Ricerca assegnazioni
     * @param {AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendentiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssegnazioneRuoloDipendentiControllerApi
     */
    public searchAssegnazioneRuoloDipendenti(requestParameters: AssegnazioneRuoloDipendentiControllerApiSearchAssegnazioneRuoloDipendentiRequest = {}, options?: AxiosRequestConfig) {
        return AssegnazioneRuoloDipendentiControllerApiFp(this.configuration).searchAssegnazioneRuoloDipendenti(requestParameters.idRuolo, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}

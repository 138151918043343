import { useEffect, useState } from 'react'
import CustomSelect, { CustomSelectItem } from '../custom-select/CustomSelect';
import { Accordion, AccordionBody, AccordionHeader, Button, Callout, CalloutTitle, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'design-react-kit'
import { t } from 'i18next'
import { CurriculumVitaeRequestDtoSezioneEnum, ElencoAmbitoEsperienzaForm, EsperienzeProfessionaliDto, EsperienzeProfessionaliRequestDto, EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum, TipologiaContrattoForm } from '../../services/ms-anagrafica-unica';
import { creaNuovoCv, getCvDetail, getElencoAmbitoEsperienza, getElencoTipologiaContratto, getGestioneCvOpts } from '../../store/curriculumVitaeSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import './style.scss'
import { formatDate } from '../../utility/formatDate';
import TextareaInfoChars from '../textarea-infochars-component/TextareaInfoChars';

export interface EspProfessionaliProps {
    disabled: boolean,
    continuaAction: Function,
    indietroAction: Function,
    scrollAction: Function
}

const EspProfessionaliTab = (props: EspProfessionaliProps) => {

    const dispatch = useAppDispatch();
    const { detailStatus, detailResult, insertStatus} = useAppSelector((state) => state.curriculumVitae);
    const { userInfoResult } = useAppSelector((state) => state.user)

    const [settoreOptions, setSettoreOptions] = useState<CustomSelectItem[]>([]);
    const [elencoEsperienzeProfessionali, setElencoEsperienzeProfessionali] = useState<EsperienzeProfessionaliRequestDto[]>([]);
    const [selectedElement, setSelectedElement] = useState<number|undefined>();
    const [saveOnClick, setSaveOnClick] = useState<boolean>(false);
    const [formCollapseOpen, setFormCollapseOpen] = useState(true);
    const [showFormInserimentoExpPRO, setShowFormInserimentoExpPRO] = useState(false);
    const [canAggiungiEsperienza, setCanAggiungiEsperienza] = useState(false);
    const [canContinua, setCanContinua] = useState(true);
    const [inModifica, setInModifica] = useState(false);
    const [ambitoEsperienza, setElencoAmbitoEsperienza] = useState<CustomSelectItem[]>([]);
    const [tipologiaContrattoList, setElencoTipologiaContratto] = useState<CustomSelectItem[]>([]);
    const [listTipologgiaContrattoFiltered, setListTipologgiaContrattoFiltered] = useState<CustomSelectItem[]>([]);
    const [isOpenExpPRO, toggleModal] = useState(false);
    const [esperienzaDaRimuovere, setEsperienzaDaRimuovere] = useState<string | number | undefined>(undefined);

    const initialValues: EsperienzeProfessionaliRequestDto = {
        da: '',
        al: '',
        inCorso: false,
        lavoroPosizioneRicoperta: '',
        principaliAttivitaResponsabilita: '',
        nomeIndirizzoLavoro: '',
        tipoAttivitaSettore: undefined,
        specificare: '',
        ambito: undefined,
        tipologiaContratto:undefined
    };
    

    const schema = z.object({
        da: z.coerce.date({ required_error: 'Il campo DA è obbligatorio' }).refine((data) => (data < new Date()), { message: 'DA non può essere maggiore della data odierna' }),
        al: z.coerce.date({ required_error: 'Il campo AL è obbligatorio' }).refine((data) => (data < new Date()), { message: 'AL non può essere maggiore della data odierna' }).optional(),
        lavoroPosizioneRicoperta: z.string().max(300),
        principaliAttivitaResponsabilita: z.string().max(300),
        nomeIndirizzoLavoro: z.string().max(300).optional(),
        tipoAttivitaSettore: z.string({ required_error: "Tipo attività o settore è un campo obbligatorio" }),
        specificare: z.string().max(300).optional(),
        ambito: z.number(),
        tipologiaContratto: z.number()
    }).superRefine((data, context) => {
        if (data.tipoAttivitaSettore == EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum.Altro && data.specificare == undefined) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Il campo è obbligatorio",
                path: ["specificare"]
            });
        }
    }).superRefine((data, context) => {
        if (data.al! < data.da) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "La data non può essere inferiore a quella inziale",
                path: ["al"]
            });
        }
    }).superRefine((data, context) => {
        if (data.ambito !== 3 && !data.nomeIndirizzoLavoro) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Il campo è obbligatorio",
                path: ["nomeIndirizzoLavoro"]
            });
        }
    });

    const formExpPRO: FormikProps<EsperienzeProfessionaliRequestDto> = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            aggiungiEsperienza(values);
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (elencoEsperienzeProfessionali && elencoEsperienzeProfessionali.length > 0) {
            setShowFormInserimentoExpPRO(false)
            setCanAggiungiEsperienza(true)
            setCanContinua(true)
        } else {
            setShowFormInserimentoExpPRO(true)
        }
    }, [elencoEsperienzeProfessionali])


    useEffect(() => {
        dispatch(getGestioneCvOpts()).unwrap().then((resp) => {
            if (resp) {
                const settoreOpt: Array<CustomSelectItem> = [];
                resp.tipoAttivita &&
                    resp.tipoAttivita.forEach((f: any) => {
                        settoreOpt.push({
                            value: f.valore,
                            label: f.label,
                        });
                    });

                setSettoreOptions(settoreOpt);
            }
            dispatch(getCvDetail(userInfoResult?.entityId!))
        })

        dispatch(getElencoAmbitoEsperienza()).then(res => {
            const getElencoAmbitoEsperienzaOpt: Array<CustomSelectItem> = [];
            const resp = res.payload as ElencoAmbitoEsperienzaForm;
            resp &&
                resp.elencoAmbitoEsperienza && resp.elencoAmbitoEsperienza.forEach((f: any) => {
                    getElencoAmbitoEsperienzaOpt.push({
                        value: f.id,
                        label: f.ambitoEsperienza,
                    });
                });
            resp.elencoAmbitoEsperienza && setElencoAmbitoEsperienza(getElencoAmbitoEsperienzaOpt);
        });

        dispatch(getElencoTipologiaContratto()).then(res => {
            const getElencoTipologiaContrattoOpt: Array<CustomSelectItem> = [];
            const resp = res.payload as TipologiaContrattoForm;
            resp &&
                resp.elencoTipologiaContratto && resp.elencoTipologiaContratto.forEach((f: any) => {
                    getElencoTipologiaContrattoOpt.push({
                        value: f.id,
                        label: f.descrizione,
                    });
                });
            console.log(resp.elencoTipologiaContratto!);
            resp.elencoTipologiaContratto && setElencoTipologiaContratto(getElencoTipologiaContrattoOpt);
        });
    }, [])

    useEffect( () => {
        !!detailResult && detailResult.elencoEsperienzeProfessionali && detailResult.elencoEsperienzeProfessionali.length > 0 && 
        fillElencoEsperienzeProfessionaliState(detailResult.elencoEsperienzeProfessionali)
    }, [detailResult])

    const fillElencoEsperienzeProfessionaliState = (elencoExp:EsperienzeProfessionaliDto[]) => {
        setElencoEsperienzeProfessionali(elencoExp.map((value, i) => {

            let valueToReturn: EsperienzeProfessionaliRequestDto;
            valueToReturn = {
                da: value.da,
                al: value.al || '',
                inCorso: !value.al,
                lavoroPosizioneRicoperta: value.lavoroPosizioneRicoperta,
                principaliAttivitaResponsabilita: value.principaliAttivitaResponsabilita,
                nomeIndirizzoLavoro: value.nomeIndirizzoLavoro,
                tipoAttivitaSettore: value.tipoAttivitaSettore,
                specificare: value.specificare,
                ambito: value.ambito,
                tipologiaContratto: value.tipologiaContratto
            }
            
            if (value.ambito && value.tipologiaContratto) {
                handleSelectChangeElencoAmbitoEsperienza('ambito', value.ambito, true)
                handleSelectChangeElencoTipologiaContratto('tipologiaContratto', value.tipologiaContratto)
            }
            return valueToReturn;
        }));
    }

    const handleSelectChange = (name: string, e: string | number) => {
        console.log(name, e);
        formExpPRO.setFieldValue(name, e);
    };

    const getFieldError = (form: FormikProps<EsperienzeProfessionaliRequestDto>, fieldName: keyof EsperienzeProfessionaliRequestDto): string => {
        if (formExpPRO.getFieldMeta(fieldName).touched) {
            return formExpPRO.errors[fieldName] || "";
        } else return "";
    };

    let aggiungiEsperienza = (values: EsperienzeProfessionaliRequestDto) => {
        formExpPRO.validateForm();
        console.log(values);
        if (formExpPRO.isValid) {
            if (selectedElement!== undefined) {
                let updatedData = values;
                const array = [...elencoEsperienzeProfessionali];
                array[selectedElement] = updatedData;
                setElencoEsperienzeProfessionali(array);
                setSelectedElement(undefined);
            } else {
                let dataDetail = [...elencoEsperienzeProfessionali, values]
                setElencoEsperienzeProfessionali(dataDetail);
            }
        }
        setSaveOnClick(true);
        formExpPRO.resetForm({ values: initialValues });
    }

    const removeItem = (index: number) => {
        setElencoEsperienzeProfessionali(prevState => prevState.filter( (el, i) => i !== index))
        setSaveOnClick(true);
        formExpPRO.resetForm({ values: initialValues });
    }

    const modificaEsperienza = (index: number) => {
        const array = [...elencoEsperienzeProfessionali];
        const esp = array[index];
        console.log("modifica", esp)
        if (esp != null && esp != undefined) {
            setSelectedElement(index);
            formExpPRO.resetForm({ values: initialValues });
            formExpPRO.setValues(esp);
            setCanContinua(false)
            setCanAggiungiEsperienza(false)
            setShowFormInserimentoExpPRO(true)
            setInModifica(true)
            if (esp.ambito && esp.tipologiaContratto) {
                handleSelectChangeElencoAmbitoEsperienza('ambito', esp.ambito, true)
                handleSelectChangeElencoTipologiaContratto('tipologiaContratto', esp.tipologiaContratto)
            }
        }
    }

    useEffect(() => {
        if (saveOnClick === true) {
            console.log("submit", elencoEsperienzeProfessionali)
            dispatch(creaNuovoCv({
                elencoEsperienzeProfessionali: elencoEsperienzeProfessionali,
                sezione: CurriculumVitaeRequestDtoSezioneEnum.EsperienzeProfessionali
            }))
            .unwrap().then((resp) => {
                if (resp) {
                    console.log('RESP >>>> ', resp);
                
                }
                dispatch(getCvDetail(userInfoResult?.entityId!))
            })
        }
        setSaveOnClick(false);
    }, [elencoEsperienzeProfessionali])

    const changeInCorso = () => {
        const inCorso = !formExpPRO.values.inCorso;
        formExpPRO.setFieldValue('inCorso', inCorso)
        inCorso && formExpPRO.setFieldValue('al', '')
    }
    
    const handleSelectChangeElencoAmbitoEsperienza = (name: string, e: string | number, forceValidation?: boolean) => {
        console.log(name, e);
        formExpPRO.setFieldValue(name, e, forceValidation); 

        formExpPRO.setFieldValue("tipologiaContratto", '', forceValidation); //reset tipo contratto

        setListTipologgiaContrattoFiltered((e===1 || e===2) ? tipologiaContrattoList.filter((f) => f.value === 1 || f.value === 2)
            : tipologiaContrattoList.filter((f) => f.value === 3 || f.value === 4));       

    };

    const handleSelectChangeElencoTipologiaContratto = (name: string, e: string | number, forceValidation?: boolean) => {
        console.log(name, e);
        formExpPRO.setFieldValue(name, e, forceValidation);
    };

    const confermaRimuoviAttivita = (stato: boolean) => {
        if(stato) {
            removeItem(Number(esperienzaDaRimuovere))
        }
        toggleModal(!isOpenExpPRO)
        setEsperienzaDaRimuovere(undefined)
    }

    return (
        <div>
            {detailStatus === STATUS_FULLFILLED && elencoEsperienzeProfessionali.length > 0 &&
                <>
                    <Accordion background="active">
                        <AccordionHeader
                            active={formCollapseOpen}
                            onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                        >
                            {t("Esperienze aggiunte")}
                        </AccordionHeader>
                        <AccordionBody active={formCollapseOpen}>
                            <Table responsive striped>
                                <tbody>
                                    {(insertStatus === STATUS_FULLFILLED) && elencoEsperienzeProfessionali.map((data, index) =>
                                        <tr key={data.da + '_' + data.ambito}>
                                            <td>{data.lavoroPosizioneRicoperta} dal {formatDate(data.da!, true)} {!!data.al ? `al ${formatDate(data.al, true)}` : ''}</td>
                                            <td>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                        <Button
                                                            className='btn-secondary mr-2'
                                                            onClick={() => {toggleModal(true);setEsperienzaDaRimuovere(index)}}
                                                            disabled={
                                                                (selectedElement !== undefined && selectedElement !== index) ||
                                                                (showFormInserimentoExpPRO)
                                                            }
                                                        >{t("elimina")}</Button>
                                                    <Button 
                                                        color={(selectedElement !== undefined && selectedElement === index && showFormInserimentoExpPRO) ? 'warning' : 'primary'}
                                                        onClick={() => modificaEsperienza(index)}
                                                        disabled={
                                                            (selectedElement !== undefined && selectedElement !== index) ||
                                                            showFormInserimentoExpPRO
                                                        }
                                                    >{t("modifica")}</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </AccordionBody>
                    </Accordion>
                </>
            }
            {showFormInserimentoExpPRO && insertStatus === STATUS_FULLFILLED &&
            <Callout className='au-callout col-md-12 mt-5'>
                <CalloutTitle>{inModifica ? 'Modifica esperienza' : 'Esperienza lavorativa'}</CalloutTitle>
                 <div className="form-row mt-5">
                    <CustomSelect label="Tipologia esperienza"
                        name="ambito"
                        placeholder="Indica l'ambito dell'esperienza lavorativa"
                        wrapperClass="form-group col-md-12 required"
                        options={ambitoEsperienza}
                        value={formExpPRO.values.ambito}
                        onChange={(e) => handleSelectChangeElencoAmbitoEsperienza('ambito', e, true)}
                        invalid={!!getFieldError(formExpPRO, "ambito")}
                        infoText={t(getFieldError(formExpPRO, "ambito")) || ""}
                    />
                    <CustomSelect label="Tipologia contratto"
                        name="tipologiaContratto"
                        placeholder="Tipologia contratto"
                        wrapperClass="form-group col-md-12 required"
                        options={(listTipologgiaContrattoFiltered)}
                        value={formExpPRO.values.tipologiaContratto}
                        onChange={(e) => handleSelectChangeElencoTipologiaContratto('tipologiaContratto', e,true)}
                        disabled={!formExpPRO.values.ambito}
                        invalid={!!getFieldError(formExpPRO, "tipologiaContratto")}
                        infoText={t(getFieldError(formExpPRO, "tipologiaContratto")) || ""}
                    />
                </div> 
                <div className="form-row mt-5">

                    <Input
                        label="Da"
                        type="date"
                        placeholder="Da"
                        id="da"
                        wrapperClassName="form-group col-md-5 required"
                        max={new Date().toISOString().slice(0, 10)}
                        name="da"
                        value={formExpPRO.values.da}
                        disabled={props.disabled}
                        onChange={formExpPRO.handleChange}
                        invalid={!!getFieldError(formExpPRO, "da")}
                        infoText={t(getFieldError(formExpPRO, "da")) || ""}
                    />

                    <FormGroup check className="form-group text-center col-md-2">
                        <Input
                            type="checkbox"
                            id="inCorso"
                            name="inCorso"
                            checked={formExpPRO.values.inCorso}
                            disabled={false}
                            onChange={changeInCorso}
                        />
                        <Label for={"inCorso"} check>In corso</Label>
                    </FormGroup>

                    <Input
                        label="A"
                        type="date"
                        placeholder="A"
                        id="al"
                        wrapperClassName={"form-group col-md-5"}
                        min={formExpPRO.values.da == '' ? '' : formExpPRO.values.da}
                        max={new Date().toISOString().slice(0, 10)}
                        name="al"
                        value={formExpPRO.values.al}
                        disabled={formExpPRO.values.inCorso}
                        onChange={formExpPRO.handleChange}
                        invalid={!!getFieldError(formExpPRO, "al")}
                        infoText={t(getFieldError(formExpPRO, "al")) || ""}
                    />
                </div>
                <TextareaInfoChars
                    className=""
                    label="Lavoro o posizione ricoperta"
                    placeholder="Indica la posizione professionale o il ruolo lavorativo ricoperto"
                    id="lavoroPosizioneRicoperta"
                    wrapperClassName="form-group required col-md-12"
                    name="lavoroPosizioneRicoperta"
                    maxLength={300}
                    value={formExpPRO.values.lavoroPosizioneRicoperta}
                    onChange={formExpPRO.handleChange}
                    invalid={!!getFieldError(formExpPRO, "lavoroPosizioneRicoperta")}
                    infoText={t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                />
                <TextareaInfoChars
                    className=""
                    label="Principali attività e responsabilità"
                    placeholder="Indica le principali attività svolte durante l’attività lavorativa"
                    id="principaliAttivitaResponsabilita"
                    wrapperClassName="form-group required col-md-12"
                    name="principaliAttivitaResponsabilita"
                    value={formExpPRO.values.principaliAttivitaResponsabilita}
                    onChange={formExpPRO.handleChange}
                    invalid={!!getFieldError(formExpPRO, "principaliAttivitaResponsabilita")}
                    infoText={t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                    maxLength={300}
                />
                <TextareaInfoChars
                    className=""
                    label={formExpPRO.values.ambito === 3 ? "Nome e indirizzo del committente" : "Nome e indirizzo del datore di lavoro"}
                    placeholder="Indica il nome dell’azienda e la sede di lavoro dell’azienda"
                    id="nomeIndirizzoLavoro"
                    wrapperClassName={formExpPRO.values.ambito === 3 ? "form-group col-md-12" : "form-group required col-md-12"}
                    name="nomeIndirizzoLavoro"
                    maxLength={300}
                    value={formExpPRO.values.nomeIndirizzoLavoro}
                    onChange={formExpPRO.handleChange}
                    invalid={!!getFieldError(formExpPRO, "nomeIndirizzoLavoro")}
                    infoText={t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                />
                <CustomSelect label="Tipo di attività o settore"
                    name="tipoAttivitaSettore"
                    placeholder="Seleziona settore"
                    wrapperClass="form-group col-md-12 required"
                    options={settoreOptions}
                    onChange={(e) => handleSelectChange('tipoAttivitaSettore', e)}
                    value={formExpPRO.values.tipoAttivitaSettore}
                    invalid={!!getFieldError(formExpPRO, "tipoAttivitaSettore")}
                    infoText={t(getFieldError(formExpPRO, "tipoAttivitaSettore")) || ""}
                />
                <TextareaInfoChars
                    className=""
                    label="Specificare"
                    placeholder="Indica la specifica del campo tipo di attività o settore"
                    id="specificare"
                    wrapperClassName={"form-group col-md-12 " + (formExpPRO.values.tipoAttivitaSettore == EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum.Altro && 'required')}
                    name="specificare"
                    maxLength={300}
                    value={formExpPRO.values.specificare}
                    onChange={formExpPRO.handleChange}
                    invalid={!!getFieldError(formExpPRO, "specificare")}
                    infoText={t('Testo massimo di 300 caratteri: si ricorda di non riportare dati e informazioni personali') || ''}
                />

                <div className="d-flex align-items-center justify-content-end mb-2">
                    {(elencoEsperienzeProfessionali && elencoEsperienzeProfessionali.length > 0) && <Button
                        color="secondary"
                        className="mr-3"
                        onClick={() => {
                            setSelectedElement(undefined);
                            setShowFormInserimentoExpPRO(false)
                            setCanAggiungiEsperienza(true)
                            setCanContinua(true)
                            if(inModifica) {
                                setInModifica(false)
                            }
                            props.scrollAction()
                            formExpPRO.resetForm();
                        }}
                    >
                        {t('Annulla')}
                    </Button>}
                    <Button
                        color="primary"
                        outline
                        onClick={() => {
                            props.scrollAction()
                            formExpPRO.submitForm();
                        }}
                    >
                        {t('salva esperienza')}
                    </Button>
                </div>
            </Callout>}
            <div className="d-flex">
                <Button
                    color="secondary"
                    className="mr-auto p-2 mt-2"
                    onClick={() => {
                        props.indietroAction()
                    }}
                >
                    {t('indietro')}
                </Button>

                <Button
                    color="primary"
                    className="mt-2"
                    disabled={!canAggiungiEsperienza}
                    onClick={() => {
                        setShowFormInserimentoExpPRO(true)
                        setCanAggiungiEsperienza(false)
                        setCanContinua(false)
                        formExpPRO.resetForm({ values: initialValues });
                    }}
                >
                    {t('Aggiungi esperienza')}
                </Button>
                &nbsp;
                <Button
                    color="primary"
                    className="mt-2"
                    disabled={!canContinua}
                    onClick={() => {
                        props.continuaAction();
                    }}
                >
                    {t('continua')}
                </Button>

            </div>
            <Modal
                isOpen={isOpenExpPRO}
                toggle={() => toggleModal(!isOpenExpPRO)}
                labelledBy='elimina-attivita'
                centered
            >
                <ModalHeader toggle={() => { toggleModal(!isOpenExpPRO) }} id='elimina-attivita'>
                    Conferma rimozione
                </ModalHeader>
                <ModalBody>
                    <p>Sei sicuro di voler eliminare questa esperienza professionale?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { confermaRimuoviAttivita(false) }}>
                        No
                    </Button>
                    <Button color='primary' onClick={() => { confermaRimuoviAttivita(true) }}>
                        Si
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default EspProfessionaliTab
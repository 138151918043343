import { Dimmer, Fade } from "design-react-kit";
import { Spinner } from "reactstrap";

import './PageLoader.scss';
import { useAppSelector } from "../../hooks";

export interface PageLoaderProps {
    loadingMessage? : string
}

function PageLoader(props: PageLoaderProps) {
    const {loaderActive} = useAppSelector((state) => state.loader);
    return (
        loaderActive > 0 ? 
        <Fade>
            <Dimmer wrapperClassName="page-loader" >

                <span className="d-flex justify-content-center mb-2">
                    <Spinner active='true' color="primary" />
                </span>

                <h3 className="text-center text-primary " > 
                    {props.loadingMessage || 'Attendere...'}
                </h3>
            </Dimmer>
        </Fade> : <></>
    )
}

export default PageLoader;


import PageHeader from "../../components/page-header/PageHeader";
import { useTranslation } from "react-i18next";
import { HREF_RUBRICA_ELENCO_DIPENDENTI } from "../../components/sidemenu/sidemenuConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { detailsDipendente, getNumeriTelefonoAssociabili } from "../../store/rubricaSlice";
import PageLoader from "../../components/page-loader/PageLoader";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import NotificationManagerComponent from "../../components/notificationComponent/NotificationManagerComponent";
import DipendenteDetailsComponent from "../../components/dipendente-detail-form/DipendenteDetailsComponent";
import { Button } from "design-react-kit";
import { CustomSelectItem } from "../../components/custom-select/CustomSelect";

const VisualizzaUtenze = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { dipendenteResult, dipendenteStatus, numeriAssociabiliResult, inserisciTelefonoCredenzialiEmailStatus} = useAppSelector((state) => state.rubrica)

    let numerazioniAssociabili: CustomSelectItem[] = [];
    numeriAssociabiliResult?.forEach((s) => {
        numerazioniAssociabili.push({ label: s.label, value: s.valore });
    });

    const getDetails = () => {
        dispatch(detailsDipendente(Number.parseInt(id!)));
    }


    useEffect(() => {
        if (id != '' && id != undefined && id != null) {
            dispatch(getNumeriTelefonoAssociabili());
            getDetails();
        }
    }, [])

    return (
        <div>
            <PageHeader
                showIcon={true}
                urlBack={HREF_RUBRICA_ELENCO_DIPENDENTI} 
                title={t("Visualizza telefono, email, credenziali")!}
            />
            {dipendenteStatus === STATUS_FULLFILLED && (
            <div>
                <DipendenteDetailsComponent dipendente={dipendenteResult!} except={[]} />
                <div className="d-flex flex-row-reverse">
                    <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_RUBRICA_ELENCO_DIPENDENTI}`) }} >{t('annulla')} </Button>
                </div>
            </div>
            )
            }
        </div>
    );
}

export default VisualizzaUtenze;
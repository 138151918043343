import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { detailsCesszioneDipendente } from '../../store/dipendentiRegionaliSlice';
import { HREF_ELENCO_DIPENDENTI_REG } from '../../components/sidemenu/sidemenuConstants';
import { Button } from 'design-react-kit';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { CODICE_CID, CODICE_FISCALE, COGNOME, COLLABORATORE, DATA_FINE_RAPPORTO, DATA_INIZIO_RAPPORTO, INCARICO_IN_CORSO, MOTIVO_CESSAZIONE, NOME, NUMERO_ATTO_CESSAZIONE, STRUTTURA_ORGANIZZATIVA, TIPOLOGIA_CONTRATTUALE } from './GestioneCessazioneDipendentiConstansts';
import CessazioneDipendenteDetailsComponent from '../../components/cessazione-dipendente-detail-component/CessazioneDipendenteDetailsComponent';
import PageHeader from '../../components/page-header/PageHeader';

const VisualizzaCessazioneDipendente = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fieldUsed: Array<string> = [CODICE_CID, NOME, COGNOME, CODICE_FISCALE, STRUTTURA_ORGANIZZATIVA, TIPOLOGIA_CONTRATTUALE, INCARICO_IN_CORSO, DATA_INIZIO_RAPPORTO, DATA_FINE_RAPPORTO, COLLABORATORE, MOTIVO_CESSAZIONE, NUMERO_ATTO_CESSAZIONE];

    const cessazioneDipendenteResult = useAppSelector((state) => state.dipendentiRegionali.detailsResult);
    const cessazioneDipendenteStatus = useAppSelector((state) => state.dipendentiRegionali.detailsStatus);

    useEffect(() => {
        getDetailsFromApi()
    }, [0])

    const getId = () => {
        return Number(id!);
    }

    const getDetailsFromApi = () => {
        let idDipendente = getId();
        dispatch(detailsCesszioneDipendente({ idDipendente }));
    }
    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_DIPENDENTI_REG} title='Visualizza cessazione dipendente'></PageHeader>
            {cessazioneDipendenteStatus === STATUS_FULLFILLED && <div>
                <div className="row">
                    <div className="col">
                        <CessazioneDipendenteDetailsComponent detailsResult={cessazioneDipendenteResult!} />
                    </div>
                </div>
                <div className="postion-relative py-5">
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2 mr-2"
                            onClick={() => navigate(HREF_ELENCO_DIPENDENTI_REG)}>Indietro</Button>
                    </div>
                </div>

            </div>}
        </div>
    )
}

export default VisualizzaCessazioneDipendente
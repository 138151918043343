import { Col, Container, Headers, Row} from "design-react-kit";
import { Outlet } from "react-router-dom";
import HeaderCenter from "../headers/HeaderCenter";
import HeaderSlim from "../headers/HeaderSlim";
import Sidemenu from "../sidemenu/Sidemenu";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUserInfo } from "../../store/userSlice";
import { getNotificheNonLette } from "../../store/notificheSlice";
import { NotificaDtoStatoEnum } from "../../services/ms-anagrafica-unica";
import Footer from "../footer/Footer";

function Layout() {
    const dispatch = useAppDispatch()
    const { userInfoResult } = useAppSelector(
        (state) => state.user
    );

    useEffect(() => {
        !userInfoResult && dispatch(getUserInfo()).unwrap().then(resp => {
            if (resp && !resp.cittadinoEsterno) return dispatch(getNotificheNonLette({stato: NotificaDtoStatoEnum.NonLetta}))
        })
    }, [userInfoResult]);

    return (
        <div>
            <Headers>

                <HeaderSlim />
                <HeaderCenter />

            </Headers>

            <Container fluid style={{minHeight: "70rem"}}>
                <Row>
                {!userInfoResult?.cittadinoEsterno ? <>
                    <Col xs="lg-2">
                        <Sidemenu />
                    </Col>

                    <Col xs="lg-10" className='p-5'>
                        <Outlet />
                    </Col>
                </> : <Col xs="lg-12" className='p-5'>
                        <Outlet />
                    </Col>}
                </Row>
            </Container>
            <Footer/>

        </div>
    )
}

export default Layout;
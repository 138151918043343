import { StrutturaOrganizzativaOrganigrammaDto } from "../../services/ms-anagrafica-unica";
import './style.scss';

interface CustomTreeNodeProps {
    active: boolean,
    struttura: StrutturaOrganizzativaOrganigrammaDto,
    cambiaSoAttiva: Function
}

const CustomTreeNode = (props:CustomTreeNodeProps) => {
    return (
        <div
            id={props.active ? 'active' : props.struttura.tipo === 'DIPARTIMENTO' ? props.struttura.tipo.toLowerCase() : ''}
            className={`so so-${props.struttura.tipo?.toLowerCase()} ${props.active ? ' active' : ''}`}
            onClick={() => (!props.active) && props.cambiaSoAttiva(props.struttura.id)}
        >{props.struttura.descrizioneBreve}</div>
    )
}

export default CustomTreeNode;
import { Button, Col, Icon, Row } from "design-react-kit";
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAppDispatch } from "../../hooks";
import {
  cancellaDocumento,
  dettaglioDocumento
} from "../../store/strutturaOrganizzativaSlice";
import {
  uploadDocumento
} from "../../store/DocumentiSlice";
import { downloadDocument } from "../../store/DocumentiSlice";
import { DocumentoDto } from "../../services/ms-anagrafica-unica";
import { showNotifica } from "../../store/loaderSlice";
import { NOTIFICA_STATO } from "../../utility/Notifica";


export interface DocumentUploadProps {
  maxSize?: number;
  types?: string[];
  documentName: string;
  className?: string;
  setDocumentId?: (id: number | undefined) => void;
  invalid?: boolean,
  infoText?: string,
  idDocumento?: number | undefined;
  hiddenDeleteButton?: boolean;
  hiddenTitle?: boolean;
  setDocumentIdNameFile?: (id: number | undefined, name: string | undefined) => void;
  returnDocumentName?: boolean;
  disabled?: boolean,
  isSigned?: boolean,
  resetUpload?: boolean,
  removedFile?: Function,
  emitRimozioneFile?: () => void;
  testoBtnScarica?: string,
  disabilitaDeleteFile?: boolean
}

export type DocumentUploadRef = {
  resetUploadFile: () => void;
}

const DocumentUpload = forwardRef((props: DocumentUploadProps, ref: Ref<DocumentUploadRef>) => {
  const dispatch = useAppDispatch();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined | { size: number, type: string, name: string }>(undefined);
  const [isUploaded, setUploaded] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<DocumentoDto | undefined>(
    undefined
  );
  const [getDocument, setGetDocument] = useState(false)
  
  useEffect(() => {
    console.log('view document')
    console.log(props.idDocumento)
    if (!!props.idDocumento && props.idDocumento !== -1) {
      dispatch(dettaglioDocumento(props.idDocumento!)).unwrap().then((a) => {
        setGetDocument(true)
        setUploaded(true)
        setUploadedFile({ ...a })
      })
    }
    console.log(uploadedFile)
  }, [props.idDocumento])

  const resetUploadFile = () => {
    setSelectedFile(undefined);
    setUploaded(false);
    setUploadedFile(undefined);
  };

  const maxSize = props.maxSize || 5000000;
  const types = props.types || [
    'application/pdf',
    'image/png',
    'image/jpeg'
  ]

  useImperativeHandle(ref, () => ({
    resetUploadFile
  }), [resetUploadFile]);


  const uploadFile = () => {
    if (selectedFile) {
      dispatch(uploadDocumento({
        file: selectedFile as File,
        signed: selectedFile.type !== 'application/pdf' ? false : props.isSigned
      }))
        .unwrap()
        .then(resp => {
          setUploadedFile(resp.data);
          setUploaded(true)
          if (props.setDocumentId) {
            props.setDocumentId(resp.data.id);
          }
          if (props.returnDocumentName) {
            if (props.setDocumentIdNameFile) {
              props.setDocumentIdNameFile(resp.data.id, props.documentName);
            }
          }
        });
    }
  };

  const handleSelectClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const fileSelected = (event.target as HTMLInputElement)?.files?.[0];
    if (fileSelected) {
      if (fileSelected.size > maxSize) {
        dispatch(showNotifica({
            titolo: 'FORMATO FILE NON CONSENTITO',
            stato: 0,
            messaggio: 'La dimensione del file deve essere inferiore a 5MB',
            tipo: NOTIFICA_STATO.error
        }))
        return false
      }
      if (!types.includes(fileSelected.type)) {
            dispatch(showNotifica({
                titolo: 'FORMATO FILE NON CONSENTITO',
                stato: 0,
                messaggio: 'Il formato del file non è del tipo ' + types.join(' oppure '),
                tipo: NOTIFICA_STATO.error
            }))
            return false
      }
      setUploaded(false);
      setSelectedFile(fileSelected);
    }
    // props.handleFile(fileUploaded);
  };

  const handleClear = () => {
    setSelectedFile(undefined);
    // @ts-ignore: Object is possibly 'null'.
    hiddenFileInput.current.value = null;
  };

  const vediFile = () => {
    dispatch(dettaglioDocumento(uploadedFile?.id!)).unwrap().then((a) => {
      a && a.id && dispatch(downloadDocument({
        idDocumento: a.id
      })).unwrap().then(resp => {
        if (!!resp) {
          const blob = new Blob([resp], { type: a.contentType })
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = a.filename || '';
          link.click();
        }
      });
    })
  }


  const handleDeleteFile = () => {
   if(props.emitRimozioneFile)
      props.emitRimozioneFile();
    else{
      if(!!!props.disabilitaDeleteFile)
        dispatch(cancellaDocumento(uploadedFile?.id!));
    }
    setSelectedFile(undefined);
    setUploaded(false);
    setUploadedFile(undefined);
    setGetDocument(false)

    if (props.setDocumentId) {
      props.setDocumentId!(-1);
    }
    if (props.returnDocumentName) {
      if (props.setDocumentIdNameFile) {
        props.setDocumentIdNameFile(-1, props.documentName);
      }
    }
    
    // @ts-ignore: Object is possibly 'null'.
    hiddenFileInput.current.value = null;
    props.removedFile && props.removedFile(true);
  };

  const checkFile = () => {
    if (props.idDocumento && props.idDocumento !== -1) {
      dispatch(dettaglioDocumento(props.idDocumento)).then(res => {
        if (!!res.payload) { 
          const response = (res.payload as DocumentoDto);
          setUploaded(true);
          setUploadedFile(response);
          setSelectedFile({ size: response.dimensione!, type: response.contentType!, name: response.filename! })
        }
      });
    }
  }

  useEffect(() => {
    checkFile()
  }, [])

  return (
    <div className={props.className}>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />

      {!props.hiddenTitle && <Row className="mt-3">
        {props.documentName && <Col md="12">
          <label><strong>{props.documentName}</strong></label>
        </Col>}
      </Row>}
      {(selectedFile || getDocument) && (
        <Row>
          <Col md="8" className="mb-2">
            {isUploaded && <Icon icon="it-check-circle" className="mr-2" />}
            {selectedFile?.name != null ? selectedFile?.name : uploadedFile?.filename}
          </Col>

          {!isUploaded && (
            <Col>
              <Button
                color="primary"
                outline
                size="xs"
                className="mx-2"
                onClick={handleClear}
              >
                <Icon icon="it-close-circle" size="xs" color="primary"></Icon>{" "}
                Rimuovi
              </Button>

              <Button
                color="primary"
                size="xs"
                className=""
                onClick={uploadFile}
              >
                <Icon icon="it-upload" size="xs" color="white"></Icon> Carica
              </Button>
            </Col>
          )}
          {getDocument && (<div>
            {uploadedFile && (
              <Col>
                <Button
                  color="primary"
                  outline
                  size="xs"
                  className="mx-2"
                  onClick={vediFile}
                >
                  <Icon
                    icon="it-password-visible"
                    size="xs"
                    color="primary"
                  ></Icon>{" "}
                  { props.testoBtnScarica ?? "Vedi" }
                </Button>

                {!props.hiddenDeleteButton && <Button
                  color="danger"
                  outline
                  size="xs"
                  onClick={handleDeleteFile}

                >
                  <Icon icon="it-delete" size="xs" color="danger"></Icon> Elimina
                </Button>}
              </Col>
            )}
          </div>
          )}
        </Row>
      )}
      {!getDocument && (
        <Row className="mb-3">
          <Col>
            <Button className="mx-1" outline size="sm" color="primary" onClick={handleSelectClick} disabled={props.disabled}>
              Seleziona file
            </Button>
            {props.resetUpload && isUploaded && (
              <Button
                className="mx-1"
                color="danger"
                outline
                size="sm"
                onClick={handleDeleteFile}
              >
                  <Icon icon="it-delete" size="xs" color="danger"></Icon> Cancella
              </Button>
            )}
          </Col>
        </Row>
      )}
      {props.infoText && <small className='form-text text-muted'> Il campo {props.documentName} è obbligatorio</small>}
    </div>
  );
})

export default DocumentUpload;

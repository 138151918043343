import { Button, Col, Icon, Input, Nav, NavItem, Row } from "design-react-kit";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  cancellaDocumento,
  dettaglioDocumento,
} from "../../store/strutturaOrganizzativaSlice";
import { DocumentoDto } from "../../services/ms-anagrafica-unica";
import DocumentUpload from '../document-upload/DocumentUpload'
import { downloadDocument } from "../../store/DocumentiSlice";
import { useTranslation } from "react-i18next";
import { hideLoader, showLoader } from "../../store/loaderSlice";
import LoaderMini from "../page-loader/LoaderMini";

export interface DocumentUploadProps {
  documentName: string;
  className?: string;
  idDocumento?: number | undefined;
  download?: boolean;
  view?: boolean;
  protocolla?: boolean;
  numProtocollo?: string;
  compact?: boolean;
  cancella?: boolean;
}

function DocumentView(props: DocumentUploadProps) {
  const dispatch = useAppDispatch();

  const [uploadedFile, setUploadedFile] = useState<DocumentoDto | undefined>(undefined);
  const [protocollo, setProtocollo] = useState<string | undefined>(undefined);
  const [getDocument, setGetDocument] = useState(false)

  useEffect(() => {
    console.log('view document')
    if (props.idDocumento != null && props.idDocumento != undefined) {
      dispatch(showLoader())
      dispatch(dettaglioDocumento(props.idDocumento!)).unwrap().then((a) => {
        if (!!a) {
          
          setUploadedFile({ ...a })
          setGetDocument(true)
          dispatch(hideLoader())
        }
      })
    }
  }, [props.idDocumento])


  const vediFile = (download: boolean = false) => {
    dispatch(dettaglioDocumento(uploadedFile?.id!)).unwrap().then((a) => {
      a && a.id && dispatch(downloadDocument({
        idDocumento: a.id
      })).unwrap().then(resp => {
        if (!!resp) {
          const blob = new Blob([resp], { type: a.contentType })
          let url;
          if (!download) {
            url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noreferrer');
          } else {
            const link = document.createElement('a');
            url = URL.createObjectURL(blob);
            link.href = url;
            link.download = a.filename || '';
            link.click()
          }
        }
      });
    })
  }

  useEffect( () => setProtocollo(props.numProtocollo), [props.numProtocollo])

  const protocolla = () => {
    console.log('funzione protocollazione');
    setProtocollo('XXX')
  }

  const handleDeleteFile = (id:number) => {
    dispatch(cancellaDocumento(uploadedFile?.id!));
    setUploadedFile(undefined);
    setGetDocument(false)
  }

  return (
    <div className={props.className}>
      {!props.compact ? 
      <Row className="mt-3">
        <Col md="12">
          <strong>{props.documentName}</strong><br />
          <small>{uploadedFile?.filename}</small>
        </Col>
      </Row> : 
      <Row className="mt-3">
        {uploadedFile?.filename ? <Col md="12">
          <Button
            color="primary"
            outline
            size="xs"
            onClick={() => vediFile(true)}
            style={{whiteSpace: "nowrap"}}
          >
            <Icon
              icon="it-download"
              size="xs"
              color="primary"
            ></Icon>&nbsp;{ uploadedFile?.filename}
          </Button>
        </Col> : <LoaderMini />}
      </Row>
      }
      {getDocument && uploadedFile && !!props.view && (
        <Button
          color="primary"
          outline
          size="xs"
          onClick={() => vediFile()}
        >
          <Icon
            icon="it-password-visible"
            size="xs"
            color="primary"
          ></Icon>{" "}
          Apri file
        </Button>
      )}
      {getDocument && uploadedFile && !!props.download && (
        <>&nbsp;<Button
          color="primary"
          outline
          size="xs"
          onClick={() => vediFile(true)}
        >
          <Icon
            icon="it-download"
            size="xs"
            color="primary"
          ></Icon>{" "}
          Scarica file
        </Button></>
      )}
      {getDocument && uploadedFile && !!props.cancella && (
        <Button
          className="mx-1"
          color="danger"
          outline
          size="xs"
          onClick={() => handleDeleteFile(uploadedFile.id!)}
        >
            <Icon icon="it-delete" size="xs" color="danger"></Icon> Cancella
        </Button>
      )}
      {getDocument && uploadedFile && !!props.protocolla && (
        <>&nbsp;
        <Button
          color="primary"
          outline
          size="xs"
          disabled={!!protocollo}
          onClick={() => protocolla()}
        >
          <Icon
            icon="it-locked"
            size="xs"
            color="primary"
          ></Icon>{" "}
          Protocolla
        </Button></>
      )}
      {protocollo && !!props.numProtocollo &&
      <Input
        type="text"
        label="Numero protocollo uscita"
        name="protocollo"
        value={protocollo}
        readOnly={true}
        wrapperClassName="my-5"
      />}
      {!props.compact && !getDocument && (
        <Row className="mb-3">
          <Col>
            <DocumentUpload documentName=""></DocumentUpload>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default DocumentView;

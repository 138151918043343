import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AreaPersonaleControllerApi, AreaPersonaleControllerApiElencoBandiRequest, AreaPersonaleControllerApiLeggiSingoloBandoRequest, AreaPersonaleDto, DocumentoDto, ElencoBandiContestoForm, ElencoDocumentiStackHolderForm, FormsControllerApi, GestioneDocumentaleControllerApi, GestioneDocumentaleControllerApiGetElencoDocumentiRequest, PagedDtoBandoDto, PagedDtoDocumentoDto, ScriviAreaPersonaleRequestDto } from '../services/ms-anagrafica-unica';
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from './store-constants';

export interface StakeholderState {
    searchResult: AreaPersonaleDto | null,
    searchStatus: string,
    modificaResult: AreaPersonaleDto | null,
    modificaStatus: string,
    documentiResult: PagedDtoDocumentoDto | null,
    documentiStatus: string,
    bandiConcorsiResult: PagedDtoBandoDto | null,
    bandiConcorsiStatus: string,
    bandiConcorsiInput: AreaPersonaleControllerApiLeggiSingoloBandoRequest | null,
    formsResult: ElencoBandiContestoForm | null,
    formsStatus: string,
    areaDocumentiResult: ElencoDocumentiStackHolderForm | null,
    areaDocumentiStatus: string
}

const initialState: StakeholderState = {
    searchResult: null, 
    searchStatus: '',
    modificaResult: null,
    modificaStatus: '',
    documentiResult: null,
    documentiStatus: '',
    bandiConcorsiResult: null,
    bandiConcorsiStatus: '',
    bandiConcorsiInput: null,
    formsResult: null,
    formsStatus: '',
    areaDocumentiResult: null,
    areaDocumentiStatus: ''
}

export const getInfoStakeholder = createAsyncThunk(
    'stakeholder/getInfoStakeholder',
    async () => {
        const api = new AreaPersonaleControllerApi(MS_AU_CONFIG);
        const response = await api.leggi();
        return response;
    }
);

export const getBandiConcorsiStakeholder = createAsyncThunk(
    'stakeholder/getBandiConcorsiStakeholder',
    async (args: AreaPersonaleControllerApiElencoBandiRequest) => {
        const api = new AreaPersonaleControllerApi(MS_AU_CONFIG);
        const response = await api.elencoBandi(args);
        return response;
    }
);

export const getAreaDocumentiStakeholder = createAsyncThunk(
    'stakeholder/getAreaDocumentiStakeholder',
    async () => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoCategorieDocumentiStackHolderForm()
        return response;
    }
);

export const modificaInfoStakeholder = createAsyncThunk(
    'stakeholder/modificaInfoStakeholder',
    async (args: ScriviAreaPersonaleRequestDto) => {
        const api = new AreaPersonaleControllerApi(MS_AU_CONFIG);
        const response = await api.scrivi({scriviAreaPersonaleRequestDto:args});
        return response;
    }
);

export const getDocumenti = createAsyncThunk(
    'stakeholder/getDocumenti',
    async (args:GestioneDocumentaleControllerApiGetElencoDocumentiRequest) => {
        const api = new GestioneDocumentaleControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoDocumenti(args);
        return response
    }
);

export const loadOptions = createAsyncThunk(
    'stakeholder/loadOptions',
    async () => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoBandiContestoForm();
        return response;
    }
);

export const StakeholderSlice = createSlice({
    name: 'Stakeholder',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInfoStakeholder.pending, (state: StakeholderState, action: any) => {
            state.searchStatus = STATUS_PENDING;
        });
        builder.addCase(getInfoStakeholder.fulfilled, (state: StakeholderState, action: any) => {
            state.searchResult = action.payload.data;
            state.searchStatus = STATUS_FULLFILLED;
        });
        builder.addCase(getInfoStakeholder.rejected, (state: StakeholderState, action: any) => {
            state.searchStatus = STATUS_REJECTED;
        });
        
        builder.addCase(getDocumenti.pending, (state: StakeholderState, action: any) => {
            state.documentiStatus = STATUS_PENDING;
        });
        builder.addCase(getDocumenti.fulfilled, (state: StakeholderState, action: any) => {
            state.documentiResult = action.payload.data;
            state.documentiStatus = STATUS_FULLFILLED;
        });
        builder.addCase(getDocumenti.rejected, (state: StakeholderState, action: any) => {
            state.documentiStatus = STATUS_REJECTED;
        });

        builder.addCase(getBandiConcorsiStakeholder.pending, (state: StakeholderState, action: any) => {
            state.bandiConcorsiStatus = STATUS_PENDING;
        });
        builder.addCase(getBandiConcorsiStakeholder.fulfilled, (state: StakeholderState, action: any) => {
            state.bandiConcorsiResult = action.payload.data;
            state.bandiConcorsiStatus = STATUS_FULLFILLED;
        });
        builder.addCase(getBandiConcorsiStakeholder.rejected, (state: StakeholderState, action: any) => {
            state.bandiConcorsiStatus = STATUS_REJECTED;
        });

        builder.addCase(loadOptions.pending, (state: StakeholderState, action: any) => {
            state.formsStatus = STATUS_PENDING;
        });
        builder.addCase(loadOptions.fulfilled, (state: StakeholderState, action: any) => {
            state.formsResult = action.payload.data;
            state.formsStatus = STATUS_FULLFILLED;
        });
        builder.addCase(loadOptions.rejected, (state: StakeholderState, action: any) => {
            state.formsStatus = STATUS_REJECTED;
        });

        builder.addCase(modificaInfoStakeholder.pending, (state: StakeholderState, action: any) => {
            state.modificaStatus = STATUS_PENDING;
        });
        builder.addCase(modificaInfoStakeholder.fulfilled, (state: StakeholderState, action: any) => {
            state.modificaResult = action.payload.data
            state.modificaStatus = STATUS_FULLFILLED;
        });
        builder.addCase(modificaInfoStakeholder.rejected, (state: StakeholderState, action: any) => {
            state.modificaStatus = STATUS_REJECTED;
        });

        builder.addCase(getAreaDocumentiStakeholder.pending, (state: StakeholderState, action: any) => {
            state.areaDocumentiStatus = STATUS_PENDING;
        });
        builder.addCase(getAreaDocumentiStakeholder.fulfilled, (state: StakeholderState, action: any) => {
            state.areaDocumentiResult = action.payload.data
            state.areaDocumentiStatus = STATUS_FULLFILLED;
        });
        builder.addCase(getAreaDocumentiStakeholder.rejected, (state: StakeholderState, action: any) => {
            state.areaDocumentiStatus = STATUS_REJECTED;
        });
    }
});

// export const {} = FunzionalitaApplicativeSlice.actions;

export default StakeholderSlice.reducer;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA } from "../../components/sidemenu/sidemenuConstants";
import { loadDipartimenti, setBreadcrumbItem, setDipartimentoSelezionato, reset, loadDipartimentiStorico, setDataStorico, setOrganigrammaStorico } from "../../store/organigrammaSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import { Alert, Button, Input, Row } from "design-react-kit";
import { DipartimentoDto,  RuoloUtenteAutenticatoDto } from "../../services/ms-anagrafica-unica";
import { showNotifica } from "../../store/loaderSlice";
import { NOTIFICA_STATO } from "../../utility/Notifica";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from 'zod'

function Organigramma(props: {operations: RuoloUtenteAutenticatoDto}) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { elencoDipartimentiResult, elencoDipartimentiStoricoResult, elencoDipartimentiStatus, elencoDipartimentiStoricoStatus, dataStorico} = useAppSelector((state) => state.organigramma);
    const [showDettaglioStrutturaOrganizzativa, setShowDettaglioStrutturaOrganizzativa] = useState<boolean>(false);
    const [isStorico, setIsStorico] = useState<boolean>(false);

    const oneDay = (3600 * 1000 * 24);

    const showOrganigrammaOperations = () => {
        props.operations?.elencoFunzionalita?.includes('ORGANIGRAMMA_DETTAGLIO') && setShowDettaglioStrutturaOrganizzativa(true);
    }

    const validationSchema = z.object({
        dataInput: z.coerce.date().max(new Date(Date.now()- oneDay), {message: 'Il campo data invio deve essere inferiore alla data odierna'}).optional()
    })

    const initialValues = {
        dataInput: undefined
    };

    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(validationSchema),
        onSubmit: (values) => {

            if(values.dataInput){
                console.log("Submit form", JSON.stringify(values, null, 2));
                var data = new Date(values.dataInput!);
                data.setUTCHours(23)
                data.setMinutes(59)
                data.setSeconds(59)
                setIsStorico(true)
                dispatch(loadDipartimentiStorico( data.toISOString()))
            }else {
                setIsStorico(false)
                dispatch(loadDipartimenti()) 
            }
        },
    });


    const hasResults = () => {
        if (!isStorico)
          return elencoDipartimentiResult && elencoDipartimentiResult.totalElements
            ? elencoDipartimentiResult.totalElements > 0
            : false;
        else 
            return elencoDipartimentiStoricoResult && elencoDipartimentiStoricoResult.totalElements
            ? elencoDipartimentiStoricoResult.totalElements > 0
            : false;
    }


    const navigateToTop = (so: DipartimentoDto) => {
        if(showDettaglioStrutturaOrganizzativa) {
            dispatch(setDipartimentoSelezionato(so))
            dispatch(setBreadcrumbItem(so))
            if (isStorico){
                dispatch(setOrganigrammaStorico(isStorico))
                dispatch(setDataStorico(form.values.dataInput!))
            }else{
                dispatch(setOrganigrammaStorico(false))
            }
            navigate(`${HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA}/${so.id}`, { replace: true });
            window.scrollTo(0, 0);
        } else {
            dispatch(showNotifica({
                titolo: 'ERRORE',
                stato: 498,
                messaggio: 'Verificare',
                tipo: NOTIFICA_STATO.error
            }))
        }
    }

    useEffect(() => {
        dispatch(reset())
        !hasResults() && dispatch(loadDipartimenti());
        showOrganigrammaOperations();
    }, [dispatch]);

    const controlloResponseVuota = () => {
        if (!isStorico)
            return elencoDipartimentiStatus === STATUS_FULLFILLED && elencoDipartimentiResult.totalElements === 0 
        else
            return elencoDipartimentiStoricoStatus === STATUS_FULLFILLED && elencoDipartimentiStoricoResult.totalElements === 0 
    }

    
    const controlloResponseOk = () => {
        if (!isStorico)
            return elencoDipartimentiStatus === STATUS_FULLFILLED 
        else
            return elencoDipartimentiStoricoStatus === STATUS_FULLFILLED
    }

    const controlloResponse = () => {
        if (isStorico)
            return elencoDipartimentiStoricoResult
        else
            return elencoDipartimentiResult
    }

    return (
        <div>
            <h2>{t("organigramma")}</h2>
            <section className="mb-3">
                <div className="row">
                    <div className="col">
                        <p>L'organigramma rappresenta la struttura organizzativa di ARPAL Puglia e le relazioni gerarchiche.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </section>
            <Row style={{marginTop: '2%'}}>
                <Input style={{marginRight: '20%'}}
                    label="Visualizza l'organigramma alla seguente data"
                    type="date"
                    placeholder="Inserisci la data"
                    id="input-data"
                    wrapperClassName="form-group col-md-6"
                    name="dataInput"
                    value={form.values.dataInput}
                    onChange={form.handleChange}
                    invalid={!!form.errors.dataInput}
                    infoText={t(form.errors.dataInput || '') || ""}
                />
                <Button style={{marginBottom: '0%', marginLeft: '5%', maxHeight: '50px'}} color='primary' className="mt-2" disabled={form.values.dataInput===undefined} onClick={() => { form.submitForm()}}>Conferma</Button>
            </Row>
            <div className="mt-5 position-relative">
                {controlloResponseVuota() && <Alert color="info">Nessun risultato disponibile</Alert>}
                {controlloResponseOk() && hasResults() && (
                <div className="row">
                    {(controlloResponse().data || []).map((x, i) => (
                        <div className="col-xl-3 col-md-6 dipartimento mb-3" onClick={() => navigateToTop(x)} key={i}>
                            <div className={`h-100 bg-primary text-white ${x.id === 'E110000' ? 'card-agricoltura' : 'card-so'}`}>
                                <div className="card-block">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <small>{x.tipo}</small>
                                            <h6>{x.descrizioneBreve}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                )}
            </div>
        </div>
    )
}

export default Organigramma;
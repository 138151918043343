import { Accordion, AccordionBody, AccordionHeader, Button, Col, Input } from "design-react-kit";
import { FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest } from "../../../services/ms-anagrafica-unica";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import CustomSelect, { CustomSelectItem } from "../../custom-select/CustomSelect";

interface FormRicercaEQProps {
    idSo?: string,
    dominioTipiEQ: CustomSelectItem[]
    dominioStruttureOrganizzative: CustomSelectItem[]
    ricercaEqStrutturaOrganizzativa: Function
}

const FormRicercaEQ = (props: FormRicercaEQProps) => {
    const { t } = useTranslation()
    const [formCollapseOpen, setFormCollapseOpen] = useState(true);

    enum TipoEQ {
        PO = 'PO',
        PO_EQUIPARATA = 'PO_EQUIPARATA'
    }

    const initialValues: BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest = {
        idSo: "",
        tipoPosizioneOrganizzativa: undefined,
        dataFineAssociazioneDa: undefined,
        dataFineAssociazioneA: undefined,
        direction: undefined,
        pageNum: undefined,
        pageSize: undefined,
        q: undefined,
        sort: undefined
    }

    const searchSchema = z.object({
        idSo: z.string({required_error: 'Campo obbligatorio'}),
        tipoPosizioneOrganizzativa: z.nativeEnum(TipoEQ).optional(),
        dataFineAssociazioneDa: z.coerce.date().optional(),
        dataFineAssociazioneA: z.coerce.date().optional(),
    })

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(searchSchema),
        onSubmit: (values) => {
            console.log(' INVIO FORM RICERCA >>> ', values);
            props.ricercaEqStrutturaOrganizzativa(values)
        },
    });

    const getFieldErrorSearch = (
        searchForm: FormikProps<BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest>,
        fieldName: keyof BandiPosizioniOrganizzativeControllerApiElencoPoPerBandoRequest
    ): string => {
        if (searchForm.getFieldMeta(fieldName).touched) {
            return searchForm.errors[fieldName] || "";
        } else return "";
    };

    useEffect( () => {
        if (!!props.idSo) {
            searchForm.setFieldValue('idSo', props.idSo)
        }
    }, [props.idSo])

    return (
        <Accordion background="active">
            <AccordionHeader
                active={formCollapseOpen}
                onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
            >
                {t("filtri di ricerca")}
            </AccordionHeader>
            <AccordionBody active={formCollapseOpen}>
                <div className="form-row mt-5">
                    <Input
                        type="hidden"
                        name="pageNum"
                        value={searchForm.values.pageNum}
                        onChange={searchForm.handleChange}
                    />
                    <Col md={6}>
                        <CustomSelect
                            label="Struttura Organizzativa"
                            options={props.dominioStruttureOrganizzative}
                            value={searchForm.values.idSo}
                            placeholder="Seleziona la struttura organizzativa"
                            invalid={!!searchForm.errors.idSo}
                            infoText={searchForm.errors.idSo}
                            onChange={(e) => searchForm.setFieldValue("idSo", e, false)}
                            name="idSo"
                            disabled={!!props.idSo}
                        />
                    </Col>
                    <Col md={6}>
                        <CustomSelect
                            label="Tipologia"
                            options={props.dominioTipiEQ}
                            value={searchForm.values.tipoPosizioneOrganizzativa}
                            placeholder="Seleziona tipologia EQ"
                            invalid={!!searchForm.errors.tipoPosizioneOrganizzativa}
                            infoText={searchForm.errors.tipoPosizioneOrganizzativa}
                            onChange={(e) => searchForm.setFieldValue("tipoPosizioneOrganizzativa", e, false)}
                            name="tipi"
                            wrapperClass="col-md"
                        />
                    </Col>
                    <Input
                        type="date"
                        label="Data fine associazione da"
                        placeholder="Inserisci la data"
                        id="input-dataApertura"
                        name="dataFineAssociazioneDa"
                        invalid={!!getFieldErrorSearch(searchForm, "dataFineAssociazioneDa")}
                        infoText={t(getFieldErrorSearch(searchForm, "dataFineAssociazioneDa")) || ''}
                        value={searchForm.values.dataFineAssociazioneDa || ''}
                        onChange={searchForm.handleChange}
                        wrapperClassName="form-group col-md-6"
                    />
                    <Input
                        type="date"
                        label="Data fine associazione a"
                        placeholder="Inserisci la data"
                        id="input-dataFineAssociazioneA"
                        name="dataFineAssociazioneA"
                        invalid={!!getFieldErrorSearch(searchForm, "dataFineAssociazioneA")}
                        infoText={t(getFieldErrorSearch(searchForm, "dataFineAssociazioneA")) || ''}
                        value={searchForm.values.dataFineAssociazioneA || ''}
                        onChange={searchForm.handleChange}
                        wrapperClassName="form-group col-md-6"
                    />

                </div>

                <div className="d-flex flex-row-reverse">
                    <Button
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                            searchForm.submitForm();
                        }}
                    >
                        Ricerca
                    </Button>
                    <Button
                        outline
                        color="primary"
                        className="mt-2 mr-2"
                        onClick={() => searchForm.resetForm()}
                    >
                        Pulisci
                    </Button>
                </div>
            </AccordionBody>
        </Accordion>
    )
}

export default FormRicercaEQ;
import { StrutturaOrganizzativaDto } from '../../services/ms-anagrafica-unica'
import { CITTA, CODICE, CODICE_CIFRA_DELIBERA, CODICE_CIFRA_DETERMINA, CODICE_DIPA_RAGIONERIA, CODICE_IPA, CODICE_SEZI_RAGIONERIA, DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, DIPARTIMENTO, EMAIL, FAX, INDIRIZZO, PEC, SEZIONE, TELEFONO, TIPOLOGIA_STRUTTURA } from '../../pages/strutture-organizzative/struttureOrganizzativeConstants'
import DocumentView from '../document-view/DocumentView'
import './style.scss'
import DocumentUpload from '../document-upload/DocumentUpload'

export interface SODetailsForm {
    value: StrutturaOrganizzativaDto,
    fields: Array<string>,
}

const StruttureOrganizzativeDetailsForm = (strutturaOrganizzativa: SODetailsForm) => {

    let soDipa, soSez;

    if (strutturaOrganizzativa.value.strutturaOrganizzativaPadre) {
        const soLiv1 = strutturaOrganizzativa.value.strutturaOrganizzativaPadre;

        switch (soLiv1.tipo) {
            case 'DIPARTIMENTO':
                soDipa = soLiv1;
                break;
            case 'SEZIONE':
                soSez = soLiv1;
                break
            default:
                break;
        }

        if (soLiv1.strutturaOrganizzativaPadre) {
            const soLiv2 = soLiv1.strutturaOrganizzativaPadre;
            switch (soLiv2.tipo) {
                case 'DIPARTIMENTO':
                    soDipa = soLiv2;
                    break;
                case 'SEZIONE':
                    soSez = soLiv2;
                    break
                default:
                    break;
            }
        }
    }

    return (
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light-so">
                        <h6 className='mb-3'>
                            Riepilogo
                        </h6>
                        <hr />
                        <div className="row">
                            <dl className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {(strutturaOrganizzativa.fields.indexOf(CODICE) != -1 && !!strutturaOrganizzativa.value.id) &&
                                            <>
                                                <dt className='col-sm-12'>{CODICE}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.id}</dd>
                                            </>
                                        }
                                    </div>
                                </div>
                            </dl>
                            <dl className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {(strutturaOrganizzativa.fields.indexOf(DENOMINAZIONE) != -1 && !!strutturaOrganizzativa.value.descrizioneBreve) &&
                                            <>
                                                <dt className='col-sm-12'>{DENOMINAZIONE}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.descrizioneBreve}</dd>
                                            </>
                                        }
                                    </div>
                                </div>
                            </dl>
                            <dl className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {(strutturaOrganizzativa.fields.indexOf(DECLARATORIA) != -1 && !!strutturaOrganizzativa.value.descrizione) &&
                                            <>
                                                <dt className='col-sm-12'>{DECLARATORIA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.descrizione}</dd>
                                            </>
                                        }
                                    </div>
                                </div>
                            </dl>
                            <dl className="col-sm-12">
                                <div className="row">
                                    {(strutturaOrganizzativa.fields.indexOf(DATA_INIZIO_VALIDITA) != -1 && !!strutturaOrganizzativa.value.dataInizioValidita) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{DATA_INIZIO_VALIDITA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.dataInizioValidita}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(TIPOLOGIA_STRUTTURA) != -1 && !!strutturaOrganizzativa.value.tipo) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{TIPOLOGIA_STRUTTURA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.tipo}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(DIPARTIMENTO) != -1 && !!soDipa) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{DIPARTIMENTO}</dt>
                                                <dd className='col-sm-12'>{soDipa?.descrizioneBreve}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(SEZIONE) != -1 && !!soSez) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{SEZIONE}</dt>
                                                <dd className='col-sm-12'>{soSez?.descrizioneBreve}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(EMAIL) != -1 && !!strutturaOrganizzativa.value.email) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{EMAIL}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.email}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(PEC) != -1 && !!strutturaOrganizzativa?.value?.pec) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{PEC}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.pec}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(TELEFONO) != -1 && !!strutturaOrganizzativa.value.telefono) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{TELEFONO}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.telefono}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(FAX) != -1 && !!strutturaOrganizzativa.value.fax) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{FAX}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.fax}</dd>
                                            </>
                                        </div>
                                    }


                                    {(strutturaOrganizzativa.fields.indexOf(INDIRIZZO) != -1 && !!strutturaOrganizzativa.value.indirizzo) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{INDIRIZZO}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.indirizzo}</dd>
                                            </>
                                        </div>
                                    }

                                    {(strutturaOrganizzativa.fields.indexOf(CITTA) != -1 && !!strutturaOrganizzativa.value.citta) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{CITTA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.citta}</dd>
                                            </>
                                        </div>
                                    }
                                    {(strutturaOrganizzativa.fields.indexOf(CODICE_CIFRA_DETERMINA) != -1 && !!strutturaOrganizzativa.value.codiceUfficioDetermine) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{CODICE_CIFRA_DETERMINA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.codiceUfficioDetermine}</dd>
                                            </>
                                        </div>
                                    }
                                    {(strutturaOrganizzativa.fields.indexOf(CODICE_CIFRA_DELIBERA) != -1 && !!strutturaOrganizzativa.value.codiceUfficioDelibere) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{CODICE_CIFRA_DELIBERA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.codiceUfficioDelibere}</dd>
                                            </>
                                        </div>
                                    }
                                    {(strutturaOrganizzativa.fields.indexOf(CODICE_DIPA_RAGIONERIA) != -1 && !!strutturaOrganizzativa.value.codiceDipartimentoRagioneria) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{CODICE_DIPA_RAGIONERIA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.codiceDipartimentoRagioneria}</dd>
                                            </>
                                        </div>
                                    }
                                    {(strutturaOrganizzativa.fields.indexOf(CODICE_SEZI_RAGIONERIA) != -1 && !!strutturaOrganizzativa.value.codiceSezioneRagioneria) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{CODICE_SEZI_RAGIONERIA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.codiceSezioneRagioneria}</dd>
                                            </>
                                        </div>
                                    }
                                    {(strutturaOrganizzativa.fields.indexOf(CODICE_IPA) != -1 && !!strutturaOrganizzativa.value.codiceIpa) &&
                                        <div className="col-sm-4">
                                            <>
                                                <dt className='col-sm-12'>{CODICE_IPA}</dt>
                                                <dd className='col-sm-12'>{strutturaOrganizzativa?.value?.codiceIpa}</dd>
                                            </>
                                        </div>
                                    }
                                </div>
                            </dl>
                            <dl className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {
                                            (strutturaOrganizzativa?.value.documenti?.length! > 0) && <div className="documentView pt-3">
                                                <h4>Documenti caricati</h4>
                                                <hr/>
                                                <div className="form-row">
                                                    {strutturaOrganizzativa?.value.documenti?.map((value, index) => (
                                                        <div className="col-4" key={index}>
                                                            <DocumentUpload documentName={value.tipo!} idDocumento={value.id?.documento?.id} ></DocumentUpload>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>

            </div >
    )
}

export default StruttureOrganizzativeDetailsForm
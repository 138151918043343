import './style.scss'
import { NuoveAssunzioniDto } from '../../services/ms-anagrafica-unica'
import { CAP, CELLULARE, CITTADINANZA, CITTA_NASCITA, CLASSE_CONCORSO, CODICE_FISCALE, COGNOME, CITTA_RESIDENZA, DATA_NASCITA, EMAIL, INDIRIZZO_RESIDENZA, NOME, NOTE_CANDIDATO, NUM_CARTA_IDENTITA, PEC, PROVINCIA, SESSO, STATO_CIVILE, TELEFONO, TITOLO_STUDIO, CODICE_CID } from '../../pages/gestione-candidati/candidatiConstants'

export interface CandidatoDetailsForm {
    value: NuoveAssunzioniDto,
    fileds: Array<string>
}

const CandidatoDetailsComponent = (candidato: CandidatoDetailsForm) => {
    return (
        <div className="col-sm-12">
            {candidato.value?.anagraficaUnica &&
                <div className="card-div row">
                    <div className="col-sm-12">
                        <div className="info-box-light-candidato">
                            <h6 className="mb-3">
                                Riepilogo
                            </h6>
                            <hr />
                            <div className="row">
                                <dl className="col-sm-12">
                                    <div className="row">

                                        {(candidato.fileds.indexOf(COGNOME) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.cognome && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{COGNOME}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.cognome}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(NOME) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.nome && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{NOME}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.nome}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(SESSO) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.genere && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{SESSO}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.genere}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(DATA_NASCITA) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.dataNascita && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{DATA_NASCITA}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.dataNascita}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(CITTA_NASCITA) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.cittaNascita && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{CITTA_NASCITA}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.cittaNascita}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(CODICE_FISCALE) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.codiceFiscale && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{CODICE_FISCALE}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.codiceFiscale}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(CITTADINANZA) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.cittadinanza && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{CITTADINANZA}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.cittadinanza}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(CITTA_RESIDENZA) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.cittaResidenza && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{CITTA_RESIDENZA}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.cittaResidenza}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(PROVINCIA) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.provinciaResidenza && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{PROVINCIA}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.provinciaResidenza}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(CAP) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.capResidenza && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{CAP}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.capResidenza}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(INDIRIZZO_RESIDENZA) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.indirizzoResidenza && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{INDIRIZZO_RESIDENZA}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.indirizzoResidenza}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(STATO_CIVILE) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.statoCivile && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{STATO_CIVILE}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.statoCivile}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(NUM_CARTA_IDENTITA) != -1 && candidato.value.anagraficaUnica != null) &&
                                            <>
                                                {!!candidato.value.anagraficaUnica?.numeroCartaIdentita && <div className="col-sm-4">
                                                    <dt className='col-sm-12'>{NUM_CARTA_IDENTITA}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.anagraficaUnica?.numeroCartaIdentita}</dd>
                                                </div>}
                                            </>
                                        }
                                        {(candidato.fileds.indexOf(CELLULARE) != -1 && !!candidato.value.cellulare) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{CELLULARE}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.cellulare}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(TELEFONO) != -1 && !!candidato.value.telefono) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{TELEFONO}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.telefono}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(EMAIL) != -1 && !!candidato.value.email) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{EMAIL}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.email}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(PEC) != -1 && !!candidato.value.pec) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{PEC}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.pec}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(CLASSE_CONCORSO) != -1 && !!candidato.value.codiceClasseConcorso) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{CLASSE_CONCORSO}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.codiceClasseConcorso}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(TITOLO_STUDIO) != -1 && !!candidato.value.titoloDiStudio) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{TITOLO_STUDIO}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.titoloDiStudio}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(NOTE_CANDIDATO) != -1 && !!candidato.value.note) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{NOTE_CANDIDATO}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.note}</dd>
                                                </>
                                            </div>
                                        }
                                        {(candidato.fileds.indexOf(CODICE_CID) != -1 && !!candidato.value.idCid) &&
                                            <div className="col-sm-4">
                                                <>
                                                    <dt className='col-sm-12'>{CODICE_CID}</dt>
                                                    <dd className='col-sm-12'>{candidato.value.idCid}</dd>
                                                </>
                                            </div>
                                        }


                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CandidatoDetailsComponent
/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssegnazioneIncaricoDto } from '../model';
// @ts-ignore
import { OptionDtoString } from '../model';
// @ts-ignore
import { PagedDtoStrutturaOrganizzativaDto } from '../model';
// @ts-ignore
import { PatchStrutturaOrganizzativaRequest } from '../model';
// @ts-ignore
import { SOInsertRequestDto } from '../model';
// @ts-ignore
import { SOUtenzaCifraRequestDto } from '../model';
// @ts-ignore
import { StrutturaOrganizzativaDto } from '../model';
// @ts-ignore
import { UtenzaCifraDto } from '../model';
/**
 * StrutturaOrganizzativaControllerApi - axios parameter creator
 * @export
 */
export const StrutturaOrganizzativaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per la cancellazione di un utenza
         * @summary Cancellazione Utenza
         * @param {number} idUtenza 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStrutturaOrganizzativa: async (idUtenza: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idUtenza' is not null or undefined
            assertParamExists('deleteStrutturaOrganizzativa', 'idUtenza', idUtenza)
            const localVarPath = `/v1/strutture-organizzative/{idUtenza}/utenze-cifra`
                .replace(`{${"idUtenza"}}`, encodeURIComponent(String(idUtenza)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il dettaglio di una struttura organizzativa
         * @summary Dettaglio struttura organizzativa
         * @param {string} idStrutturaOrganizzativa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailStrutturaOrganizzativa: async (idStrutturaOrganizzativa: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idStrutturaOrganizzativa' is not null or undefined
            assertParamExists('detailStrutturaOrganizzativa', 'idStrutturaOrganizzativa', idStrutturaOrganizzativa)
            const localVarPath = `/v1/strutture-organizzative/{idStrutturaOrganizzativa}`
                .replace(`{${"idStrutturaOrganizzativa"}}`, encodeURIComponent(String(idStrutturaOrganizzativa)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero delle utenze cifra attraverso una struttura
         * @summary Recupero utenze
         * @param {string} idStrutturaOrganizzativa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByStruttura: async (idStrutturaOrganizzativa: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idStrutturaOrganizzativa' is not null or undefined
            assertParamExists('getAllByStruttura', 'idStrutturaOrganizzativa', idStrutturaOrganizzativa)
            const localVarPath = `/v1/strutture-organizzative/{idStrutturaOrganizzativa}/utenze-cifra`
                .replace(`{${"idStrutturaOrganizzativa"}}`, encodeURIComponent(String(idStrutturaOrganizzativa)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest leggere i dati di una associazione relative ad un incarico e a un dipendente.
         * @summary Dati di una specifica associazione delle strutture organizzative
         * @param {string} idIncarico 
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociazioneIncarico: async (idIncarico: string, idDipendente: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idIncarico' is not null or undefined
            assertParamExists('getAssociazioneIncarico', 'idIncarico', idIncarico)
            // verify required parameter 'idDipendente' is not null or undefined
            assertParamExists('getAssociazioneIncarico', 'idDipendente', idDipendente)
            const localVarPath = `/v1/incarico/associazione/{idIncarico}/{idDipendente}`
                .replace(`{${"idIncarico"}}`, encodeURIComponent(String(idIncarico)))
                .replace(`{${"idDipendente"}}`, encodeURIComponent(String(idDipendente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest leggere i documenti di un\'incarico.
         * @summary Recupera i documenti di un incarico.
         * @param {string} idIncarico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentiIncarico: async (idIncarico: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idIncarico' is not null or undefined
            assertParamExists('getDocumentiIncarico', 'idIncarico', idIncarico)
            const localVarPath = `/v1/incarico/documenti/{idIncarico}`
                .replace(`{${"idIncarico"}}`, encodeURIComponent(String(idIncarico)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero delle sezioni attraverso il dipartimento
         * @summary Recupero sezioni
         * @param {string} idDipartimento 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSezioniByDipartimento: async (idDipartimento: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idDipartimento' is not null or undefined
            assertParamExists('getSezioniByDipartimento', 'idDipartimento', idDipartimento)
            const localVarPath = `/v1/strutture-organizzative/{idDipartimento}/sezioni`
                .replace(`{${"idDipartimento"}}`, encodeURIComponent(String(idDipartimento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero delle SO attraverso la denominazione
         * @summary Recupero SO
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoByDenominazione: async (chiave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/strutture-organizzative/dominio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chiave !== undefined) {
                localVarQueryParameter['chiave'] = chiave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento di una nuova struttura organizzativa
         * @summary Inserimento struttura organizzativa
         * @param {SOInsertRequestDto} sOInsertRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertStrutturaOrganizzativa: async (sOInsertRequestDto: SOInsertRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sOInsertRequestDto' is not null or undefined
            assertParamExists('insertStrutturaOrganizzativa', 'sOInsertRequestDto', sOInsertRequestDto)
            const localVarPath = `/v1/strutture-organizzative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sOInsertRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch struttura organizzativa
         * @param {string} idStrutturaOrganizzativa 
         * @param {PatchStrutturaOrganizzativaRequest} patchStrutturaOrganizzativaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStrutturaOrganizzativa: async (idStrutturaOrganizzativa: string, patchStrutturaOrganizzativaRequest: PatchStrutturaOrganizzativaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idStrutturaOrganizzativa' is not null or undefined
            assertParamExists('patchStrutturaOrganizzativa', 'idStrutturaOrganizzativa', idStrutturaOrganizzativa)
            // verify required parameter 'patchStrutturaOrganizzativaRequest' is not null or undefined
            assertParamExists('patchStrutturaOrganizzativa', 'patchStrutturaOrganizzativaRequest', patchStrutturaOrganizzativaRequest)
            const localVarPath = `/v1/strutture-organizzative/{idStrutturaOrganizzativa}`
                .replace(`{${"idStrutturaOrganizzativa"}}`, encodeURIComponent(String(idStrutturaOrganizzativa)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchStrutturaOrganizzativaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la creazione di una unova utenza cifra
         * @summary Crea utenza cifra
         * @param {string} idStrutturaOrganizzativa 
         * @param {SOUtenzaCifraRequestDto} sOUtenzaCifraRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUtenzaCifra: async (idStrutturaOrganizzativa: string, sOUtenzaCifraRequestDto: SOUtenzaCifraRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idStrutturaOrganizzativa' is not null or undefined
            assertParamExists('saveUtenzaCifra', 'idStrutturaOrganizzativa', idStrutturaOrganizzativa)
            // verify required parameter 'sOUtenzaCifraRequestDto' is not null or undefined
            assertParamExists('saveUtenzaCifra', 'sOUtenzaCifraRequestDto', sOUtenzaCifraRequestDto)
            const localVarPath = `/v1/strutture-organizzative/{idStrutturaOrganizzativa}/utenze-cifra`
                .replace(`{${"idStrutturaOrganizzativa"}}`, encodeURIComponent(String(idStrutturaOrganizzativa)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sOUtenzaCifraRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca delle strutture organizzative
         * @summary Ricerca strutture organizzative
         * @param {string} [codInca] 
         * @param {string} [denominazione] 
         * @param {'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'} [stato] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipologiaStruttura] 
         * @param {'S' | 'N'} [equiparata] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStrutturaOrganizzativa: async (codInca?: string, denominazione?: string, stato?: 'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA', tipologiaStruttura?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', equiparata?: 'S' | 'N', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/strutture-organizzative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codInca !== undefined) {
                localVarQueryParameter['codInca'] = codInca;
            }

            if (denominazione !== undefined) {
                localVarQueryParameter['denominazione'] = denominazione;
            }

            if (stato !== undefined) {
                localVarQueryParameter['stato'] = stato;
            }

            if (tipologiaStruttura !== undefined) {
                localVarQueryParameter['tipologiaStruttura'] = tipologiaStruttura;
            }

            if (equiparata !== undefined) {
                localVarQueryParameter['equiparata'] = equiparata;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StrutturaOrganizzativaControllerApi - functional programming interface
 * @export
 */
export const StrutturaOrganizzativaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StrutturaOrganizzativaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per la cancellazione di un utenza
         * @summary Cancellazione Utenza
         * @param {number} idUtenza 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStrutturaOrganizzativa(idUtenza: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStrutturaOrganizzativa(idUtenza, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il dettaglio di una struttura organizzativa
         * @summary Dettaglio struttura organizzativa
         * @param {string} idStrutturaOrganizzativa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailStrutturaOrganizzativa(idStrutturaOrganizzativa: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrutturaOrganizzativaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailStrutturaOrganizzativa(idStrutturaOrganizzativa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero delle utenze cifra attraverso una struttura
         * @summary Recupero utenze
         * @param {string} idStrutturaOrganizzativa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByStruttura(idStrutturaOrganizzativa: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtenzaCifraDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByStruttura(idStrutturaOrganizzativa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest leggere i dati di una associazione relative ad un incarico e a un dipendente.
         * @summary Dati di una specifica associazione delle strutture organizzative
         * @param {string} idIncarico 
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssociazioneIncarico(idIncarico: string, idDipendente: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssegnazioneIncaricoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociazioneIncarico(idIncarico, idDipendente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest leggere i documenti di un\'incarico.
         * @summary Recupera i documenti di un incarico.
         * @param {string} idIncarico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentiIncarico(idIncarico: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentiIncarico(idIncarico, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero delle sezioni attraverso il dipartimento
         * @summary Recupero sezioni
         * @param {string} idDipartimento 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSezioniByDipartimento(idDipartimento: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionDtoString>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSezioniByDipartimento(idDipartimento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero delle SO attraverso la denominazione
         * @summary Recupero SO
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSoByDenominazione(chiave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionDtoString>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSoByDenominazione(chiave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento di una nuova struttura organizzativa
         * @summary Inserimento struttura organizzativa
         * @param {SOInsertRequestDto} sOInsertRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertStrutturaOrganizzativa(sOInsertRequestDto: SOInsertRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrutturaOrganizzativaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertStrutturaOrganizzativa(sOInsertRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch struttura organizzativa
         * @param {string} idStrutturaOrganizzativa 
         * @param {PatchStrutturaOrganizzativaRequest} patchStrutturaOrganizzativaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchStrutturaOrganizzativa(idStrutturaOrganizzativa: string, patchStrutturaOrganizzativaRequest: PatchStrutturaOrganizzativaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrutturaOrganizzativaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchStrutturaOrganizzativa(idStrutturaOrganizzativa, patchStrutturaOrganizzativaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la creazione di una unova utenza cifra
         * @summary Crea utenza cifra
         * @param {string} idStrutturaOrganizzativa 
         * @param {SOUtenzaCifraRequestDto} sOUtenzaCifraRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUtenzaCifra(idStrutturaOrganizzativa: string, sOUtenzaCifraRequestDto: SOUtenzaCifraRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtenzaCifraDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUtenzaCifra(idStrutturaOrganizzativa, sOUtenzaCifraRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca delle strutture organizzative
         * @summary Ricerca strutture organizzative
         * @param {string} [codInca] 
         * @param {string} [denominazione] 
         * @param {'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'} [stato] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipologiaStruttura] 
         * @param {'S' | 'N'} [equiparata] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchStrutturaOrganizzativa(codInca?: string, denominazione?: string, stato?: 'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA', tipologiaStruttura?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', equiparata?: 'S' | 'N', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoStrutturaOrganizzativaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchStrutturaOrganizzativa(codInca, denominazione, stato, tipologiaStruttura, equiparata, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StrutturaOrganizzativaControllerApi - factory interface
 * @export
 */
export const StrutturaOrganizzativaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StrutturaOrganizzativaControllerApiFp(configuration)
    return {
        /**
         * API Rest per la cancellazione di un utenza
         * @summary Cancellazione Utenza
         * @param {StrutturaOrganizzativaControllerApiDeleteStrutturaOrganizzativaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiDeleteStrutturaOrganizzativaRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteStrutturaOrganizzativa(requestParameters.idUtenza, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il dettaglio di una struttura organizzativa
         * @summary Dettaglio struttura organizzativa
         * @param {StrutturaOrganizzativaControllerApiDetailStrutturaOrganizzativaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiDetailStrutturaOrganizzativaRequest, options?: AxiosRequestConfig): AxiosPromise<StrutturaOrganizzativaDto> {
            return localVarFp.detailStrutturaOrganizzativa(requestParameters.idStrutturaOrganizzativa, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero delle utenze cifra attraverso una struttura
         * @summary Recupero utenze
         * @param {StrutturaOrganizzativaControllerApiGetAllByStrutturaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByStruttura(requestParameters: StrutturaOrganizzativaControllerApiGetAllByStrutturaRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UtenzaCifraDto>> {
            return localVarFp.getAllByStruttura(requestParameters.idStrutturaOrganizzativa, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest leggere i dati di una associazione relative ad un incarico e a un dipendente.
         * @summary Dati di una specifica associazione delle strutture organizzative
         * @param {StrutturaOrganizzativaControllerApiGetAssociazioneIncaricoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociazioneIncarico(requestParameters: StrutturaOrganizzativaControllerApiGetAssociazioneIncaricoRequest, options?: AxiosRequestConfig): AxiosPromise<AssegnazioneIncaricoDto> {
            return localVarFp.getAssociazioneIncarico(requestParameters.idIncarico, requestParameters.idDipendente, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest leggere i documenti di un\'incarico.
         * @summary Recupera i documenti di un incarico.
         * @param {StrutturaOrganizzativaControllerApiGetDocumentiIncaricoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentiIncarico(requestParameters: StrutturaOrganizzativaControllerApiGetDocumentiIncaricoRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getDocumentiIncarico(requestParameters.idIncarico, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero delle sezioni attraverso il dipartimento
         * @summary Recupero sezioni
         * @param {StrutturaOrganizzativaControllerApiGetSezioniByDipartimentoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSezioniByDipartimento(requestParameters: StrutturaOrganizzativaControllerApiGetSezioniByDipartimentoRequest, options?: AxiosRequestConfig): AxiosPromise<Array<OptionDtoString>> {
            return localVarFp.getSezioniByDipartimento(requestParameters.idDipartimento, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero delle SO attraverso la denominazione
         * @summary Recupero SO
         * @param {StrutturaOrganizzativaControllerApiGetSoByDenominazioneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoByDenominazione(requestParameters: StrutturaOrganizzativaControllerApiGetSoByDenominazioneRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<OptionDtoString>> {
            return localVarFp.getSoByDenominazione(requestParameters.chiave, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento di una nuova struttura organizzativa
         * @summary Inserimento struttura organizzativa
         * @param {StrutturaOrganizzativaControllerApiInsertStrutturaOrganizzativaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiInsertStrutturaOrganizzativaRequest, options?: AxiosRequestConfig): AxiosPromise<StrutturaOrganizzativaDto> {
            return localVarFp.insertStrutturaOrganizzativa(requestParameters.sOInsertRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch struttura organizzativa
         * @param {StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativaRequest, options?: AxiosRequestConfig): AxiosPromise<StrutturaOrganizzativaDto> {
            return localVarFp.patchStrutturaOrganizzativa(requestParameters.idStrutturaOrganizzativa, requestParameters.patchStrutturaOrganizzativaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la creazione di una unova utenza cifra
         * @summary Crea utenza cifra
         * @param {StrutturaOrganizzativaControllerApiSaveUtenzaCifraRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUtenzaCifra(requestParameters: StrutturaOrganizzativaControllerApiSaveUtenzaCifraRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UtenzaCifraDto>> {
            return localVarFp.saveUtenzaCifra(requestParameters.idStrutturaOrganizzativa, requestParameters.sOUtenzaCifraRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca delle strutture organizzative
         * @summary Ricerca strutture organizzative
         * @param {StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativaRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoStrutturaOrganizzativaDto> {
            return localVarFp.searchStrutturaOrganizzativa(requestParameters.codInca, requestParameters.denominazione, requestParameters.stato, requestParameters.tipologiaStruttura, requestParameters.equiparata, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteStrutturaOrganizzativa operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiDeleteStrutturaOrganizzativaRequest
 */
export interface StrutturaOrganizzativaControllerApiDeleteStrutturaOrganizzativaRequest {
    /**
     * 
     * @type {number}
     * @memberof StrutturaOrganizzativaControllerApiDeleteStrutturaOrganizzativa
     */
    readonly idUtenza: number
}

/**
 * Request parameters for detailStrutturaOrganizzativa operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiDetailStrutturaOrganizzativaRequest
 */
export interface StrutturaOrganizzativaControllerApiDetailStrutturaOrganizzativaRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiDetailStrutturaOrganizzativa
     */
    readonly idStrutturaOrganizzativa: string
}

/**
 * Request parameters for getAllByStruttura operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiGetAllByStrutturaRequest
 */
export interface StrutturaOrganizzativaControllerApiGetAllByStrutturaRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiGetAllByStruttura
     */
    readonly idStrutturaOrganizzativa: string
}

/**
 * Request parameters for getAssociazioneIncarico operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiGetAssociazioneIncaricoRequest
 */
export interface StrutturaOrganizzativaControllerApiGetAssociazioneIncaricoRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiGetAssociazioneIncarico
     */
    readonly idIncarico: string

    /**
     * 
     * @type {number}
     * @memberof StrutturaOrganizzativaControllerApiGetAssociazioneIncarico
     */
    readonly idDipendente: number
}

/**
 * Request parameters for getDocumentiIncarico operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiGetDocumentiIncaricoRequest
 */
export interface StrutturaOrganizzativaControllerApiGetDocumentiIncaricoRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiGetDocumentiIncarico
     */
    readonly idIncarico: string
}

/**
 * Request parameters for getSezioniByDipartimento operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiGetSezioniByDipartimentoRequest
 */
export interface StrutturaOrganizzativaControllerApiGetSezioniByDipartimentoRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiGetSezioniByDipartimento
     */
    readonly idDipartimento: string
}

/**
 * Request parameters for getSoByDenominazione operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiGetSoByDenominazioneRequest
 */
export interface StrutturaOrganizzativaControllerApiGetSoByDenominazioneRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiGetSoByDenominazione
     */
    readonly chiave?: string
}

/**
 * Request parameters for insertStrutturaOrganizzativa operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiInsertStrutturaOrganizzativaRequest
 */
export interface StrutturaOrganizzativaControllerApiInsertStrutturaOrganizzativaRequest {
    /**
     * 
     * @type {SOInsertRequestDto}
     * @memberof StrutturaOrganizzativaControllerApiInsertStrutturaOrganizzativa
     */
    readonly sOInsertRequestDto: SOInsertRequestDto
}

/**
 * Request parameters for patchStrutturaOrganizzativa operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativaRequest
 */
export interface StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativaRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativa
     */
    readonly idStrutturaOrganizzativa: string

    /**
     * 
     * @type {PatchStrutturaOrganizzativaRequest}
     * @memberof StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativa
     */
    readonly patchStrutturaOrganizzativaRequest: PatchStrutturaOrganizzativaRequest
}

/**
 * Request parameters for saveUtenzaCifra operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiSaveUtenzaCifraRequest
 */
export interface StrutturaOrganizzativaControllerApiSaveUtenzaCifraRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiSaveUtenzaCifra
     */
    readonly idStrutturaOrganizzativa: string

    /**
     * 
     * @type {SOUtenzaCifraRequestDto}
     * @memberof StrutturaOrganizzativaControllerApiSaveUtenzaCifra
     */
    readonly sOUtenzaCifraRequestDto: SOUtenzaCifraRequestDto
}

/**
 * Request parameters for searchStrutturaOrganizzativa operation in StrutturaOrganizzativaControllerApi.
 * @export
 * @interface StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativaRequest
 */
export interface StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativaRequest {
    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly codInca?: string

    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly denominazione?: string

    /**
     * 
     * @type {'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly stato?: 'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'

    /**
     * 
     * @type {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly tipologiaStruttura?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'

    /**
     * 
     * @type {'S' | 'N'}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly equiparata?: 'S' | 'N'

    /**
     * 
     * @type {number}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativa
     */
    readonly q?: string
}

/**
 * StrutturaOrganizzativaControllerApi - object-oriented interface
 * @export
 * @class StrutturaOrganizzativaControllerApi
 * @extends {BaseAPI}
 */
export class StrutturaOrganizzativaControllerApi extends BaseAPI {
    /**
     * API Rest per la cancellazione di un utenza
     * @summary Cancellazione Utenza
     * @param {StrutturaOrganizzativaControllerApiDeleteStrutturaOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public deleteStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiDeleteStrutturaOrganizzativaRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).deleteStrutturaOrganizzativa(requestParameters.idUtenza, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il dettaglio di una struttura organizzativa
     * @summary Dettaglio struttura organizzativa
     * @param {StrutturaOrganizzativaControllerApiDetailStrutturaOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public detailStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiDetailStrutturaOrganizzativaRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).detailStrutturaOrganizzativa(requestParameters.idStrutturaOrganizzativa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero delle utenze cifra attraverso una struttura
     * @summary Recupero utenze
     * @param {StrutturaOrganizzativaControllerApiGetAllByStrutturaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public getAllByStruttura(requestParameters: StrutturaOrganizzativaControllerApiGetAllByStrutturaRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).getAllByStruttura(requestParameters.idStrutturaOrganizzativa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest leggere i dati di una associazione relative ad un incarico e a un dipendente.
     * @summary Dati di una specifica associazione delle strutture organizzative
     * @param {StrutturaOrganizzativaControllerApiGetAssociazioneIncaricoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public getAssociazioneIncarico(requestParameters: StrutturaOrganizzativaControllerApiGetAssociazioneIncaricoRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).getAssociazioneIncarico(requestParameters.idIncarico, requestParameters.idDipendente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest leggere i documenti di un\'incarico.
     * @summary Recupera i documenti di un incarico.
     * @param {StrutturaOrganizzativaControllerApiGetDocumentiIncaricoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public getDocumentiIncarico(requestParameters: StrutturaOrganizzativaControllerApiGetDocumentiIncaricoRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).getDocumentiIncarico(requestParameters.idIncarico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero delle sezioni attraverso il dipartimento
     * @summary Recupero sezioni
     * @param {StrutturaOrganizzativaControllerApiGetSezioniByDipartimentoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public getSezioniByDipartimento(requestParameters: StrutturaOrganizzativaControllerApiGetSezioniByDipartimentoRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).getSezioniByDipartimento(requestParameters.idDipartimento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero delle SO attraverso la denominazione
     * @summary Recupero SO
     * @param {StrutturaOrganizzativaControllerApiGetSoByDenominazioneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public getSoByDenominazione(requestParameters: StrutturaOrganizzativaControllerApiGetSoByDenominazioneRequest = {}, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).getSoByDenominazione(requestParameters.chiave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento di una nuova struttura organizzativa
     * @summary Inserimento struttura organizzativa
     * @param {StrutturaOrganizzativaControllerApiInsertStrutturaOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public insertStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiInsertStrutturaOrganizzativaRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).insertStrutturaOrganizzativa(requestParameters.sOInsertRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch struttura organizzativa
     * @param {StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public patchStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiPatchStrutturaOrganizzativaRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).patchStrutturaOrganizzativa(requestParameters.idStrutturaOrganizzativa, requestParameters.patchStrutturaOrganizzativaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la creazione di una unova utenza cifra
     * @summary Crea utenza cifra
     * @param {StrutturaOrganizzativaControllerApiSaveUtenzaCifraRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public saveUtenzaCifra(requestParameters: StrutturaOrganizzativaControllerApiSaveUtenzaCifraRequest, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).saveUtenzaCifra(requestParameters.idStrutturaOrganizzativa, requestParameters.sOUtenzaCifraRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca delle strutture organizzative
     * @summary Ricerca strutture organizzative
     * @param {StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrutturaOrganizzativaControllerApi
     */
    public searchStrutturaOrganizzativa(requestParameters: StrutturaOrganizzativaControllerApiSearchStrutturaOrganizzativaRequest = {}, options?: AxiosRequestConfig) {
        return StrutturaOrganizzativaControllerApiFp(this.configuration).searchStrutturaOrganizzativa(requestParameters.codInca, requestParameters.denominazione, requestParameters.stato, requestParameters.tipologiaStruttura, requestParameters.equiparata, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}

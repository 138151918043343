import { useNavigate, useParams } from "react-router-dom";
import { AssegnazioneBandiPoDto, BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest, BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest, CandidaturaBandoPoDto, CandidaturaBandoPoDtoStatoEnum, CandidaturaBandoPoEssenzialeDto, CandidaturaBandoPoVintaDto, DipendenteRegionaleDto, IncaricoEssenzialeDto, RuoloUtenteAutenticatoDto, ValutaCandidaturaBandoPoRequestDto } from "../../../services/ms-anagrafica-unica";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDettaglioAssegnazioneBando, getElencoEQ, insertValutazioneBandoPo } from "../../../store/bandoSlice";
import PageHeader from "../../../components/page-header/PageHeader";
import { HREF_ELENCO_ASSEGNAZIONI } from "../../../components/sidemenu/sidemenuConstants";
import { STATUS_FULLFILLED } from "../../../store/store-constants";
import { Alert, Badge, Button, Icon, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table, TextArea } from "design-react-kit";
import { z } from "zod";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import EqDetail from "../../../components/bando-eq-detail-form/EqDetail";
import './style.scss';
import { cvDownload } from "../../../store/curriculumVitaeSlice";
import DocumentView from "../../../components/document-view/DocumentView";
import { DipendenteRegionale } from "../../../services/ms-anagrafica-unica/model/dipendente-regionale";

const ElencoCandidature = (props:{operations: RuoloUtenteAutenticatoDto}) => {
    interface VinciteI {
        nomePo: string,
        so: string,
        dirigentePO: string
        assegnata: Boolean
    }
    
    const {codiceUnivocoEQ} = useParams();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {assegnazioneBandoStatus} = useAppSelector(state => state.bando)

    const [assegnazioneBandoResult, setAssegnazioneBandoResult] = useState<AssegnazioneBandiPoDto>();

    const [isOpenListDetails, toggleModalListDetails] = useState(false);
    const [elencoDetails, setElencoDetails] = useState<any[] | undefined>([]);
    const [isOpenConteso, toggleModalConteso] = useState(false);
    const [isVisibleSecondaScelta, setIsVisibleSecondaScelta] = useState(false);
    const [isOpenAltreCandidature, toggleModalAltreCandidature] = useState(false);
    const [vincite, setVincite] = useState<Array<VinciteI>>([]);
    const [elencoValutazioni, setElencoValutazioni] = useState<Array<ValutaCandidaturaBandoPoRequestDto>>([]);
    const [elencoAltreCandidatureDipRegi, setElencoAltreCandidatureDipRegi] = useState<Array<CandidaturaBandoPoEssenzialeDto>>([]);

    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    let initialValuesValutazione:BandiPosizioniOrganizzativeControllerApiValutazioneBandoPoRequest = {
        id: Number(codiceUnivocoEQ),
        valutazioneBandoPoRequestDto: {
            elencoCandidature: elencoValutazioni
        }
    };

    const initialValues:BandiPosizioniOrganizzativeControllerApiElencoAssegnazioniBandiPoRequest = {
        denominazionePo: '',
        pageNum: 0,
        pageSize: 10,
        sort: '',
        direction: 'ASC',
        q: '',
    };

    type Direction = 'ASC' | 'DESC';
    interface SortingState {
        direction: Direction;
    }

    const schema = z.object({
        denominazionePo: z.string().optional(),
    })

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: (values) => {
            getElencoEQ(values)
        },
    });

    const esistonoAltreCandidature = (dipReg: DipendenteRegionaleDto) => {
        return dipReg?.elencoCandidatureBandi &&
            dipReg?.elencoCandidatureBandi.filter(cand => cand.assegnazioneBandoPo?.codiceUnivoco != codiceUnivocoEQ).length > 0
    }
    function recuperaInfoDaDto(resp: AssegnazioneBandiPoDto) {
        if (!!resp && resp.elencoCandidature && resp.elencoCandidature.length > 0) {
            const valutazioniObj = resp.elencoCandidature.map(candidatura => {
                
                return {
                    idCandidatura: candidatura.id!,
                    note: candidatura.note,
                    scelta: candidatura.vincitore,
                    secondaScelta: candidatura.flagForzaVincitore
                };
            });
            setElencoValutazioni(valutazioniObj);
            setIsVisibleSecondaScelta(resp.elencoCandidature.some(cand => cand?.flagForzaVincitore));
            setAssegnazioneBandoResult(resp)
        }else if(!!resp) {
            setAssegnazioneBandoResult(resp)
        } 
    }

    useEffect( () => {
        dispatch(getDettaglioAssegnazioneBando({
            id: Number(codiceUnivocoEQ!)
        })).unwrap().then( (resp) => {
            recuperaInfoDaDto(resp);
        })
    }, []);

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };

    const stampaStruttureDiAppartenenza = (struttureOrganizzative:IncaricoEssenzialeDto[]) => {
        return struttureOrganizzative.map( (so, index) => so.descrizione)
    }

    const modalViewDetails = (list: any) => {
        if (list.length > 0) {
            setElencoDetails(list);
            toggleModalListDetails(!isOpenListDetails);
        }
    }

    const modalConteso = (vincita: CandidaturaBandoPoVintaDto[]) => {
        const v: VinciteI[] = [];
        vincita.forEach( x => {
            v.push({
                nomePo: x.assegnazioneBandoPo?.posizioneOrganizzativa?.denominazione!,
                so: x.assegnazioneBandoPo?.strutturaOrganizzativaBando!,
                dirigentePO: x.assegnazioneBandoPo?.posizioneOrganizzativa?.dirigenteStrutturaOrganizzativa!,
                assegnata: x.assegnazioneBandoPo?.assegnato!
            })
        })
        setVincite(v);
        toggleModalConteso(!isOpenConteso)
    }

    const modalAltreCandidature = (dipReg: DipendenteRegionaleDto) => {
        if(esistonoAltreCandidature(dipReg)){
            setElencoAltreCandidatureDipRegi(dipReg.elencoCandidatureBandi!);
        }
        toggleModalAltreCandidature(!isOpenAltreCandidature)
    }

    const changeValutazione = (idCandidatura: number, nota?:string, scelta?: boolean, secondaScelta?: boolean) => {
        const indiceValutazioneDaModificare = elencoValutazioni.findIndex( v => v.idCandidatura === idCandidatura);
        if(elencoValutazioni[indiceValutazioneDaModificare]){
            const newElencoValutazioni = [...elencoValutazioni];
            newElencoValutazioni[indiceValutazioneDaModificare].note = nota;
            newElencoValutazioni[indiceValutazioneDaModificare].scelta = scelta;
            newElencoValutazioni[indiceValutazioneDaModificare].secondaScelta = secondaScelta;
            setElencoValutazioni(newElencoValutazioni)
        }
    }

    const submitValutazione = () => {
        dispatch(insertValutazioneBandoPo(initialValuesValutazione)).unwrap().then(resp => !!resp && recuperaInfoDaDto(resp))
    }

    const showPotenzialeConteso = (candidatura: CandidaturaBandoPoDto) => 
        candidatura.elencoCandidatureVinte?.filter( vincite => vincite.assegnazioneBandoPo?.codiceUnivoco != codiceUnivocoEQ).length

    const showConteso = (candidatura: CandidaturaBandoPoDto) => 
        candidatura.elencoCandidatureVinte?.length

    const isAssegnato = (candidatura: CandidaturaBandoPoDto) => 
        candidatura.elencoCandidatureVinte?.some( vincite => vincite.assegnazioneBandoPo?.assegnato)

    const definisciStato = (candidatura: CandidaturaBandoPoDto) => {
        if (isAssegnato(candidatura)){
            return 'Assegnato';
        }else if (showConteso(candidatura)!>1) {
            return 'Conteso';
        }else if(showPotenzialeConteso(candidatura) === 1) {
            return 'Opzionato';
        }

        return '';
    }

    const disableScelta = (candidatura: CandidaturaBandoPoDto) => 
        candidatura.conteso

    const callCvDownload = (id: number, nomeDipendente: string) => {
            dispatch(cvDownload({
                idCid: Number(id!)
            })).unwrap().then((resp) => {
                if (!!resp) {
                    const blob = new Blob([resp], { type: 'application/pdf' })
                    let url;
                    const link = document.createElement('a');
                    url = URL.createObjectURL(blob);
                    link.href = url;
                    link.download = `CV_${nomeDipendente.replace(' ','_').replace('\'','')}`;
                    link.click()
                }
            });
    };
    
    return (
        <>
        {assegnazioneBandoStatus === STATUS_FULLFILLED && !!assegnazioneBandoResult && (
        <div>
            <PageHeader
                showIcon={true}
                urlBack={`${HREF_ELENCO_ASSEGNAZIONI}/${assegnazioneBandoResult.idBando}`}
                title={`${t("Candidature per ")!} ${assegnazioneBandoResult.nomeBando}`}
                buttons={[]}
            />
            <EqDetail bando={assegnazioneBandoResult} />
            <div className="mt-2 position-relative">
                {assegnazioneBandoResult.elencoCandidature?.length === 0 && (
                    <Alert color="info">Non sono ancora state presentate candidature per il bando.</Alert>
                )}
                {assegnazioneBandoResult.elencoCandidature?.length! > 0 && (
                    <div>
                        <Table responsive striped>
                            <thead>
                                <th scope='col'>
                                    <div className="table-order">
                                        Dipendente
                                    </div>
                                </th>
                                <th scope='col' onClick={() => ordinamento("struttura")}>
                                    <div className="table-order">
                                        Struttura
                                        <div className="icon-direction">
                                            <Icon
                                                icon="it-collapse"
                                                size="xs"
                                            />
                                            <Icon
                                                icon="it-expand"
                                                size="xs"
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th  scope='col'>
                                    <div className="table-order justify-content-center">
                                        CV
                                    </div>
                                </th>
                                <th scope='col'>
                                    <div className="table-order justify-content-center">
                                        Modello d'istanza
                                    </div>
                                </th>
                                <th scope='col'>
                                    <div className="table-order">
                                        Conteso
                                    </div>
                                </th>
                                <th scope='col'>
                                    <div className="table-order">
                                        Note
                                    </div>
                                </th>
                                <th scope='col'>
                                    <div className="table-order">
                                        Scelta
                                    </div>
                                </th>
                                {(assegnazioneBandoResult.conteso || isVisibleSecondaScelta) && <th style={{padding: '10px'}} scope='col'>
                                    <div className="table-order">
                                        Forza Scelta
                                    </div>
                                </th>}
                            </thead>
                            <tbody>
                                {assegnazioneBandoResult.elencoCandidature?.filter(cand => cand.stato !== CandidaturaBandoPoDtoStatoEnum.InComp).map((x, i) => (
                                    <tr key={i}>
                                        <td>
                                            {x.dipendenteRegionale?.nomeCompleto} {' '}
                                            {esistonoAltreCandidature(x.dipendenteRegionale!) && (
                                                <Button color='primary' size='xs' icon title="Visualizza altre candidature" onClick={() => modalAltreCandidature(x.dipendenteRegionale!)}>
                                                    <Icon color="white" icon="it-list"></Icon>
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            {x.dipendenteRegionale?.elencoStruttureOrganizzative && x.dipendenteRegionale?.elencoStruttureOrganizzative.length > 1 ?
                                                <Badge
                                                    color='primary'
                                                    className=" col-sm-12"
                                                    style={{ cursor: 'pointer', padding: '10px', fontSize: '15px' }}
                                                    onClick={() => modalViewDetails(stampaStruttureDiAppartenenza(x.dipendenteRegionale?.elencoStruttureOrganizzative!))}
                                                >
                                                    {`${x.dipendenteRegionale?.elencoStruttureOrganizzative.length} Strutture organizzative`}
                                                </Badge>
                                                :
                                                x.dipendenteRegionale?.elencoStruttureOrganizzative && x.dipendenteRegionale?.elencoStruttureOrganizzative.length === 1 ?
                                                `${x.dipendenteRegionale.elencoStruttureOrganizzative[0].descrizione}`
                                                :
                                                ' - '
                                            }
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {x.dipendenteRegionale?.flagCvInserito? (
                                                <Button
                                                    outline
                                                    size="sm"
                                                    className="mx-2"
                                                    onClick={() => callCvDownload(x.dipendenteRegionale?.id!, x.dipendenteRegionale?.nomeCompleto!)}>
                                                   <Icon
                                                        icon="it-download"
                                                        size="sm"
                                                        color="primary">
                                                    </Icon>{""}
                                                </Button>
                                            ) : '-'}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {!!x.idRiepilogoFirmato ? 
                                                <DocumentView documentName={''} idDocumento={x.idRiepilogoFirmato.id} download></DocumentView>
                                                 : ' - '
                                            }
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {definisciStato(x) !== ''? 
                                                <Badge
                                                    color='primary'
                                                    className=" col-sm-12"
                                                    style={{ cursor: 'pointer', padding: '10px', fontSize: '15px' }}
                                                    onClick={() => modalConteso(x.elencoCandidatureVinte!)}>
                                                    {definisciStato(x)}
                                                </Badge> :                                    
                                                <span style={{fontWeight: 'bold', color: 'green'}}>NO</span>
                                            }
                                        </td>
                                        <td>
                                            <TextArea
                                                id="note"
                                                placeholder="Note"
                                                wrapperClassName="col col-md-6 no-form-group"
                                                name="note"
                                                disabled={assegnazioneBandoResult.statoBando !== 'CHIUSO'}
                                                defaultValue={elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)] ? elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)].note : ''}
                                                onChange={(e) => changeValutazione(x.id!, e.target.value,
                                                     elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)].scelta,
                                                     elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)].secondaScelta)}
                                            />
                                        </td>
                                        <td className="checkbox-scelta">
                                            <Input
                                                type="checkbox"
                                                id="scelta"
                                                disabled={assegnazioneBandoResult.statoBando !== 'CHIUSO' || disableScelta(x)}
                                                wrapperClassName="col col-md-6"
                                                name="scelta"       
                                                defaultChecked={elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)]?.scelta}
                                                onClick={(e: any) => changeValutazione(x.id!, 
                                                    elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)].note, 
                                                    e.target.checked,
                                                    elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)].secondaScelta)}
                                            />
                                        </td>
                                        {(assegnazioneBandoResult.conteso  || isVisibleSecondaScelta) && <td className="checkbox-scelta">
                                            <Input
                                                type="checkbox"
                                                id="forza-scelta"
                                                disabled={assegnazioneBandoResult.statoBando !== 'CHIUSO' || !x.vincitore || disableScelta(x)}
                                                wrapperClassName="col col-md-6"
                                                name="forza-scelta"       
                                                defaultChecked={elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)]?.secondaScelta}
                                                onClick={(e: any) => changeValutazione(x.id!, 
                                                    elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)].note,
                                                    elencoValutazioni[elencoValutazioni.findIndex( v => v.idCandidatura === x.id!)].scelta,
                                                    e.target.checked)}
                                            />
                                        </td>}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
                {assegnazioneBandoResult.statoBando !== 'CHIUSO' && 
                    <Alert color="warning">La valutazione delle candidature sarà disponibile alla chiusura del bando</Alert>
                }
                <div className="d-flex flex-row-reverse">
                    <Button color='primary' className="mt-2 mr-2" disabled={assegnazioneBandoResult.statoBando !== 'CHIUSO'} onClick={submitValutazione}>Conferma</Button>
                    <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_ASSEGNAZIONI}/${assegnazioneBandoResult.idBando}`) }} >{t('annulla')} </Button>
                </div>
            </div>
            <Modal
                isOpen={isOpenListDetails}
                toggle={() => toggleModalListDetails(!isOpenListDetails)}
                labelledBy='listDetails'
                centered
                size="lg"
            >
                <ModalHeader toggle={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }} id='listDetails'>
                    Elenco
                </ModalHeader>
                <ModalBody>
                    <ul>
                        {elencoDetails?.map((value, index) => (
                            <li key={index}>{value}</li>
                        ))}
                    </ul>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }}>
                        Annulla
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenConteso}
                toggle={() => toggleModalConteso(!isOpenConteso)}
                labelledBy='conteso'
                centered
                size="lg"
            >
                <ModalHeader toggle={() => toggleModalConteso(!isOpenConteso)} id='conteso'>
                    Elenco EQ per le quali il dipendente è stato indicato come scelta
                </ModalHeader>
                <ModalBody>
                    {vincite.length > 0 &&
                        <Table responsive>
                            <thead>
                                <th>Nome EQ</th>
                                <th>Struttura organizzativa</th>
                                <th>Dirigente</th>
                            </thead>
                            <tbody>
                                {vincite.map( (v, i) =>
                                    <tr key={i} className={v.assegnata? 'candidato' : ''}>
                                        <td>{v.nomePo}</td>
                                        <td>{v.so}</td>
                                        <td>{v.dirigentePO}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => toggleModalConteso(!isOpenConteso)}>
                        Annulla
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenAltreCandidature}
                toggle={() => toggleModalAltreCandidature(!isOpenAltreCandidature)}
                labelledBy='altreCandidature'
                centered
                size="lg"
            >
                <ModalHeader toggle={() => toggleModalAltreCandidature(!isOpenAltreCandidature)} id='altreCandidature'>
                    Elenco altre candidature
                </ModalHeader>
                <ModalBody>
                    {elencoAltreCandidatureDipRegi.filter(c => c.stato !== 'IN_COMP').length > 0 &&
                        <Table responsive>
                            <thead>
                                <th>Nome EQ</th>
                                <th>Struttura organizzativa</th>
                                <th>Dirigente</th>
                            </thead>
                            <tbody>
                                {elencoAltreCandidatureDipRegi.filter(c => c.stato !== 'IN_COMP').map( cand =>
                                    <tr>
                                        <td>{cand.assegnazioneBandoPo?.posizioneOrganizzativa?.denominazione}</td>
                                        <td>{cand.assegnazioneBandoPo?.posizioneOrganizzativa?.nomeStrutturaOrganizzativa}</td>
                                        <td>{cand.assegnazioneBandoPo?.posizioneOrganizzativa?.dirigenteStrutturaOrganizzativa}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => toggleModalAltreCandidature(!isOpenAltreCandidature)}>
                        Annulla
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
        )}
        </>
    )
}

export default ElencoCandidature;

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { assegnaCodiceIPA, detailsStruttureOrganizzative, resetUpdateForm } from '../../store/strutturaOrganizzativaSlice';
import { FormikProps, useFormik } from 'formik';
import {  Button, Input } from 'design-react-kit';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail-form/StruttureOrganizzativeDetailsForm';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/sidemenu/sidemenuConstants';
import { CITTA, CODICE, DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, DIPARTIMENTO, EMAIL, FAX, INDIRIZZO, PEC, SEZIONE, TELEFONO, TIPOLOGIA_STRUTTURA } from './struttureOrganizzativeConstants';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { z } from 'zod';
import { SOCodiceIpaRequestDto } from '../../services/ms-anagrafica-unica';
import { toFormikValidationSchema } from "zod-formik-adapter";
import PageHeader from '../../components/page-header/PageHeader';

const schemaConfirm = z
  .object({
    codiceIpa: z.string({ required_error: 'required' }).trim().min(6, 'lenghtMinCodiceIpa').max(6, 'lenghtCodiceIpa'),
  })

const schemaSave = z
  .object({
    codiceIpa: z.string().min(6, 'lenghtMinCodiceIpa').max(6, 'lenghtCodiceIpa').optional(),
  })

const initialValues: SOCodiceIpaRequestDto = {
  codiceIpa: undefined,
  requestType: 'CodiceIpa',
  stato: undefined
}

const AssegnazioneCodiceIpa = () => {

  const { t } = useTranslation()
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fieldUsed: Array<string> = [CODICE, DENOMINAZIONE, DECLARATORIA, DATA_INIZIO_VALIDITA, TIPOLOGIA_STRUTTURA, DIPARTIMENTO, SEZIONE, EMAIL, PEC, TELEFONO, FAX, INDIRIZZO, CITTA]


  useEffect(() => {
    dispatch(resetUpdateForm())
    getDetailsFromApi(id!)
  }, [0])

  const strutturaOrganizzativa = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult);
  const strutturaOrganizzativaResult = useAppSelector((state) => state.strutturaOrganizzativa.detailsStatus);
  const [button, setButtonSubmit] = useState('')


  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  }

  const validationSchema = button === "CONFIRM" ? toFormikValidationSchema(schemaConfirm) : toFormikValidationSchema(schemaSave)


  const formData = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log('Submit form', JSON.stringify(values, null, 2));
      addCodiceIpa()
    },
  })

  const addCodiceIpa = () => {
    const body = {
      idStrutturaOrganizzativa: strutturaOrganizzativa?.id,
      bodyRequest: formData.values
    }

    dispatch(assegnaCodiceIPA(body));
  }



  useEffect(() => {
    formData.setValues({
      codiceIpa: strutturaOrganizzativa?.codiceIpa || '',
      requestType: 'CodiceIpa'
    }
    )
  }, [strutturaOrganizzativa])

  const getFieldError = (
    formAssegnazioneIpa: FormikProps<SOCodiceIpaRequestDto>,
    fieldName: keyof SOCodiceIpaRequestDto
  ): string => {
    console.log("formAssegnazioneIpa.touched", formAssegnazioneIpa.touched);
    if (formAssegnazioneIpa.getFieldMeta(fieldName).touched) {
      return formAssegnazioneIpa.errors[fieldName] || "";
    } else return "";
  };

  const submitConfirmAssIPA = () => {
    formData.setFieldValue('stato', 'CONFIRM');
    setTimeout(() => {
      formData.submitForm();
    }, 500);

  }
  const submitSaveAssIPA = () => {
    formData.setFieldValue('stato', 'SAVE');
    setTimeout(() => {
      formData.submitForm();
    }, 500);

  }
  const handleInputChangeAssIPA = (event: any) => {
    formData.setFieldValue('codiceIpa', event.target.value.toUpperCase());
  };

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} title={t("assegnazione codice IPA")!}></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED && <div>
        <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
        <div className="py-5">

          <div className="form-row">
            <Input
              label='Codice IPA'
              type='text'
              placeholder='Inserisci il Codice IPA '
              id='input-codiceIpa'
              wrapperClassName="form-group required col-md-12"
              name="codiceIpa"
              invalid={!!getFieldError(formData, "codiceIpa")}
              infoText={t(getFieldError(formData, "codiceIpa")) || ""}
              value={formData.values.codiceIpa}
              onChange={handleInputChangeAssIPA}
            />

          </div>
          <div className="d-flex flex-row-reverse">

            <Button color='primary' className="mt-2" onClick={() => { submitConfirmAssIPA(); setButtonSubmit('CONFIRM') }}>{t('conferma')}</Button>
            <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSaveAssIPA(); setButtonSubmit('SAVE') }}>{t('salva')}</Button>
            <Button color='secondary' outline className="mt-2 mr-2" onClick={() => navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`)} >{t('annulla')}</Button>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default AssegnazioneCodiceIpa
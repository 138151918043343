/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TitoliDiStudioMacroCategorieDto
 */
export interface TitoliDiStudioMacroCategorieDto {
    /**
     * 
     * @type {number}
     * @memberof TitoliDiStudioMacroCategorieDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TitoliDiStudioMacroCategorieDto
     */
    'classe'?: number;
    /**
     * 
     * @type {number}
     * @memberof TitoliDiStudioMacroCategorieDto
     */
    'sub'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TitoliDiStudioMacroCategorieDto
     */
    'previstoDominioSelezione'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TitoliDiStudioMacroCategorieDto
     */
    'descrizione'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitoliDiStudioMacroCategorieDto
     */
    'tipoDominioSelezione'?: TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum;
}

export const TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum = {
    TitoloDiStudio: 'TITOLO_DI_STUDIO',
    Ambito: 'AMBITO'
} as const;

export type TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum = typeof TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum[keyof typeof TitoliDiStudioMacroCategorieDtoTipoDominioSelezioneEnum];



import { Dimmer, Fade } from "design-react-kit";
import { Spinner } from "reactstrap";

import './PageLoader.scss';

export interface PageLoaderProps {
    loadingMessage? : string
}

function LoaderMini(props: PageLoaderProps) {
   
    return (
            <span className="d-flex justify-content-center mb-2 loader-mini">
                <Spinner active='true' color="primary" />
            </span>
    )
}

export default LoaderMini;


import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    Alert,
    Button,
    Col,
    DropdownMenu,
    DropdownToggle,
    Icon,
    Input,
    LinkList,
    LinkListItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
    UncontrolledDropdown,
} from "design-react-kit";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import CustomSelect, {
    CustomSelectItem,
} from "../../components/custom-select/CustomSelect";
import Pagination from "../../components/pager/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    inoltraStruttureOrganizzative,
    loadSOFormSearch,
    patchInoltraSo,
    resetInputForm,
    resetInsertResult,
    resetUpdateForm,
    searchStruttureOrganizzative,
} from "../../store/strutturaOrganizzativaSlice";
import { useTranslation } from "react-i18next";
import {
    STATUS_FULLFILLED,
} from "../../store/store-constants";
import { useNavigate } from "react-router-dom";
import {
    HREF_ASSEGNAZIONE_CODICE_BILANCIO,
    HREF_ASSEGNAZIONE_CODICE_CIFRA,
    HREF_ASSEGNAZIONE_CODICE_IPA,
    HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA,
    HREF_ASSEGNAZIONE_UTENZA_CIFRA,
    HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA,
    HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA,
    HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA,
    HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA,
    HREF_NUOVA_STRUTTURA_ORGANIZZATIVA,
} from "../../components/sidemenu/sidemenuConstants";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { parseISO, format } from 'date-fns';
import { OptionDtoStatoStrutturaOrganizzativaValoreEnum, OptionDtoStatoStrutturaOrganizzativaValueEnum, OptionDtoTipoStrutturaOrganizzativaValueEnum, RuoloUtenteAutenticatoDto, StrutturaOrganizzativaDto, StrutturaOrganizzativaDtoTipoEnum } from "../../services/ms-anagrafica-unica";
import PageHeader from "../../components/page-header/PageHeader";
import './style.scss'
import { setOrganigrammaStorico } from "../../store/organigrammaSlice";

const schema = z.object({
    codInca: z.string().max(8, { message: "lenghtCodInca" }).optional(),
    denominazione: z.string().max(50, { message: "lenghtdenominazione" }).optional(),
    stato: z.string().optional(),
    tipo: z.string().optional(),
    strutturaEquiparata: z.string().optional()
});

const initialValues = {
    codInca: "",
    denominazione: "",
    stato: "",
    tipo: "",
    pageNum: 0,
    pageSize: 10,
    sort: "",
    direction: "",
    strutturaEquiparata: ""
};

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

function ElencoStruttureOrganizzative(props: {operations: RuoloUtenteAutenticatoDto}) {
    const searchFormElencoSO = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { 
        searchResult,
        searchStatus,
        formSearchComponents,
        searchInput
    } = useAppSelector((state) => state.strutturaOrganizzativa);

    const [formCollapseOpen, setFormCollapseOpen] = useState(false);

    useEffect(() => {
        dispatch(resetUpdateForm());
        dispatch(resetInputForm())
        dispatch(resetInsertResult())
        dispatch(loadSOFormSearch());
        dispatch(setOrganigrammaStorico(false))
        if ((searchInput?.codInca === '' && searchInput?.denominazione === '' && searchInput?.stato === '' && searchInput?.strutturaEquiparata === '' && searchInput?.tipo === '' && searchInput.sort === '' && searchInput.direction === '') || searchInput == null) {
            doSearch();
        }
    }, [])


    let hasResults =
        searchResult && searchResult.totalElements
            ? searchResult.totalElements > 0
            : false;

    const handleChange = (name: string, selectedOption: any) => {
        searchFormElencoSO.setFieldValue(name, selectedOption);
    };

    useEffect(() => {
        searchFormElencoSO.setValues({
            codInca: searchInput?.codInca || '',
            denominazione: searchInput?.denominazione || '',
            stato: searchInput?.stato || '',
            tipo: searchInput?.tipo || '',
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            sort: searchInput?.sort || '',
            direction: searchInput?.direction || '',
            strutturaEquiparata: searchInput?.strutturaEquiparata || ''

        })

        if (!!searchInput && (searchInput.codInca !== '' || searchInput.denominazione !== '' || searchInput.stato !== '' || searchInput.strutturaEquiparata !== '' || searchInput.tipo !== '')) {
            if (searchInput.sort === '' && searchInput.direction === '') {
                setFormCollapseOpen(true);
            }
            dispatch(searchStruttureOrganizzative(searchInput))
        } else if (!!searchInput && (searchInput.sort !== '' || searchInput.direction !== '')) {
            dispatch(searchStruttureOrganizzative(searchInput))
        }

    }, [searchInput])

    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    let statoOptions: CustomSelectItem[] = [];
    formSearchComponents?.stato?.forEach((s) => {
        statoOptions.push({ label: s.label, value: s.valore });
    });

    let tipoStrutturaOptions: CustomSelectItem[] = [];
    formSearchComponents?.tipo?.forEach((s) => {
        tipoStrutturaOptions.push({ label: s.label, value: s.valore });
    });

    let strutturaEquiparataOptions: CustomSelectItem[] = [];
    formSearchComponents?.siNo?.forEach((s) => {
        strutturaEquiparataOptions.push({ label: s.label, value: s.valore });
    })

    const doSearch = () => {
        dispatch(searchStruttureOrganizzative(searchFormElencoSO.values));
    };

    const handlePageChange = (pageNum: number) => {
        searchFormElencoSO.setFieldValue("pageNum", pageNum - 1);
        searchFormElencoSO.handleSubmit();
    };

    const searchStartKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            searchFormElencoSO.setFieldValue("pageNum", 0);
            searchFormElencoSO.handleSubmit();
        }
    }

    const ordinamentoElencoSO = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchFormElencoSO.setFieldValue("sort", columnName);
        searchFormElencoSO.setFieldValue("direction", sorting.direction);
        searchFormElencoSO.handleSubmit();
    };
    const [isOpen, toggleModal] = useState(false);
    const [strutturaSelezionata, setStrutturaSelezionata] = useState<string>()

    const inoltra = (id: string) => {
        setStrutturaSelezionata(id)
        toggleModal(!isOpen)
    }

    const confermoInoltro = () => {
        const body: patchInoltraSo = {
            idStrutturaOrganizzativa: strutturaSelezionata,
            bodyRequest: { requestType: 'Inoltra' }
        }

        setStrutturaSelezionata(undefined)
        dispatch(inoltraStruttureOrganizzative(body)).unwrap().then(resp => !!resp && window.location.reload());
    }

    const showModificaSOOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum) =>
        (props.operations?.elencoFunzionalita?.includes('SO_MOD_DIPA')
        || props.operations?.elencoFunzionalita?.includes('SO_MOD_SEZ')
        || props.operations?.elencoFunzionalita?.includes('SO_MOD_SERV')
        || props.operations?.elencoFunzionalita?.includes('SO_DISA_SERV')
        || props.operations?.elencoFunzionalita?.includes('SO_DISA_DIPA')
        || props.operations?.elencoFunzionalita?.includes('SO_DISA_SEZ')
        || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODCIFRA')
        || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODIPA')
        || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SERV')
        || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_DIP')
        || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SEZ')
        || props.operations?.elencoFunzionalita?.includes('SO_CENSISCI')
        || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SERV')
        || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_DIPA')
        || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SEZ'))
        && props.operations?.elencoFunzionalita?.includes('SO_DETTAGLIO')
        && (soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Compilata || soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Censita || soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata)

    const showAssegnazioneCodiceCifraOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum, soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('SO_ASS_CODCIFRA')
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata
        && soTipo !== OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio

    const showDisassocia = (soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) =>
        (props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SERV') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio)
        || (props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_DIPA') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Dipartimento)
        || (props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SEZ') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Sezione)

    const showAssocia = (soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) =>
        (props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SERV') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio)
        || (props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_DIP') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Dipartimento)
        || (props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SEZ') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Sezione)

    const showNuovaSo = () =>
        props.operations?.elencoFunzionalita?.includes('SO_INS_DIPA')
            || props.operations?.elencoFunzionalita?.includes('SO_INS_SEZ') 
            || props.operations?.elencoFunzionalita?.includes('SO_INS_SERV') 


    const showAssegnazioneCodiceIPAOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('SO_ASS_CODIPA')
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata

    const showAssegnazioneCodiceBilancioOperations = (so: StrutturaOrganizzativaDto) => {
        if (
            props.operations?.elencoFunzionalita?.includes('SO_ASS_CODBILA') && so.stato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata
            && ((so.tipo === StrutturaOrganizzativaDtoTipoEnum.Dipartimento) || (
                so.tipo === StrutturaOrganizzativaDtoTipoEnum.Sezione && !!so.strutturaOrganizzativaPadre?.codiceDipartimentoRagioneria))
        ) {
            return true
        }
        return false
    }

    const showDisattivaSOOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum) =>
        (props.operations?.elencoFunzionalita?.includes('SO_DISA_DIPA') 
        || props.operations?.elencoFunzionalita?.includes('SO_DISA_SERV')
        || props.operations?.elencoFunzionalita?.includes('SO_DISA_SEZ'))
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Censita

    const showAssegnazioneUtenteCifraOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum, soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) => 
        props.operations?.elencoFunzionalita?.includes('SO_INSERIMENTO_CIFRA')
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Censita
        && soTipo !== OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio


    const showInoltra = (x: StrutturaOrganizzativaDto) => 
        props.operations?.elencoFunzionalita?.includes('SO_INOLTRA')
        && ((x.stato === 'REGISTRATA' && x.tipo === 'DIPARTIMENTO' && x.codiceDipartimentoRagioneria != null && x.codiceIpa != null) ||
            (x.stato === 'REGISTRATA' && x.tipo === 'SEZIONE' && x.codiceSezioneRagioneria != null && x.codiceIpa != null) ||
            (x.stato === 'REGISTRATA' && x.tipo === 'SERVIZIO' && x.codiceIpa != null))


    const showVisualizza = (x: StrutturaOrganizzativaDto) => 
        (x.stato !== 'COMPILATA' || (
            (x.stato === 'COMPILATA' && x.tipo === 'DIPARTIMENTO') || 
             x.stato === 'COMPILATA' && !!x.codiceIncaricoPadre)) 

    const enableAzioni = (x: StrutturaOrganizzativaDto)=> showAssegnazioneCodiceBilancioOperations(x) || showAssegnazioneCodiceCifraOperations(x.stato!, x.tipo!) ||
                                                            showAssegnazioneCodiceIPAOperations(x.stato!) || showAssegnazioneUtenteCifraOperations(x.stato!, x.tipo!) ||
                                                             showAssocia(x.tipo!) || showDisassocia(x.tipo!) || showDisattivaSOOperations(x.stato!) || showModificaSOOperations(x.stato!) || 
                                                              showInoltra(x) || showVisualizza(x)

    const tastoNuovaSo = {
            buttonProperties: { outline: true },
            title: 'Nuova struttura organizzativa',
            buttonColor: 'primary',
            action: () => navigate(HREF_NUOVA_STRUTTURA_ORGANIZZATIVA),
            showButtonIcon: true,
            icon: 'it-plus',
            iconColor: 'primary',
        };

    const tastoRicerca = {
            buttonProperties: { outline: true },
            title: 'Ricerca',
            buttonColor: 'primary',
            showButtonIcon: true,
            icon: 'it-search',
            iconColor: 'primary',
            action: () => { formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true) },
        };
    
    const bottoni = showNuovaSo()? [tastoNuovaSo, tastoRicerca] : [tastoRicerca];
    
    
    const goToOrganigramma = (x: StrutturaOrganizzativaDto) => {

        let dipartimentoID;
        let soID;
        let tipo;

        switch (x.tipo) {
            case StrutturaOrganizzativaDtoTipoEnum.Servizio:
                dipartimentoID = x.strutturaOrganizzativaPadre?.codiceIncaricoPadre
                soID = x.id
                tipo = StrutturaOrganizzativaDtoTipoEnum.Servizio
                break;
            case StrutturaOrganizzativaDtoTipoEnum.Sezione:
                dipartimentoID = x.codiceIncaricoPadre
                soID = x.id
                tipo = StrutturaOrganizzativaDtoTipoEnum.Sezione
                break;
            default:
                dipartimentoID = x.id
                soID = x.id
                tipo = StrutturaOrganizzativaDtoTipoEnum.Dipartimento
                break;
        }
        navigate({
            pathname: `${HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA}/${dipartimentoID}`,
            search: `so=${soID}&tipo=${tipo}`
        })
    }

    return (
        <div>
            <PageHeader showIcon={false} title={t("elenco strutture organizzative")!}
                buttons={bottoni}
            />
            <Accordion background="active">
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-5">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchFormElencoSO.values.pageNum}
                            onChange={searchFormElencoSO.handleChange}
                        />
                        <Input
                            type="text"
                            label="Codice"
                            id="input-codice-1"
                            placeholder="Inserisci un codice"
                            wrapperClassName="col col-md-6"
                            name="codInca"
                            invalid={!!searchFormElencoSO.errors.codInca}
                            infoText={t(searchFormElencoSO.errors.codInca || "") || ""}
                            value={searchFormElencoSO.values.codInca}
                            onChange={searchFormElencoSO.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Denominazione"
                            id="input-denominazione-1"
                            placeholder="Inserisci una denominazione"
                            wrapperClassName="col col-md-6"
                            name="denominazione"
                            invalid={!!searchFormElencoSO.errors.denominazione}
                            infoText={t(searchFormElencoSO.errors.denominazione || "") || ""}
                            value={searchFormElencoSO.values.denominazione}
                            onChange={searchFormElencoSO.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Col md="6">
                            <CustomSelect
                                label="Stato"
                                options={statoOptions}
                                value={searchFormElencoSO.values.stato}
                                placeholder="Seleziona stato"
                                onChange={(e) => handleChange("stato", e)}
                                invalid={!!searchFormElencoSO.errors.stato}
                                infoText={t(searchFormElencoSO.errors.stato || "") || ""}
                                name="stato"
                            />
                        </Col>

                        <Col md="6">
                            <CustomSelect
                                label="Tipo struttura"
                                options={tipoStrutturaOptions}
                                value={searchFormElencoSO.values.tipo}
                                placeholder="Seleziona tipo"
                                invalid={!!searchFormElencoSO.errors.tipo}
                                infoText={t(searchFormElencoSO.errors.tipo || "") || ""}
                                onChange={(e) => handleChange("tipo", e)}
                                name="tipo"
                            />
                        </Col>

                        <Col md="6">
                            <CustomSelect
                                label="Struttura equiparata"
                                options={strutturaEquiparataOptions}
                                value={searchFormElencoSO.values.strutturaEquiparata}
                                placeholder="Struttura equiparata"
                                invalid={!!searchFormElencoSO.errors.strutturaEquiparata}
                                infoText={t(searchFormElencoSO.errors.strutturaEquiparata || "") || ""}
                                onChange={(e) => handleChange("strutturaEquiparata", e)}
                                name="strutturaEquiparata"
                            />
                        </Col>
                    </div>

                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchFormElencoSO.setFieldValue("pageNum", 0);
                                searchFormElencoSO.handleSubmit();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchFormElencoSO.handleReset}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>

            <div className="mt-5 position-relative">
                <div>
                    {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
                    {searchStatus === STATUS_FULLFILLED && hasResults && (
                        <div className={searchResult.totalPages === 1 ? 'mb-5 table-responsive' : 'table-responsive'} >
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th scope="col" onClick={() => ordinamentoElencoSO("codInca")} >
                                            <div className="table-order column-codice">
                                                {t("codice")}
                                                <div className="icon-direction">
                                                    <Icon
                                                        icon="it-collapse"
                                                        size="xs"
                                                    />
                                                    <Icon
                                                        icon="it-expand"
                                                        size="xs"
                                                    />
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col-2" onClick={() => ordinamentoElencoSO("denominazione")} style={{ width: '20%'}}>
                                            <div className="table-order column-denominazione">
                                                {t("denominazione")}
                                                <div className="icon-direction">
                                                    <Icon
                                                        icon="it-collapse"
                                                        size="xs"
                                                    />
                                                    <Icon
                                                        icon="it-expand"
                                                        size="xs"
                                                    />
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" onClick={() => ordinamentoElencoSO("dataInserimento")}>
                                            <div className="table-ordercolumn-data-ins">
                                                {t("data inserimento")}
                                                <div className="icon-direction">
                                                    <Icon
                                                        icon="it-collapse"
                                                        size="xs"
                                                    />
                                                    <Icon
                                                        icon="it-expand"
                                                        size="xs"
                                                    />
                                                </div>
                                            </div></th>
                                        <th scope="col" onClick={() => ordinamentoElencoSO("dataModifica")}>
                                            <div className="table-order column-data-mod">
                                                {t("data modifica")}
                                                <div className="icon-direction">
                                                    <Icon
                                                        icon="it-collapse"
                                                        size="xs"
                                                    />
                                                    <Icon
                                                        icon="it-expand"
                                                        size="xs"
                                                    />
                                                </div>

                                            </div> </th>
                                        <th scope="col" onClick={() => ordinamentoElencoSO("tipologiaStruttura")}>
                                            <div className="table-order column-tipo-so">
                                                {t("tipo struttura")}
                                                <div className="icon-direction">
                                                    <Icon
                                                        icon="it-collapse"
                                                        size="xs"
                                                    />
                                                    <Icon
                                                        icon="it-expand"
                                                        size="xs"
                                                    />
                                                </div>

                                            </div> </th>
                                        <th scope="col" onClick={() => ordinamentoElencoSO("codiceStato")}>
                                            <div className="table-order column-stato">
                                                {t("stato")}
                                                <div className="icon-direction">
                                                    <Icon
                                                        icon="it-collapse"
                                                        size="xs"
                                                    />
                                                    <Icon
                                                        icon="it-expand"
                                                        size="xs"
                                                    />
                                                </div>

                                            </div></th>
                                        <th scope="col">
                                            <div className="table-order column-azioni">
                                                {t("azioni")}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(searchResult.data || []).map((x, i) => (
                                        <tr key={x.id}>
                                            <td>{x.id}</td>
                                            <td>{x.descrizioneBreve}</td>
                                            <td>{x.dataInserimento != null ? format(parseISO(x.dataInserimento), 'dd/MM/yyyy') : ''}</td>
                                            <td>{x.dataModifica != null ? format(parseISO(x.dataModifica), 'dd/MM/yyyy') : ''}</td>
                                            <td>{x.tipo}</td>
                                            <td>{x.stato}</td>
                                            <td>
                                                <UncontrolledDropdown direction="left">
                                                    <DropdownToggle disabled={!enableAzioni(x)} nav>
                                                        <Icon icon="it-more-actions"/>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="no-arrow">
                                                        <LinkList>
                                                            {showVisualizza(x) && <LinkListItem
                                                                size="medium"
                                                                onClick={() => goToOrganigramma(x)}>
                                                                <span>Visualizza</span>
                                                            </LinkListItem>}
                                                            { showModificaSOOperations(x.stato!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Modifica</span>
                                                            </LinkListItem>}
                                                            {showAssocia(x.tipo!) && x.stato === 'CENSITA' && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Associa</span>
                                                            </LinkListItem>}
                                                            {showDisassocia(x.tipo!) && x.stato === 'CENSITA' && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Disassocia</span>
                                                            </LinkListItem>}
                                                            {showAssegnazioneCodiceCifraOperations(x.stato!, x.tipo!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_ASSEGNAZIONE_CODICE_CIFRA}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Codice CIFRA</span>
                                                            </LinkListItem>}
                                                            {showAssegnazioneCodiceIPAOperations(x.stato!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_ASSEGNAZIONE_CODICE_IPA}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Codice IPA</span>
                                                            </LinkListItem>}
                                                            {showAssegnazioneCodiceBilancioOperations(x) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_ASSEGNAZIONE_CODICE_BILANCIO}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Codice Bilancio</span>
                                                            </LinkListItem>}
                                                            {showDisattivaSOOperations(x.stato!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Disattivazione</span>
                                                            </LinkListItem>}
                                                            {showAssegnazioneUtenteCifraOperations(x.stato!, x.tipo!) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `${HREF_ASSEGNAZIONE_UTENZA_CIFRA}/${x.id}`
                                                                    )
                                                                }
                                                            >
                                                                <span>Utenza cifra</span>
                                                            </LinkListItem>}
                                                            {showInoltra(x) && <LinkListItem
                                                                size="medium"
                                                                onClick={() =>
                                                                    inoltra(x.id!)
                                                                }
                                                            >
                                                                    <span>Inoltra</span>
                                                                </LinkListItem>}
                                                        </LinkList>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div>
                                <Modal
                                    isOpen={isOpen}
                                    toggle={() => toggleModal(!isOpen)}
                                    labelledBy='esempio1'
                                    centered
                                >
                                    <ModalHeader toggle={() => { toggleModal(!isOpen); setStrutturaSelezionata(undefined) }} id='esempio1'>
                                        Inoltra struttura organizzativa
                                    </ModalHeader>
                                    <ModalBody>
                                        <p>Sei sicuro di voler inoltrare questa struttura organizzativa?</p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color='secondary' onClick={() => { toggleModal(!isOpen); setStrutturaSelezionata(undefined) }}>
                                            No
                                        </Button>
                                        <Button color='primary' onClick={() => { toggleModal(!isOpen); confermoInoltro() }}>
                                            Si
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                            <Pagination
                                totalCount={searchResult.totalElements || 0}
                                siblingCount={1}
                                currentPage={searchFormElencoSO.values.pageNum + 1}
                                pageSize={searchResult.pageSize || 10}
                                onPageChange={(page: number) => {
                                    handlePageChange(page);
                                }}
                                className="justify-content-center"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ElencoStruttureOrganizzative;

/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DipendenteRegionaleCessaRequestDto } from '../model';
// @ts-ignore
import { DipendenteRegionaleDto } from '../model';
// @ts-ignore
import { InfoDecessoDipendenteDto } from '../model';
// @ts-ignore
import { PagedDtoDipendenteRegionaleDto } from '../model';
// @ts-ignore
import { PatchDipendenteRegionaleRequest } from '../model';
/**
 * DipendentiRegionaliControllerApi - axios parameter creator
 * @export
 */
export const DipendentiRegionaliControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per il dettaglio del dipendente.
         * @summary Dettaglio dipendente
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDipendenteRegionale: async (idDipendente: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idDipendente' is not null or undefined
            assertParamExists('detailDipendenteRegionale', 'idDipendente', idDipendente)
            const localVarPath = `/v1/dipendenti/{idDipendente}`
                .replace(`{${"idDipendente"}}`, encodeURIComponent(String(idDipendente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero delle informazione decesso.
         * @summary Dettaglio decesso per il dipendente
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoDecesso: async (idDipendente: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idDipendente' is not null or undefined
            assertParamExists('infoDecesso', 'idDipendente', idDipendente)
            const localVarPath = `/v1/dipendenti/{idDipendente}/infoDecesso`
                .replace(`{${"idDipendente"}}`, encodeURIComponent(String(idDipendente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch dipendente regionale
         * @param {number} idDipendente 
         * @param {PatchDipendenteRegionaleRequest} patchDipendenteRegionaleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDipendenteRegionale: async (idDipendente: number, patchDipendenteRegionaleRequest: PatchDipendenteRegionaleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idDipendente' is not null or undefined
            assertParamExists('patchDipendenteRegionale', 'idDipendente', idDipendente)
            // verify required parameter 'patchDipendenteRegionaleRequest' is not null or undefined
            assertParamExists('patchDipendenteRegionale', 'patchDipendenteRegionaleRequest', patchDipendenteRegionaleRequest)
            const localVarPath = `/v1/dipendenti/{idDipendente}`
                .replace(`{${"idDipendente"}}`, encodeURIComponent(String(idDipendente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchDipendenteRegionaleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca dei dipendenti regionali.
         * @summary Ricerca dipendenti regionali
         * @param {number} [id] 
         * @param {string} [nomeCompleto] 
         * @param {string} [codiceFiscale] 
         * @param {string} [strutturaOrganizzativa] 
         * @param {'CENSITO' | 'TERMINATO'} [statoDipendente] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipoStrutturaOrganizzativa] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDipendenteRegionale: async (id?: number, nomeCompleto?: string, codiceFiscale?: string, strutturaOrganizzativa?: string, statoDipendente?: 'CENSITO' | 'TERMINATO', tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dipendenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (nomeCompleto !== undefined) {
                localVarQueryParameter['nomeCompleto'] = nomeCompleto;
            }

            if (codiceFiscale !== undefined) {
                localVarQueryParameter['codiceFiscale'] = codiceFiscale;
            }

            if (strutturaOrganizzativa !== undefined) {
                localVarQueryParameter['strutturaOrganizzativa'] = strutturaOrganizzativa;
            }

            if (statoDipendente !== undefined) {
                localVarQueryParameter['statoDipendente'] = statoDipendente;
            }

            if (tipoStrutturaOrganizzativa !== undefined) {
                localVarQueryParameter['tipoStrutturaOrganizzativa'] = tipoStrutturaOrganizzativa;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DipendentiRegionaliControllerApi - functional programming interface
 * @export
 */
export const DipendentiRegionaliControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DipendentiRegionaliControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per il dettaglio del dipendente.
         * @summary Dettaglio dipendente
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDipendenteRegionale(idDipendente: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDipendenteRegionale(idDipendente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero delle informazione decesso.
         * @summary Dettaglio decesso per il dipendente
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoDecesso(idDipendente: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoDecessoDipendenteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoDecesso(idDipendente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch dipendente regionale
         * @param {number} idDipendente 
         * @param {PatchDipendenteRegionaleRequest} patchDipendenteRegionaleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDipendenteRegionale(idDipendente: number, patchDipendenteRegionaleRequest: PatchDipendenteRegionaleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDipendenteRegionale(idDipendente, patchDipendenteRegionaleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca dei dipendenti regionali.
         * @summary Ricerca dipendenti regionali
         * @param {number} [id] 
         * @param {string} [nomeCompleto] 
         * @param {string} [codiceFiscale] 
         * @param {string} [strutturaOrganizzativa] 
         * @param {'CENSITO' | 'TERMINATO'} [statoDipendente] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipoStrutturaOrganizzativa] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDipendenteRegionale(id?: number, nomeCompleto?: string, codiceFiscale?: string, strutturaOrganizzativa?: string, statoDipendente?: 'CENSITO' | 'TERMINATO', tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoDipendenteRegionaleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDipendenteRegionale(id, nomeCompleto, codiceFiscale, strutturaOrganizzativa, statoDipendente, tipoStrutturaOrganizzativa, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DipendentiRegionaliControllerApi - factory interface
 * @export
 */
export const DipendentiRegionaliControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DipendentiRegionaliControllerApiFp(configuration)
    return {
        /**
         * API Rest per il dettaglio del dipendente.
         * @summary Dettaglio dipendente
         * @param {DipendentiRegionaliControllerApiDetailDipendenteRegionaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDipendenteRegionale(requestParameters: DipendentiRegionaliControllerApiDetailDipendenteRegionaleRequest, options?: AxiosRequestConfig): AxiosPromise<DipendenteRegionaleDto> {
            return localVarFp.detailDipendenteRegionale(requestParameters.idDipendente, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero delle informazione decesso.
         * @summary Dettaglio decesso per il dipendente
         * @param {DipendentiRegionaliControllerApiInfoDecessoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoDecesso(requestParameters: DipendentiRegionaliControllerApiInfoDecessoRequest, options?: AxiosRequestConfig): AxiosPromise<InfoDecessoDipendenteDto> {
            return localVarFp.infoDecesso(requestParameters.idDipendente, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch dipendente regionale
         * @param {DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDipendenteRegionale(requestParameters: DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest, options?: AxiosRequestConfig): AxiosPromise<DipendenteRegionaleDto> {
            return localVarFp.patchDipendenteRegionale(requestParameters.idDipendente, requestParameters.patchDipendenteRegionaleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca dei dipendenti regionali.
         * @summary Ricerca dipendenti regionali
         * @param {DipendentiRegionaliControllerApiSearchDipendenteRegionaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDipendenteRegionale(requestParameters: DipendentiRegionaliControllerApiSearchDipendenteRegionaleRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoDipendenteRegionaleDto> {
            return localVarFp.searchDipendenteRegionale(requestParameters.id, requestParameters.nomeCompleto, requestParameters.codiceFiscale, requestParameters.strutturaOrganizzativa, requestParameters.statoDipendente, requestParameters.tipoStrutturaOrganizzativa, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for detailDipendenteRegionale operation in DipendentiRegionaliControllerApi.
 * @export
 * @interface DipendentiRegionaliControllerApiDetailDipendenteRegionaleRequest
 */
export interface DipendentiRegionaliControllerApiDetailDipendenteRegionaleRequest {
    /**
     * 
     * @type {number}
     * @memberof DipendentiRegionaliControllerApiDetailDipendenteRegionale
     */
    readonly idDipendente: number
}

/**
 * Request parameters for infoDecesso operation in DipendentiRegionaliControllerApi.
 * @export
 * @interface DipendentiRegionaliControllerApiInfoDecessoRequest
 */
export interface DipendentiRegionaliControllerApiInfoDecessoRequest {
    /**
     * 
     * @type {number}
     * @memberof DipendentiRegionaliControllerApiInfoDecesso
     */
    readonly idDipendente: number
}

/**
 * Request parameters for patchDipendenteRegionale operation in DipendentiRegionaliControllerApi.
 * @export
 * @interface DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest
 */
export interface DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest {
    /**
     * 
     * @type {number}
     * @memberof DipendentiRegionaliControllerApiPatchDipendenteRegionale
     */
    readonly idDipendente: number

    /**
     * 
     * @type {PatchDipendenteRegionaleRequest}
     * @memberof DipendentiRegionaliControllerApiPatchDipendenteRegionale
     */
    readonly patchDipendenteRegionaleRequest: PatchDipendenteRegionaleRequest
}

/**
 * Request parameters for searchDipendenteRegionale operation in DipendentiRegionaliControllerApi.
 * @export
 * @interface DipendentiRegionaliControllerApiSearchDipendenteRegionaleRequest
 */
export interface DipendentiRegionaliControllerApiSearchDipendenteRegionaleRequest {
    /**
     * 
     * @type {number}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly nomeCompleto?: string

    /**
     * 
     * @type {string}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly codiceFiscale?: string

    /**
     * 
     * @type {string}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly strutturaOrganizzativa?: string

    /**
     * 
     * @type {'CENSITO' | 'TERMINATO'}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly statoDipendente?: 'CENSITO' | 'TERMINATO'

    /**
     * 
     * @type {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'

    /**
     * 
     * @type {number}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof DipendentiRegionaliControllerApiSearchDipendenteRegionale
     */
    readonly q?: string
}

/**
 * DipendentiRegionaliControllerApi - object-oriented interface
 * @export
 * @class DipendentiRegionaliControllerApi
 * @extends {BaseAPI}
 */
export class DipendentiRegionaliControllerApi extends BaseAPI {
    /**
     * API Rest per il dettaglio del dipendente.
     * @summary Dettaglio dipendente
     * @param {DipendentiRegionaliControllerApiDetailDipendenteRegionaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DipendentiRegionaliControllerApi
     */
    public detailDipendenteRegionale(requestParameters: DipendentiRegionaliControllerApiDetailDipendenteRegionaleRequest, options?: AxiosRequestConfig) {
        return DipendentiRegionaliControllerApiFp(this.configuration).detailDipendenteRegionale(requestParameters.idDipendente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero delle informazione decesso.
     * @summary Dettaglio decesso per il dipendente
     * @param {DipendentiRegionaliControllerApiInfoDecessoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DipendentiRegionaliControllerApi
     */
    public infoDecesso(requestParameters: DipendentiRegionaliControllerApiInfoDecessoRequest, options?: AxiosRequestConfig) {
        return DipendentiRegionaliControllerApiFp(this.configuration).infoDecesso(requestParameters.idDipendente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch dipendente regionale
     * @param {DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DipendentiRegionaliControllerApi
     */
    public patchDipendenteRegionale(requestParameters: DipendentiRegionaliControllerApiPatchDipendenteRegionaleRequest, options?: AxiosRequestConfig) {
        return DipendentiRegionaliControllerApiFp(this.configuration).patchDipendenteRegionale(requestParameters.idDipendente, requestParameters.patchDipendenteRegionaleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca dei dipendenti regionali.
     * @summary Ricerca dipendenti regionali
     * @param {DipendentiRegionaliControllerApiSearchDipendenteRegionaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DipendentiRegionaliControllerApi
     */
    public searchDipendenteRegionale(requestParameters: DipendentiRegionaliControllerApiSearchDipendenteRegionaleRequest = {}, options?: AxiosRequestConfig) {
        return DipendentiRegionaliControllerApiFp(this.configuration).searchDipendenteRegionale(requestParameters.id, requestParameters.nomeCompleto, requestParameters.codiceFiscale, requestParameters.strutturaOrganizzativa, requestParameters.statoDipendente, requestParameters.tipoStrutturaOrganizzativa, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}

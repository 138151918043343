import { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Accordion, AccordionBody, AccordionHeader, Button, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown } from 'design-react-kit';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { resetEditStatus, resetInfoDecesso, resetInfoDettaglio } from '../../store/dipendentiRegionaliSlice';
import { z } from 'zod';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useNavigate } from 'react-router-dom';
import { loadSOFormSearch } from '../../store/strutturaOrganizzativaSlice';
import { t } from 'i18next';
import Pagination from '../../components/pager/Pagination';
import { DipendenteRegionaleDtoStatoEnum, RuoloUtenteAutenticatoDto} from '../../services/ms-anagrafica-unica';
import { HREF_CONSULENTE_ESTERNO_DETTAGLIO, HREF_CONSULENTE_ESTERNO_INSERISCI, HREF_CONSULENTE_ESTERNO_MODIFICA} from '../../components/sidemenu/sidemenuConstants';
import CustomAlert from '../../components/custom-alert/CustomAlert';
import PageHeader from '../../components/page-header/PageHeader';
import { deleteConsulente, searchConsulentiEsterni } from '../../store/consulentiEsterniSlice';
import { format } from 'date-fns';
import { it } from 'date-fns/locale'

const schema = z.object({
    id: z.number().optional(),
    nome: z.string().max(200, { message: "lenghtnome" }).optional(),
    cognome: z.string().max(200, { message: "lenghtcognome" }).optional(),
    strutturaOrganizzativa: z.string().optional()
});

const initialValues = {
    pageNum: 0,
    pageSize: 10,
    direction: 'ASC',
    sort: 'nomeCompleto',
    id: 0,
    nome: '',
    cognome: '',
    strutturaOrganizzativa: '',
};

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

const formatDate = (date:string) => {
    if (!date)
        return ''
    const giorno = format(new Date(date), 'd');
    const mese = format(new Date(date), 'MM', {locale: it});
    const anno = format(new Date(date), 'yyyy');
    const meseFormattato = mese.charAt(0).toUpperCase() + mese.slice(1).toLowerCase()
    return `${giorno}/${meseFormattato}/${anno}`
}

const ElencoConsulentiEsterni = (props: { operations: RuoloUtenteAutenticatoDto }) => {

    const searchFormElencoCosulentiExt = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    const {
        searchResult,
        searchStatus,
        searchInput
    } = useAppSelector((state) => state.consulentiEsterni);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [formCollapseOpen, setFormCollapseOpen] = useState(false);
    const [consulente, setConsulente] = useState<number>();
    const [isOpenListDetails, toggleModalListDetails] = useState(false);
    const [elencoDetails, setElencoDetails] = useState<any[] | undefined>([]);
    const [isOpenElimininaRichiesta, toggleModalElimininaRichiesta] = useState(false);

    useEffect(() => {
        dispatch(resetEditStatus());
        dispatch(resetInfoDettaglio());
        dispatch(resetInfoDecesso());
        dispatch(loadSOFormSearch());
        if (searchInput == undefined || (searchInput?.id == 0 && searchInput?.nome == '' && searchInput?.cognome == '' && searchInput?.strutturaOrganizzativa == '' && searchInput?.sort == '' && searchInput?.direction == '')) {
            doSearch();
        }
    }, [0])

    useEffect(() => {
        searchFormElencoCosulentiExt.setValues({
            id: searchInput?.id || 0,
            nome: searchInput?.nome || '',
            cognome: searchInput?.cognome || '',
            strutturaOrganizzativa: searchInput?.strutturaOrganizzativa || '',
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            sort: searchInput?.sort || '',
            direction: searchInput?.direction || '',
        })

        if (searchInput != undefined && (searchInput.id != 0 || searchInput.nome != '' || searchInput.cognome != '' || searchInput.strutturaOrganizzativa != '' || searchInput?.sort != '' || searchInput?.direction != '')) {
            if (searchInput?.sort == '' && searchInput?.direction == '') {
                setFormCollapseOpen(true);
            }
            dispatch(searchConsulentiEsterni(searchInput))
        }

    }, [searchInput])

    let hasResults =
        searchResult && searchResult.totalElements
            ? searchResult.totalElements > 0
            : false;

    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    const doSearch = () => {
        dispatch(searchConsulentiEsterni(searchFormElencoCosulentiExt.values));
    };

    const searchStartKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            searchFormElencoCosulentiExt.setFieldValue("pageNum", 0);
            searchFormElencoCosulentiExt.handleSubmit();
        }
    }

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchFormElencoCosulentiExt.setFieldValue("sort", columnName);
        searchFormElencoCosulentiExt.setFieldValue("direction", sorting.direction);
        searchFormElencoCosulentiExt.handleSubmit();
    };


    const handlePageChange = (pageNum: number) => {
        console.log("Handle page change", pageNum - 1);
        searchFormElencoCosulentiExt.setFieldValue("pageNum", pageNum - 1);
        searchFormElencoCosulentiExt.handleSubmit();
    };

    const showModifica = (stato:  DipendenteRegionaleDtoStatoEnum) =>
        stato === DipendenteRegionaleDtoStatoEnum.Censito
        && props.operations?.elencoFunzionalita?.includes('CONSULENTEESTERNO_PATCH')

    const showInserisci = () => {
        if (props.operations?.elencoFunzionalita?.includes('CONSULENTEESTERNO_INSERT'))
            {return true;}
        return false;
    }

    const showCancella = (stato: DipendenteRegionaleDtoStatoEnum) =>
        stato === DipendenteRegionaleDtoStatoEnum.Censito
        && props.operations?.elencoFunzionalita?.includes('CONSULENTEESTERNO_DELETE')

    const insertButton = {
        buttonProperties: { outline: true },
        title: 'Nuovo consulente esterno',
        buttonColor: 'primary',
        action: () => navigate(HREF_CONSULENTE_ESTERNO_INSERISCI),
        showButtonIcon: true,
        icon: 'it-plus',
        iconColor: 'primary'
    }

    const searchButton = {
        buttonProperties: { outline: true },
        title: 'Ricerca',
        buttonColor: 'primary',
        showButtonIcon: true,
        icon: 'it-search',
        iconColor: 'primary',
        action: () => { formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true) }
    }

    const confermaEliminazione = (id: number) => {
        setConsulente(id)
        toggleModalElimininaRichiesta(!isOpenElimininaRichiesta)
    }

    const eliminaConsulente = () => {
        dispatch(deleteConsulente(consulente!)).unwrap().then( resp => {
            resp === 200 && doSearch();
        })
    }

    return (
        <div>
            <PageHeader showIcon={false} title='Elenco consulenti esterni'
                buttons={showInserisci()? [insertButton, searchButton] : [searchButton]}
            />
            <Accordion background="active">
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-5">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchFormElencoCosulentiExt.values.pageNum}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                        />
                        <Input
                            type="number"
                            label="Codice"
                            id="input-codice"
                            placeholder="Inserisci un codice"
                            wrapperClassName="col col-md-6"
                            name="id"
                            invalid={!!searchFormElencoCosulentiExt.errors.id}
                            infoText={t(searchFormElencoCosulentiExt.errors.id || "") || ""}
                            value={searchFormElencoCosulentiExt.values.id == 0 ? '' : searchFormElencoCosulentiExt.values.id}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Nome"
                            id="input-nome"
                            placeholder="Inserisci un nome"
                            wrapperClassName="col col-md-6"
                            name="nome"
                            invalid={!!searchFormElencoCosulentiExt.errors.nome}
                            infoText={t(searchFormElencoCosulentiExt.errors.nome || "") || ""}
                            value={searchFormElencoCosulentiExt.values.nome}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Cognome"
                            id="input-cognome"
                            placeholder="Inserisci un cognome"
                            wrapperClassName="col col-md-6"
                            name="cognome"
                            invalid={!!searchFormElencoCosulentiExt.errors.cognome}
                            infoText={t(searchFormElencoCosulentiExt.errors.cognome || "") || ""}
                            value={searchFormElencoCosulentiExt.values.cognome}
                            onChange={searchFormElencoCosulentiExt.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchFormElencoCosulentiExt.setFieldValue("pageNum", 0);
                                searchFormElencoCosulentiExt.handleSubmit();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchFormElencoCosulentiExt.handleReset}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>
            <div className="mt-5 position-relative">
                <div>
                    {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && <CustomAlert color='info' message='Nessun risultato disponibile' showButton={false} />}
                    {searchStatus === STATUS_FULLFILLED && hasResults && (<>
                    <div className={searchResult.totalPages == 1 ? 'mb-5 table-responsive' : 'table-responsive'}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th scope="col" onClick={() => ordinamento("id")} style={{ minWidth: '20%', maxWidth: '20%' }}>
                                        <div className="table-order">
                                            Codice CID
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" onClick={() => ordinamento("nomeCompleto")} style={{ minWidth: '20%', maxWidth: '20%' }}>
                                        <div className="table-order">
                                            Cognome e Nome
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope='col'>
                                        Data inizio
                                    </th>
                                    <th scope='col'>
                                        Data fine
                                    </th>
                                    <th scope="col">
                                        Stato
                                    </th>
                                    <th scope="col">
                                        Azioni
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(searchResult.data || []).map((x, i, dataset) =>
                                    <Fragment key={x.id}><tr>
                                        <td>{x.id}</td>
                                        <td>{x.cognome} {x.nome}</td>
                                        <td>{formatDate(x.dataInizio!)}</td>
                                        <td>{formatDate(x.dataFine!)}</td>
                                        <td title={x.stato} >
                                            {x.stato}
                                        </td>
                                        <td>
                                            <UncontrolledDropdown direction="left">
                                                <DropdownToggle nav>
                                                    <Icon icon="it-more-actions" />
                                                </DropdownToggle>
                                                <DropdownMenu className="no-arrow">
                                                    <LinkList>
                                                        {showModifica(x.stato!) && (
                                                            <LinkListItem size="medium" onClick={() => { navigate(`${HREF_CONSULENTE_ESTERNO_MODIFICA}/${x.id}`) }}>
                                                                <span>Modifica consulente esterno</span>
                                                            </LinkListItem>
                                                        )
                                                        }
                                                        {showCancella(x.stato!) && (
                                                            <LinkListItem size="medium" onClick={() => confermaEliminazione(x.id!)}>
                                                                <span>Elimina consulente esterno</span>
                                                            </LinkListItem>
                                                        )
                                                        }
                                                        {(
                                                            <LinkListItem size="medium" onClick={() => { navigate(`${HREF_CONSULENTE_ESTERNO_DETTAGLIO}/${x.id}`) }}>
                                                                <span>Visualizza consulente esterno</span>
                                                            </LinkListItem>
                                                        )
                                                        }
                                                    </LinkList>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                    {dataset.length === 1 && <tr><td style={{height:100, border: 0}} colSpan={7}></td></tr>}
                                    </Fragment>
                                )}
                            </tbody>
                        </Table>
                        <Modal
                            isOpen={isOpenListDetails}
                            toggle={() => toggleModalListDetails(!isOpenListDetails)}
                            labelledBy='listDetails'
                            centered
                        >
                            <ModalHeader toggle={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }} id='listDetails'>
                                Elenco
                            </ModalHeader>
                            <ModalBody>
                                {elencoDetails?.map((value, index) => (
                                    <ul key={index}>
                                        <li>{`${value != null ? `[${value.id}]` : ''} ${value.descrizione != null ? `${value.descrizione}` : ''}`}</li>
                                    </ul>
                                ))}
                            </ModalBody>
                            <ModalFooter>
                                <Button color='secondary' onClick={() => { toggleModalListDetails(!isOpenListDetails); setElencoDetails(undefined) }}>
                                    Annulla
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <Modal
                            isOpen={isOpenElimininaRichiesta}
                            toggle={() => toggleModalElimininaRichiesta(!isOpenElimininaRichiesta)}
                            labelledBy='eliminaConsulente'
                            centered
                        >
                            <ModalHeader toggle={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); setConsulente(undefined) }} id='eliminaConsulente'>
                                Elimina Consulente
                            </ModalHeader>
                            <ModalBody>
                                {consulente && t('conferma eliminazione consulente')}
                            </ModalBody>
                            <ModalFooter>
                                <Button color='secondary' onClick={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); setConsulente(undefined) }}>
                                    Annulla
                                </Button>
                                <Button color='primary' onClick={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); eliminaConsulente() }}>
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <Pagination
                        totalCount={searchResult.totalElements || 0}
                        siblingCount={1}
                        currentPage={searchFormElencoCosulentiExt.values.pageNum + 1}
                        pageSize={searchResult.pageSize || 10}
                        onPageChange={(page: number) => {
                            handlePageChange(page);
                        }}
                        className="justify-content-center"
                    />
                    </>)}
                </div>
            </div>
        </div>
    )
}

export default ElencoConsulentiEsterni;


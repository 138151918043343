import React, { useEffect, useState } from 'react'
import CandidatoDetailsComponent from '../../components/candidato-detail-form/CandidatoDetailsComponent'
import { CODICE_FISCALE, COGNOME, EMAIL, NOME, PEC } from './candidatiConstants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Alert, Button, Input, Table } from 'design-react-kit'
import { FormikProps, useFormik } from 'formik'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useTranslation } from 'react-i18next'
import DocumentUpload from '../../components/document-upload/DocumentUpload'
import { comunicazioneConvocazioneSottoscrizione, convocazioneComunicazione, detailsCandidato, generaDocumentoConvocazioneSottoscrizione, getConvocazioniSottoscrizione } from '../../store/candidatoSlice'
import { useNavigate, useParams } from 'react-router-dom'
import PageHeader from '../../components/page-header/PageHeader'
import { HREF_ELENCO_CANDIDATI } from '../../components/sidemenu/sidemenuConstants'
import { ComunicazioneConvocazioneRequestDto, ConvocazioneNuoveAssunzioniDto, DocumentoConvocazioneSottoscrizioneRequestDto, RuoloUtenteAutenticatoDto } from '../../services/ms-anagrafica-unica'
import NotificationManagerComponent from '../../components/notificationComponent/NotificationManagerComponent'
import PageLoader from '../../components/page-loader/PageLoader'
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants'
import {format,parseISO} from 'date-fns'

const initialValues: ComunicazioneConvocazioneRequestDto = {
    dataConvocazione: '',
    dataImmissione: '',
    idDocumento: undefined,
}

const validationSchema = z.object({
    dataConvocazione: z.coerce.date(),
    dataImmissione: z.coerce.date(),
    idDocumento: z.number({ required_error: 'required' }),
})

const ComunicazioneConvocazioneSottoscrizioneContratto = (props: {operations: RuoloUtenteAutenticatoDto}) => {

    const fieldUsed: Array<string> = [COGNOME, NOME, CODICE_FISCALE, EMAIL, PEC]
    const { detailsResult, detailsStatus, generaDocumentoResult, generaDocumentoStatus, updateStatus } = useAppSelector((state) => state.candidato);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [elencoConvocazioni, setElencoConvocazioni] = useState<Array<ConvocazioneNuoveAssunzioniDto>>([]);

    const form: FormikProps<ComunicazioneConvocazioneRequestDto> = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(validationSchema),
        onSubmit: (values) => {
            console.log("Submit form", JSON.stringify(values, null, 2));
            doInsert()
        },
    })


    useEffect(() => {
        dispatch(detailsCandidato(Number.parseInt(id!)))
        dispatch(getConvocazioniSottoscrizione(Number.parseInt(id!))).unwrap().then( convocazioni => {
            const rettifiche = convocazioni.filter( c => !!c.dataRettifica && !!c.note)
            setElencoConvocazioni(rettifiche);
        })
    }, [])

    const doInsert = () => {
        const body: convocazioneComunicazione = {
            id: Number.parseInt(id!),
            bodyRequest: form.values
        }
        dispatch(comunicazioneConvocazioneSottoscrizione(body))
    }

    const getFieldError = (
        form: FormikProps<ComunicazioneConvocazioneRequestDto>,
        fieldName: keyof ComunicazioneConvocazioneRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const handleIdChange = (id: number | undefined) => {
        if (id && id != -1) {
            form.setFieldValue('idDocumento', id);
        } else {
            form.setFieldValue('idDocumento', undefined);
        }
    }

    const anteprimaDocumentoConvocazioneSottoscrizione = () => {
        const body: DocumentoConvocazioneSottoscrizioneRequestDto = {
            dataConvocazione: form.values.dataConvocazione,
            dataImmissioneRuolo: form.values.dataImmissione
        }
        dispatch(generaDocumentoConvocazioneSottoscrizione(body))
    }

    const disabilitaBottoneGeneraDocumento = () => {
        if (!form.values.dataConvocazione?.trim() ||
            !form.values.dataImmissione?.trim() || 
            !form.values.idDocumento ||
            !props.operations.elencoFunzionalita?.includes('ASSUNZIONI_ANTEPRIMA_DOCUMENTO')
        ) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (generaDocumentoResult != null) {
            const blob = new Blob([generaDocumentoResult], { type: 'application/pdf' })
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            console.log(url);
            link.href = url;
            link.download = 'Convocazione sottoscrizione.pdf';
            link.click();
        }
    }, [generaDocumentoResult])

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_CANDIDATI} title={"Comunicazione convocazione sottoscrizione contratto"}></PageHeader>
            {detailsStatus === STATUS_FULLFILLED && <div>
                <div className="row">
                    <CandidatoDetailsComponent fileds={fieldUsed} value={detailsResult!} />
                    <div className="col-md-12">
                        <div className='position-relative py-5'>
                            <Input
                                label="Data di convocazione"
                                type="date"
                                placeholder="Inserisci la data"
                                min={new Date().toISOString().split("T")[0]}
                                id="input-dataConvocazione"
                                wrapperClassName="form-group required col-md-12"
                                name="dataConvocazione"
                                value={form.values.dataConvocazione}
                                onChange={form.handleChange}
                                invalid={!!getFieldError(form, "dataConvocazione")}
                                infoText={t(getFieldError(form, "dataConvocazione")) || ""}
                            />
                            <Input
                                label="Data di immissione"
                                type="date"
                                placeholder="Inserisci la data"
                                id="input-dataImmissione"
                                wrapperClassName="form-group required col-md-12"
                                min={new Date().toISOString().split("T")[0]}
                                name="dataImmissione"
                                value={form.values.dataImmissione}
                                onChange={form.handleChange}
                                invalid={!!getFieldError(form, "dataImmissione")}
                                infoText={t(getFieldError(form, "dataImmissione")) || ""}
                            />
                            <DocumentUpload documentName='Dichiarazione di disponibilità all’impiego ' invalid={!!form.errors.idDocumento} infoText={t(getFieldError(form, "idDocumento")) || ""} idDocumento={form.values.idDocumento} className='required' setDocumentId={handleIdChange} />

                            {elencoConvocazioni.length > 0 &&
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="table-order">
                                                {t("Data rettifica")}
                                            </div>
                                        </th>
                                        <th scope="col-2">
                                            <div className="table-order">
                                                {t("Note")}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {elencoConvocazioni.map( (c, i) => 
                                    <tr key={i}>
                                        <td>{format(parseISO(c.dataRettifica!), 'dd/MM/yyyy')}</td>
                                        <td>{c.note}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                            }

                            <div className="d-flex flex-row-reverse">
                                <Button color='primary' className="mt-2" disabled={!generaDocumentoResult} onClick={() => { form.handleSubmit() }}>Conferma</Button>
                                <Button outline color='primary' className="mt-2 mr-2" disabled={disabilitaBottoneGeneraDocumento()} onClick={() => { anteprimaDocumentoConvocazioneSottoscrizione() }}>Genera documento</Button>
                                <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(HREF_ELENCO_CANDIDATI) }} >Indietro</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ComunicazioneConvocazioneSottoscrizioneContratto
export const CODICE = 'Codice'
export const DENOMINAZIONE = 'Denominazione'
export const DECLARATORIA = 'Declaratoria'
export const DATA_INIZIO_VALIDITA = 'Data Inizio Validità'
export const TIPOLOGIA_STRUTTURA  = 'Tipologia struttura'
export const DIPARTIMENTO = 'Dipartimento'
export const SEZIONE = 'Sezione'
export const EMAIL = 'Email'
export const PEC = 'Pec'
export const TELEFONO = 'Telefono'
export const FAX = 'Fax'
export const INDIRIZZO = 'Indirizzo'
export const CITTA = 'Città'
export const CODICE_CIFRA_DETERMINA = 'Codice CIFRA Determina'
export const CODICE_CIFRA_DELIBERA = 'Codice CIFRA Delibera'
export const CODICE_IPA = 'Codice IPA'
export const CODICE_DIPA_RAGIONERIA = 'Codice Bilancio Dipartimento'
export const CODICE_SEZI_RAGIONERIA = 'Codice Bilancio Sezione'

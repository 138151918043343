import React from 'react'
import { PosizioneOrganizzativaDto, PosizioneOrganizzativaInsertRequestDto } from '../../services/ms-anagrafica-unica'
import {
    ATTO_DI_ASSGENAZIONE,
    CODICE_CID_DIPENDENTE,
    CODICE_UNIVOCO_PO,
    DATA_FINE_VALIDITA_INCARICO,
    DATA_FINE_VALIDITA_PO,
    DATA_INIZIO_VALIDITA_INCARICO,
    DATA_INIZIO_VALIDITA_PO,
    DECLARATORIA_PO,
    DENOMINAZIONE_PO,
    NOME_E_COGNOME_DIPENDENTE,
    STRUTTURA_ORGANIZZATIVA_PO,
    TIPO_INCARICO,
    TIPO_PO
} from '../../pages/posizioni-organizzative/posizioniOrganizzativeConstants'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import { useTranslation } from "react-i18next";

export interface PODetailsComponent {
    value: PosizioneOrganizzativaDto,
    fields: Array<string>,
}

const PosizioniOrganizzativeDetailsComponent = (posizioneOrganizzativa: PODetailsComponent) => {
    
    const {t} = useTranslation();
    const formatDate = (date:string) => {
        if (!date)
            return ''
        const giorno = format(new Date(date), 'd');
        const mese = format(new Date(date), 'MMMM', {locale: it});
        const anno = format(new Date(date), 'yyyy');
        const meseFormattato = mese.charAt(0).toUpperCase() + mese.slice(1).toLowerCase()
        return `${giorno} ${meseFormattato} ${anno}`
    }
    return (
        <div className="card-div row">
            <div className="col-sm-12">
                <div className="info-box-light-so">
                    <h6 className='mb-3'>
                        Riepilogo
                    </h6>
                    <hr />
                    <div className="row">
                        <dl className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(CODICE_UNIVOCO_PO) != -1 && !!posizioneOrganizzativa?.value?.codiceUnivoco) &&
                                        <>
                                            <dt className='col-sm-12'>{CODICE_UNIVOCO_PO}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.codiceUnivoco}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(DENOMINAZIONE_PO) != -1 && !!posizioneOrganizzativa?.value?.denominazione) &&
                                        <>
                                            <dt className='col-sm-12'>{DENOMINAZIONE_PO}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.denominazione}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(DECLARATORIA_PO) != -1 && !!posizioneOrganizzativa?.value?.declaratoria) &&
                                        <>
                                            <dt className='col-sm-12'>{DECLARATORIA_PO}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.declaratoria}</dd>
                                        </>
                                    }
                                </div>
                            </div>
                        </dl>
                      
                        <dl className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(STRUTTURA_ORGANIZZATIVA_PO) != -1 && !!posizioneOrganizzativa?.value?.strutturaOrganizzativa) &&
                                        <>
                                            <dt className='col-sm-12'>{STRUTTURA_ORGANIZZATIVA_PO}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.strutturaOrganizzativa}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(DATA_INIZIO_VALIDITA_PO) != -1 && !!posizioneOrganizzativa?.value?.dataInizio) &&
                                        <>
                                            <dt className='col-sm-12'>{DATA_INIZIO_VALIDITA_PO}</dt>
                                            <dd className='col-sm-12'>{formatDate(posizioneOrganizzativa?.value?.dataInizio)}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(DATA_FINE_VALIDITA_PO) != -1 && !!posizioneOrganizzativa?.value?.dataFine) &&
                                        <>
                                            <dt className='col-sm-12'>{DATA_FINE_VALIDITA_PO}</dt>
                                            <dd className='col-sm-12'>{formatDate(posizioneOrganizzativa?.value?.dataFine)}</dd>
                                        </>
                                    }
                                </div>
                            </div>
                        </dl>
                        
                        <dl className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-12">
                                    {(posizioneOrganizzativa.fields.indexOf(TIPO_PO) != -1 && !!posizioneOrganizzativa?.value?.tipo) &&
                                        <>
                                            <dt className='col-sm-12'>{TIPO_PO}</dt>
                                            <dd className='col-sm-12'>{t( "tipoEQ_" + posizioneOrganizzativa?.value?.tipo)}</dd>
                                        </>
                                    }
                                </div>
                            </div>
                        </dl>
                        
                        <dl className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(CODICE_CID_DIPENDENTE) != -1 && !!posizioneOrganizzativa?.value?.idCidDipendente) &&
                                        <>
                                            <dt className='col-sm-12'>{CODICE_CID_DIPENDENTE}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.idCidDipendente}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(NOME_E_COGNOME_DIPENDENTE) != -1 && !!posizioneOrganizzativa?.value?.dipendenteRegionale) &&
                                        <>
                                            <dt className='col-sm-12'>{NOME_E_COGNOME_DIPENDENTE}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.dipendenteRegionale}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(TIPO_INCARICO) != -1 && !!posizioneOrganizzativa?.value?.tipoIncarico && !!posizioneOrganizzativa?.value?.tipoIncaricoLabel) &&
                                        <>
                                            <dt className='col-sm-12'>{TIPO_INCARICO}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.tipoIncaricoLabel}</dd>
                                        </>
                                    }
                                </div>
                            </div>
                        </dl>
                        <dl className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(ATTO_DI_ASSGENAZIONE) != -1 && !!posizioneOrganizzativa?.value?.estremiAttoConferimento) &&
                                        <>
                                            <dt className='col-sm-12'>{ATTO_DI_ASSGENAZIONE}</dt>
                                            <dd className='col-sm-12'>{posizioneOrganizzativa?.value?.estremiAttoConferimento}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(DATA_INIZIO_VALIDITA_INCARICO) != -1 && !!posizioneOrganizzativa?.value?.dipendenteRegionale) &&
                                        <>
                                            <dt className='col-sm-12'>{DATA_INIZIO_VALIDITA_INCARICO}</dt>
                                            <dd className='col-sm-12'>{formatDate(posizioneOrganizzativa?.value?.dataInizioAssociazione!)}</dd>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    {(posizioneOrganizzativa.fields.indexOf(DATA_FINE_VALIDITA_INCARICO) != -1) &&
                                        <>
                                            <dt className='col-sm-12'>{DATA_FINE_VALIDITA_INCARICO}</dt>
                                            <dd className='col-sm-12'>{formatDate(posizioneOrganizzativa?.value?.dataFineAssociazione!) || ' - '}</dd>
                                        </>
                                    }
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default PosizioniOrganizzativeDetailsComponent
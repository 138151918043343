import './Footer.scss'
import logoregionepuglia from '../../assets/images/logo-regione-puglia-white.svg'
import pugliafesr from '../../assets/images/puglia-fesr.png'
import logoArpal from '../../assets/images/Arpal_puglia_white.png';

function Footer() {
  return (
    <footer className="dms-footer">
      {/*         
        <div className="pre-footer d-flex justify-content-center align-items-center">
            <a href="#" target="_blank" title="Valuta questo sito. Aiutaci a migliorare">
            Valuta questo sito. Aiutaci a migliorare
            </a>
        </div>
        */}
      <div className="container-fluid p-0">
        <div className="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <a href="https://www.regione.puglia.it/" title="ARPAL Puglia" target="_blank" className="footer-logo">
                  <img src={logoArpal} alt="logo ARPAL Puglia" className="img-fluid" />
                </a>
                {/* <div className="col-12 col-lg-8">
                        <div className="dms-footer--social">
                          <a href="https://www.facebook.com/weareinpuglia" title="Facebook" target="_blank">
                          <img  className="dms-wk-icon" src={fb} ></img>    
                          </a>
                          <a href="https://twitter.com/weareinpuglia" title="Twitter" target="_blank">
                          <img className="dms-wk-icon" src={tw} ></img>    
                          </a>
                          <a href="https://www.youtube.com/user/Viaggiareinpuglia" title="Youtube" target="_blank">
                          <img className="dms-wk-icon" src={yt} ></img>    
                          </a>
                        </div>
                    </div> */}
              </div>
              <div className="col-12 col-lg-4">
                <div className="dms-footer--info">
                  <div className="col-md-4">
                    <span className="size-7">Contatti e indirizzi</span>
                    <ul className="menu-list pt-2">
                      <li>
                        {/* <p>
                                      Corso Sonnino, 177 - 70121 - Bari<br />
                                      Telefono: +39 080 5403469<br />
                                      Scrivici: 
                                      <a
                                          href="mailto:infrastruttureinfotelematiche@pec.rupar.puglia.it"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          >
                                      PEC
                                      </a>
                                    </p> */}
                        <p>
                          Lorem ipsum<br />
                          Lorem ipsum<br />
                          Lorem ipsum
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dms-footer--info">
                <div className="col-md-4">
                  <span className="size-7" title="Ufficio Relazioni con il Pubblico">URP</span>
                  <ul className="menu-list pt-2">
                    <li>
                      {/* <p>
                        Telefono: 800 713939<br />
                        Scrivici: <a href="mailto:quiregione@regione.puglia.it" target="_blank" rel="noopener noreferrer">quiregione@regione.puglia.it</a>
                      </p> */}
                      <p>
                        Lorem ipsum<br />
                        Lorem ipsum
                      </p>
                    </li>
                  </ul>
                </div>
              </div>


            </div>
            <div className='row'>
              <div className="col-12 col-lg-4 puglia-fesr">
                <div className="puglia-fesr-img">
                  <img src={pugliafesr} alt="logo ARPAL Puglia fesr" className="img-fesr" />
                </div>
              </div>
              <div className="col-md-auto d-flex justify-content-md-end justify-content-center iniziativa">
                {/* Iniziativa finanziata con risorse del POR Puglia 2014/2020 - Asse II, Azione 2.3 */}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              </div>
            </div>
            <div className="d-flex flex-column flex-lg-row dms-footer--bottom">
              {/*                     <div className="dms-footer--bottom-link">
                        <a style={{textDecoration: 'none'}} href="https://www.regione.puglia.it/note-legali" title="Note legali">Note legali</a>
                    </div>
                    <div className="dms-footer--bottom-link">
                        <a style={{textDecoration: 'none'}} href="https://www.regione.puglia.it/atti-di-notifica" title="Atti di notifica">Atti di notifica</a>
                    </div>
                    <div className="dms-footer--bottom-link">
                        <a style={{textDecoration: 'none'}} href="https://www.regione.puglia.it/cookie-e-privacy" title="Cookie e privacy">Cookie e Privacy</a>
                    </div>
                    <div className="dms-footer--bottom-link">
                        <a style={{textDecoration: 'none'}} href="https://trasparenza.regione.puglia.it" title="Amministrazione Trasparente">Amministrazione Trasparente</a>
                    </div> */}
              <div className="mt-4 mt-lg-0 ml-lg-auto">
                <a href="https://www.regione.puglia.it/" title="ARPAL Puglia" style={{ textDecoration: 'none' }}> © ARPAL Puglia</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
import {Input} from "design-react-kit";
import React from "react";
import {AmministrazioneControlloDto} from "../../../services/ms-anagrafica-unica";

const RIAmministrazioneControlloComponent = (dati: { value: AmministrazioneControlloDto }) => {
    return (<div className={"row pt-5"}>
        <Input type='text'
               label="Sistema Amministrazione"
               disabled={true}
               value={dati.value["sistema-amministrazione"]?.value ?? "-"}
               wrapperClassName="col-md-4"/>
        <Input type='text'
               label="Forma Amministrativa"
               disabled={true}
               value={dati.value["forme-amministrative"]?.["forma-amministrativa"]?.value ?? "-"}
               wrapperClassName="col-md-4"/>
        <Input type='text'
               label="Collegi Sindacali Effettivi"
               disabled={true}
               value={dati.value["collegio-sindacale"]?.["n-effettivi"] ?? "0"}
               wrapperClassName="col-md-2"/>
        <Input type='text'
               label="Collegi Sindacali Supplenti"
               disabled={true}
               value={dati.value["collegio-sindacale"]?.["n-supplenti"] ?? "0"}
               wrapperClassName="col-md-2"/>
    </div>);
}

export default RIAmministrazioneControlloComponent;
import {InfoAttivitaDto} from "../../../services/ms-anagrafica-unica";
import {Input} from "design-react-kit";
import React from "react";
import RIAtecoComponent from "../ri-atecori-component/RIAtecoComponent";

const RIInformazioniAttivitaComponent = (dati: { value: InfoAttivitaDto }) => {
    return (
        <div className={"row pt-5"}>
            <Input type='text'
                   label="Data Inizio Attività Impresa"
                   disabled={true}
                   value={dati.value["dt-inizio-attivita-impresa"] ?? "-"}
                   wrapperClassName="col-md-3"/>
            <Input type='textarea'
                   label="Attività esercitata"
                   disabled={true}
                   value={dati.value["attivita-esercitata"] ?? "-"}
                   wrapperClassName="col-12"/>
            <Input type='textarea'
                   label="Attività prevalente"
                   disabled={true}
                   value={dati.value["attivita-prevalente"] ?? "-"}
                   wrapperClassName="col-12"/>
            {dati.value["classificazioni-ateco"] && <RIAtecoComponent value={dati.value["classificazioni-ateco"]}/>}
        </div>);
}

export default RIInformazioniAttivitaComponent;
import { useEffect } from 'react';
import { deleteJwtToken } from '../../utility/cookie';

function Logout() {

    const logout = () => {
        deleteJwtToken()
        window.location.href=process.env.REACT_APP_MS_AU_LOGOUT_URL || '';
        return
    }

    useEffect(() => {
        logout();
    });

    return (
        <></>
    )
}

export default Logout;
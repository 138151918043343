import { useEffect, useState } from "react";
import PageHeader from "../../components/page-header/PageHeader";
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from "../../components/sidemenu/sidemenuConstants";
import { getPropostaIncarico, reset, updateAssegnazioneIncarico } from "../../store/proposteIncarichiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { Button, Col, Icon, Input, Table, TextArea } from "design-react-kit";
import { DatiAttoDto, UpdateAssegnazioneIncarichiDirigenzialiRequestDto } from "../../services/ms-anagrafica-unica";
import { FormikProps, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import DocumentUpload from "../../components/document-upload/DocumentUpload";
import { downloadDocument } from "../../store/DocumentiSlice";
import DettaglioAtto from "../../components/dettaglio-atto-cifra/DettaglioAtto";

const ModificaAssegnazioneIncarico = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { propostaSelezionataResult, propostaSelezionataStatus } = useAppSelector((state) => state.proposteIncarichi);
    const [ datiAtto, setDatiAtto ] = useState<DatiAttoDto>({});

    useEffect( () => {
        dispatch(reset())
        dispatch(getPropostaIncarico({id: Number(id)})).unwrap().then( proposta => {
            if (proposta.assegnazioneIncarico && proposta.assegnazioneIncarico.elencoModifiche) {
                insertForm.setValues({
                    dataInizioValidita: proposta.assegnazioneIncarico?.dataInizio,
                    dataFineValidita:  proposta.assegnazioneIncarico?.dataFine,
                })
            }
            setDatiAtto(proposta.assegnazioneIncarico?.datiAtto || {});
        })
    }, [])

    const initialValuesUtenteRecuperato: UpdateAssegnazioneIncarichiDirigenzialiRequestDto = {
        dataInizioValidita: '',
        dataFineValidita: '',
        note: '',
        idFile: undefined
    }

    const schemaConferma = z.object({
        dataInizioValidita: z.coerce.date(),
        dataFineValidita: z.coerce.date(),
        note: z.string().max(2000, { message: "Campo troppo lungo" }).optional(),
        idFile: z.number().gt(0).optional()
    }).refine( (data) => data.dataInizioValidita < data.dataFineValidita, {
        message: 'La data di inizio validità deve essere precedente alla data di fine',
        path: ['dataInizioValidita']
    }).refine( (data) => data.dataInizioValidita < data.dataFineValidita, {
        message: 'La data di inizio validità deve essere precedente alla data di fine',
        path: ['dataFineValidita']
    })

    const insertForm: FormikProps<UpdateAssegnazioneIncarichiDirigenzialiRequestDto> = useFormik({
        initialValues: initialValuesUtenteRecuperato,
        validationSchema: toFormikValidationSchema(schemaConferma),
        onSubmit: (values) => {
            console.log("Submit form", JSON.stringify(values, null, 2));
            dispatch(updateAssegnazioneIncarico({
                idProposta: Number(id),
                updateAssegnazioneIncarichiDirigenzialiRequestDto: values
            }))
        },
    })

    const getFieldError = (
        form: FormikProps<UpdateAssegnazioneIncarichiDirigenzialiRequestDto>,
        fieldName: keyof UpdateAssegnazioneIncarichiDirigenzialiRequestDto
    ): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    const setDateInput = (field:string, e: any) => {
        insertForm.setFieldValue(field, e.target.value)
    }

    const setDocumentId = (name: string, id: number | undefined) => {
        if (id) {
            insertForm.setFieldValue(name, id);
        } else {
            console.error('errore durante la chiamata di caricamento documento');
        }
    }

    const vediFile = (file:any) => {
        dispatch(downloadDocument({
            idDocumento: file.id
        })).unwrap().then(resp => {
            if (!!resp) {
                const blob = new Blob([resp], { type: file.contentType })
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = file.filename || '';
                link.click();
            }
        });
    }

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI} title={'modificaAssegnazioneIncarico.titolo'} />
            {propostaSelezionataStatus === STATUS_FULLFILLED && !!propostaSelezionataResult && (<>
            <div className="col-sm-12 modifica-assegnazione">
                <div className="card-div">
                    <div className="col-sm-12">
                        <div className="info-box-light-so">
                            <h6 className='mb-3 modifica-assegnazione-title'>
                                Riepilogo
                            </h6>
                            <hr />
                            <div className="row  modifica-assegnazione-table-info">
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12 modifica-assegnazione-info">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.denominazioneSo')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.denominazioneSo}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12 modifica-assegnazione-info">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.codiceStruttura')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.codiceIncaricoSo}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12 modifica-assegnazione-info">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.tipoStruttura')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.tipoStrutturaOrganizzativa}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12 modifica-assegnazione-info">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.tipoIncarico')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.tipoIncarico}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12 modifica-assegnazione-info">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.candidato')}</dt>
                                            {propostaSelezionataResult.candidatoInterno ? (
                                                <dd className='col-sm-12'>{propostaSelezionataResult.dipendenteRegionale?.nomeCompleto}</dd>
                                            ) : (
                                                <dd className='col-sm-12'>{`${propostaSelezionataResult.nomeCognomeEsterno} ${propostaSelezionataResult.codiceFiscaleEsterno ? '(${propostaSelezionataResult.codiceFiscaleEsterno})' : ''}`}</dd>
                                            )}
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12 modifica-assegnazione-info">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.candidatoInterno')}</dt>
                                            {propostaSelezionataResult.candidatoInterno ? (
                                                <dd className='col-sm-12'>{t('si')}</dd>
                                            ) : (
                                                <dd className='col-sm-12'>{t('no')}</dd>
                                            )}
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12 modifica-assegnazione-info">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.inComando')}</dt>
                                            {propostaSelezionataResult.inComando ? (
                                                <dd className='col-sm-12'>{t('si')}</dd>
                                            ) : (
                                                <dd className='col-sm-12'>{t('no')}</dd>
                                            )}
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Input
                label="Data inizio incarico"
                type="date"
                placeholder="Inserisci la data"
                id="input-dataInizioIncarico"
                wrapperClassName="mt-5 form-group required"
                name="dataInizioIncarico"
                min={new Date().toISOString().split("T")[0]}
                value={insertForm.values.dataInizioValidita}
                onChange={(e:any) => setDateInput('dataInizioValidita', e)}
                invalid={!!getFieldError(insertForm, "dataInizioValidita")}
                infoText={t(getFieldError(insertForm, "dataInizioValidita")) || ""}
                />
            <Input
                label="Data fine incarico"
                type="date"
                placeholder="Inserisci la data"
                id="input-dataFineIncarico"
                wrapperClassName="mt-5 form-group required"
                name="dataFineIncarico"
                min={new Date().toISOString().split("T")[0]}
                value={insertForm.values.dataFineValidita}
                onChange={(e:any) => setDateInput('dataFineValidita', e)}
                invalid={!!getFieldError(insertForm, "dataFineValidita")}
                infoText={t(getFieldError(insertForm, "dataFineValidita")) || ""}
            />
            <Input
                label="Atto"
                type="text"
                placeholder="Estremi di conferimento"
                id="input-estremiDiConferimento"
                wrapperClassName="form-group col-md-12 mb-3"
                name="estremiDiConferimento"
                value={propostaSelezionataResult.assegnazioneIncarico?.estremiAttoConferimento}
                disabled={true}
            />

              {(datiAtto && datiAtto.oggetto) && (
                <Col md="12 mt-2 mb-1">
                    <DettaglioAtto datiAtto={datiAtto}></DettaglioAtto>
                </Col>
                )}

            <TextArea
                label="Note"
                placeholder="Note"
                id="input-note"
                wrapperClassName="mt-5 form-group col-md-12"
                name="note"
                value={insertForm.values.note}
                onChange={(e:any) => insertForm.setFieldValue('note', e.target.value)}
                invalid={!!getFieldError(insertForm, "note")}
                infoText={t(getFieldError(insertForm, "note")) || ""}
            />
            <DocumentUpload
                documentName="Allega documento"
                className="my-3"
                invalid={!!getFieldError(insertForm, "idFile")}
                infoText={t(getFieldError(insertForm, "idFile")) || ""}
                idDocumento={insertForm.values.idFile}
                setDocumentId={(id) => setDocumentId('idFile', id)}
            />
            <div className="d-flex flex-row-reverse">
                <Button color='primary' className="mt-2 mr-2" onClick={insertForm.submitForm}>Conferma</Button>
                <Button color='secondary' className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`) }} >{t('annulla')} </Button>
            </div>

            {propostaSelezionataResult.assegnazioneIncarico && propostaSelezionataResult.assegnazioneIncarico.elencoModifiche && propostaSelezionataResult.assegnazioneIncarico.elencoModifiche.length > 0 && (
                <Table className="elenco-incarichi">
                    <tr>
                        <th>N modifica</th>
                        <th>Note</th>
                        <th>File</th>
                    </tr>
                    {propostaSelezionataResult.assegnazioneIncarico.elencoModifiche.map ((proposta) => (

                    <tr key={proposta.codiceUnivoco}>
                        <td>{proposta.nmodifica}</td>
                        <td>{proposta.note}</td>
                        <td> {proposta.documento &&
                            <Button
                                color="primary"
                                outline
                                size="xs"
                                className="mx-2"
                                onClick={() => vediFile(proposta.documento)}
                                >
                                <Icon
                                    icon="it-password-visible"
                                    size="xs"
                                    color="primary"
                                ></Icon>{" "}
                                {proposta.documento?.filename}
                            </Button>}
                        </td>
                    </tr>
                    ))}
                </Table>
            )}
            </>)}
        </div>
    )
}

export default ModificaAssegnazioneIncarico
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants";
import { AuthControllerApi } from "../services/ms-anagrafica-unica/api/auth-controller-api";
import { RuoloUtenteAutenticatoDto, UtenteAutenticatoDto } from "../services/ms-anagrafica-unica";

export interface userState {
    userInfoResult: UtenteAutenticatoDto | null,
    userInfoStatus: string,
    userRoleActive: RuoloUtenteAutenticatoDto | null,
    switchRuoloAttivoStatus: string,
    registraCittadinoStatus: string
}

const initialState: userState = {
    userInfoResult: null,
    userInfoStatus: '',
    userRoleActive: null,
    switchRuoloAttivoStatus:'',
    registraCittadinoStatus: ''
}

export const getUserInfo = createAsyncThunk(
    'user/leggiUtenteLoggato',
    async () => {
        const api = new AuthControllerApi(MS_AU_CONFIG);
        const response = await api.leggiUtenteLoggato();        
        return response.data;
    }
);

export const switchRuoloAttivo = createAsyncThunk(
    'user/switchRuoloAttivo',
    async (idRuolo: number) => {
        const api = new AuthControllerApi(MS_AU_CONFIG);
        const response = await api.switchRuoloAttivo({idRuoloDaAttivare: idRuolo});        
        return response;
    }
);

export const registraCittadino = createAsyncThunk(
    'user/registraCittadino',
    async () => {
        const api = new AuthControllerApi(MS_AU_CONFIG);
        const response = await api.registraCittadino({});        
        return response;
    }
);

export const userSlice = createSlice({
    name: 'userLogged',
    initialState,
    reducers: {
        resetUser: (state) => {
            state.userRoleActive= null
        }
    },
    extraReducers: (builder) => {
        // Info aggiuntive dell'utente
        builder.addCase(getUserInfo.pending, (state: userState, action: any) => {
            state.userInfoStatus = STATUS_PENDING;
        });
        builder.addCase(getUserInfo.fulfilled, (state: userState, action: any) => {
            state.userInfoResult = action.payload;
            state.userInfoStatus = STATUS_FULLFILLED;
            state.userRoleActive = action.payload?.ruoloAttivo
        });
        builder.addCase(getUserInfo.rejected, (state: userState, action: any) => {
            state.userInfoStatus = STATUS_REJECTED;
        });
        builder.addCase(switchRuoloAttivo.fulfilled, (state: userState, action: any) => {
            state.switchRuoloAttivoStatus = STATUS_FULLFILLED;
        });
        builder.addCase(switchRuoloAttivo.pending, (state: userState, action: any) => {
            state.switchRuoloAttivoStatus = STATUS_PENDING;
        });
        builder.addCase(switchRuoloAttivo.rejected, (state: userState, action: any) => {
            state.switchRuoloAttivoStatus = STATUS_REJECTED;
        });
        builder.addCase(registraCittadino.rejected, (state: userState, action: any) => {
            state.userInfoStatus = STATUS_REJECTED;
        });
        builder.addCase(registraCittadino.pending, (state: userState, action: any) => {
            state.userInfoStatus = STATUS_PENDING;
        });
        builder.addCase(registraCittadino.fulfilled, (state: userState, action: any) => {
            state.userInfoStatus = STATUS_FULLFILLED;
        });
    }
})

export const { resetUser } = userSlice.actions;

export default userSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AnagraficaRuoloCancellaRequestDto, AnagraficaRuoloDto, AnagraficaRuoloInsertRequestDto, AnagraficaRuoloModificaRequestDto, AssegnaRuoloConDipendenteRequestDto, AssegnaRuoloConFunzionalitaRequestDto, AssegnazioneRuoloDipendentiControllerApi, AssegnazioneRuoloFunzionalitaControllerApi, ElencoRuoliContestoForm, FormsControllerApi, PagedDtoAnagraficaRuoloDto, PagedDtoAssegnazioneRuoloConDipendenteDto, PagedDtoAssegnazioneRuoloConFunzionalitaDto, RuoloControllerApi } from "../services/ms-anagrafica-unica";
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants";

export interface RuoloState {
    searchResult: PagedDtoAnagraficaRuoloDto,
    searchStatus: string,
    searchInput: SearchRuoloRequest | null,
    formSearchRuolo: ElencoRuoliContestoForm | null,
    insertStatus: string,
    detailsResult: AnagraficaRuoloDto | null,
    detailsStatus: string,
    updateStatus: string,
    codicePresenteResult: boolean | null,
    deteleStatus: string,
    funzionalitaRuoloListResult: PagedDtoAssegnazioneRuoloConFunzionalitaDto | null,
    funzionalitaRuoloListStatus: string,
    insertFunzionalitaRuoloStauts: string,
    selectFunzionalitaResult: ElencoRuoliContestoForm | null,
    selectFunzionalitaStatus: string,
    dipendentiRuoloListResult: PagedDtoAssegnazioneRuoloConDipendenteDto | null,
    dipendentiRuoloListStatus: string,
    insertDipendentiRuoloStauts: string,
}

const initialState: RuoloState = {
    searchResult: {
        pageNum: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        data: []
    },
    searchStatus: '',
    searchInput: null,
    formSearchRuolo: null,
    insertStatus: '',
    detailsResult: null,
    detailsStatus: '',
    updateStatus: '',
    codicePresenteResult: null,
    deteleStatus: '',
    funzionalitaRuoloListResult: null,
    funzionalitaRuoloListStatus: '',
    insertFunzionalitaRuoloStauts: '',
    selectFunzionalitaResult: null,
    selectFunzionalitaStatus: '',
    dipendentiRuoloListResult: null,
    dipendentiRuoloListStatus: '',
    insertDipendentiRuoloStauts: ''
}

export interface SearchRuoloRequest {
    pageSize?: number,
    pageNum?: number,
    ruolo?: string,
    stato?: any,
    sort: any,
    direction: any,
    funzionalita?: any,
    dipendente?: string,
}

export interface patchValueUpdate {
    idRuolo: number | undefined,
    bodyRequest: AnagraficaRuoloModificaRequestDto,
}

export interface pathDeleteRuolo {
    idRuolo: number | undefined,
    bodyRequest: AnagraficaRuoloCancellaRequestDto
}

export interface deleteFunzionalita {
    idRuolo: number | undefined,
    funzionalita: any,
}

export interface deleteDipendenti {
    idRuolo: number | undefined,
    idDipendente:any,
}

export const searchRuoli = createAsyncThunk(
    'ruoli/search',
    async (args: SearchRuoloRequest) => {
        const api = new RuoloControllerApi(MS_AU_CONFIG);
        const response = await api.searchAnagraficaRuolo({
            pageNum: args.pageNum,
            pageSize: args.pageSize,
            nome: args.ruolo,
            stato: args.stato,
            dipendente: args.dipendente,
            funzionalita: args.funzionalita,
            direction: args.direction,
            sort: args.sort
        })
        return response.data
    }
)

export const formSearchRuoloStatoSelect = createAsyncThunk(
    'ruoli/loadFormSearch',
    async () => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoRuoliContestoForm();
        return response.data
    }
)

export const insertRuolo = createAsyncThunk(
    'ruoli/insert',
    async (body: AnagraficaRuoloInsertRequestDto) => {
        const api = new RuoloControllerApi(MS_AU_CONFIG);
        const response = await api.insertAnagraficaRuolo({
            anagraficaRuoloInsertRequestDto: body,
        })
        return response.data;
    }
)

export const detailsRuolo = createAsyncThunk(
    'ruoli/details',
    async (idRuolo: number) => {
        const api = new RuoloControllerApi(MS_AU_CONFIG);
        const response = await api.detailAnagraficaRuolo({
            idRuolo: idRuolo
        })
        return response.data
    }
)

export const updateRuolo = createAsyncThunk(
    'ruoli/update',
    async (body: patchValueUpdate) => {
        const api = new RuoloControllerApi(MS_AU_CONFIG);
        const response = await api.patchAnagraficaRuolo({
            idRuolo: body.idRuolo!,
            patchAnagraficaRuoloRequest: body.bodyRequest,
        })
        return response.data
    }
)

export const codiceRuoloEsistente = createAsyncThunk(
    'ruoli/codiceRuoloEsistente',
    async (codice: string) => {
        const api = new RuoloControllerApi(MS_AU_CONFIG);
        const response = await api.giaPresente({
            codice: codice,
        })
        return response.data;
    }
)

export const deleteRuolo = createAsyncThunk(
    'ruoli/deleteRuolo',
    async (body: pathDeleteRuolo) => {
        const api = new RuoloControllerApi(MS_AU_CONFIG);
        const response = await api.patchAnagraficaRuolo({
            idRuolo: body.idRuolo!,
            patchAnagraficaRuoloRequest: body.bodyRequest
        })
        return response.data
    }
)

export const selectFunzionalitaInsert = createAsyncThunk(
    'ruoli/selectFunzionalitaInsert',
    async (idRuolo: number) => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getAssegnaRuoloFunzionalitaContestoForm({
            idRuolo
        })
        return response.data;
    }
)

export const listFunzionalita = createAsyncThunk(
    'ruoli/listFunzionalita',
    async (idRuolo: number) => {
        const api = new AssegnazioneRuoloFunzionalitaControllerApi(MS_AU_CONFIG);
        const response = await api.searchAssegnazioneRuoliFunzionalita({
            idRuolo: idRuolo,
            pageNum: -1,
            pageSize: -1,
        })
        return response.data;
    }
)


export const insertFunzionalitaRuolo = createAsyncThunk(
    'ruoli/associaFunzionalità',
    async (body: AssegnaRuoloConFunzionalitaRequestDto) => {
        const api = new AssegnazioneRuoloFunzionalitaControllerApi(MS_AU_CONFIG);
        const response = await api.insertAssegnazioneRuoliFunzionalita({
            assegnaRuoloConFunzionalitaRequestDto: body,
        })
        return response.data
    }
)

export const disassociaFunzionalita = createAsyncThunk(
    'ruoli/disassociaFunzionalità',
    async (body: deleteFunzionalita) => {
        const api = new AssegnazioneRuoloFunzionalitaControllerApi(MS_AU_CONFIG);
        const response = await api.deleteAssegnazioneRuoliFunzionalita({
            idRuolo: body.idRuolo!,
            funzionalita: body.funzionalita,
        })
        return response.data
    }
)

export const listDipendenti = createAsyncThunk(
    'ruoli/listDipendenti',
    async (idRuolo: number) => {
        const api = new AssegnazioneRuoloDipendentiControllerApi(MS_AU_CONFIG);
        const response = await api.searchAssegnazioneRuoloDipendenti({
            idRuolo,
            pageNum: -1,
            pageSize: -1
        })
        return response.data
    }
)

export const insertRuoloDipendenti = createAsyncThunk(
    'ruoli/insertRuoloDipendenti',
    async (body:AssegnaRuoloConDipendenteRequestDto) => {
        const api = new AssegnazioneRuoloDipendentiControllerApi(MS_AU_CONFIG);
        const response = await api.insertAssegnazioneRuoloDipendenti({
            assegnaRuoloConDipendenteRequestDto: body
        })
        return response.data
    }
)

export const disassociaDipendente = createAsyncThunk(
    'ruoli/disassociaDipendente',
    async(body:deleteDipendenti) =>{
        const api = new AssegnazioneRuoloDipendentiControllerApi(MS_AU_CONFIG);
        const response = await api.deleteAssegnazioneRuoloDipendenti({
            idRuolo: body.idRuolo!,
            idDipendente:body.idDipendente
        })
        return response.data
    }
)


export const ruoloSlice = createSlice({
    name: 'ruolo',
    initialState,
    reducers: {
        resetInputForm: (state) => {
            state.insertStatus = ''
        },
        resetUpdateForm: (state) => {
            state.updateStatus = ''
            state.detailsResult = null
            state.detailsStatus = ''
        },
        resetDeleteStatus: (state) => {
            state.deteleStatus = ''
        },
        resetAssociazioneStatus: (state) => {
            state.insertFunzionalitaRuoloStauts = ''
            state.insertDipendentiRuoloStauts = ''
        }
        
    },
    extraReducers: (builder) => {

        //search 
        builder.addCase(searchRuoli.pending, (state: RuoloState, action: any) => {
            state.searchStatus = STATUS_PENDING;
        });
        builder.addCase(searchRuoli.fulfilled, (state: RuoloState, action: any) => {
            state.searchResult = action.payload;
            state.searchInput = action.meta.arg;
            state.searchStatus = STATUS_FULLFILLED;
        });
        builder.addCase(searchRuoli.rejected, (state: RuoloState, action: any) => {
            state.searchStatus = STATUS_REJECTED;
        });


        //pupulate select stato in ricerca
        builder.addCase(formSearchRuoloStatoSelect.pending, (state: RuoloState, action: any) => {

        });
        builder.addCase(formSearchRuoloStatoSelect.fulfilled, (state: RuoloState, action: any) => {
            state.formSearchRuolo = action.payload;
        });
        builder.addCase(formSearchRuoloStatoSelect.rejected, (state: RuoloState, action: any) => {

        });

        //insert ruolo
        builder.addCase(insertRuolo.pending, (state: RuoloState, action: any) => {
            state.insertStatus = STATUS_PENDING;
        });
        builder.addCase(insertRuolo.fulfilled, (state: RuoloState, action: any) => {
            state.insertStatus = STATUS_FULLFILLED
        });
        builder.addCase(insertRuolo.rejected, (state: RuoloState, action: any) => {
            state.insertStatus = STATUS_REJECTED;
        });

        //details ruolo
        builder.addCase(detailsRuolo.pending, (state: RuoloState, action: any) => {
            state.detailsStatus = STATUS_PENDING;
        });
        builder.addCase(detailsRuolo.fulfilled, (state: RuoloState, action: any) => {
            state.detailsResult = action.payload
            state.detailsStatus = STATUS_FULLFILLED
        });
        builder.addCase(detailsRuolo.rejected, (state: RuoloState, action: any) => {
            state.detailsStatus = STATUS_REJECTED;
        });

        //update ruolo
        builder.addCase(updateRuolo.pending, (state: RuoloState, action: any) => {
            state.updateStatus = STATUS_PENDING;
        });
        builder.addCase(updateRuolo.fulfilled, (state: RuoloState, action: any) => {
            state.updateStatus = STATUS_FULLFILLED
        });
        builder.addCase(updateRuolo.rejected, (state: RuoloState, action: any) => {
            state.updateStatus = STATUS_REJECTED;
        });

        //codice già presente
        builder.addCase(codiceRuoloEsistente.pending, (state: RuoloState, action: any) => {

        });
        builder.addCase(codiceRuoloEsistente.fulfilled, (state: RuoloState, action: any) => {
            state.codicePresenteResult = action.payload
        });
        builder.addCase(codiceRuoloEsistente.rejected, (state: RuoloState, action: any) => {

        });

        //delete ruolo
        builder.addCase(deleteRuolo.pending, (state: RuoloState, action: any) => {
            state.deteleStatus = STATUS_PENDING
        });
        builder.addCase(deleteRuolo.fulfilled, (state: RuoloState, action: any) => {
            state.deteleStatus = STATUS_FULLFILLED
        });
        builder.addCase(deleteRuolo.rejected, (state: RuoloState, action: any) => {
            state.deteleStatus = STATUS_REJECTED
        });

        //lista funzionalità
        builder.addCase(listFunzionalita.pending, (state: RuoloState, action: any) => {
            state.funzionalitaRuoloListStatus = STATUS_PENDING
        });
        builder.addCase(listFunzionalita.fulfilled, (state: RuoloState, action: any) => {
            state.funzionalitaRuoloListResult = action.payload
            state.funzionalitaRuoloListStatus = STATUS_FULLFILLED
        });
        builder.addCase(listFunzionalita.rejected, (state: RuoloState, action: any) => {
            state.funzionalitaRuoloListStatus = STATUS_REJECTED
        });

        //insert funzionalità ruolo
        builder.addCase(insertFunzionalitaRuolo.pending, (state: RuoloState, action: any) => {
            state.insertFunzionalitaRuoloStauts = STATUS_PENDING
        });
        builder.addCase(insertFunzionalitaRuolo.fulfilled, (state: RuoloState, action: any) => {
            state.insertFunzionalitaRuoloStauts = STATUS_FULLFILLED
        });
        builder.addCase(insertFunzionalitaRuolo.rejected, (state: RuoloState, action: any) => {
            state.insertFunzionalitaRuoloStauts = STATUS_REJECTED
        });


        //populate select funzionalità 
        builder.addCase(selectFunzionalitaInsert.pending, (state: RuoloState, action: any) => {
            state.selectFunzionalitaStatus = STATUS_PENDING
        });
        builder.addCase(selectFunzionalitaInsert.fulfilled, (state: RuoloState, action: any) => {
            state.selectFunzionalitaResult = action.payload
            state.selectFunzionalitaStatus = STATUS_FULLFILLED
        });
        builder.addCase(selectFunzionalitaInsert.rejected, (state: RuoloState, action: any) => {
            state.selectFunzionalitaStatus = STATUS_REJECTED
        });

        //disassicia ruolo funzionalità
        builder.addCase(disassociaFunzionalita.pending, (state: RuoloState, action: any) => {
            state.insertFunzionalitaRuoloStauts = STATUS_PENDING
        });
        builder.addCase(disassociaFunzionalita.fulfilled, (state: RuoloState, action: any) => {
            state.insertFunzionalitaRuoloStauts = STATUS_FULLFILLED
        });
        builder.addCase(disassociaFunzionalita.rejected, (state: RuoloState, action: any) => {
            state.insertFunzionalitaRuoloStauts = STATUS_REJECTED
        });

        //list dipendenti
        builder.addCase(listDipendenti.pending, (state: RuoloState, action: any) => {
            state.dipendentiRuoloListStatus = STATUS_PENDING
        });
        builder.addCase(listDipendenti.fulfilled, (state: RuoloState, action: any) => {
            state.dipendentiRuoloListResult = action.payload
            state.dipendentiRuoloListStatus = STATUS_FULLFILLED
        });
        builder.addCase(listDipendenti.rejected, (state: RuoloState, action: any) => {
            state.dipendentiRuoloListStatus = STATUS_REJECTED
        });

        
        //associazione tra un ruolo e dipendenti
        builder.addCase(insertRuoloDipendenti.pending, (state: RuoloState, action: any) => {
            state.insertDipendentiRuoloStauts = STATUS_PENDING
        });
        builder.addCase(insertRuoloDipendenti.fulfilled, (state: RuoloState, action: any) => {
            state.insertDipendentiRuoloStauts = STATUS_FULLFILLED
        });
        builder.addCase(insertRuoloDipendenti.rejected, (state: RuoloState, action: any) => {
            state.insertDipendentiRuoloStauts = STATUS_REJECTED
        });

        //disassociazione tra ruolo e dipendente
        builder.addCase(disassociaDipendente.pending, (state: RuoloState, action: any) => {
            state.insertDipendentiRuoloStauts = STATUS_PENDING
        });
        builder.addCase(disassociaDipendente.fulfilled, (state: RuoloState, action: any) => {
            state.insertDipendentiRuoloStauts = STATUS_FULLFILLED
        });
        builder.addCase(disassociaDipendente.rejected, (state: RuoloState, action: any) => {
            state.insertDipendentiRuoloStauts = STATUS_REJECTED
        });
    }
});

export const { resetInputForm, resetUpdateForm, resetDeleteStatus, resetAssociazioneStatus } = ruoloSlice.actions

export default ruoloSlice.reducer;
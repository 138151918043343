import { useTranslation } from "react-i18next";
import { AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest, OptionDtoStatoRichiestaAbilitazioneValoreEnum, RichiestaAbilitazioneDto, RuoloUtenteAutenticatoDto } from "../../services/ms-anagrafica-unica";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/page-header/PageHeader";
import { HREF_ABILITAZIONI_MODIFICA_RICHIESTA, HREF_ABILITAZIONI_APPROVAZIONE_ATTIVAZIONE, HREF_ABILITAZIONI_NUOVA_RICHIESTA, HREF_ABILITAZIONI_DETTAGLIO_RICHIESTA } from '../../components/sidemenu/sidemenuConstants'
import { Accordion, AccordionBody, AccordionHeader, Alert, Button, Col, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown } from "design-react-kit";
import { deleteRichiestaAbilitazione, loadFormsFunzionalitaApplicative, revocaUtenzaAbilitazione, riattivaUtenzaAbilitazione, searchElencoRichieste, sospendiUtenzaAbilitazione } from "../../store/funzionalitaApplicativeSlice";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import CustomSelect, { CustomSelectItem } from "../../components/custom-select/CustomSelect";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import Pagination from "../../components/pager/Pagination";

const initialValues:AssegnazioneFunzionalitaApplicativeControllerApiSearchAssegnazioneFunzionalitaApplicativeRequest = {
    codiceCid: 0,
    nome: '',
    cognome: '',
    codiceFiscale: '',
    applicativo: 0,
    stato: undefined,    
    pageNum: 0,
    pageSize: 10,
    direction: 'ASC',
    sort: 'codiceCid'
};

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

const schema = z.object({
    codiceCid: z.number().optional(),
    nome: z.string().max(200, { message: "lenghtnome" }).optional(),
    cognome: z.string().max(200, { message: "lenghtcognome" }).optional(),
    codiceFiscale: z.string().optional()
});

const ElencoRichieste = (props: {operations: RuoloUtenteAutenticatoDto}) => {
    const { t } = useTranslation()
    const [formCollapseOpen, setFormCollapseOpen] = useState(false);
    const [isOpenElimininaRichiesta, toggleModalElimininaRichiesta] = useState(false);
    const [isOpenSospendiUtenza, toggleModalSospendiUtenza] = useState(false);
    const [isOpenRiattivaUtenza, toggleModalRiattivaUtenza] = useState(false);
    const [isOpenRevocaUtenza, toggleModalRevocaUtenza] = useState(false);
    const [richiesta, setRichiesta] = useState<number>();
    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
    const { formsResult, searchStatus, searchResult, searchInput } = useAppSelector((state) => state.abilitazioni)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isGestore = props.operations?.elencoFunzionalita?.includes('RICHIESTEFUNZAPP_PATCH_VALUTAZIONE');
    const isDirigente = props.operations?.elencoFunzionalita?.includes('RICHIESTEFUNZAPP_PATCH');

    const searchForm = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => {
            doSearch();
        },
    });

    const searchStartKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            searchForm.setFieldValue("pageNum", 0);
            searchForm.handleSubmit();
        }
    }

    const doSearch = () => {
        dispatch(searchElencoRichieste(searchForm.values));
    };

    let statoOptions: CustomSelectItem[] = [];
    formsResult?.stato?.forEach((s) => {
        statoOptions.push({ label: s.label, value: s.valore });
    });

    let applicativoOptions: CustomSelectItem[] = [];
    formsResult?.applicativi?.forEach((s) => {
        applicativoOptions.push({ label: s.label, value: s.valore });
    });

    let hasResults =
    searchResult && searchResult.totalElements
        ? searchResult.totalElements > 0
        : false;

            
    useEffect(() => {
        if (!!searchInput && (!!searchInput.codiceCid || !!searchInput.stato || !!searchInput.codiceFiscale ||
             !!searchInput.nome || !!searchInput.cognome || !!searchInput.applicativo) ) {
            setFormCollapseOpen(true);
            searchElencoRichieste(searchInput)
        } else {
            doSearch();
        }
        dispatch(loadFormsFunzionalitaApplicative());
    },[])

    useEffect(() => {
        searchForm.setValues({
            codiceCid: searchInput?.codiceCid,
            codiceFiscale: searchInput?.codiceFiscale || '',
            nome: searchInput?.nome || '',
            cognome: searchInput?.cognome || '',
            applicativo: searchInput?.applicativo || undefined,
            stato: searchInput?.stato || undefined,
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            direction: searchInput?.direction || '' || undefined,
            sort: searchInput?.sort || ''
        })
    }, [searchInput])


    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };
    const handleChange = (name: string, selectedOption: any) => {
        searchForm.setFieldValue(name, selectedOption);
    };

    const showModificaOperation = (richiesta: RichiestaAbilitazioneDto) => {
        if (isDirigente) {
            if (richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Bozza || 
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Attiva) {
                return true;
            }
        }
        return false;
    }

    const showDettaglioOperation = (richiesta: RichiestaAbilitazioneDto) => {
        if (isDirigente) {
            if (richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Bozza || 
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneAttivazione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneModifica ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneSospensione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneRevoca ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneRiattivazione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Attiva ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Annullata ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Revocata ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Sospesa ) {
                return true;
            }
        }
        if (isGestore) {
            if (richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneAttivazione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneModifica ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneSospensione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneRevoca ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneRiattivazione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Attiva ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Revocata ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Sospesa ) {
                return true;
            }
        }
        return false;
    }

    const showCancellaOperation = (richiesta: RichiestaAbilitazioneDto) => {
        if (isDirigente) {
            if (richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Bozza) {
                return true;
            }
        }
        return false;
    }

    const showValutazioneOperation = (richiesta: RichiestaAbilitazioneDto) => {
        if (isGestore) {
            if (richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneAttivazione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneModifica ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneSospensione ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneRevoca ||
                richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.AttesaApprovazioneRiattivazione ) {
                return true;
            }
        }
        return false;
    }

    const showSospendiOperation = (richiesta: RichiestaAbilitazioneDto) =>
        isDirigente && richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Attiva

    const showRiattivaOperation = (richiesta: RichiestaAbilitazioneDto) =>
        isDirigente && richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Sospesa

    const showRevocaOperation = (richiesta: RichiestaAbilitazioneDto) =>
        isDirigente && richiesta.stato === OptionDtoStatoRichiestaAbilitazioneValoreEnum.Attiva

    const showInserisciRichiestaOperation = () => 
        props.operations.elencoFunzionalita?.includes('RICHIESTEFUNZAPP_INSERT')

    const handlePageChange = (pageNum: number) => {
        console.log("Handle page change", pageNum - 1);
        // setCurrentPage(pageNum);
        searchForm.setFieldValue("pageNum", pageNum - 1);
        // doSearch();
        searchForm.handleSubmit();
    };

    const confermaEliminazione = (id: number) => {
        setRichiesta(id)
        toggleModalElimininaRichiesta(!isOpenElimininaRichiesta)
    }

    const confermaSospensione = (id: number) => {
        setRichiesta(id)
        toggleModalSospendiUtenza(!isOpenSospendiUtenza)
    }

    const confermaRiattivazione = (id: number) => {
        setRichiesta(id)
        toggleModalRiattivaUtenza(!isOpenRiattivaUtenza)
    }

    const confermaRevoca = (id: number) => {
        setRichiesta(id)
        toggleModalRevocaUtenza(!isOpenRevocaUtenza)
    }

    const eliminaRichiesta = () => {
        dispatch(deleteRichiestaAbilitazione(richiesta!)).unwrap().then( resp => {
            resp === 200 && doSearch();
        })
    }

    const sospendiUtenza = () => {
        dispatch(sospendiUtenzaAbilitazione(richiesta!)).unwrap().then( resp => {
            resp === 200 && doSearch();
        })
    }

    const riattivaUtenza = () => {
        dispatch(riattivaUtenzaAbilitazione(richiesta!)).unwrap().then( resp => {
            resp === 200 && doSearch();
        })
    }

    const revocaUtenza = () => {
        dispatch(revocaUtenzaAbilitazione(richiesta!)).unwrap().then( resp => {
            resp === 200 && doSearch();
        })
    }

    const insertButton = {
        buttonProperties: { outline: true },
        title: 'Nuova richiesta',
        buttonColor: 'primary',
        action: () => navigate(HREF_ABILITAZIONI_NUOVA_RICHIESTA),
        showButtonIcon: true,
        icon: 'it-plus',
        iconColor: 'primary',
        disabled: !showInserisciRichiestaOperation()
    }

    const searchButton = {
        buttonProperties: { outline: true },
        title: 'Ricerca',
        buttonColor: 'primary',
        showButtonIcon: true,
        icon: 'it-search',
        iconColor: 'primary',
        action: () => { formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true) }
    }

    const showInserisci = () => {
        if (props.operations?.elencoFunzionalita?.includes('RICHIESTEFUNZAPP_INSERT'))
            {return true;}
        return false;
    }

    return (
        <div>
            <PageHeader showIcon={false} title={t("elenco richieste")!} 
                buttons={showInserisci()? [insertButton, searchButton] : [searchButton]}
            />

            <Accordion background="active">
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t("filtri di ricerca")}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-5">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchForm.values.pageNum}
                            onChange={searchForm.handleChange}
                        />
                        <Input
                            type="number"
                            label="Codice"
                            id="input-codice"
                            placeholder="Inserisci un codice"
                            wrapperClassName="col col-md-6"
                            name="codiceCid"
                            invalid={!!searchForm.errors.codiceCid}
                            infoText={t(searchForm.errors.codiceCid || "") || ""}
                            value={searchForm.values.codiceCid || ''}
                            onChange={searchForm.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Nome"
                            id="input-nome"
                            placeholder="Inserisci un nome"
                            wrapperClassName="col col-md-6"
                            name="nome"
                            invalid={!!searchForm.errors.nome}
                            infoText={t(searchForm.errors.nome || "") || ""}
                            value={searchForm.values.nome}
                            onChange={searchForm.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Cognome"
                            id="input-cognome"
                            placeholder="Inserisci un cognome"
                            wrapperClassName="col col-md-6"
                            name="cognome"
                            invalid={!!searchForm.errors.cognome}
                            infoText={t(searchForm.errors.cognome || "") || ""}
                            value={searchForm.values.cognome}
                            onChange={searchForm.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Input
                            type="text"
                            label="Codice fiscale"
                            id="codiceFiscale"
                            placeholder="Codice fiscale"
                            wrapperClassName="col col-md-6"
                            name="codiceFiscale"
                            invalid={!!searchForm.errors.codiceFiscale}
                            infoText={t(searchForm.errors.codiceFiscale || "") || ""}
                            value={searchForm.values.codiceFiscale}
                            onChange={searchForm.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />

                        <Col md="6">
                            <CustomSelect
                                label="Stato"
                                options={statoOptions}
                                value={searchForm.values.stato}
                                placeholder="Seleziona stato"
                                onChange={(e) => handleChange("stato", e)}
                                invalid={!!searchForm.errors.stato}
                                infoText={t(searchForm.errors.stato || "") || ""}
                                name="stato"
                            />
                        </Col>

                        <Col md="6">
                            <CustomSelect
                                label="Applicativo"
                                options={applicativoOptions}
                                value={searchForm.values.applicativo}
                                placeholder="Seleziona applicativo"
                                invalid={!!searchForm.errors.applicativo}
                                infoText={t(searchForm.errors.applicativo || "") || ""}
                                onChange={(e) => handleChange("applicativo", e)}
                                name="applicativo"
                            />
                        </Col>
                    </div>

                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchForm.setFieldValue("pageNum", 0);
                                searchForm.handleSubmit();
                            }}
                        >
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={() => searchForm.resetForm()}
                        >
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>

            <div className="mt-2 position-relative">
                {searchStatus === STATUS_FULLFILLED &&
                    searchResult?.totalElements === 0 && (
                        <Alert color="info">Nessun risultato disponibile</Alert>
                    )
                }
                {searchStatus === STATUS_FULLFILLED && hasResults && (
                    <div className={searchResult?.totalPages === 1 ? 'mb-5' : ''}>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th scope='col' onClick={() => ordinamento("codiceFiscale")}>
                                        <div className="table-order">
                                            Codice fiscale
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamento("codiceCid")}>
                                        <div className="table-order">
                                            Codice CID
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamento("cognome")}>
                                        <div className="table-order">
                                            Cognome
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamento("nome")}>
                                        <div className="table-order">
                                            Nome
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamento("applicativo")}>
                                        <div className="table-order">
                                            Applicativo
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamento("stato")}>
                                        <div className="table-order">
                                            Stato
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col'>
                                        Azioni
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {(searchResult?.data || []).map((x, i) => (
                                <tr key={i}>
                                    <td>{x.codiceFiscale}</td>
                                    <td>{x.dipendenteRegionale?.id}</td>
                                    <td>{x.cognome}</td>
                                    <td>{x.nome}</td>
                                    <td>{x.applicativo}</td>
                                    <td>{x.descrizioneStato}</td>
                                    <td>
                                        <UncontrolledDropdown direction="left">
                                            <DropdownToggle nav>
                                                <Icon icon="it-more-actions" />
                                            </DropdownToggle>
                                            <DropdownMenu className="no-arrow">
                                                <LinkList>
                                                    {showModificaOperation(x) && <LinkListItem
                                                        size="medium"
                                                        onClick={() =>
                                                            navigate(
                                                                `${HREF_ABILITAZIONI_MODIFICA_RICHIESTA}/${x.codiceUnivoco}`
                                                            )
                                                        }
                                                    >
                                                        <span>Modifica</span>
                                                    </LinkListItem>}
                                                    {showDettaglioOperation(x) && <LinkListItem
                                                        size="medium"
                                                        onClick={() =>
                                                            navigate(
                                                                `${HREF_ABILITAZIONI_DETTAGLIO_RICHIESTA}/${x.codiceUnivoco}`
                                                            )
                                                        }
                                                    >
                                                        <span>Dettaglio</span>
                                                    </LinkListItem>}
                                                    {showCancellaOperation(x) && <LinkListItem
                                                        size="medium"
                                                        onClick={() => confermaEliminazione(x.codiceUnivoco!)}
                                                    >
                                                        <span>Elimina</span>
                                                    </LinkListItem>}
                                                    {showValutazioneOperation(x) &&
                                                    <LinkListItem
                                                        size="medium"
                                                        onClick={() =>
                                                            navigate(
                                                                `${HREF_ABILITAZIONI_APPROVAZIONE_ATTIVAZIONE}/${x.codiceUnivoco}`
                                                            )
                                                        }
                                                    >
                                                        <span>Valutazione</span>
                                                    </LinkListItem>}
                                                    {showSospendiOperation(x) &&
                                                    <LinkListItem
                                                        size="medium"
                                                        onClick={() => confermaSospensione(x.codiceUnivoco!)}
                                                    >
                                                        <span>Sospendi</span>
                                                    </LinkListItem>}
                                                    {showRiattivaOperation(x) &&
                                                    <LinkListItem
                                                        size="medium"
                                                        onClick={() => confermaRiattivazione(x.codiceUnivoco!)}
                                                    >
                                                        <span>Riattiva</span>
                                                    </LinkListItem>}
                                                    {showRevocaOperation(x) &&
                                                    <LinkListItem
                                                        size="medium"
                                                        onClick={() => confermaRevoca(x.codiceUnivoco!)}
                                                    >
                                                        <span>Revoca</span>
                                                    </LinkListItem>}
                                                </LinkList>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination
                            totalCount={searchResult?.totalElements || 0}
                            siblingCount={1}
                            currentPage={searchForm.values.pageNum! + 1}
                            pageSize={searchResult?.pageSize || 10}
                            onPageChange={(page: number) => {
                                handlePageChange(page);
                            }}
                            className="justify-content-center"
                        />
                </div>
                )}
            </div>
            <Modal
                isOpen={isOpenElimininaRichiesta}
                toggle={() => toggleModalElimininaRichiesta(!isOpenElimininaRichiesta)}
                labelledBy='eliminaRichiesta'
                centered
            >
                <ModalHeader toggle={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); setRichiesta(undefined) }} id='eliminaRichiesta'>
                    Elimina richiesta
                </ModalHeader>
                <ModalBody>
                    {richiesta && t('conferma eliminazione richiesta')}
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); setRichiesta(undefined) }}>
                        Annulla
                    </Button>
                    <Button color='primary' onClick={() => { toggleModalElimininaRichiesta(!isOpenElimininaRichiesta); eliminaRichiesta() }}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenSospendiUtenza}
                toggle={() => toggleModalSospendiUtenza(!isOpenSospendiUtenza)}
                labelledBy='sospendiUtenza'
                centered
            >
                <ModalHeader toggle={() => { toggleModalSospendiUtenza(!isOpenSospendiUtenza); setRichiesta(undefined) }} id='sospendiUtenza'>
                    Sospendi utenza
                </ModalHeader>
                <ModalBody>
                    {richiesta && t('conferma sospensione utenza')}
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { toggleModalSospendiUtenza(!isOpenSospendiUtenza); setRichiesta(undefined) }}>
                        Annulla
                    </Button>
                    <Button color='primary' onClick={() => { toggleModalSospendiUtenza(!isOpenSospendiUtenza); sospendiUtenza() }}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenRiattivaUtenza}
                toggle={() => toggleModalRiattivaUtenza(!isOpenRiattivaUtenza)}
                labelledBy='riattivaUtenza'
                centered
            >
                <ModalHeader toggle={() => { toggleModalRiattivaUtenza(!isOpenRiattivaUtenza); setRichiesta(undefined) }} id='riattivaUtenza'>
                    Riattiva utenza
                </ModalHeader>
                <ModalBody>
                    {richiesta && t('conferma riattivazione utenza')}
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { toggleModalRiattivaUtenza(!isOpenRiattivaUtenza); setRichiesta(undefined) }}>
                        Annulla
                    </Button>
                    <Button color='primary' onClick={() => { toggleModalRiattivaUtenza(!isOpenRiattivaUtenza); riattivaUtenza() }}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenRevocaUtenza}
                toggle={() => toggleModalRevocaUtenza(!isOpenRevocaUtenza)}
                labelledBy='revocaUtenza'
                centered
            >
                <ModalHeader toggle={() => { toggleModalRevocaUtenza(!isOpenRevocaUtenza); setRichiesta(undefined) }} id='revocaUtenza'>
                    Revoca utenza
                </ModalHeader>
                <ModalBody>
                    {richiesta && t('conferma revoca utenza')}
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => { toggleModalRevocaUtenza(!isOpenRevocaUtenza); setRichiesta(undefined) }}>
                        Annulla
                    </Button>
                    <Button color='primary' onClick={() => { toggleModalRevocaUtenza(!isOpenRevocaUtenza); revocaUtenza() }}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ElencoRichieste
import {useEffect, useState} from 'react'
import {Button, Col, Input} from "design-react-kit";
import {FormikProps, useFormik} from "formik";
import CustomSelect from '../../components/custom-select/CustomSelect';
import {useNavigate, useParams} from "react-router-dom";
import {HREF_ELENCO_POSIZIONI_ORGANIZZATIVE} from '../../components/sidemenu/sidemenuConstants';
import {useAppDispatch, useAppSelector} from "../../hooks";
import posizioneOrganizzativaSlice, {associaPO, detailsPosizioneOrganizzativa, getTipoByDipendente} from "../../store/posizioneOrganizzativaSlice";
import {FormsControllerApi, PosizioneOrganizzativaAssociaRequestDto, PosizioneOrganizzativaAssociaRequestDtoAllOf} from "../../services/ms-anagrafica-unica";
import {MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_REJECTED} from "../../store/store-constants";
import {useTranslation} from "react-i18next";
import {z, ZodIssue} from "zod";
import {toFormikValidationSchema} from "zod-formik-adapter";
import CustomAutocomplete from '../../components/custom-autocomplete/CustomAutocomplete';
import { debounce } from 'lodash';
import PageHeader from '../../components/page-header/PageHeader';
import PosizioniOrganizzativeDetailsComponent from '../../components/posizioni-organizzative-detail-component/PosizioniOrganizzativeDetailsComponent';
import { CODICE_UNIVOCO_PO, DATA_FINE_VALIDITA_PO, DATA_INIZIO_VALIDITA_PO, DECLARATORIA_PO, DENOMINAZIONE_PO, STRUTTURA_ORGANIZZATIVA_PO, TIPO_PO } from './posizioniOrganizzativeConstants';

interface IAssociazioneOptionsDTO {
    dipendenti: ITipologica[];
    tipiIncarico: ITipologica[];
}

interface ITipologica {
    label: string;
    value: string;
}

const AssociazionePosizioneOrganizzativa = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [tipoOpts, setTipoOpts] = useState<ITipologica[]>([]);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const {idCodiceIncarico} = useParams();
    const detailsResult = useAppSelector((state) => state.posizioneOrganizzativa.detailsResult);
    const {t} = useTranslation();
    const fieldUsed: Array<string> = [CODICE_UNIVOCO_PO, DENOMINAZIONE_PO, DECLARATORIA_PO, STRUTTURA_ORGANIZZATIVA_PO, DATA_INIZIO_VALIDITA_PO, DATA_FINE_VALIDITA_PO, TIPO_PO];

    useEffect(() => {
        getDetailsFromApi(idCodiceIncarico!);
    }, [])

    const getDetailsFromApi = (idCodiceIncarico: string) => {
        dispatch(detailsPosizioneOrganizzativa({idCodiceIncarico}));
    }
    
    const initialValues: PosizioneOrganizzativaAssociaRequestDtoAllOf = {
        idDipendente: undefined,
        idDecodifica: undefined,
        dataInizioValidita: '',
        dataFineValidita: '',
        estremiDiConferimento: ''
    };

    const schema = z.object({
        idDipendente: z.number({required_error: "Dipendente è un campo obbligatorio"}),
        idDecodifica: z.string({required_error: "Tipologia incarico è un campo obbligatorio"}),
        dataInizioValidita: z.coerce.date({required_error: 'Il campo data inizio è obbligatorio'}),
        dataFineValidita: z.coerce.date({required_error: 'Il campo data fine è obbligatorio'}),
        estremiDiConferimento: z.string({required_error: "Estremi atto di conferimento è un campo obbligatorio"})
    }).refine(data => {
        if (data.dataFineValidita <= data.dataInizioValidita) {
            throw new z.ZodError([{
                path: ['dataFineValidita'],
                message: 'Il campo data fine deve essere maggiore alla data inizio'
            }] as ZodIssue[]);
        }
    });

    const form: FormikProps<PosizioneOrganizzativaAssociaRequestDtoAllOf> = useFormik({
        initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            let params: PosizioneOrganizzativaAssociaRequestDto = {
                codiceUnivoco: idCodiceIncarico,
                requestType: 'Associa',
                idDipendente: values.idDipendente,
                idDecodifica: values.idDecodifica,
                dataInizioValidita: values.dataInizioValidita,
                dataFineValidita: values.dataFineValidita,
                estremiDiConferimento: values.estremiDiConferimento
            }
            setOpenAlert(true);
            dispatch(associaPO({patchPosizioneOrganizzativaRequest: params, idCodiceIncarico: idCodiceIncarico as string}));
        }
    })

    const autocompleteDipendenti = debounce(async (inputValue: string, callback: any) => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getAssociaIncarichiContestoForm({
            idIncarico: idCodiceIncarico!,
            chiave: inputValue
        });
        let options: { value: number | undefined; label: string | undefined }[] = [];
        if (response.data.dipendenti) {
            response.data.dipendenti?.data?.forEach(value => {
                options.push({ value: value.id, label: value.nomeCompleto });
            })
        }
        callback(options)
    }, 500)

    const handleSelectChange = (name: string, e: string | number) => {
        console.log(name, e);
        form.setFieldValue(name, e);
        if (name === 'idDipendente') {
            dispatch(getTipoByDipendente(e as number)).then(res => setTipoOpts((res.payload as IAssociazioneOptionsDTO).tipiIncarico));
        }
    };

    const getFieldError = (form: FormikProps<PosizioneOrganizzativaAssociaRequestDtoAllOf>, fieldName: keyof PosizioneOrganizzativaAssociaRequestDtoAllOf): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE} title={t("Associa elevata qualificazione")!}></PageHeader>
            <PosizioniOrganizzativeDetailsComponent value={detailsResult!} fields={fieldUsed} ></PosizioniOrganizzativeDetailsComponent>
            <div className="">
                <div className="form-row">
                    
                    <Col md="12">
                    <CustomAutocomplete
                            label="Dipendente"
                            placeholder="Cerca un dipendente"
                            id="select-dipendente"
                            loadOptionsFn={autocompleteDipendenti}
                            handleOptionSelect={(e: string) => handleSelectChange("idDipendente", e)}
                            invalid={!!getFieldError(form, "idDipendente")}
                            infoText={t(getFieldError(form, "idDipendente")) || ""}
                            
                       />
                    </Col>
                    <Col md="12">
                        <CustomSelect label="Tipologia incarico"
                                      placeholder="Seleziona la tipologia dell'incarico"
                                      options={tipoOpts}
                                      name="idDecodifica"
                                      invalid={!!getFieldError(form, "idDecodifica")}
                                      infoText={t(getFieldError(form, "idDecodifica")) || ""}
                                      value={form.values.idDecodifica}
                                      onChange={(idDecodifica) => handleSelectChange('idDecodifica', idDecodifica)}/>
                    </Col>
                    <Input
                        label='Data Inizio incarico'
                        type='date'
                        placeholder='Inserisci la data di Inizio incarico'
                        id='input-dataInizioIncarico'
                        wrapperClassName="form-group col-md-12"
                        name="dataInizioValidita"
                        invalid={!!getFieldError(form, "dataInizioValidita")}
                        infoText={t(getFieldError(form, "dataInizioValidita")) || ""}
                        value={form.values.dataInizioValidita}
                        onChange={form.handleChange}
                    />
                    <Input
                        label='Data Fine incarico'
                        type='date'
                        placeholder='Inserisci la data di fine incarico'
                        id='input-dataFineIncarico'
                        wrapperClassName="form-group col-md-12"
                        name="dataFineValidita"
                        invalid={!!getFieldError(form, "dataFineValidita")}
                        infoText={t(getFieldError(form, "dataFineValidita")) || ""}
                        value={form.values.dataFineValidita}
                        onChange={form.handleChange}
                    />
                    <Input
                        label='Estremi atto di conferimento'
                        type='text'
                        placeholder="Inserisci l'estremo atto di conferimento"
                        id='input-estremoAttoConferimento'
                        wrapperClassName="form-group col-md-12 required"
                        name="estremiDiConferimento"
                        invalid={!!getFieldError(form, "estremiDiConferimento")}
                        infoText={t(getFieldError(form, "estremiDiConferimento")) || ""}
                        value={form.values.estremiDiConferimento}
                        onChange={form.handleChange}
                    />
                </div>
                <div className="d-flex flex-row-reverse">
                    <Button color='primary' className="mt-2" onClick={form.submitForm}>Conferma</Button>
                    <Button outline color='primary' className="mt-2 mr-2"
                            onClick={() => navigate(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE)}>Annulla</Button>
                </div>
            </div>
        </div>
    )
}

export default AssociazionePosizioneOrganizzativa
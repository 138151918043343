import { CessazioneDipendenteDetailsComponent } from './CessazioneDipendenteDetailsComponent'

const CessazioneDipendenteLightDetails = (props: CessazioneDipendenteDetailsComponent) => {
    return (
        <div className='col-sm-12 cessazione-dipendente'>
            {(props.detailsResult?.id || props.detailsResult?.nome || props.detailsResult?.cognome || props.detailsResult?.codiceFiscale || props.detailsResult?.elencoStruttureOrganizzative || props.detailsResult?.tipoContratto || props.detailsResult?.elencoIncarichiInCorso || props.detailsResult?.dataInizioRapporto) &&
                <div className="card-div col-sm-12">
                    <div className="col-sm-12">
                        <div className="info-box-light">
                            <h6 className="mb-3 cessazione-dipendente-title">
                                Riepilogo
                            </h6>
                            <hr />
                            <div className="row cessazione-dipendente-table-info">
                                <dl className='col-sm-3'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.id &&
                                                <>
                                                    <dt className='col-sm-12'>Codice CID</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.id}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-3'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.nome &&
                                                <>
                                                    <dt className='col-sm-12'>Nome</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.nome}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-3'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.cognome &&
                                                <>
                                                    <dt className='col-sm-12'>Cognome</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.cognome}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-3'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.codiceFiscale &&
                                                <>
                                                    <dt className='col-sm-12'>Codice fiscale</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.codiceFiscale}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-12'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.numeroSO! > 0 &&
                                                <>
                                                    <dt className='col-sm-12'>Strutture organizzative di appartenenza</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.numeroSO === 1 ? `${props.detailsResult?.elencoStruttureOrganizzative![0].descrizione}` : <ul>{props.detailsResult?.elencoStruttureOrganizzative!.map((data) => <li key={data.descrizione}>{data.descrizione}</li>)} </ul>}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-12'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.tipoContratto &&
                                                <>
                                                    <dt className='col-sm-12'>Tipologia contrattuale</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.tipoContratto}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-12'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.elencoIncarichiInCorso &&
                                                <>
                                                    <dt className='col-sm-12'>Incarichi in corso</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.numeroIncarichiInCorso == 1 ? `${props.detailsResult?.elencoIncarichiInCorso[0]}` : <ul key={props.detailsResult?.id}>{props.detailsResult?.elencoIncarichiInCorso!.map((data, i) => <li key={i}>{data}</li>)} </ul>}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                                <dl className='col-sm-12'>
                                    <div className="row cessazione-dipendente-info">
                                        <div className="col-sm-12">
                                            {props.detailsResult?.dataInizioRapporto &&
                                                <>
                                                    <dt className='col-sm-12'>Data inizio rapporto</dt>
                                                    <dd className='col-sm-12'>{props.detailsResult?.dataInizioRapporto}</dd>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </dl>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CessazioneDipendenteLightDetails
import { useEffect, useState } from 'react'
// import { CustomSelectItem } from '../custom-select/CustomSelect';
import { Button, Callout, CalloutTitle, Input } from 'design-react-kit'
import { t } from 'i18next'
import { CurriculumVitaeRequestDtoSezioneEnum, InformazioniPersonaliRequestDto } from '../../services/ms-anagrafica-unica';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { creaNuovoCv, getCvDetail/*, getGestioneCvOpts*/ } from '../../store/curriculumVitaeSlice';
import { z } from 'zod';
import { FormikProps, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { detailsDipendente } from '../../store/rubricaSlice';
import { STATUS_FULLFILLED } from '../../store/store-constants';

export interface InfoPersonaliProps {
    disabled: boolean,
    continuaAction: Function
}

const InformazioniPersonaliTab = (props: InfoPersonaliProps) => {
    const dispatch = useAppDispatch();
    const { detailResult, detailStatus } = useAppSelector((state) => state.curriculumVitae);
    const { dipendenteResult, dipendenteStatus } = useAppSelector((state) => state.rubrica);
    const { userInfoResult } = useAppSelector((state) => state.user)
    const [alertDatiMancanti, setAlertDatiMancanti] = useState<boolean>(false);

    useEffect(() => {
        dispatch(detailsDipendente(Number(userInfoResult?.entityId!))).unwrap().then( (resp) => {
            if (!!resp) {
                resp.cvPresente && dispatch(getCvDetail(userInfoResult?.entityId!))  
            }
        })
    }, [userInfoResult]);

    useEffect(() => {

        const numeroEsiste = dipendenteResult?.assegnazioneNumeroTelefono?.numeroTelefono || detailResult?.dipendenteRegionale?.assegnazioneNumeroTelefono
        const emailEsiste = dipendenteResult?.email || detailResult?.dipendenteRegionale?.email

        if (!numeroEsiste || !emailEsiste) {
            setAlertDatiMancanti(true)
        }else {
            setAlertDatiMancanti(false)
        }
 
}, [detailResult, dipendenteResult])

    const form: FormikProps<InformazioniPersonaliRequestDto> = useFormik({
        initialValues: {},
        onSubmit: () => {
            dispatch(creaNuovoCv({
                sezione: CurriculumVitaeRequestDtoSezioneEnum.InformazioniPersonali
            })).then(resp => resp && props.continuaAction())
        }
    });

    return (
        <div>
            <Callout className='au-callout col-md-12 mb-5'>
                <CalloutTitle>Nota</CalloutTitle>
                Si invitano i dipendenti a porre particolare attenzione alla compilazione dei CV omettendo l'inserimento di dati eccedenti rispetto a quelli necessari per adempiere agli obblighi di legge quali, ad esempio, l’indirizzo di residenza, il numero di cellulare e gli indirizzi di posta elettronica privati oltre a categorie particolari di dati.
            </Callout>
            
            {alertDatiMancanti && (
                <Callout className='au-callout col-md-12 mb-5'>
                    <CalloutTitle>Attenzione</CalloutTitle>
                    Il numero di telefono e/o la mail regionale non risultano presenti nella base dati regionale. Se in possesso comunicarli all’indirizzo helpdesk.au@regione.puglia.it
                </Callout>
            )}
            {(dipendenteStatus === STATUS_FULLFILLED || detailStatus === STATUS_FULLFILLED) ? 
            <div>
                <div className="form-row">
                    <Input type="text"
                        label="Cognome/Nome"
                        id="cognomeNome"
                        placeholder="Cognome e nome"
                        wrapperClassName="col-md-12 mt-5 required"
                        name="cognomeNome"
                        value={dipendenteResult?.nomeCompleto}
                        disabled={true}
                    />
                </div>
                <div className="d-flex flex-row-reverse">
                    <Button
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                            form.submitForm();
                        }}
                    >
                        {t('continua')}
                    </Button>
                </div>
            </div> : <></>}
        </div>
    )
}

export default InformazioniPersonaliTab
/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OptionDtoStatoPropostaIncarico
 */
export interface OptionDtoStatoPropostaIncarico {
    /**
     * 
     * @type {string}
     * @memberof OptionDtoStatoPropostaIncarico
     */
    'value'?: OptionDtoStatoPropostaIncaricoValueEnum;
    /**
     * 
     * @type {string}
     * @memberof OptionDtoStatoPropostaIncarico
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof OptionDtoStatoPropostaIncarico
     */
    'valore': OptionDtoStatoPropostaIncaricoValoreEnum;
}

export const OptionDtoStatoPropostaIncaricoValueEnum = {
    InCompilazione: 'IN_COMPILAZIONE',
    Inserita: 'INSERITA',
    Rigettata: 'RIGETTATA',
    Accettata: 'ACCETTATA',
    Assegnata: 'ASSEGNATA',
    Approvata: 'APPROVATA',
    NonApprovata: 'NON_APPROVATA',
    Terminata: 'TERMINATA'
} as const;

export type OptionDtoStatoPropostaIncaricoValueEnum = typeof OptionDtoStatoPropostaIncaricoValueEnum[keyof typeof OptionDtoStatoPropostaIncaricoValueEnum];
export const OptionDtoStatoPropostaIncaricoValoreEnum = {
    InCompilazione: 'IN_COMPILAZIONE',
    Inserita: 'INSERITA',
    Rigettata: 'RIGETTATA',
    Accettata: 'ACCETTATA',
    Assegnata: 'ASSEGNATA',
    Approvata: 'APPROVATA',
    NonApprovata: 'NON_APPROVATA',
    Terminata: 'TERMINATA'
} as const;

export type OptionDtoStatoPropostaIncaricoValoreEnum = typeof OptionDtoStatoPropostaIncaricoValoreEnum[keyof typeof OptionDtoStatoPropostaIncaricoValoreEnum];



export const CODICE_FISCALE = 'Codice fiscale';


// TAB HEADERS
export const DATI_IDENTIFICATIVI = "Dati Identificativi";
export const INFORMAZIONI_ATTIVITA = "Informazioni Attività";
export const SEDI = "Sedi";
export const PERSONE = "Persone";
export const SOCI = "Elenco Soci";
export const LICENZE = "Albi ruoli licenze ridotti";
export const INFORMAZIONI_STATUTO = "Informazioni statuto";
export const AMMINISTRAZIONE_CONTROLLO = "Amministrazione controllo";
export const INFORMAZIONI_PATRIMONIALI = "Informazioni patrimoniali";


import { Alert, Button, Col, FormGroup, Input, Label, TextArea } from "design-react-kit";
import { FormikProps, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CustomSelect, {
  CustomSelectItem,
} from "../../components/custom-select/CustomSelect";
import DocumentUpload, { DocumentUploadRef } from "../../components/document-upload/DocumentUpload";
import PageLoader from "../../components/page-loader/PageLoader";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { detailsStruttureOrganizzative, formSelectPopulateCreate, insertStruttureOrganizzative, resetInputForm, resetSelectSezioni, resetUpdateForm, selectCreateSOSezioni, updateSoCompleto } from "../../store/strutturaOrganizzativaSlice";
import { SOInsertRequestDto } from "../../services/ms-anagrafica-unica";
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from "../../components/sidemenu/sidemenuConstants";
import { useNavigate, useParams } from "react-router-dom";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import NotificationManagerComponent from "../../components/notificationComponent/NotificationManagerComponent";
import PageHeader from "../../components/page-header/PageHeader";


const initialValues: SOInsertRequestDto = {
  denominazione: '',
  declaratoria: '',
  dataInizioValidita: '',
  tipo: undefined,
  dipartimento: undefined,
  sezione: '',
  email: '',
  pec: '',
  telefono: '',
  fax: '',
  indirizzo: '',
  citta: '',
  tipoRichiesta: undefined,
  stato: undefined,
  idFile: undefined,
  equiparata: false,
};

const schemaConfirm = z
  .object({
    denominazione: z.string({ required_error: 'required' }).trim().min(1).max(50, 'lenghtdenominazione'),
    declaratoria: z.string({ required_error: 'required' }).trim().min(1).max(5000, 'lenghtdeclaratoria'),
    dataInizioValidita: z.coerce.date(),
    tipo: z.string({ required_error: 'required' }),
    dipartimento: z.string().optional(),
    sezione: z.string().optional(),
    email: z.string().trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec: z.string().trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    telefono: z.string().regex(new RegExp(/[0-9]/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    fax: z.string().regex(new RegExp(/[0-9]/), 'numberFax').trim().min(1).max(20, 'lenghtTelefono').optional(),
    indirizzo: z.string().trim().min(1).max(200, 'lenghtIndirizzo').optional(),
    citta: z.string().trim().min(1).max(100, 'lenghtCitta').optional(),
    idFile: z.number({ required_error: 'required' }),
    equiparata: z.boolean().optional(),
  })
  .refine(
    (data) => {
      // obbligatorio se tipoStruttura è SEZIONE o SERVIZIO
      // ritorno false se è vuoto e se tipoStruttura è SEZIONE o SERVIZIO
      // ma la validazione scatta solo DOPO che sono ok le validazioni normali ???
      if (data.tipo === "SEZIONE" || data.tipo === "SERVIZIO") {
        if (!data.dipartimento) {
          return false;
        }
      }
      return true;
    },
    {
      message: "required",
      path: ["dipartimento"], // path of error
    }
  );

const schemaSave = z
  .object({
    denominazione: z.string().max(50, 'lenghtdenominazione').optional(),
    declaratoria: z.string().max(5000, 'lenghtdeclaratoria').optional(),
    dataInizioValidita: z.coerce.date().optional(),
    tipo: z.string({ required_error: 'required' }),
    dipartimento: z.string().optional(),
    sezione: z.string().optional(),
    email: z.string().trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec: z.string().trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    telefono: z.string().regex(new RegExp(/[0-9]/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    fax: z.string().regex(new RegExp(/[0-9]/), 'numberFax').trim().min(1).max(20, 'lenghtTelefono').optional(),
    indirizzo: z.string().trim().min(1).max(200, 'lenghtIndirizzo').optional(),
    citta: z.string().trim().min(1).max(100, 'lenghtCitta').optional(),
    idFile: z.number().optional(),
    equiparata: z.boolean().optional(),
  })

function NuovaStrutturaOrganizzativa() {

  const { t } = useTranslation();
  const formResult = useAppSelector((state) => state.strutturaOrganizzativa.selectCreateFormResult)
  const formStatus = useAppSelector((state) => state.strutturaOrganizzativa.selectCreateFormStatus)
  const { id } = useParams();
  const [visualizzaDocumento, setVisualizzazioneDocumento] = useState<boolean | null>(null)
  const [button, setButtonSubmit] = useState('')
  const navigate = useNavigate();
  const validationSchema = button === "REGISTRATA" ? toFormikValidationSchema(schemaConfirm) : toFormikValidationSchema(schemaSave);
  const [tipiStruttura, setTipiStruttura] = useState<CustomSelectItem[]>([]);
  const [dipartimentoOptions, setDipartimentoStruttura] = useState<CustomSelectItem[]>([]);
  const [sezioneOptions, setSezioneStruttura] = useState<CustomSelectItem[]>([]);
  const dispatch = useAppDispatch();
  const ref = useRef<DocumentUploadRef>(null);
  const insertStatus = useAppSelector((state) => state.strutturaOrganizzativa.inputStatus);
  const insertResult = useAppSelector((state) => state.strutturaOrganizzativa.inputResult);
  const updateSOCensita = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult);
  const sezioniCreaStrutturaOrganizzativaStatus = useAppSelector ((state)=> state.strutturaOrganizzativa.sezioniCreaStrutturaOrganizzativaStatus);
  const sezioniCreaStrutturaOrganizzativaResult = useAppSelector ((state)=> state.strutturaOrganizzativa.sezioniCreaStrutturaOrganizzativaResult);

  const handleIdChange = (id: number | undefined) => {
    if (id && id != -1) {
      form.setFieldValue('idFile', id);
    } else {
      form.setFieldValue('idFile', undefined);
    }
  }


  useEffect(() => {
    dispatch(formSelectPopulateCreate());
    dispatch(resetUpdateForm())
  }, [0])

  useEffect(() => {
    let customSelectArrayTipo: CustomSelectItem[] = [];
    formResult?.tipo?.map((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label != undefined ? value.label : '',
        value: value.valore != undefined ? value.valore : ''
      };
      customSelectArrayTipo.push(customSelect);
    });
    setTipiStruttura(customSelectArrayTipo)

    let customSelectArrayDipartimento: CustomSelectItem[] = [];
    formResult?.dipartimento?.map((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label != undefined ? value.label : '',
        value: value.valore != undefined ? value.valore : ''
      };
      customSelectArrayDipartimento.push(customSelect);
    });
    setDipartimentoStruttura(customSelectArrayDipartimento)

  }, [formResult]);

  useEffect(() => {
    if(id != null && id!= undefined){
      getDetailsFromApi(id!)
    }
  }, [id])

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  }

  useEffect(() => {
    let soDipa, soSez;
    if (updateSOCensita?.tipo === "DIPARTIMENTO" || updateSOCensita?.tipo === "SEZIONE") {
      setVisualizzazioneDocumento(true);
    }
    if(updateSOCensita?.strutturaOrganizzativaPadre) {
      const soLiv1 = updateSOCensita?.strutturaOrganizzativaPadre;

      switch (soLiv1.tipo) {
          case 'DIPARTIMENTO':
              soDipa = soLiv1;
              break;
          case 'SEZIONE':
              soSez = soLiv1;
              break
          default:
              break;
      }

      if(soLiv1.strutturaOrganizzativaPadre) {
          const soLiv2 = soLiv1.strutturaOrganizzativaPadre;
          switch (soLiv2.tipo) {
              case 'DIPARTIMENTO':
                  soDipa = soLiv2;
                  break;
              case 'SEZIONE':
                  soSez = soLiv2;
                  break
              default:
                  break;
          }
      }
  }

    form.setValues({
      codiceInca: updateSOCensita?.id || '',
      denominazione: updateSOCensita?.descrizioneBreve || '',
      declaratoria: updateSOCensita?.descrizione || '',
      dataInizioValidita: updateSOCensita?.dataInizioValidita || '',
      tipo: updateSOCensita?.tipo || undefined,
      dipartimento: soDipa?.id || undefined,
      sezione: soSez?.id || undefined,
      email: updateSOCensita?.email || '',
      pec: updateSOCensita?.pec || '',
      telefono: updateSOCensita?.telefono || '',
      fax: updateSOCensita?.fax || '',
      indirizzo: updateSOCensita?.indirizzo || '',
      citta: updateSOCensita?.citta || '',
      idFile: updateSOCensita?.documenti != null && updateSOCensita?.documenti.length > 0
      ? updateSOCensita?.documenti[0].id?.documento?.id
      : undefined,
    })

  }, [updateSOCensita])

  // per testare i diversi comportamenti del profilo dell'utente loggato
  // R5 segreteria
  // R11 personale
  const [profiloUtente] = useState<{
    ruolo: "R5" | "R11";
  }>({
    ruolo: "R5",
  });

  /*  useEffect(() => {
      if (profiloUtente.ruolo === "R5") {
        setTipiStruttura(tipiStrutturaSegreteria);
      } else if (profiloUtente.ruolo === "R11") {
        setTipiStruttura(tipiStrutturaPersonale);
      }
    }, [profiloUtente]);*/

  const form: FormikProps<SOInsertRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Submit form", JSON.stringify(values, null, 2));
      doInsert();
    },
  });

  useEffect(() => {
    let soDipa, soSez;
    if (insertResult != undefined && insertResult != null) {
      console.log(insertResult)
      if (insertResult.tipo === "DIPARTIMENTO" || insertResult.tipo === "SEZIONE") {
        setVisualizzazioneDocumento(true);
      }
      if(insertResult?.strutturaOrganizzativaPadre) {
        const soLiv1 = insertResult?.strutturaOrganizzativaPadre;
  
        switch (soLiv1.tipo) {
            case 'DIPARTIMENTO':
                soDipa = soLiv1;
                break;
            case 'SEZIONE':
                soSez = soLiv1;
                break
            default:
                break;
        }
  
        if(soLiv1.strutturaOrganizzativaPadre) {
            const soLiv2 = soLiv1.strutturaOrganizzativaPadre;
            switch (soLiv2.tipo) {
                case 'DIPARTIMENTO':
                    soDipa = soLiv2;
                    break;
                case 'SEZIONE':
                    soSez = soLiv2;
                    break
                default:
                    break;
            }
        }
    }
  
      form.setValues({
        codiceInca: insertResult?.id || '',
        denominazione: insertResult?.descrizioneBreve || '',
        declaratoria: insertResult?.descrizione || '',
        dataInizioValidita: insertResult?.dataInizioValidita || '',
        tipo: insertResult?.tipo || undefined,
        dipartimento: form.values.dipartimento,
        sezione: form.values.sezione|| undefined,
        email: insertResult?.email || '',
        pec: insertResult?.pec || '',
        telefono: insertResult?.telefono || '',
        fax: insertResult?.fax || '',
        indirizzo: insertResult?.indirizzo || '',
        citta: insertResult?.citta || '',
        idFile:  insertResult?.documenti != null && insertResult?.documenti.length > 0
        ? insertResult?.documenti[0].id?.documento?.id
        : undefined,
      })
    }
  }, [insertResult])

  const doInsert = () => {
    if(id != null && id != undefined){
    dispatch(updateSoCompleto(form.values));
    }else{
      dispatch(insertStruttureOrganizzative(form.values));
    }
   
  };

  const handleSelectChange = (e: any, name: string) => {
    console.log(e, name)
    if (e === "DIPARTIMENTO") {
      form.setFieldValue('dipartimento', undefined);
      form.setFieldValue('sezione', undefined);
    }
    if (e === "SEZIONE") {
      form.setFieldValue('sezione', undefined);
    }
    if (e === '') {
      form.setFieldValue('dipartimento', undefined);
      form.setFieldValue('sezione', undefined);
    }
    form.setFieldValue(name, e);
  };

  const handleSelectTipoStruttura = (e: any, name: string) => {
    if (e === "DIPARTIMENTO" || e === "SEZIONE") {
      setVisualizzazioneDocumento(true);
    } else if (e === "SERVIZIO") {
      setVisualizzazioneDocumento(false);
    } else {
      setVisualizzazioneDocumento(null);
    }
  }


  useEffect(() => {
    let customSelectArraySezione: CustomSelectItem[] = [];
    sezioniCreaStrutturaOrganizzativaResult?.map((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label != undefined ? value.label : '',
        value: value.valore != undefined ? value.valore : ''
      };
      customSelectArraySezione.push(customSelect);
    });
    setSezioneStruttura(customSelectArraySezione)
  }, [sezioniCreaStrutturaOrganizzativaResult])

  const populateSezioneSelect = (e: any, name: string) =>{
    if(e == ""){
        dispatch(resetSelectSezioni())
    }else {
      dispatch(selectCreateSOSezioni(e))
    }
  }

  const getFieldError = (
    form: FormikProps<SOInsertRequestDto>,
    fieldName: keyof SOInsertRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || "";
    } else return "";
  };

  const submitConfirm = () => {
    form.setFieldValue('stato', 'REGISTRATA');
    form.setFieldValue('tipoRichiesta', 'CONFIRM');
    setTimeout(() => {
      form.submitForm();
    }, 500);
  }

  const submitSave = () => {
    form.setFieldValue('stato', 'COMPILATA');
    form.setFieldValue('tipoRichiesta', 'SAVE');
    setTimeout(() => {
      form.submitForm();
    }, 500);
  }

  /* useEffect(() => {
     if (insertStatus === STATUS_FULLFILLED) {
       form.resetForm();
       handleIdChange(undefined)
       ref.current?.resetUploadFile();
     }
   }, [insertStatus])*/

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} title={`${id == null ? 'Nuova' : ''} Struttura Organizzativa`}></PageHeader>
      {formStatus === STATUS_FULLFILLED &&
        <div className="position-relative py-5">
          {false && <PageLoader />}


          <div className="form-row">
            <Input
              label="Denominazione"
              type="text"
              placeholder="Inserisci la denominazione"
              id="input-denominazione"
              wrapperClassName="form-group required col-md-12"
              name="denominazione"
              value={form.values.denominazione}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, "denominazione")}
              infoText={t(getFieldError(form, "denominazione")) || ""}

            />

            <TextArea
              label="Declaratoria"
              placeholder="Inserisci la declaratoria"
              id="input-declaratoria"
              wrapperClassName="form-group required col-md-12"
              name="declaratoria"
              value={form.values.declaratoria}
              onChange={form.handleChange}
              invalid={!!getFieldError(form, "declaratoria")}
              infoText={t(getFieldError(form, "declaratoria")) || ""}
            />

            <Input
              type="date"
              label="Data inizio validità"
              placeholder="Inserisci la data di inizio Validità"
              id="input-dataInizioValidita"
              name="dataInizioValidita"
              value={form.values.dataInizioValidita}
              onChange={form.handleChange}
              wrapperClassName="form-group required col-md-6"
              invalid={!!getFieldError(form, "dataInizioValidita")}
              infoText={t(getFieldError(form, "dataInizioValidita")) || ""}
            />

            <Col md="6">
              <CustomSelect
                label="Tipo struttura"
                placeholder="Seleziona il tipo della struttura"
                options={tipiStruttura}
                name="tipoStruttura"
                invalid={!!getFieldError(form, "tipo")}
                infoText={t(getFieldError(form, "tipo")) || ""}
                value={form.values.tipo}
                onChange={(e) => { handleSelectChange(e, "tipo"); handleSelectTipoStruttura(e, "tipo") }}
                wrapperClass="required"
                disabled={id !== undefined}
              />
            </Col>

            <Col md="6">
              <CustomSelect
                label="Dipartimento"
                name="dipartimento"
                disabled={
                  form.values.tipo !== "SEZIONE" &&
                  form.values.tipo !== "SERVIZIO"
                }
                options={dipartimentoOptions}
                invalid={!!getFieldError(form, "dipartimento")}
                infoText={t(getFieldError(form, "dipartimento")) || ""}
                value={form.values.dipartimento}
                onChange={(e) => {handleSelectChange(e, "dipartimento"); populateSezioneSelect(e, "dipartimento")}}
              />
            </Col>

            <Col md="6">
              <CustomSelect
                label="Sezione"
                name="sezione"
                disabled={form.values.tipo !== "SERVIZIO"}
                options={sezioneOptions}
                invalid={!!getFieldError(form, "sezione")}
                infoText={t(getFieldError(form, "sezione")) || ""}
                value={form.values.sezione}
                onChange={(e) => handleSelectChange(e, "sezione")}
              />
            </Col>

            <Input
              type="email"
              label="Email"
              placeholder="Inserisci email"
              id="input-email"
              name="email"
              value={form.values.email}
              onChange={form.handleChange}
              wrapperClassName="form-group col-md-6"
              invalid={!!getFieldError(form, "email")}
              infoText={t(getFieldError(form, "email")) || ""}
            />

            <Input
              type="email"
              label="PEC"
              placeholder="Inserisci PEC"
              id="input-pec"
              name="pec"
              value={form.values.pec}
              onChange={form.handleChange}
              wrapperClassName="form-group col-md-6"
              invalid={!!getFieldError(form, "pec")}
              infoText={t(getFieldError(form, "pec")) || ""}
            />

            <Input
              type="tel"
              label="Telefono"
              placeholder="Inserisci il numero di telefono"
              id="input-telefono"
              name="telefono"
              value={form.values.telefono}
              onChange={form.handleChange}
              wrapperClassName="form-group col-md-6"
              invalid={!!getFieldError(form, "telefono")}
              infoText={t(getFieldError(form, "telefono")) || ""}
            />

            <Input
              type="tel"
              label="Fax"
              placeholder="Inserisci il fax"
              id="input-fax"
              name="fax"
              value={form.values.fax}
              onChange={form.handleChange}
              wrapperClassName="form-group col-md-6"
              invalid={!!getFieldError(form, "fax")}
              infoText={t(getFieldError(form, "fax")) || ""}
            />

            <Input
              type="text"
              label="Indirizzo"
              placeholder="Inserisci indirizzo"
              id="input-indirizzo"
              name="indirizzo"
              value={form.values.indirizzo}
              onChange={form.handleChange}
              wrapperClassName="form-group col-md-6"
              invalid={!!getFieldError(form, "indirizzo")}
              infoText={t(getFieldError(form, "indirizzo")) || ""}
            />

            <Input
              type="text"
              label="Città"
              placeholder="Inserisci città"
              id="input-citta"
              name="citta"
              value={form.values.citta}
              onChange={form.handleChange}
              wrapperClassName="form-group col-md-6"
              invalid={!!getFieldError(form, "citta")}
              infoText={t(getFieldError(form, "citta")) || ""}
            />


            <FormGroup check className="form-group col-md-6">
              <Input id='strutturaEquiparata' name="equiparata" onChange={form.handleChange} checked={form.values.equiparata} type='checkbox' />
              <Label for='strutturaEquiparata' check>
                Struttura equiparata
              </Label>
            </FormGroup>

          </div>
          {visualizzaDocumento && visualizzaDocumento != null &&
            <DocumentUpload ref={ref} idDocumento={form.values.idFile} invalid={!!form.errors.idFile} infoText={t(getFieldError(form, "idFile")) || ""} setDocumentId={handleIdChange} documentName="Provvedimento di Giunta" className="my-3 required" />
          }
          {!visualizzaDocumento && visualizzaDocumento != null &&
            <DocumentUpload ref={ref} idDocumento={form.values.idFile} invalid={!!form.errors.idFile} infoText={t(getFieldError(form, "idFile")) || ""} setDocumentId={handleIdChange} documentName="Determina di Giunta" className="my-3 required" />
          }
          <div className="d-flex flex-row-reverse">
            <Button color='primary' className="mt-2" onClick={() => { submitConfirm(); setButtonSubmit('REGISTRATA') }}> {t('conferma')}</Button>
            <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSave(); setButtonSubmit('COMPILATA') }}>{t('salva_bozza')}</Button>
            <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`); dispatch(resetInputForm()) }} >{t('annulla')} </Button>
          </div>
        </div>}
    </div>
  );
}

export default NuovaStrutturaOrganizzativa;

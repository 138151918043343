import { DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Table, UncontrolledDropdown } from "design-react-kit";
import { AssegnazioneBandoPoRequestDto, PagedDtoPosizioneOrganizzativaDto, PosizioneOrganizzativaDto } from "../../../services/ms-anagrafica-unica";
import { useState } from "react";
import Pagination from "../../pager/Pagination";
import { formatDate } from "../../../utility/formatDate";

interface TableResultProps {
    ricercaEqStrutturaOrganizzativa: Function,
    content: PagedDtoPosizioneOrganizzativaDto,
    selezioneEqPerInserimento: Function,
    EQInseriteABando: Array<AssegnazioneBandoPoRequestDto>
    idSo: string | undefined
}

interface SortingState {
  direction: Direction;
}

type Direction = "ASC" | "DESC";

const TableResult = (props: TableResultProps) => {

    console.log('EQInseriteABando >>> ', props.EQInseriteABando);
    
    const [sorting, setSorting] = useState<SortingState>({ direction: "DESC" });

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        props.ricercaEqStrutturaOrganizzativa({
            sort: columnName,
            direction: sorting.direction
        })
    };

    const handlePageChange = (pageNum: number) => {
        props.ricercaEqStrutturaOrganizzativa({
            pageNum: pageNum - 1,
            idSo: props.idSo
        }); 
    };

    const selezionaEqDaInserireABando = (eq: PosizioneOrganizzativaDto) => {
        props.selezioneEqPerInserimento(eq)
    }

    const showInserisciAction = (eq: PosizioneOrganizzativaDto) => 
        !props.EQInseriteABando.some( eqInserita => eqInserita.idPosizioneOrganizzativa === eq.codiceUnivoco)

    return (
        <div className={props.content.totalPages === 1 ? 'mb-5' : ''}>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th scope="col" onClick={() => ordinamento("")}>
                            <div className="table-order">
                                Denominazione
                                <div className="icon-direction">
                                    <Icon icon="it-collapse" size="xs" />
                                    <Icon icon="it-expand" size="xs" />
                                </div>
                            </div>
                        </th>
                        <th scope="col" onClick={() => ordinamento("")}>
                            <div className="table-order">
                                Struttura Organizzativa
                                <div className="icon-direction">
                                    <Icon icon="it-collapse" size="xs" />
                                    <Icon icon="it-expand" size="xs" />
                                </div>
                            </div>
                        </th>
                        <th scope="col" onClick={() => ordinamento("")}>
                            <div className="table-order">
                                Tipologia EQ
                                <div className="icon-direction">
                                    <Icon icon="it-collapse" size="xs" />
                                    <Icon icon="it-expand" size="xs" />
                                </div>
                            </div>
                        </th>
                        <th scope="col" onClick={() => ordinamento("")}>
                            <div className="table-order">
                                Associato
                                <div className="icon-direction">
                                    <Icon icon="it-collapse" size="xs" />
                                    <Icon icon="it-expand" size="xs" />
                                </div>
                            </div>
                        </th>
                        <th scope="col" onClick={() => ordinamento("")}>
                            <div className="table-order">
                                Data fine associazione
                                <div className="icon-direction">
                                    <Icon icon="it-collapse" size="xs" />
                                    <Icon icon="it-expand" size="xs" />
                                </div>
                            </div>
                        </th>
                        <th scope="col">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {(props.content.data || []).map((eq, index) => (
                        <tr key={index}>
                            <td>{eq.denominazione}</td>
                            <td>{eq.nomeStrutturaOrganizzativa}</td>
                            <td>{eq.tipoLabel || "-"}</td>
                            <td>{eq.dipendenteRegionale}</td>
                            <td>{formatDate(eq.dataFineAssociazione!, true)}</td>
                            <td>
                                {showInserisciAction(eq) && (
                                    <UncontrolledDropdown direction="left">
                                        <DropdownToggle nav>
                                            <Icon icon="it-more-actions" />
                                        </DropdownToggle>
                                        <DropdownMenu className="no-arrow">
                                            <LinkList>
                                                
                                                    <LinkListItem
                                                        size="medium"
                                                        onClick={() => selezionaEqDaInserireABando(eq)}
                                                    >
                                                        <span>Inserisci nel bando</span>
                                                    </LinkListItem>
                                                
                                            </LinkList>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination
                totalCount={props.content.totalElements || 0}
                siblingCount={1}
                currentPage={props.content.pageNum ? props.content.pageNum + 1 : 1}
                pageSize={props.content.pageSize || 10}
                onPageChange={(page: number) => {
                    handlePageChange(page);
                }}
                className="justify-content-center"
            />
        </div>
    )
}

export default TableResult;
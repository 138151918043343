import { useNavigate, useParams } from "react-router-dom";
import { RichiestaAbilitazioneDtoStatoEnum, RuoloUtenteAutenticatoDto } from "../../services/ms-anagrafica-unica";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect } from "react";
import { getDettaglioRichiesta } from "../../store/funzionalitaApplicativeSlice";
import PageHeader from "../../components/page-header/PageHeader";
import { HREF_ABILITAZIONI_ELENCO_RICHIESTE } from "../../components/sidemenu/sidemenuConstants";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import PageLoader from "../../components/page-loader/PageLoader";
import NotificationManagerComponent from "../../components/notificationComponent/NotificationManagerComponent";
import AbilitazioneDetailsComponent from "../../components/abilitazione-detail-component/AbilitazioneDetailsComponent";
import { Button, Input, Table } from "design-react-kit";

const DettaglioRichiestaAbilitazione = (props:{operations: RuoloUtenteAutenticatoDto}) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { dettaglioRichiestaResult, dettaglioRichiestaStatus, valutazioneRichiestaAttivazioneStatus } = useAppSelector((state) => state.abilitazioni)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getDettaglioRichiesta(Number(id)))
    }, []);

    return (
        <div>
            <PageHeader showIcon={true}  urlBack={HREF_ABILITAZIONI_ELENCO_RICHIESTE} title={t("Dettaglio richiesta")!}/>
        {(dettaglioRichiestaStatus === STATUS_PENDING || valutazioneRichiestaAttivazioneStatus === STATUS_PENDING) && (
            <PageLoader loadingMessage="Ricerca in corso.." />
        )}
        {(dettaglioRichiestaStatus === STATUS_REJECTED  || valutazioneRichiestaAttivazioneStatus === STATUS_REJECTED) &&
            <NotificationManagerComponent state='error' title='Errore'
                                            content='Siamo spiacenti, si &egrave; verificato un errore durante il salvataggio'/>
        }
        {dettaglioRichiestaStatus === STATUS_FULLFILLED && !!dettaglioRichiestaResult && (
            <div className="mt-5 position-relative">
                <AbilitazioneDetailsComponent abilitazione={dettaglioRichiestaResult} except={[]}/>
                <div className="form-row mt-5">
                    <Table md={12}>
                        <thead>
                            <tr>
                                <th>Funzionalità</th>
                                <th>Attiva</th>
                                <th>Note Dirigente</th>
                            </tr>
                        </thead>
                        <tbody>
                        {dettaglioRichiestaResult.elencoFunzionalitaRichieste && dettaglioRichiestaResult.elencoFunzionalitaRichieste.map( (funz, key) => (
                            <tr>
                                <td>{funz.funzionalita?.descrizione}</td>
                                <td>{funz.tipoAbilitazione}</td>
                                <td>{funz.noteDirigente || '-'}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <Input type="text"
                    label="Note Gestore"
                    id="noteGestore"
                    wrapperClassName="col-md-12 mt-5"
                    name="noteGestore"
                    value={dettaglioRichiestaResult.noteGestore || ' '}
                    readOnly={true}
                    />
                </div>
                <div className="d-flex flex-row-reverse">
                    <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ABILITAZIONI_ELENCO_RICHIESTE}`) }} >{t('annulla')} </Button>
                </div>
            </div>
        )}
        </div>
    )
}

export default DettaglioRichiestaAbilitazione;
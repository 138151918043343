/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DipendenteRegionaleRubricaDto } from '../model';
// @ts-ignore
import { GenerazioneEmailDto } from '../model';
// @ts-ignore
import { PagedDtoDipendenteRegionaleRubricaDto } from '../model';
// @ts-ignore
import { PatchDatiRubricaDipendenteRequest } from '../model';
/**
 * RubricaControllerApi - axios parameter creator
 * @export
 */
export const RubricaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per la ricerca del dettaglio di un dipendente regionali in rubrica.
         * @summary Dettaglio di un dipendente regionale in rubrica
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioDipendenteInRubrica: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dettaglioDipendenteInRubrica', 'id', id)
            const localVarPath = `/v1/rubrica/dipendenti/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per generare un\'email suggerita per un dipendente
         * @summary Generazione email suggerita per un dipendente regionale in rubrica
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailGenerata: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailGenerata', 'id', id)
            const localVarPath = `/v1/rubrica/dipendenti/{id}/generaEmail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per l\'inserimento del numero di telefono e/o le credenziali di dominio ad un dipendente
         * @summary Patch su un dipendente regionale in rubrica
         * @param {number} id 
         * @param {PatchDatiRubricaDipendenteRequest} patchDatiRubricaDipendenteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDatiRubricaDipendente: async (id: number, patchDatiRubricaDipendenteRequest: PatchDatiRubricaDipendenteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchDatiRubricaDipendente', 'id', id)
            // verify required parameter 'patchDatiRubricaDipendenteRequest' is not null or undefined
            assertParamExists('patchDatiRubricaDipendente', 'patchDatiRubricaDipendenteRequest', patchDatiRubricaDipendenteRequest)
            const localVarPath = `/v1/rubrica/dipendenti/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchDatiRubricaDipendenteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per la ricerca dei dipendenti regionali censiti.
         * @summary Ricerca dipendenti regionali
         * @param {number} [id] 
         * @param {string} [cognome] 
         * @param {string} [nome] 
         * @param {string} [strutturaOrganizzativa] 
         * @param {string} [idTipoIncarico] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipoStrutturaOrganizzativa] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDipendentiInRubrica: async (id?: number, cognome?: string, nome?: string, strutturaOrganizzativa?: string, idTipoIncarico?: string, tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/rubrica/dipendenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (cognome !== undefined) {
                localVarQueryParameter['cognome'] = cognome;
            }

            if (nome !== undefined) {
                localVarQueryParameter['nome'] = nome;
            }

            if (strutturaOrganizzativa !== undefined) {
                localVarQueryParameter['strutturaOrganizzativa'] = strutturaOrganizzativa;
            }

            if (idTipoIncarico !== undefined) {
                localVarQueryParameter['idTipoIncarico'] = idTipoIncarico;
            }

            if (tipoStrutturaOrganizzativa !== undefined) {
                localVarQueryParameter['tipoStrutturaOrganizzativa'] = tipoStrutturaOrganizzativa;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RubricaControllerApi - functional programming interface
 * @export
 */
export const RubricaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RubricaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per la ricerca del dettaglio di un dipendente regionali in rubrica.
         * @summary Dettaglio di un dipendente regionale in rubrica
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dettaglioDipendenteInRubrica(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DipendenteRegionaleRubricaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dettaglioDipendenteInRubrica(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per generare un\'email suggerita per un dipendente
         * @summary Generazione email suggerita per un dipendente regionale in rubrica
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailGenerata(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerazioneEmailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailGenerata(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per l\'inserimento del numero di telefono e/o le credenziali di dominio ad un dipendente
         * @summary Patch su un dipendente regionale in rubrica
         * @param {number} id 
         * @param {PatchDatiRubricaDipendenteRequest} patchDatiRubricaDipendenteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDatiRubricaDipendente(id: number, patchDatiRubricaDipendenteRequest: PatchDatiRubricaDipendenteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DipendenteRegionaleRubricaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDatiRubricaDipendente(id, patchDatiRubricaDipendenteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per la ricerca dei dipendenti regionali censiti.
         * @summary Ricerca dipendenti regionali
         * @param {number} [id] 
         * @param {string} [cognome] 
         * @param {string} [nome] 
         * @param {string} [strutturaOrganizzativa] 
         * @param {string} [idTipoIncarico] 
         * @param {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'} [tipoStrutturaOrganizzativa] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {'ASC' | 'DESC'} [direction] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDipendentiInRubrica(id?: number, cognome?: string, nome?: string, strutturaOrganizzativa?: string, idTipoIncarico?: string, tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO', pageNum?: number, pageSize?: number, sort?: string, direction?: 'ASC' | 'DESC', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDtoDipendenteRegionaleRubricaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDipendentiInRubrica(id, cognome, nome, strutturaOrganizzativa, idTipoIncarico, tipoStrutturaOrganizzativa, pageNum, pageSize, sort, direction, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RubricaControllerApi - factory interface
 * @export
 */
export const RubricaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RubricaControllerApiFp(configuration)
    return {
        /**
         * API Rest per la ricerca del dettaglio di un dipendente regionali in rubrica.
         * @summary Dettaglio di un dipendente regionale in rubrica
         * @param {RubricaControllerApiDettaglioDipendenteInRubricaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettaglioDipendenteInRubrica(requestParameters: RubricaControllerApiDettaglioDipendenteInRubricaRequest, options?: AxiosRequestConfig): AxiosPromise<DipendenteRegionaleRubricaDto> {
            return localVarFp.dettaglioDipendenteInRubrica(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per generare un\'email suggerita per un dipendente
         * @summary Generazione email suggerita per un dipendente regionale in rubrica
         * @param {RubricaControllerApiGetEmailGenerataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailGenerata(requestParameters: RubricaControllerApiGetEmailGenerataRequest, options?: AxiosRequestConfig): AxiosPromise<GenerazioneEmailDto> {
            return localVarFp.getEmailGenerata(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per l\'inserimento del numero di telefono e/o le credenziali di dominio ad un dipendente
         * @summary Patch su un dipendente regionale in rubrica
         * @param {RubricaControllerApiPatchDatiRubricaDipendenteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDatiRubricaDipendente(requestParameters: RubricaControllerApiPatchDatiRubricaDipendenteRequest, options?: AxiosRequestConfig): AxiosPromise<DipendenteRegionaleRubricaDto> {
            return localVarFp.patchDatiRubricaDipendente(requestParameters.id, requestParameters.patchDatiRubricaDipendenteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per la ricerca dei dipendenti regionali censiti.
         * @summary Ricerca dipendenti regionali
         * @param {RubricaControllerApiSearchDipendentiInRubricaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDipendentiInRubrica(requestParameters: RubricaControllerApiSearchDipendentiInRubricaRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PagedDtoDipendenteRegionaleRubricaDto> {
            return localVarFp.searchDipendentiInRubrica(requestParameters.id, requestParameters.cognome, requestParameters.nome, requestParameters.strutturaOrganizzativa, requestParameters.idTipoIncarico, requestParameters.tipoStrutturaOrganizzativa, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dettaglioDipendenteInRubrica operation in RubricaControllerApi.
 * @export
 * @interface RubricaControllerApiDettaglioDipendenteInRubricaRequest
 */
export interface RubricaControllerApiDettaglioDipendenteInRubricaRequest {
    /**
     * 
     * @type {number}
     * @memberof RubricaControllerApiDettaglioDipendenteInRubrica
     */
    readonly id: number
}

/**
 * Request parameters for getEmailGenerata operation in RubricaControllerApi.
 * @export
 * @interface RubricaControllerApiGetEmailGenerataRequest
 */
export interface RubricaControllerApiGetEmailGenerataRequest {
    /**
     * 
     * @type {number}
     * @memberof RubricaControllerApiGetEmailGenerata
     */
    readonly id: number
}

/**
 * Request parameters for patchDatiRubricaDipendente operation in RubricaControllerApi.
 * @export
 * @interface RubricaControllerApiPatchDatiRubricaDipendenteRequest
 */
export interface RubricaControllerApiPatchDatiRubricaDipendenteRequest {
    /**
     * 
     * @type {number}
     * @memberof RubricaControllerApiPatchDatiRubricaDipendente
     */
    readonly id: number

    /**
     * 
     * @type {PatchDatiRubricaDipendenteRequest}
     * @memberof RubricaControllerApiPatchDatiRubricaDipendente
     */
    readonly patchDatiRubricaDipendenteRequest: PatchDatiRubricaDipendenteRequest
}

/**
 * Request parameters for searchDipendentiInRubrica operation in RubricaControllerApi.
 * @export
 * @interface RubricaControllerApiSearchDipendentiInRubricaRequest
 */
export interface RubricaControllerApiSearchDipendentiInRubricaRequest {
    /**
     * 
     * @type {number}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly cognome?: string

    /**
     * 
     * @type {string}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly nome?: string

    /**
     * 
     * @type {string}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly strutturaOrganizzativa?: string

    /**
     * 
     * @type {string}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly idTipoIncarico?: string

    /**
     * 
     * @type {'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly tipoStrutturaOrganizzativa?: 'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'

    /**
     * 
     * @type {number}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly pageNum?: number

    /**
     * 
     * @type {number}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly direction?: 'ASC' | 'DESC'

    /**
     * 
     * @type {string}
     * @memberof RubricaControllerApiSearchDipendentiInRubrica
     */
    readonly q?: string
}

/**
 * RubricaControllerApi - object-oriented interface
 * @export
 * @class RubricaControllerApi
 * @extends {BaseAPI}
 */
export class RubricaControllerApi extends BaseAPI {
    /**
     * API Rest per la ricerca del dettaglio di un dipendente regionali in rubrica.
     * @summary Dettaglio di un dipendente regionale in rubrica
     * @param {RubricaControllerApiDettaglioDipendenteInRubricaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubricaControllerApi
     */
    public dettaglioDipendenteInRubrica(requestParameters: RubricaControllerApiDettaglioDipendenteInRubricaRequest, options?: AxiosRequestConfig) {
        return RubricaControllerApiFp(this.configuration).dettaglioDipendenteInRubrica(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per generare un\'email suggerita per un dipendente
     * @summary Generazione email suggerita per un dipendente regionale in rubrica
     * @param {RubricaControllerApiGetEmailGenerataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubricaControllerApi
     */
    public getEmailGenerata(requestParameters: RubricaControllerApiGetEmailGenerataRequest, options?: AxiosRequestConfig) {
        return RubricaControllerApiFp(this.configuration).getEmailGenerata(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per l\'inserimento del numero di telefono e/o le credenziali di dominio ad un dipendente
     * @summary Patch su un dipendente regionale in rubrica
     * @param {RubricaControllerApiPatchDatiRubricaDipendenteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubricaControllerApi
     */
    public patchDatiRubricaDipendente(requestParameters: RubricaControllerApiPatchDatiRubricaDipendenteRequest, options?: AxiosRequestConfig) {
        return RubricaControllerApiFp(this.configuration).patchDatiRubricaDipendente(requestParameters.id, requestParameters.patchDatiRubricaDipendenteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per la ricerca dei dipendenti regionali censiti.
     * @summary Ricerca dipendenti regionali
     * @param {RubricaControllerApiSearchDipendentiInRubricaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubricaControllerApi
     */
    public searchDipendentiInRubrica(requestParameters: RubricaControllerApiSearchDipendentiInRubricaRequest = {}, options?: AxiosRequestConfig) {
        return RubricaControllerApiFp(this.configuration).searchDipendentiInRubrica(requestParameters.id, requestParameters.cognome, requestParameters.nome, requestParameters.strutturaOrganizzativa, requestParameters.idTipoIncarico, requestParameters.tipoStrutturaOrganizzativa, requestParameters.pageNum, requestParameters.pageSize, requestParameters.sort, requestParameters.direction, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}

import { AssegnazioneBandiPoDto } from "../../services/ms-anagrafica-unica";

const EqDetail = (props: {bando: AssegnazioneBandiPoDto}) => {
    const {bando} = props;
    return (
        <div className="col-sm-12">
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light info-box-light-bando">
                        <h6 className="mb-3">
                            Riepilogo bando
                        </h6>
                        <hr />
                        <div className="row">
                            <dl className="col-sm-12">
                                <div className="row">
                                    {bando.posizioneOrganizzativa?.codiceUnivoco && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Codice univoco</dt>
                                            <dd className='col-sm-12'>{bando.posizioneOrganizzativa?.codiceUnivoco}</dd>
                                        </div>
                                    )}
                                    {bando.posizioneOrganizzativa?.tipo && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Tipo di EQ</dt>
                                            <dd className='col-sm-12'>{bando.posizioneOrganizzativa?.tipoLabel}</dd>
                                        </div>
                                    )}
                                    {bando.tipologiaBandoPo && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Tipologia</dt>
                                            <dd className='col-sm-12'>{bando.tipologiaBandoPoLabel}</dd>
                                        </div>
                                    )}
                                    {bando.durataIncarico && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Durata (mesi)</dt>
                                            <dd className='col-sm-12'>{bando.durataIncarico}</dd>
                                        </div>
                                    )}
                                    {bando.sedi && bando.sedi.length > 0 && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Sedi</dt>
                                            {/* {bando.sedi.map( sede => (
                                                <dd className='col-sm-12'>{sede}</dd>
                                            ))} */}
                                            <dd className='col-sm-12'>{bando.sedi.join(', ')}</dd>
                                        </div>
                                    )}
                                    {bando.requisitiOggettivi && (
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>Requisiti oggettivi</dt>
                                            <dd className='col-sm-12'>{bando.requisitiOggettivi}</dd>
                                        </div>
                                    )}
                                    {bando.requisitiSoggettivi && (
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>Requisiti soggettivi</dt>
                                            <dd className='col-sm-12'>{bando.requisitiSoggettivi}</dd>
                                        </div>
                                    )}
                                    {bando.posizioneOrganizzativa?.denominazione && (
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>Denominazione EQ</dt>
                                            <dd className='col-sm-12'>
                                                {bando.posizioneOrganizzativa?.denominazione}<br/>
                                                {bando.posizioneOrganizzativa?.descrizione}
                                                </dd>
                                        </div>
                                    )}
                                    {bando.posizioneOrganizzativa?.declaratoriaFunzioni && (
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>Descrizione funzioni</dt>
                                            <dd className='col-sm-12'>{bando.posizioneOrganizzativa?.declaratoriaFunzioni}</dd>
                                        </div>
                                    )}
                                    {bando.codiceCifra && (
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>Codice Cifra</dt>
                                            <dd className='col-sm-12'>{bando.codiceCifra}</dd>
                                        </div>
                                    )}
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EqDetail;
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect, useState } from 'react';
import { detailsStrutturaOrganizzativa, loadDipartimenti, setDipartimentoSelezionato, reset, loadDipartimentiStorico, detailsStrutturaOrganizzativaStorico, setDataStorico} from '../../store/organigrammaSlice';
import CustomBreadcrumb from "../../components/custom-breadcrumb/CustomBreadcrumb";
import { DipendenteRegionaleDto, DipendenteRegionaleDtoCategoriaEnum, RuoloUtenteAutenticatoDto, StrutturaOrganizzativaDtoTipoEnum, StrutturaOrganizzativaOrganigrammaDto } from '../../services/ms-anagrafica-unica';
import OrganigrammaTree from '../../components/organigramma-tree/OrganigrammaTree';
import { useSearchParams } from "react-router-dom";
import { da, id, it } from 'date-fns/locale'
import './style.scss'
import { format } from 'date-fns';
import PageHeader from '../../components/page-header/PageHeader';
import { HREF_ORGANIGRAMMA } from '../../components/sidemenu/sidemenuConstants';

const DettaglioStrutturaOrganizzativa = () => {
    const { idStrutturaOrganizzativa } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const so = searchParams.get('so')
    const tipo = searchParams.get('tipo')

    const [scrollTop, setScrollTop] = useState(0);
    const [mostraTornaSu, setMostraTornaSu] = useState(false);
    const [mostraTitoloStorico, setMostraTitoloStorico] = useState('');

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { elencoDipartimentiResult, dipartimentoResult, breadcrumb, strutturaOrganizzativaAttiva, dipartimentoSelezionato, 
            dataStorico, organigrammaStorico, dipartimentoStoricoResult, elencoDipartimentiStoricoResult} = useAppSelector(
        (state) => state.organigramma
    );

    const formatDate = (date: string) => {
        const giorno = format(new Date(date), 'd');
        const mese = format(new Date(date), 'MMMM', { locale: it });
        const anno = format(new Date(date), 'yyyy');
        const meseFormattato = mese.charAt(0).toUpperCase() + mese.slice(1).toLowerCase()
        return `${giorno} ${meseFormattato} ${anno}`
    }

    const isoDateFineGiornata = () => {
        var data = new Date(dataStorico!);
        data.setUTCHours(23)
        data.setMinutes(59)
        data.setSeconds(59)
        setDataStorico(data)

        return data.toISOString()
    }

    const scrollToContent = () => {
        document && document.getElementById('dettaglio-struttura')?.scrollIntoView();
    }

    const apriDettaglioStrutturaOrganizzativa = (strutturaOrganizzativa: StrutturaOrganizzativaOrganigrammaDto) => {
        if(!organigrammaStorico) {
            if (strutturaOrganizzativa.id && strutturaOrganizzativa.tipo) {
                setSearchParams({
                    so: strutturaOrganizzativa.id,
                    tipo: strutturaOrganizzativa.tipo
                }, { replace: true });
                dispatch(detailsStrutturaOrganizzativa({
                    idStrutturaOrganizzativa: strutturaOrganizzativa.id,
                    setAttiva: true
                })).unwrap().then( resp => !!resp &&  scrollToContent())
            }       
        }
        else {
            if (strutturaOrganizzativa.id && strutturaOrganizzativa.tipo) {
                setSearchParams({
                    so: strutturaOrganizzativa.id,
                    tipo: strutturaOrganizzativa.tipo
                }, { replace: true });
                dispatch(detailsStrutturaOrganizzativaStorico({
                    idStrutturaOrganizzativa: strutturaOrganizzativa.id,
                    setAttiva: true,
                    dataInput: isoDateFineGiornata()
                })).unwrap().then( resp => !!resp &&  scrollToContent())
            }
        }

    }

    // useEffect(() => {
    //     if (idStrutturaOrganizzativa && !dipartimentoSelezionato) {
    //         dispatch(loadDipartimenti()).unwrap().then((resp) => {
    //             const dip = resp.data?.filter(d => d.id === idStrutturaOrganizzativa)[0]
    //             dip && dispatch(setDipartimentoSelezionato(dip))
    //         })
    //         dispatch(detailsStrutturaOrganizzativa({
    //             idStrutturaOrganizzativa: idStrutturaOrganizzativa,
    //             tipo: StrutturaOrganizzativaDtoTipoEnum.Dipartimento
    //         }))
    //             .unwrap()
    //             .then(() => {
    //                 so && tipo && tipo !== StrutturaOrganizzativaDtoTipoEnum.Dipartimento && dispatch(detailsStrutturaOrganizzativa({ idStrutturaOrganizzativa: so, tipo: tipo }))
    //             })
    //     }
    // }, [dispatch, so, tipo, idStrutturaOrganizzativa, dipartimentoSelezionato])

    // useEffect(() => {
    //     idStrutturaOrganizzativa && !so && !tipo && dispatch(detailsStrutturaOrganizzativa({
    //         idStrutturaOrganizzativa: idStrutturaOrganizzativa,
    //         tipo: StrutturaOrganizzativaDtoTipoEnum.Dipartimento
    //     }))
    // }, [dispatch, idStrutturaOrganizzativa, so, tipo])
    
    useEffect(() => {
        if(organigrammaStorico && dataStorico) {
            if (!!elencoDipartimentiStoricoResult) {
                dispatch(loadDipartimentiStorico(isoDateFineGiornata()))
            }
            dispatch(detailsStrutturaOrganizzativaStorico({
                idStrutturaOrganizzativa: idStrutturaOrganizzativa!,
                setAttiva: true,
                dataInput: isoDateFineGiornata()
            }))

             setMostraTitoloStorico(titoloStorico())
        }else{
            if (!!elencoDipartimentiResult) {
                dispatch(loadDipartimenti())
            }
           
            if(!dipartimentoResult || dipartimentoResult.id !== idStrutturaOrganizzativa){
                dispatch(detailsStrutturaOrganizzativa({
                    idStrutturaOrganizzativa: idStrutturaOrganizzativa!,
                    setAttiva: !!!so,
                }))      
            }
            if(!!so){
                dispatch(detailsStrutturaOrganizzativa({
                    idStrutturaOrganizzativa: so,
                    setAttiva: true,
                }))
            }
        }
    }, []);

    useEffect(() => {
        const handleScroll = (event:any) => {
          if(window.scrollY > 500) {
            setMostraTornaSu(true)
          } else {
            setMostraTornaSu(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const titoloStorico = () => {
      return organigrammaStorico && dataStorico? ' al ' + formatDate(dataStorico.toLocaleString()) : ''
    }

    // useEffect(() => {
    //     if(organigrammaStorico && dataStorico) {
    //         if (!!elencoDipartimentiStoricoResult) {
    //             dispatch(loadDipartimentiStorico(isoDateFineGiornata()))
    //         }
    //         dispatch(detailsStrutturaOrganizzativaStorico({
    //             idStrutturaOrganizzativa: idStrutturaOrganizzativa!,
    //             setAttiva: true,
    //             dataInput: isoDateFineGiornata()
    //          }))
    //     }
    //     setMostraTitoloStorico(titoloStorico())
    // }, [organigrammaStorico]);

    const verificaEsistenzaRisultati = () => {
        if(!organigrammaStorico) {
            return dipartimentoResult && Object.keys(dipartimentoResult).length > 0 
        }
        else {
            return dipartimentoStoricoResult && Object.keys(dipartimentoStoricoResult).length > 0 
        }
    }

    const scegliDipartimentoResult = () => {
        if(!organigrammaStorico) {
            return dipartimentoResult!
        }
        else {
            return dipartimentoStoricoResult!
        }
    }

    const decodificaTipoIncarico = (dip: DipendenteRegionaleDto) =>  {
        let idDecodifica;
        
        dip.elencoAssegnazioni?.forEach(ass=> {
            if (ass.idIncarico === strutturaOrganizzativaAttiva?.id)
                idDecodifica=ass.idDecodifica
        })

        return idDecodifica
    }

    const defineBack = () => {
        if(organigrammaStorico)
            return HREF_ORGANIGRAMMA
        else 
            return 'INDIETRO'
    }

    return (
        <>
            <div className="organigramma-struttura-organizzativa">
                {verificaEsistenzaRisultati() && strutturaOrganizzativaAttiva && strutturaOrganizzativaAttiva.id && idStrutturaOrganizzativa && (
                    <>
                        <div className="mb-5">
                        <PageHeader showIcon={true} urlBack={defineBack()} title={strutturaOrganizzativaAttiva.descrizioneBreve! + mostraTitoloStorico}></PageHeader>
                            <CustomBreadcrumb items={breadcrumb} openSo={apriDettaglioStrutturaOrganizzativa} />
                            <OrganigrammaTree
                                organigramma={scegliDipartimentoResult()}
                                active={strutturaOrganizzativaAttiva}
                                visualizzazione={tipo === StrutturaOrganizzativaDtoTipoEnum.Servizio}
                                openSo={apriDettaglioStrutturaOrganizzativa} />
                        </div>

                        <section className="mb-3" id="dettaglio-struttura">
                            <div id='info-so'>
                                <div className="row" id='label-so'>
                                    {strutturaOrganizzativaAttiva.descrizione && (
                                        <div className="col-sm-12">
                                            <dl className='row'>
                                                <dt className='col-md-3 col-sm-3'>{t('codice struttura')}</dt>
                                                <dd className='col-md-9 col-sm-9'>{strutturaOrganizzativaAttiva.id}</dd>
                                            </dl>
                                            {strutturaOrganizzativaAttiva.dataInizioValidita && (
                                                <dl className='row'>
                                                    <dt className='col-md-3 col-sm-3'>{t('data inizio validita')}</dt>
                                                    <dd className='col-md-9 col-sm-9'>{formatDate(strutturaOrganizzativaAttiva.dataInizioValidita)}</dd>
                                                </dl>
                                            )}
                                            {strutturaOrganizzativaAttiva.codiceIpa && (
                                                <dl className='row'>
                                                    <dt className='col-md-3 col-sm-3'>{t('Codice IPA')}</dt>
                                                    <dd className='col-md-9 col-sm-9'>{strutturaOrganizzativaAttiva.codiceIpa}</dd>
                                                </dl>
                                            )}
                                            {strutturaOrganizzativaAttiva.codiceDipartimentoRagioneria && (
                                                <dl className='row'>
                                                    <dt className='col-md-3 col-sm-3'>{t('Codice Bilancio Dipartimento')}</dt>
                                                    <dd className='col-md-9 col-sm-9'>{strutturaOrganizzativaAttiva.codiceDipartimentoRagioneria}</dd>
                                                </dl>
                                            )}
                                            {strutturaOrganizzativaAttiva.codiceSezioneRagioneria && (
                                                <dl className='row'>
                                                    <dt className='col-md-3 col-sm-3'>{t('Codice Bilancio Sezione')}</dt>
                                                    <dd className='col-md-9 col-sm-9'>{strutturaOrganizzativaAttiva.codiceSezioneRagioneria}</dd>
                                                </dl>
                                            )}
                                            {strutturaOrganizzativaAttiva.codiceUfficioDetermine && (
                                                <dl className='row'>
                                                    <dt className='col-md-3 col-sm-3'>{t('Codice CIFRA Determina')}</dt>
                                                    <dd className='col-md-9 col-sm-9'>{strutturaOrganizzativaAttiva.codiceUfficioDetermine}</dd>
                                                </dl>
                                            )}
                                            {strutturaOrganizzativaAttiva.codiceUfficioDelibere && (
                                                <dl className='row'>
                                                    <dt className='col-md-3 col-sm-3'>{t('Codice CIFRA Delibera')}</dt>
                                                    <dd className='col-md-9 col-sm-9'>{strutturaOrganizzativaAttiva.codiceUfficioDelibere}</dd>
                                                </dl>
                                            )}
                                            <div id='label-so-descrizione'> {strutturaOrganizzativaAttiva.descrizione}</div>
                                        </div>)}
                                </div>
                                {(strutturaOrganizzativaAttiva.email || strutturaOrganizzativaAttiva.pec || strutturaOrganizzativaAttiva.telefono || strutturaOrganizzativaAttiva.fax || strutturaOrganizzativaAttiva.indirizzo || strutturaOrganizzativaAttiva.citta || strutturaOrganizzativaAttiva.cap) && (
                                    <div className="col-sm-4" id='contatti'>
                                        <div className="info-box">
                                            <h6 className="mb-3">{t("contatti di struttura")}</h6>
                                            <dl className='row'>
                                                {!!strutturaOrganizzativaAttiva.email && (
                                                    <>
                                                        <dt className='col-sm-12'>{t("email")}</dt>
                                                        <dd className='col-sm-12'><a href={`mailto:${strutturaOrganizzativaAttiva.email}`}>{strutturaOrganizzativaAttiva.email}</a></dd>
                                                    </>
                                                )}
                                                {!!strutturaOrganizzativaAttiva.pec && (
                                                    <>
                                                        <dt className='col-sm-12'>{t("pec")}</dt>
                                                        <dd className='col-sm-12'><a href={`mailto:${strutturaOrganizzativaAttiva.pec}`}>{strutturaOrganizzativaAttiva.pec}</a></dd>
                                                    </>
                                                )}

                                                {!!strutturaOrganizzativaAttiva.telefono && (
                                                    <>
                                                        <dt className='col-sm-12'>{t("telefono")}</dt>
                                                        <dd className='col-sm-12'><a href={`tel:${strutturaOrganizzativaAttiva.telefono}`}>{strutturaOrganizzativaAttiva.telefono}</a></dd>
                                                    </>
                                                )}
                                                {!!strutturaOrganizzativaAttiva.fax && (
                                                    <>
                                                        <dt className='col-sm-12'>{t("fax")}</dt>
                                                        <dd className='col-sm-12'>{strutturaOrganizzativaAttiva.fax}</dd>
                                                    </>
                                                )}
                                                {!!strutturaOrganizzativaAttiva.indirizzo && (
                                                    <>
                                                        <dt className='col-sm-12'>{t("indirizzo")}</dt>
                                                        <dd className='col-sm-12'>{strutturaOrganizzativaAttiva.indirizzo}</dd>
                                                    </>
                                                )}
                                                {!!strutturaOrganizzativaAttiva.citta && !!strutturaOrganizzativaAttiva.cap && (
                                                    <>
                                                        <dt className='col-sm-12'>{t("citta")}</dt>
                                                        <dd className='col-sm-12'>{`${strutturaOrganizzativaAttiva.cap} ${strutturaOrganizzativaAttiva.citta}`}</dd>
                                                    </>
                                                )}
                                            </dl>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>
                        {strutturaOrganizzativaAttiva.dipendentiRegionali && strutturaOrganizzativaAttiva.dipendentiRegionali.length > 0 && (
                            <section>
                                <div className="row">
                                    <div className="col" id='elenco-dip'>
                                        <h3 className="mb-4">Elenco dipendenti</h3>
                                        {strutturaOrganizzativaAttiva.dipendentiRegionali.map((dipendente, i) => (
                                            <dl className='row' key={i}>
                                                <dt className='col-sm-2'>{t(`incaricoDipendente.${decodificaTipoIncarico(dipendente)}`)}</dt>
                                                <dd className='col-sm-10'>
                                                    <ul className='list-unstyled'>
                                                        {dipendente.categoria === DipendenteRegionaleDtoCategoriaEnum.Cd
                                                            || dipendente.categoria === DipendenteRegionaleDtoCategoriaEnum.Sz
                                                            || DipendenteRegionaleDtoCategoriaEnum.Sr ?
                                                            <li key={i}>{dipendente.cognome} {dipendente.nome}</li>
                                                            :
                                                            <li key={i}>{dipendente.cognome} {dipendente.nome} (Categoria {dipendente.categoria}</li>
                                                        }
                                                    </ul>
                                                </dd>
                                            </dl>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        )}
                        {strutturaOrganizzativaAttiva.posizioniOrganizzative && strutturaOrganizzativaAttiva.posizioniOrganizzative.length > 0 && (
                            <section className="posizioni-organizzative mb-5">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="mb-4">Elevate Qualificazioni</h3>
                                        {strutturaOrganizzativaAttiva.posizioniOrganizzative.map((posizione, i) => (
                                            <dl className='row' key={i}>
                                                <dt className='col-sm-5'>{posizione.descrizioneBreve}</dt>
                                                <dd className='col-sm-7'>{posizione.responsabile} {posizione.dipendenteRegionale}</dd>
                                            </dl>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        )}
                    </>
                )}
            </div>
            {mostraTornaSu && <div className='scroll-up' onClick={() => window.scrollTo(0,0)}>Torna su</div>}
        </>
    );
}

export default DettaglioStrutturaOrganizzativa;
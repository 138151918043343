/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { FeedbackNotificaDto } from './feedback-notifica-dto';
// May contain unused imports in some cases
// @ts-ignore
import { NotificaDocumentoDto } from './notifica-documento-dto';

/**
 * 
 * @export
 * @interface NotificaDto
 */
export interface NotificaDto {
    /**
     * 
     * @type {number}
     * @memberof NotificaDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificaDto
     */
    'oggetto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificaDto
     */
    'testo'?: string;
    /**
     * 
     * @type {FeedbackNotificaDto}
     * @memberof NotificaDto
     */
    'ultimoFeedback'?: FeedbackNotificaDto;
    /**
     * 
     * @type {string}
     * @memberof NotificaDto
     */
    'dataInserimento'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificaDto
     */
    'dataModifica'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificaDto
     */
    'dataCancellazione'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificaDto
     */
    'stato'?: NotificaDtoStatoEnum;
    /**
     * 
     * @type {Array<NotificaDocumentoDto>}
     * @memberof NotificaDto
     */
    'allegati'?: Array<NotificaDocumentoDto>;
    /**
     * 
     * @type {boolean}
     * @memberof NotificaDto
     */
    'feedbackInseribile'?: boolean;
}

export const NotificaDtoStatoEnum = {
    NonLetta: 'NON_LETTA',
    Letta: 'LETTA',
    Archiviata: 'ARCHIVIATA'
} as const;

export type NotificaDtoStatoEnum = typeof NotificaDtoStatoEnum[keyof typeof NotificaDtoStatoEnum];



import { Alert, Button, Col, Input, Row } from "design-react-kit";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { AssegnazioneBandoPoRequestDto, CreaAggiornaBandoPoRequestDto, DatiAttoDto } from "../../../services/ms-anagrafica-unica";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { t } from 'i18next';
import DettaglioAtto from "../../dettaglio-atto-cifra/DettaglioAtto";
import { showNotifica } from "../../../store/loaderSlice";
import { NOTIFICA_STATO } from "../../../utility/Notifica";
import { useAppDispatch } from "../../../hooks";
import { zodCifra } from "../../../utility/formUtils";

interface FormRicercaEQProps {
    creaBando: Function
    idSo: string,
    elencoPo: Array<AssegnazioneBandoPoRequestDto>,
    cifra?: string,
    dataApertura?: string,
    dataChiusura?: string
}

const FormDatiEQ = (props: FormRicercaEQProps) => {   
    const dispatch = useAppDispatch(); 
    const [tipoSalvataggio, setTipoSalvataggio] = useState<'SALVA' | 'CONFERMA' | undefined>(undefined);
    const [ datiAtto, setDatiAtto ] = useState<DatiAttoDto>({});
    const [ codiceCifra, setCodiceCifra ] = useState<string>('');

    const initialValues: CreaAggiornaBandoPoRequestDto = {
        codiceCifraDeliberaDetermina: undefined,
        dataApertura: undefined,
        dataChiusura: undefined,
        idSo: undefined,
        elencoPo: undefined,
        requestType: undefined
    }

    const insertSchemaSalva = z.object({
        codiceCifraDeliberaDetermina: zodCifra(z),
        dataApertura: z.coerce.date().optional(),
        dataChiusura: z.coerce.date().optional(),
        idSo: z.string().optional(),
        elencoPo: z.any().array().optional(),
    })

    const insertSchemaConferma = z.object({
        codiceCifraDeliberaDetermina: zodCifra(z, true),

        dataApertura: z.coerce.date({
            errorMap: (issue, { defaultError }) => ({
              message: issue.code === "invalid_date" ? "La data di apertura è un campo obbligatorio" : defaultError,
            }),
        }),
        dataChiusura: z.coerce.date({
            errorMap: (issue, { defaultError }) => ({
              message: issue.code === "invalid_date" ? "La data di chiusura è un campo obbligatorio" : defaultError,
            }),
        }),
        idSo: z.string({required_error: 'La struttura organizzativa è un campo obbligatorio'}),
        elencoPo: z.object({
            idPosizioneOrganizzativa: z.string()
        }).array().min(1, {message: 'Per la pubblicazione del bando è necessario assegnare almeno una EQ'}),
    }).superRefine((data, context) => {
        if (data.dataChiusura <= data.dataApertura) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "La data di chiusura del bando deve essere maggiore della data di apertura",
                path: ["dataChiusura"]
            });
        }
    })

    let validationSchema = tipoSalvataggio && tipoSalvataggio === 'CONFERMA' ? toFormikValidationSchema(insertSchemaConferma) : toFormikValidationSchema(insertSchemaSalva)

    const insertForm = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(!datiAtto.oggetto && tipoSalvataggio === 'CONFERMA'){
                dispatch(showNotifica({
                    titolo: 'ATTENZIONE',
                    messaggio: 'Non è possibile proseguire: atto non presente. Caricare un atto valido!',
                    tipo: NOTIFICA_STATO.warning
                }))
            }else {
                const request:CreaAggiornaBandoPoRequestDto = {
                    ...values,
                    requestType: tipoSalvataggio,
                    datiAtto: {...datiAtto, codiceAtto: values.codiceCifraDeliberaDetermina}
                }
                props.creaBando(request)
            }
        },
    });

    useEffect( () => {
        insertForm.setFieldValue('idSo', props.idSo)
        insertForm.setFieldValue('codiceCifraDeliberaDetermina', props.cifra)
        insertForm.setFieldValue('dataApertura', props.dataApertura)
        insertForm.setFieldValue('dataChiusura', props.dataChiusura)
    }, []);

    useEffect( () => {
        insertForm.setFieldValue('elencoPo', props.elencoPo);
    },[props.elencoPo])

    useEffect( () => {
        if (tipoSalvataggio === 'SALVA') {
            insertForm.submitForm()
        }
        if (tipoSalvataggio === 'CONFERMA') {
                insertForm.submitForm()
        }
    }, [tipoSalvataggio])

    const submitConfirm = () => {
        if(tipoSalvataggio === 'CONFERMA') {
            insertForm.submitForm()
        } else {
            setTipoSalvataggio('CONFERMA')
        }
    }

    const submitSave = () => {
        if(tipoSalvataggio === 'SALVA') {
            insertForm.submitForm()
        } else {
            setTipoSalvataggio('SALVA')
        }
    }

    const onChangeCodiceAtto = (el: any) => {
        insertForm.handleChange(el);
        setCodiceCifra(el.target.value)
        setDatiAtto({});
    }

    const notFoundDatiAtto = () => {
        dispatch(showNotifica({
            titolo: 'ATTENZIONE',
            messaggio: 'Nessun atto associato al codice CIFRA inserito!',
            tipo: NOTIFICA_STATO.warning
        }))
    }

    return (
        <>
            <Row style={{marginTop: '4em'}}>
                <Col md={4} className="px-1">
                    <Input
                        label="Codice CIFRA atto (formato <codiceAoo>/<anno>/<numeroProvvedimento>)"
                        type="text"
                        id="input-codice-cifra-atto"
                        name="codiceCifraDeliberaDetermina"
                        invalid={!!insertForm.errors.codiceCifraDeliberaDetermina}
                        infoText={t(insertForm.errors.codiceCifraDeliberaDetermina!) || ""}
                        value={insertForm.values.codiceCifraDeliberaDetermina || ''}
                        onChange={(e) => onChangeCodiceAtto(e)}
                        className="required"
                    />
                </Col>
                <Col md={4} className="px-1">
                    <Input
                        type="date"
                        label="Data Apertura"
                        placeholder="Inserisci la data"
                        id="input-dataApertura"
                        name="dataApertura"
                        invalid={!!insertForm.errors.dataApertura}
                        infoText={insertForm.errors.dataApertura}
                        value={insertForm.values.dataApertura || ''}
                        onChange={(e) => insertForm.setFieldValue('dataApertura', e.target.value)}
                        wrapperClassName="form-group required"
                    />
                </Col>
                <Col md={4} className="px-1">
                    <Input
                        type="date"
                        label="Data Chiusura"
                        placeholder="Inserisci la data"
                        id="input-dataChiusura"
                        name="dataChiusura"
                        invalid={!!insertForm.errors.dataChiusura}
                        infoText={insertForm.errors.dataChiusura}
                        value={insertForm.values.dataChiusura || ''}
                        onChange={(e) => insertForm.setFieldValue('dataChiusura', e.target.value)}
                        wrapperClassName="form-group required"
                    />
                </Col>
            </Row>
            <Row>
                {(insertForm.values.codiceCifraDeliberaDetermina && !insertForm.errors.codiceCifraDeliberaDetermina) && (
                    <Col md="12 mb-4">
                        <DettaglioAtto 
                            codiceCifra={codiceCifra}
                            datiAtto={datiAtto} 
                            setDatiAtto={(e) => setDatiAtto(e)} 
                            notFoundDatiAtto={() => notFoundDatiAtto()} 
                        ></DettaglioAtto>
                    </Col>
                )}
            </Row>
            {insertForm.errors && insertForm.errors.elencoPo && (
                <Alert color="warning">
                    { insertForm.errors.elencoPo}
                </Alert>
            )}
            <div className="d-flex flex-row-reverse">
                <Button color='primary' className="mt-2 mr-2" onClick={() => submitConfirm()}>Conferma</Button>
                <Button color='primary' className="mt-2 mr-2" onClick={() => submitSave()}>Salva</Button>
            </div>
        </>
    )
}

export default FormDatiEQ;
/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssegnaRuoloFunzionalitaContestoForm } from '../model';
// @ts-ignore
import { AssociaIncarichiContestoForm } from '../model';
// @ts-ignore
import { AssociaPosizioniOrganizzativeContestoForm } from '../model';
// @ts-ignore
import { CessaDipendentiRegionaliContestoForm } from '../model';
// @ts-ignore
import { CreaBandoPoContestoForm } from '../model';
// @ts-ignore
import { CreaCVContestoForm } from '../model';
// @ts-ignore
import { CreaPosizioneOrganizzativaContestoForm } from '../model';
// @ts-ignore
import { DisassociaIncarichiContestoForm } from '../model';
// @ts-ignore
import { ElencoAmbitiRuolo } from '../model';
// @ts-ignore
import { ElencoAmbitoEsperienzaForm } from '../model';
// @ts-ignore
import { ElencoAssegnazioniBandiPoContestoForm } from '../model';
// @ts-ignore
import { ElencoBandiContestoForm } from '../model';
// @ts-ignore
import { ElencoBandiPoContestoForm } from '../model';
// @ts-ignore
import { ElencoDipendentiRegionaliForm } from '../model';
// @ts-ignore
import { ElencoDocumentiStackHolderForm } from '../model';
// @ts-ignore
import { ElencoNuoveAssunzioniForm } from '../model';
// @ts-ignore
import { ElencoPosizioniOrganizzativeContestoForm } from '../model';
// @ts-ignore
import { ElencoProfiliForm } from '../model';
// @ts-ignore
import { ElencoRuoliContestoForm } from '../model';
// @ts-ignore
import { ElencoStatiLetturaNotificheContestoForm } from '../model';
// @ts-ignore
import { ElencoStruttureOrganizzativeContestoForm } from '../model';
// @ts-ignore
import { ElencoStruttureOrganizzativeForm } from '../model';
// @ts-ignore
import { ElencoTitoliDiStudioCVContestoForm } from '../model';
// @ts-ignore
import { ElencoTitoliDiStudioDettaglioCVContestoForm } from '../model';
// @ts-ignore
import { FunzionalitaApplicativeForm } from '../model';
// @ts-ignore
import { InsertConfigNotificaForm } from '../model';
// @ts-ignore
import { InsertIncaricoDirigForm } from '../model';
// @ts-ignore
import { NuovaStrutturaOrganizzativaContestoForm } from '../model';
// @ts-ignore
import { OptionDtoLong } from '../model';
// @ts-ignore
import { OptionDtoString } from '../model';
// @ts-ignore
import { TipologiaContrattoForm } from '../model';
// @ts-ignore
import { UploadBandiEqContestoForm } from '../model';
/**
 * FormsControllerApi - axios parameter creator
 * @export
 */
export const FormsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Rest per il recupero dei profili professionali
         * @summary Dati del form per l\'elenco dei profili professionali
         * @param {number} idProfilo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmbitoRuolo: async (idProfilo: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idProfilo' is not null or undefined
            assertParamExists('getAmbitoRuolo', 'idProfilo', idProfilo)
            const localVarPath = `/v1/forms/cv/elenco-ambito-ruolo/{idProfilo}`
                .replace(`{${"idProfilo"}}`, encodeURIComponent(String(idProfilo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la assegnazione ruolo alla funzionalità
         * @summary Dati del form per \"assegnazione\" del ruolo alla funzionalità
         * @param {number} idRuolo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssegnaRuoloFunzionalitaContestoForm: async (idRuolo: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRuolo' is not null or undefined
            assertParamExists('getAssegnaRuoloFunzionalitaContestoForm', 'idRuolo', idRuolo)
            const localVarPath = `/v1/forms/ruoli/{idRuolo}/funzionalita/assegna`
                .replace(`{${"idRuolo"}}`, encodeURIComponent(String(idRuolo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delgli incarichi
         * @summary Dati del form per \"associa\" delgli incarichi
         * @param {string} idIncarico 
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociaIncarichiContestoForm: async (idIncarico: string, chiave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idIncarico' is not null or undefined
            assertParamExists('getAssociaIncarichiContestoForm', 'idIncarico', idIncarico)
            const localVarPath = `/v1/forms/incarichi/associa/{idIncarico}`
                .replace(`{${"idIncarico"}}`, encodeURIComponent(String(idIncarico)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chiave !== undefined) {
                localVarQueryParameter['chiave'] = chiave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
         * @summary Dati del form per \"associa\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociaPosizioniOrganizzativeContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/posizioni-organizzative/associa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
         * @summary Dati del form per \"associa\" delle posizioni organizzative
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente: async (idDipendente: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idDipendente' is not null or undefined
            assertParamExists('getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente', 'idDipendente', idDipendente)
            const localVarPath = `/v1/forms/posizioni-organizzative/associa/{idDipendente}`
                .replace(`{${"idDipendente"}}`, encodeURIComponent(String(idDipendente)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
         * @summary Dati del form per \"elenco\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCessaDipendenteContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/dipendente/cessazione`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per la creazione bando po
         * @summary Dati del form per il recupero dei dati per la creazione bando po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreaBandoPoContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/bando-po/crea-bando`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per il form di creazione o modifica CV
         * @summary Dati del form per la creazione o modifica di un CV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreaCVContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/cv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità creazione posizione organizzativa
         * @summary Dati del form per \"inserimento\" nuova posizione organizzativa
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreaPosizioniOrganizzativeContestoForm: async (chiave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/posizioni-organizzative/insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chiave !== undefined) {
                localVarQueryParameter['chiave'] = chiave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di disassociazione degli incarichi
         * @summary Dati del form per \"disassociare\" degli incarichi
         * @param {string} idIncarico 
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisassociaIncarichiContestoFormSuBaseDipendente: async (idIncarico: string, chiave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idIncarico' is not null or undefined
            assertParamExists('getDisassociaIncarichiContestoFormSuBaseDipendente', 'idIncarico', idIncarico)
            const localVarPath = `/v1/forms/incarichi/disassocia/{idIncarico}`
                .replace(`{${"idIncarico"}}`, encodeURIComponent(String(idIncarico)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chiave !== undefined) {
                localVarQueryParameter['chiave'] = chiave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoAmbitoEsperienzaForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/cv/elenco-ambito-di-esperienza`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoAssegnazioniBandiPoContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/bando-po/elenco-assegnazioni-bandi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po in base a id bando
         * @param {string} idBando 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoAssegnazioniBandiPoContestoFormChiave: async (idBando: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idBando' is not null or undefined
            assertParamExists('getElencoAssegnazioniBandiPoContestoFormChiave', 'idBando', idBando)
            const localVarPath = `/v1/forms/bando-po/elenco-assegnazioni-bandi/{idBando}`
                .replace(`{${"idBando"}}`, encodeURIComponent(String(idBando)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco dei bandi
         * @summary Dati del form per \"elenco\" deli bandi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoBandiContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/bandi/elenco`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco dei bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco dei bandi po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoBandiPoContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/bando-po/elenco-bandi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
         * @summary Dati del form per il recupero delle nuove assunzioni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoCategorieDocumentiStackHolderForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/stackholder/elenco-documenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco dipendenti
         * @summary Dati del form per \"elenco\" dei dipendenti
         * @param {string} [nomeCompleto] 
         * @param {Array<'A' | 'B' | 'C' | 'D' | 'ARF' | 'ARI' | 'ARE' | 'ARO' | 'CD' | 'SZ' | 'SR'>} [categorie] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoDipendenti: async (nomeCompleto?: string, categorie?: Array<'A' | 'B' | 'C' | 'D' | 'ARF' | 'ARI' | 'ARE' | 'ARO' | 'CD' | 'SZ' | 'SR'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/incarichi-dirigenziali/elencoDipendenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nomeCompleto !== undefined) {
                localVarQueryParameter['nomeCompleto'] = nomeCompleto;
            }

            if (categorie) {
                localVarQueryParameter['categorie'] = categorie;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità della rubrica
         * @summary Dati del form per il recupero di dati necessari per la rubrica
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoNumeriDiTelefonoForm: async (chiave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/rubrica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chiave !== undefined) {
                localVarQueryParameter['chiave'] = chiave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
         * @summary Dati del form per il recupero delle nuove assunzioni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoNuoveAssunzioniForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/nuove-assunzioni/elenco`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
         * @summary Dati del form per \"elenco\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoPosizioniOrganizzativeContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/posizioni-organizzative/elenco`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco deli ruoli
         * @summary Dati del form per \"elenco\" dei ruoli
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoRuoliContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/ruoli/elenco`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco SO
         * @summary Dati del form per \"elenco\" delle SO
         * @param {Array<string>} [codIncaPadre] 
         * @param {string} [descrizioneBreve] 
         * @param {'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'} [stato] 
         * @param {Array<'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'>} [tipi] 
         * @param {'VISUALIZZA' | 'INSERIMENTO'} [tipoRichiesta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoSO: async (codIncaPadre?: Array<string>, descrizioneBreve?: string, stato?: 'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA', tipi?: Array<'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'>, tipoRichiesta?: 'VISUALIZZA' | 'INSERIMENTO', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/incarichi-dirigenziali/elencoSO`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codIncaPadre) {
                localVarQueryParameter['codIncaPadre'] = codIncaPadre;
            }

            if (descrizioneBreve !== undefined) {
                localVarQueryParameter['descrizioneBreve'] = descrizioneBreve;
            }

            if (stato !== undefined) {
                localVarQueryParameter['stato'] = stato;
            }

            if (tipi) {
                localVarQueryParameter['tipi'] = tipi;
            }

            if (tipoRichiesta !== undefined) {
                localVarQueryParameter['tipoRichiesta'] = tipoRichiesta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco degli stati di lettura di una notifica
         * @summary Dati del form per \"elenco\" degli stati di lettura di una notifica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoStatiLetturaNotificheContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/notifica/stato`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle strutture organizzative
         * @summary Dati del form per \"elenco\" delle strutture organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoStruttureOrganizzativeContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/strutture-organizzative/elenco`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoTitoliDiStudioCVContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/cv/elenco-titoli-di-studio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco dei titoli di studio per macro categoria
         * @summary Dati del form per per l\'elenco dei titoli di studio per macro categoria
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoTitoliDiStudioCVContestoFormConChiave: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getElencoTitoliDiStudioCVContestoFormConChiave', 'id', id)
            const localVarPath = `/v1/forms/cv/elenco-titoli-di-studio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la gestione delle funzionalità applicative
         * @summary Dati del form per il recupero di dati necessari per la gestione delle funzionalita applicative
         * @param {number} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFunzionalitaApplicativeForm: async (chiave?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/funzionalita-applicative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chiave !== undefined) {
                localVarQueryParameter['chiave'] = chiave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di inserimento configurazione notifica
         * @summary Dati del form per l\'inserimento di una configurazione notifica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsertConfigNotificheContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/configurazioni-notifiche/inserisci`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form di inserimento di una proposta di incarico dirigenziale
         * @summary Dati del form per l\'inserimento di una propopsta di incarico dirigenziale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsertIncaricoDirigForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/incarichi-dirigenziali/inserisci`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità di creazione nuova struttura organizzativa
         * @summary Dati del form per \"crea\" struttura organizzativa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNuovaStrutturaOrganizzativaContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/strutture-organizzative/crea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei profili professionali
         * @summary Dati del form per l\'elenco dei profili professionali
         * @param {number} idArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiliProfessionali: async (idArea: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idArea' is not null or undefined
            assertParamExists('getProfiliProfessionali', 'idArea', idArea)
            const localVarPath = `/v1/forms/cv/elenco-profili-professionali/{idArea}`
                .replace(`{${"idArea"}}`, encodeURIComponent(String(idArea)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per i dati del form per la funzionalità della rubrica
         * @summary Dati del form per il recupero di dati necessari per l\'elenco rubrica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubricaElencoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/rubrica/elenco`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipologiaDiContrattoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/cv/elenco-tipologia-di-contratto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API Rest per il recupero dei dati per il form di creazione massiva bandi/eq
         * @summary Dati del form per la creazione massiva di bandi/eq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadBandiEqContestoForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms/upload-bandi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormsControllerApi - functional programming interface
 * @export
 */
export const FormsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * API Rest per il recupero dei profili professionali
         * @summary Dati del form per l\'elenco dei profili professionali
         * @param {number} idProfilo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmbitoRuolo(idProfilo: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoAmbitiRuolo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAmbitoRuolo(idProfilo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la assegnazione ruolo alla funzionalità
         * @summary Dati del form per \"assegnazione\" del ruolo alla funzionalità
         * @param {number} idRuolo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssegnaRuoloFunzionalitaContestoForm(idRuolo: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssegnaRuoloFunzionalitaContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssegnaRuoloFunzionalitaContestoForm(idRuolo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delgli incarichi
         * @summary Dati del form per \"associa\" delgli incarichi
         * @param {string} idIncarico 
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssociaIncarichiContestoForm(idIncarico: string, chiave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssociaIncarichiContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociaIncarichiContestoForm(idIncarico, chiave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
         * @summary Dati del form per \"associa\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssociaPosizioniOrganizzativeContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssociaPosizioniOrganizzativeContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociaPosizioniOrganizzativeContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
         * @summary Dati del form per \"associa\" delle posizioni organizzative
         * @param {number} idDipendente 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente(idDipendente: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssociaPosizioniOrganizzativeContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente(idDipendente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
         * @summary Dati del form per \"elenco\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCessaDipendenteContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CessaDipendentiRegionaliContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCessaDipendenteContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per la creazione bando po
         * @summary Dati del form per il recupero dei dati per la creazione bando po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreaBandoPoContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreaBandoPoContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreaBandoPoContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per il form di creazione o modifica CV
         * @summary Dati del form per la creazione o modifica di un CV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreaCVContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreaCVContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreaCVContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità creazione posizione organizzativa
         * @summary Dati del form per \"inserimento\" nuova posizione organizzativa
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreaPosizioniOrganizzativeContestoForm(chiave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreaPosizioneOrganizzativaContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreaPosizioniOrganizzativeContestoForm(chiave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di disassociazione degli incarichi
         * @summary Dati del form per \"disassociare\" degli incarichi
         * @param {string} idIncarico 
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisassociaIncarichiContestoFormSuBaseDipendente(idIncarico: string, chiave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisassociaIncarichiContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisassociaIncarichiContestoFormSuBaseDipendente(idIncarico, chiave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoAmbitoEsperienzaForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoAmbitoEsperienzaForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoAmbitoEsperienzaForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoAssegnazioniBandiPoContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoAssegnazioniBandiPoContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoAssegnazioniBandiPoContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po in base a id bando
         * @param {string} idBando 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoAssegnazioniBandiPoContestoFormChiave(idBando: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoAssegnazioniBandiPoContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoAssegnazioniBandiPoContestoFormChiave(idBando, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco dei bandi
         * @summary Dati del form per \"elenco\" deli bandi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoBandiContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoBandiContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoBandiContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco dei bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco dei bandi po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoBandiPoContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoBandiPoContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoBandiPoContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
         * @summary Dati del form per il recupero delle nuove assunzioni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoCategorieDocumentiStackHolderForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoDocumentiStackHolderForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoCategorieDocumentiStackHolderForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco dipendenti
         * @summary Dati del form per \"elenco\" dei dipendenti
         * @param {string} [nomeCompleto] 
         * @param {Array<'A' | 'B' | 'C' | 'D' | 'ARF' | 'ARI' | 'ARE' | 'ARO' | 'CD' | 'SZ' | 'SR'>} [categorie] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoDipendenti(nomeCompleto?: string, categorie?: Array<'A' | 'B' | 'C' | 'D' | 'ARF' | 'ARI' | 'ARE' | 'ARO' | 'CD' | 'SZ' | 'SR'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoDipendentiRegionaliForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoDipendenti(nomeCompleto, categorie, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità della rubrica
         * @summary Dati del form per il recupero di dati necessari per la rubrica
         * @param {string} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoNumeriDiTelefonoForm(chiave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionDtoLong>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoNumeriDiTelefonoForm(chiave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
         * @summary Dati del form per il recupero delle nuove assunzioni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoNuoveAssunzioniForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoNuoveAssunzioniForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoNuoveAssunzioniForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
         * @summary Dati del form per \"elenco\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoPosizioniOrganizzativeContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoPosizioniOrganizzativeContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoPosizioniOrganizzativeContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco deli ruoli
         * @summary Dati del form per \"elenco\" dei ruoli
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoRuoliContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoRuoliContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoRuoliContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco SO
         * @summary Dati del form per \"elenco\" delle SO
         * @param {Array<string>} [codIncaPadre] 
         * @param {string} [descrizioneBreve] 
         * @param {'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'} [stato] 
         * @param {Array<'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'>} [tipi] 
         * @param {'VISUALIZZA' | 'INSERIMENTO'} [tipoRichiesta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoSO(codIncaPadre?: Array<string>, descrizioneBreve?: string, stato?: 'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA', tipi?: Array<'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'>, tipoRichiesta?: 'VISUALIZZA' | 'INSERIMENTO', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoStruttureOrganizzativeForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoSO(codIncaPadre, descrizioneBreve, stato, tipi, tipoRichiesta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco degli stati di lettura di una notifica
         * @summary Dati del form per \"elenco\" degli stati di lettura di una notifica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoStatiLetturaNotificheContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoStatiLetturaNotificheContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoStatiLetturaNotificheContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle strutture organizzative
         * @summary Dati del form per \"elenco\" delle strutture organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoStruttureOrganizzativeContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoStruttureOrganizzativeContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoStruttureOrganizzativeContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoTitoliDiStudioCVContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoTitoliDiStudioCVContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoTitoliDiStudioCVContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco dei titoli di studio per macro categoria
         * @summary Dati del form per per l\'elenco dei titoli di studio per macro categoria
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElencoTitoliDiStudioCVContestoFormConChiave(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoTitoliDiStudioDettaglioCVContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElencoTitoliDiStudioCVContestoFormConChiave(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la gestione delle funzionalità applicative
         * @summary Dati del form per il recupero di dati necessari per la gestione delle funzionalita applicative
         * @param {number} [chiave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFunzionalitaApplicativeForm(chiave?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FunzionalitaApplicativeForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFunzionalitaApplicativeForm(chiave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di inserimento configurazione notifica
         * @summary Dati del form per l\'inserimento di una configurazione notifica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsertConfigNotificheContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertConfigNotificaForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsertConfigNotificheContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form di inserimento di una proposta di incarico dirigenziale
         * @summary Dati del form per l\'inserimento di una propopsta di incarico dirigenziale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsertIncaricoDirigForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertIncaricoDirigForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsertIncaricoDirigForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità di creazione nuova struttura organizzativa
         * @summary Dati del form per \"crea\" struttura organizzativa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNuovaStrutturaOrganizzativaContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NuovaStrutturaOrganizzativaContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNuovaStrutturaOrganizzativaContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei profili professionali
         * @summary Dati del form per l\'elenco dei profili professionali
         * @param {number} idArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfiliProfessionali(idArea: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoProfiliForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfiliProfessionali(idArea, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per i dati del form per la funzionalità della rubrica
         * @summary Dati del form per il recupero di dati necessari per l\'elenco rubrica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRubricaElencoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionDtoString>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRubricaElencoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTipologiaDiContrattoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TipologiaContrattoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTipologiaDiContrattoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API Rest per il recupero dei dati per il form di creazione massiva bandi/eq
         * @summary Dati del form per la creazione massiva di bandi/eq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadBandiEqContestoForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBandiEqContestoForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadBandiEqContestoForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormsControllerApi - factory interface
 * @export
 */
export const FormsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormsControllerApiFp(configuration)
    return {
        /**
         * API Rest per il recupero dei profili professionali
         * @summary Dati del form per l\'elenco dei profili professionali
         * @param {FormsControllerApiGetAmbitoRuoloRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmbitoRuolo(requestParameters: FormsControllerApiGetAmbitoRuoloRequest, options?: AxiosRequestConfig): AxiosPromise<ElencoAmbitiRuolo> {
            return localVarFp.getAmbitoRuolo(requestParameters.idProfilo, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la assegnazione ruolo alla funzionalità
         * @summary Dati del form per \"assegnazione\" del ruolo alla funzionalità
         * @param {FormsControllerApiGetAssegnaRuoloFunzionalitaContestoFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssegnaRuoloFunzionalitaContestoForm(requestParameters: FormsControllerApiGetAssegnaRuoloFunzionalitaContestoFormRequest, options?: AxiosRequestConfig): AxiosPromise<AssegnaRuoloFunzionalitaContestoForm> {
            return localVarFp.getAssegnaRuoloFunzionalitaContestoForm(requestParameters.idRuolo, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delgli incarichi
         * @summary Dati del form per \"associa\" delgli incarichi
         * @param {FormsControllerApiGetAssociaIncarichiContestoFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociaIncarichiContestoForm(requestParameters: FormsControllerApiGetAssociaIncarichiContestoFormRequest, options?: AxiosRequestConfig): AxiosPromise<AssociaIncarichiContestoForm> {
            return localVarFp.getAssociaIncarichiContestoForm(requestParameters.idIncarico, requestParameters.chiave, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
         * @summary Dati del form per \"associa\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociaPosizioniOrganizzativeContestoForm(options?: AxiosRequestConfig): AxiosPromise<AssociaPosizioniOrganizzativeContestoForm> {
            return localVarFp.getAssociaPosizioniOrganizzativeContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
         * @summary Dati del form per \"associa\" delle posizioni organizzative
         * @param {FormsControllerApiGetAssociaPosizioniOrganizzativeContestoFormSuBaseDipendenteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente(requestParameters: FormsControllerApiGetAssociaPosizioniOrganizzativeContestoFormSuBaseDipendenteRequest, options?: AxiosRequestConfig): AxiosPromise<AssociaPosizioniOrganizzativeContestoForm> {
            return localVarFp.getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente(requestParameters.idDipendente, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
         * @summary Dati del form per \"elenco\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCessaDipendenteContestoForm(options?: AxiosRequestConfig): AxiosPromise<CessaDipendentiRegionaliContestoForm> {
            return localVarFp.getCessaDipendenteContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per la creazione bando po
         * @summary Dati del form per il recupero dei dati per la creazione bando po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreaBandoPoContestoForm(options?: AxiosRequestConfig): AxiosPromise<CreaBandoPoContestoForm> {
            return localVarFp.getCreaBandoPoContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per il form di creazione o modifica CV
         * @summary Dati del form per la creazione o modifica di un CV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreaCVContestoForm(options?: AxiosRequestConfig): AxiosPromise<CreaCVContestoForm> {
            return localVarFp.getCreaCVContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità creazione posizione organizzativa
         * @summary Dati del form per \"inserimento\" nuova posizione organizzativa
         * @param {FormsControllerApiGetCreaPosizioniOrganizzativeContestoFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreaPosizioniOrganizzativeContestoForm(requestParameters: FormsControllerApiGetCreaPosizioniOrganizzativeContestoFormRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreaPosizioneOrganizzativaContestoForm> {
            return localVarFp.getCreaPosizioniOrganizzativeContestoForm(requestParameters.chiave, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di disassociazione degli incarichi
         * @summary Dati del form per \"disassociare\" degli incarichi
         * @param {FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendenteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisassociaIncarichiContestoFormSuBaseDipendente(requestParameters: FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendenteRequest, options?: AxiosRequestConfig): AxiosPromise<DisassociaIncarichiContestoForm> {
            return localVarFp.getDisassociaIncarichiContestoFormSuBaseDipendente(requestParameters.idIncarico, requestParameters.chiave, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoAmbitoEsperienzaForm(options?: AxiosRequestConfig): AxiosPromise<ElencoAmbitoEsperienzaForm> {
            return localVarFp.getElencoAmbitoEsperienzaForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoAssegnazioniBandiPoContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoAssegnazioniBandiPoContestoForm> {
            return localVarFp.getElencoAssegnazioniBandiPoContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po in base a id bando
         * @param {FormsControllerApiGetElencoAssegnazioniBandiPoContestoFormChiaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoAssegnazioniBandiPoContestoFormChiave(requestParameters: FormsControllerApiGetElencoAssegnazioniBandiPoContestoFormChiaveRequest, options?: AxiosRequestConfig): AxiosPromise<ElencoAssegnazioniBandiPoContestoForm> {
            return localVarFp.getElencoAssegnazioniBandiPoContestoFormChiave(requestParameters.idBando, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco dei bandi
         * @summary Dati del form per \"elenco\" deli bandi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoBandiContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoBandiContestoForm> {
            return localVarFp.getElencoBandiContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco dei bandi po
         * @summary Dati del form per il recupero dei dati per l\'elenco dei bandi po
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoBandiPoContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoBandiPoContestoForm> {
            return localVarFp.getElencoBandiPoContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
         * @summary Dati del form per il recupero delle nuove assunzioni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoCategorieDocumentiStackHolderForm(options?: AxiosRequestConfig): AxiosPromise<ElencoDocumentiStackHolderForm> {
            return localVarFp.getElencoCategorieDocumentiStackHolderForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco dipendenti
         * @summary Dati del form per \"elenco\" dei dipendenti
         * @param {FormsControllerApiGetElencoDipendentiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoDipendenti(requestParameters: FormsControllerApiGetElencoDipendentiRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ElencoDipendentiRegionaliForm> {
            return localVarFp.getElencoDipendenti(requestParameters.nomeCompleto, requestParameters.categorie, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità della rubrica
         * @summary Dati del form per il recupero di dati necessari per la rubrica
         * @param {FormsControllerApiGetElencoNumeriDiTelefonoFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoNumeriDiTelefonoForm(requestParameters: FormsControllerApiGetElencoNumeriDiTelefonoFormRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<OptionDtoLong>> {
            return localVarFp.getElencoNumeriDiTelefonoForm(requestParameters.chiave, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
         * @summary Dati del form per il recupero delle nuove assunzioni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoNuoveAssunzioniForm(options?: AxiosRequestConfig): AxiosPromise<ElencoNuoveAssunzioniForm> {
            return localVarFp.getElencoNuoveAssunzioniForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
         * @summary Dati del form per \"elenco\" delle posizioni organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoPosizioniOrganizzativeContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoPosizioniOrganizzativeContestoForm> {
            return localVarFp.getElencoPosizioniOrganizzativeContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco deli ruoli
         * @summary Dati del form per \"elenco\" dei ruoli
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoRuoliContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoRuoliContestoForm> {
            return localVarFp.getElencoRuoliContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di recupero elenco SO
         * @summary Dati del form per \"elenco\" delle SO
         * @param {FormsControllerApiGetElencoSORequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoSO(requestParameters: FormsControllerApiGetElencoSORequest = {}, options?: AxiosRequestConfig): AxiosPromise<ElencoStruttureOrganizzativeForm> {
            return localVarFp.getElencoSO(requestParameters.codIncaPadre, requestParameters.descrizioneBreve, requestParameters.stato, requestParameters.tipi, requestParameters.tipoRichiesta, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco degli stati di lettura di una notifica
         * @summary Dati del form per \"elenco\" degli stati di lettura di una notifica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoStatiLetturaNotificheContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoStatiLetturaNotificheContestoForm> {
            return localVarFp.getElencoStatiLetturaNotificheContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di elenco delle strutture organizzative
         * @summary Dati del form per \"elenco\" delle strutture organizzative
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoStruttureOrganizzativeContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoStruttureOrganizzativeContestoForm> {
            return localVarFp.getElencoStruttureOrganizzativeContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoTitoliDiStudioCVContestoForm(options?: AxiosRequestConfig): AxiosPromise<ElencoTitoliDiStudioCVContestoForm> {
            return localVarFp.getElencoTitoliDiStudioCVContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco dei titoli di studio per macro categoria
         * @summary Dati del form per per l\'elenco dei titoli di studio per macro categoria
         * @param {FormsControllerApiGetElencoTitoliDiStudioCVContestoFormConChiaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElencoTitoliDiStudioCVContestoFormConChiave(requestParameters: FormsControllerApiGetElencoTitoliDiStudioCVContestoFormConChiaveRequest, options?: AxiosRequestConfig): AxiosPromise<ElencoTitoliDiStudioDettaglioCVContestoForm> {
            return localVarFp.getElencoTitoliDiStudioCVContestoFormConChiave(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la gestione delle funzionalità applicative
         * @summary Dati del form per il recupero di dati necessari per la gestione delle funzionalita applicative
         * @param {FormsControllerApiGetFunzionalitaApplicativeFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFunzionalitaApplicativeForm(requestParameters: FormsControllerApiGetFunzionalitaApplicativeFormRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FunzionalitaApplicativeForm> {
            return localVarFp.getFunzionalitaApplicativeForm(requestParameters.chiave, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di inserimento configurazione notifica
         * @summary Dati del form per l\'inserimento di una configurazione notifica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsertConfigNotificheContestoForm(options?: AxiosRequestConfig): AxiosPromise<InsertConfigNotificaForm> {
            return localVarFp.getInsertConfigNotificheContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form di inserimento di una proposta di incarico dirigenziale
         * @summary Dati del form per l\'inserimento di una propopsta di incarico dirigenziale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsertIncaricoDirigForm(options?: AxiosRequestConfig): AxiosPromise<InsertIncaricoDirigForm> {
            return localVarFp.getInsertIncaricoDirigForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità di creazione nuova struttura organizzativa
         * @summary Dati del form per \"crea\" struttura organizzativa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNuovaStrutturaOrganizzativaContestoForm(options?: AxiosRequestConfig): AxiosPromise<NuovaStrutturaOrganizzativaContestoForm> {
            return localVarFp.getNuovaStrutturaOrganizzativaContestoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei profili professionali
         * @summary Dati del form per l\'elenco dei profili professionali
         * @param {FormsControllerApiGetProfiliProfessionaliRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiliProfessionali(requestParameters: FormsControllerApiGetProfiliProfessionaliRequest, options?: AxiosRequestConfig): AxiosPromise<ElencoProfiliForm> {
            return localVarFp.getProfiliProfessionali(requestParameters.idArea, options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per i dati del form per la funzionalità della rubrica
         * @summary Dati del form per il recupero di dati necessari per l\'elenco rubrica
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubricaElencoForm(options?: AxiosRequestConfig): AxiosPromise<Array<OptionDtoString>> {
            return localVarFp.getRubricaElencoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
         * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipologiaDiContrattoForm(options?: AxiosRequestConfig): AxiosPromise<TipologiaContrattoForm> {
            return localVarFp.getTipologiaDiContrattoForm(options).then((request) => request(axios, basePath));
        },
        /**
         * API Rest per il recupero dei dati per il form di creazione massiva bandi/eq
         * @summary Dati del form per la creazione massiva di bandi/eq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadBandiEqContestoForm(options?: AxiosRequestConfig): AxiosPromise<UploadBandiEqContestoForm> {
            return localVarFp.getUploadBandiEqContestoForm(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAmbitoRuolo operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetAmbitoRuoloRequest
 */
export interface FormsControllerApiGetAmbitoRuoloRequest {
    /**
     * 
     * @type {number}
     * @memberof FormsControllerApiGetAmbitoRuolo
     */
    readonly idProfilo: number
}

/**
 * Request parameters for getAssegnaRuoloFunzionalitaContestoForm operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetAssegnaRuoloFunzionalitaContestoFormRequest
 */
export interface FormsControllerApiGetAssegnaRuoloFunzionalitaContestoFormRequest {
    /**
     * 
     * @type {number}
     * @memberof FormsControllerApiGetAssegnaRuoloFunzionalitaContestoForm
     */
    readonly idRuolo: number
}

/**
 * Request parameters for getAssociaIncarichiContestoForm operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetAssociaIncarichiContestoFormRequest
 */
export interface FormsControllerApiGetAssociaIncarichiContestoFormRequest {
    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetAssociaIncarichiContestoForm
     */
    readonly idIncarico: string

    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetAssociaIncarichiContestoForm
     */
    readonly chiave?: string
}

/**
 * Request parameters for getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetAssociaPosizioniOrganizzativeContestoFormSuBaseDipendenteRequest
 */
export interface FormsControllerApiGetAssociaPosizioniOrganizzativeContestoFormSuBaseDipendenteRequest {
    /**
     * 
     * @type {number}
     * @memberof FormsControllerApiGetAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente
     */
    readonly idDipendente: number
}

/**
 * Request parameters for getCreaPosizioniOrganizzativeContestoForm operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetCreaPosizioniOrganizzativeContestoFormRequest
 */
export interface FormsControllerApiGetCreaPosizioniOrganizzativeContestoFormRequest {
    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetCreaPosizioniOrganizzativeContestoForm
     */
    readonly chiave?: string
}

/**
 * Request parameters for getDisassociaIncarichiContestoFormSuBaseDipendente operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendenteRequest
 */
export interface FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendenteRequest {
    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendente
     */
    readonly idIncarico: string

    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendente
     */
    readonly chiave?: string
}

/**
 * Request parameters for getElencoAssegnazioniBandiPoContestoFormChiave operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetElencoAssegnazioniBandiPoContestoFormChiaveRequest
 */
export interface FormsControllerApiGetElencoAssegnazioniBandiPoContestoFormChiaveRequest {
    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetElencoAssegnazioniBandiPoContestoFormChiave
     */
    readonly idBando: string
}

/**
 * Request parameters for getElencoDipendenti operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetElencoDipendentiRequest
 */
export interface FormsControllerApiGetElencoDipendentiRequest {
    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetElencoDipendenti
     */
    readonly nomeCompleto?: string

    /**
     * 
     * @type {Array<'A' | 'B' | 'C' | 'D' | 'ARF' | 'ARI' | 'ARE' | 'ARO' | 'CD' | 'SZ' | 'SR'>}
     * @memberof FormsControllerApiGetElencoDipendenti
     */
    readonly categorie?: Array<'A' | 'B' | 'C' | 'D' | 'ARF' | 'ARI' | 'ARE' | 'ARO' | 'CD' | 'SZ' | 'SR'>
}

/**
 * Request parameters for getElencoNumeriDiTelefonoForm operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetElencoNumeriDiTelefonoFormRequest
 */
export interface FormsControllerApiGetElencoNumeriDiTelefonoFormRequest {
    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetElencoNumeriDiTelefonoForm
     */
    readonly chiave?: string
}

/**
 * Request parameters for getElencoSO operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetElencoSORequest
 */
export interface FormsControllerApiGetElencoSORequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof FormsControllerApiGetElencoSO
     */
    readonly codIncaPadre?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof FormsControllerApiGetElencoSO
     */
    readonly descrizioneBreve?: string

    /**
     * 
     * @type {'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'}
     * @memberof FormsControllerApiGetElencoSO
     */
    readonly stato?: 'COMPILATA' | 'REGISTRATA' | 'CENSITA' | 'CESSATA'

    /**
     * 
     * @type {Array<'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'>}
     * @memberof FormsControllerApiGetElencoSO
     */
    readonly tipi?: Array<'DIPARTIMENTO' | 'SEZIONE' | 'SERVIZIO'>

    /**
     * 
     * @type {'VISUALIZZA' | 'INSERIMENTO'}
     * @memberof FormsControllerApiGetElencoSO
     */
    readonly tipoRichiesta?: 'VISUALIZZA' | 'INSERIMENTO'
}

/**
 * Request parameters for getElencoTitoliDiStudioCVContestoFormConChiave operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetElencoTitoliDiStudioCVContestoFormConChiaveRequest
 */
export interface FormsControllerApiGetElencoTitoliDiStudioCVContestoFormConChiaveRequest {
    /**
     * 
     * @type {number}
     * @memberof FormsControllerApiGetElencoTitoliDiStudioCVContestoFormConChiave
     */
    readonly id: number
}

/**
 * Request parameters for getFunzionalitaApplicativeForm operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetFunzionalitaApplicativeFormRequest
 */
export interface FormsControllerApiGetFunzionalitaApplicativeFormRequest {
    /**
     * 
     * @type {number}
     * @memberof FormsControllerApiGetFunzionalitaApplicativeForm
     */
    readonly chiave?: number
}

/**
 * Request parameters for getProfiliProfessionali operation in FormsControllerApi.
 * @export
 * @interface FormsControllerApiGetProfiliProfessionaliRequest
 */
export interface FormsControllerApiGetProfiliProfessionaliRequest {
    /**
     * 
     * @type {number}
     * @memberof FormsControllerApiGetProfiliProfessionali
     */
    readonly idArea: number
}

/**
 * FormsControllerApi - object-oriented interface
 * @export
 * @class FormsControllerApi
 * @extends {BaseAPI}
 */
export class FormsControllerApi extends BaseAPI {
    /**
     * API Rest per il recupero dei profili professionali
     * @summary Dati del form per l\'elenco dei profili professionali
     * @param {FormsControllerApiGetAmbitoRuoloRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getAmbitoRuolo(requestParameters: FormsControllerApiGetAmbitoRuoloRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getAmbitoRuolo(requestParameters.idProfilo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la assegnazione ruolo alla funzionalità
     * @summary Dati del form per \"assegnazione\" del ruolo alla funzionalità
     * @param {FormsControllerApiGetAssegnaRuoloFunzionalitaContestoFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getAssegnaRuoloFunzionalitaContestoForm(requestParameters: FormsControllerApiGetAssegnaRuoloFunzionalitaContestoFormRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getAssegnaRuoloFunzionalitaContestoForm(requestParameters.idRuolo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di associazione delgli incarichi
     * @summary Dati del form per \"associa\" delgli incarichi
     * @param {FormsControllerApiGetAssociaIncarichiContestoFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getAssociaIncarichiContestoForm(requestParameters: FormsControllerApiGetAssociaIncarichiContestoFormRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getAssociaIncarichiContestoForm(requestParameters.idIncarico, requestParameters.chiave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
     * @summary Dati del form per \"associa\" delle posizioni organizzative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getAssociaPosizioniOrganizzativeContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getAssociaPosizioniOrganizzativeContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di associazione delle posizioni organizzative
     * @summary Dati del form per \"associa\" delle posizioni organizzative
     * @param {FormsControllerApiGetAssociaPosizioniOrganizzativeContestoFormSuBaseDipendenteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente(requestParameters: FormsControllerApiGetAssociaPosizioniOrganizzativeContestoFormSuBaseDipendenteRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getAssociaPosizioniOrganizzativeContestoFormSuBaseDipendente(requestParameters.idDipendente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
     * @summary Dati del form per \"elenco\" delle posizioni organizzative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getCessaDipendenteContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getCessaDipendenteContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per la creazione bando po
     * @summary Dati del form per il recupero dei dati per la creazione bando po
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getCreaBandoPoContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getCreaBandoPoContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per il form di creazione o modifica CV
     * @summary Dati del form per la creazione o modifica di un CV
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getCreaCVContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getCreaCVContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità creazione posizione organizzativa
     * @summary Dati del form per \"inserimento\" nuova posizione organizzativa
     * @param {FormsControllerApiGetCreaPosizioniOrganizzativeContestoFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getCreaPosizioniOrganizzativeContestoForm(requestParameters: FormsControllerApiGetCreaPosizioniOrganizzativeContestoFormRequest = {}, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getCreaPosizioniOrganizzativeContestoForm(requestParameters.chiave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di disassociazione degli incarichi
     * @summary Dati del form per \"disassociare\" degli incarichi
     * @param {FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendenteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getDisassociaIncarichiContestoFormSuBaseDipendente(requestParameters: FormsControllerApiGetDisassociaIncarichiContestoFormSuBaseDipendenteRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getDisassociaIncarichiContestoFormSuBaseDipendente(requestParameters.idIncarico, requestParameters.chiave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
     * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoAmbitoEsperienzaForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoAmbitoEsperienzaForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
     * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoAssegnazioniBandiPoContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoAssegnazioniBandiPoContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per l\'elenco delle assegnazioni bandi po
     * @summary Dati del form per il recupero dei dati per l\'elenco delle assegnazioni bandi po in base a id bando
     * @param {FormsControllerApiGetElencoAssegnazioniBandiPoContestoFormChiaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoAssegnazioniBandiPoContestoFormChiave(requestParameters: FormsControllerApiGetElencoAssegnazioniBandiPoContestoFormChiaveRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoAssegnazioniBandiPoContestoFormChiave(requestParameters.idBando, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di elenco dei bandi
     * @summary Dati del form per \"elenco\" deli bandi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoBandiContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoBandiContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per l\'elenco dei bandi po
     * @summary Dati del form per il recupero dei dati per l\'elenco dei bandi po
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoBandiPoContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoBandiPoContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
     * @summary Dati del form per il recupero delle nuove assunzioni
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoCategorieDocumentiStackHolderForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoCategorieDocumentiStackHolderForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di recupero elenco dipendenti
     * @summary Dati del form per \"elenco\" dei dipendenti
     * @param {FormsControllerApiGetElencoDipendentiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoDipendenti(requestParameters: FormsControllerApiGetElencoDipendentiRequest = {}, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoDipendenti(requestParameters.nomeCompleto, requestParameters.categorie, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità della rubrica
     * @summary Dati del form per il recupero di dati necessari per la rubrica
     * @param {FormsControllerApiGetElencoNumeriDiTelefonoFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoNumeriDiTelefonoForm(requestParameters: FormsControllerApiGetElencoNumeriDiTelefonoFormRequest = {}, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoNumeriDiTelefonoForm(requestParameters.chiave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di recupero elenco nuove assunzioni
     * @summary Dati del form per il recupero delle nuove assunzioni
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoNuoveAssunzioniForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoNuoveAssunzioniForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di elenco delle posizioni organizzative
     * @summary Dati del form per \"elenco\" delle posizioni organizzative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoPosizioniOrganizzativeContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoPosizioniOrganizzativeContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di elenco deli ruoli
     * @summary Dati del form per \"elenco\" dei ruoli
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoRuoliContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoRuoliContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di recupero elenco SO
     * @summary Dati del form per \"elenco\" delle SO
     * @param {FormsControllerApiGetElencoSORequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoSO(requestParameters: FormsControllerApiGetElencoSORequest = {}, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoSO(requestParameters.codIncaPadre, requestParameters.descrizioneBreve, requestParameters.stato, requestParameters.tipi, requestParameters.tipoRichiesta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di elenco degli stati di lettura di una notifica
     * @summary Dati del form per \"elenco\" degli stati di lettura di una notifica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoStatiLetturaNotificheContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoStatiLetturaNotificheContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di elenco delle strutture organizzative
     * @summary Dati del form per \"elenco\" delle strutture organizzative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoStruttureOrganizzativeContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoStruttureOrganizzativeContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
     * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoTitoliDiStudioCVContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoTitoliDiStudioCVContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per l\'elenco dei titoli di studio per macro categoria
     * @summary Dati del form per per l\'elenco dei titoli di studio per macro categoria
     * @param {FormsControllerApiGetElencoTitoliDiStudioCVContestoFormConChiaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getElencoTitoliDiStudioCVContestoFormConChiave(requestParameters: FormsControllerApiGetElencoTitoliDiStudioCVContestoFormConChiaveRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getElencoTitoliDiStudioCVContestoFormConChiave(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la gestione delle funzionalità applicative
     * @summary Dati del form per il recupero di dati necessari per la gestione delle funzionalita applicative
     * @param {FormsControllerApiGetFunzionalitaApplicativeFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getFunzionalitaApplicativeForm(requestParameters: FormsControllerApiGetFunzionalitaApplicativeFormRequest = {}, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getFunzionalitaApplicativeForm(requestParameters.chiave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di inserimento configurazione notifica
     * @summary Dati del form per l\'inserimento di una configurazione notifica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getInsertConfigNotificheContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getInsertConfigNotificheContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form di inserimento di una proposta di incarico dirigenziale
     * @summary Dati del form per l\'inserimento di una propopsta di incarico dirigenziale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getInsertIncaricoDirigForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getInsertIncaricoDirigForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità di creazione nuova struttura organizzativa
     * @summary Dati del form per \"crea\" struttura organizzativa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getNuovaStrutturaOrganizzativaContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getNuovaStrutturaOrganizzativaContestoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei profili professionali
     * @summary Dati del form per l\'elenco dei profili professionali
     * @param {FormsControllerApiGetProfiliProfessionaliRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getProfiliProfessionali(requestParameters: FormsControllerApiGetProfiliProfessionaliRequest, options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getProfiliProfessionali(requestParameters.idArea, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per i dati del form per la funzionalità della rubrica
     * @summary Dati del form per il recupero di dati necessari per l\'elenco rubrica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getRubricaElencoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getRubricaElencoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per l\'elenco delle macro categorie di titoli di studio
     * @summary Dati del form per l\'elenco delle macro categorie di titoli di studio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getTipologiaDiContrattoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getTipologiaDiContrattoForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API Rest per il recupero dei dati per il form di creazione massiva bandi/eq
     * @summary Dati del form per la creazione massiva di bandi/eq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsControllerApi
     */
    public getUploadBandiEqContestoForm(options?: AxiosRequestConfig) {
        return FormsControllerApiFp(this.configuration).getUploadBandiEqContestoForm(options).then((request) => request(this.axios, this.basePath));
    }
}

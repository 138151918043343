import './style.scss'
import { DipendenteRegionaleDto } from '../../services/ms-anagrafica-unica'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'

const ConsulenteEsternoDetailsComponent = (props: {dipendente: DipendenteRegionaleDto, except: Array<string>}) => {
    const formatDate = (date:string) => {
        if (!date)
            return ''
        const giorno = format(new Date(date), 'd');
        const mese = format(new Date(date), 'MMMM', {locale: it});
        const anno = format(new Date(date), 'yyyy');
        const meseFormattato = mese.charAt(0).toUpperCase() + mese.slice(1).toLowerCase()
        return `${giorno} ${meseFormattato} ${anno}`
    }
    
    return (
        <div className="col-sm-12">
            {props.dipendente != null &&
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light-candidato">
                        <h6 className="mb-3">
                            Riepilogo
                        </h6>
                        <hr />
                        <div className="row">
                            <dl className="col-sm-12">
                                <div className="row">
                                {!!props.dipendente.id && (
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Codice CID'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.id}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.cognome && (
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Cognome'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.cognome}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.nome && (
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Nome'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.nome}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.codiceFiscale && (
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Codice Fiscale'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.codiceFiscale}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.telefono && (
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Telefono'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.telefono}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.cellulare && (
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Cellulare'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.cellulare}</dd>
                                    </div>
                                )}
                                {!!props.dipendente.dataInizio && (
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Data Inizio'}</dt>
                                        <dd className='col-sm-12'>{formatDate(props.dipendente.dataInizio!)}</dd>
                                    </div>
                                )}
                                {
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Data Fine'}</dt>
                                        <dd className='col-sm-12'>{formatDate(props.dipendente.dataFine!)}</dd>
                                    </div>
                                }
                                {
                                    <div className="col-sm-4 consul-esterno">
                                        <dt className='col-sm-12'>{'Stato'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.stato}</dd>
                                    </div>
                                }
                                </div>
                                <div className="row">
                                </div>
                                <div className="row">
                                {!props.except.includes('indirizzoEmail') && !!props.dipendente.email && (
                                    <div className="col-sm-12 consul-esterno">
                                        <dt className='col-sm-12'>{'Email'}</dt>
                                        <dd className='col-sm-12'>{props.dipendente.email}</dd>
                                    </div>
                                )}
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default ConsulenteEsternoDetailsComponent
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    Alert,
    Button,
    Col,
    DropdownMenu,
    DropdownToggle,
    Icon,
    Input,
    LinkList,
    LinkListItem,
    Table,
    UncontrolledDropdown
} from "design-react-kit";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/pager/Pagination";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    loadPOFormSearch,
    protocollaPO,
    resetDetailResult,
    resetStatus,
    SearchPoRequest,
    searchPosizioniOrganizzative
} from "../../store/posizioneOrganizzativaSlice";
import CustomSelect, { CustomSelectItem } from "../../components/custom-select/CustomSelect";
import CustomAutocomplete from "../../components/custom-autocomplete/CustomAutocomplete";
import {
    OptionDtoStatoPosizioneOrganizzativaValueEnum,
    PosizioneOrganizzativaDtoTipoEnum,
    PosizioneOrganizzativaProtocollaRequestDto,
    RuoloUtenteAutenticatoDto,
    StrutturaOrganizzativaControllerApi
} from "../../services/ms-anagrafica-unica";
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "../../store/store-constants";
import { useNavigate } from "react-router-dom";
import {
    HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA,
    HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA,
    HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA,
    HREF_DOWNLOAD_NULLA_OSTA,
    HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA,
    HREF_NUOVA_POSIZIONE_ORGANIZZATIVA,
    HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO,
    HREF_UPLOAD_NOMINA,
    HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA
} from "../../components/sidemenu/sidemenuConstants";
import { debounce } from 'lodash'
import { format, parseISO } from "date-fns";
import PageHeader from "../../components/page-header/PageHeader";

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

const initialValues: SearchPoRequest = {
    pageNum: 0,
    pageSize: 10,
    denominazione: '',
    strutturaOrganizzativa: '',
    stato: undefined,
    tipo: undefined,
    sort: '',
    direction: undefined
};


function ElencoPosizioniOrganizzative(props: {operations: RuoloUtenteAutenticatoDto}) {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        searchResult,
        searchStatus,
        formSearchComponents,
        searchInput
    } = useAppSelector((state) => state.posizioneOrganizzativa);
    const protocollaStatus = useAppSelector((state) => state.posizioneOrganizzativa.protocollaStatus);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [formCollapseOpen, setFormCollapseOpen] = useState(false);
    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });
    const navigate = useNavigate();

    let hasResults = (searchResult && searchResult.totalElements) ? searchResult.totalElements > 0 : false;

    let statoOptions: CustomSelectItem[] = [];
    let tipoEqOptions: CustomSelectItem[] = [
        {label: t("tipoEQ_PO"), value: "PO"}, 
        {label: t("tipoEQ_PO_EQUIPARATA"), value: "PO_EQUIPARATA"}
    ];

    formSearchComponents?.stato?.forEach(s => {
        statoOptions.push({ label: s.label, value: s.valore });
    });

    const searchForm = useFormik<SearchPoRequest>({
        initialValues: initialValues,
        onSubmit: () => {
            //handlePageChange(1);
            doSearch();
        }
    });

    const doSearch = () => {
        dispatch(searchPosizioniOrganizzative(searchForm.values));
    }

    const handlePageChange = (page: number) => {
        searchForm.setFieldValue('pageNum', page - 1);
        searchForm.handleSubmit();
    }

    const handleChange = (selectedOption: any) => {
        searchForm.setFieldValue('stato', selectedOption);
        searchForm.handleChange(selectedOption);
    };
    const handleChangeTipoEq = (selectedOption: any) => {
        searchForm.setFieldValue('tipo', selectedOption);
        searchForm.handleChange(selectedOption);
    };

    const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
        const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
        const response = await api.searchStrutturaOrganizzativa({
            denominazione: inputValue
        });
        let options: { value: string | undefined; label: string | undefined; }[] = []
        if (response.data && response.data.data) {
            response.data.data.forEach(d => {
                options.push({ value: d.id, label: d.descrizione });
            });
        }
        callback(options);
    }, 500)

    const handleStrutturaOrganizzativaSelect = (value: string) => {
        console.log('handleStrutturaOrganizzativaSelect', value);
        searchForm.setFieldValue('strutturaOrganizzativa', value);
        searchForm.handleChange(value);
    }

    const ordinamento = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchForm.setFieldValue("sort", columnName);
        searchForm.setFieldValue("direction", sorting.direction);
        searchForm.handleSubmit();
    };

    const protocollaPatch = (idCodiceIncarico: string | undefined) => {
        const param: PosizioneOrganizzativaProtocollaRequestDto = {
            codiceUnivoco: idCodiceIncarico,
            requestType: 'Protocollazione'
        }
        setOpenAlert(true);
        dispatch(protocollaPO({ patchPosizioneOrganizzativaRequest: param, idCodiceIncarico: idCodiceIncarico as string })).then(() => {
            doSearch();
        })
    }

    const showInserisciPOOperations = () => props.operations?.elencoFunzionalita?.includes('PO_INSERIMENTO')

    const showModificaPOOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('PO_PATCH') && poStato !== OptionDtoStatoPosizioneOrganizzativaValueEnum.Disattivata

    const showVisualizzaPOOperations = () =>
        props.operations?.elencoFunzionalita?.includes('PO_DETTAGLIO')

    const showAssociaPOOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('PO_ASSOCIA')
        && poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Associabile

    const showDisassociaPOOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('PO_DISASSOCIA')
        && poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Associata

    const showUploadNominaOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('PO_UPLOAD_DETERMINA_DI_NOMINA')
        && poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Creata

    const showUploadDeterminaOperations = (poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('PO_UPLOAD_DETERMINA_AVVISO')
        && poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.InseritaEComunicata
        
    const showDisattivaPOOperations = () =>
    props.operations?.elencoFunzionalita?.includes('PO_DISATTIVA')

    const showDownloadNullaOstaOperations = (poTipo: PosizioneOrganizzativaDtoTipoEnum, poStato: OptionDtoStatoPosizioneOrganizzativaValueEnum) =>
        poStato === OptionDtoStatoPosizioneOrganizzativaValueEnum.Inserita
        && poTipo === PosizioneOrganizzativaDtoTipoEnum.Po

    useEffect(() => {
        if (!!searchInput && (!!searchInput.denominazione || !!searchInput.stato || !!searchInput.strutturaOrganizzativa) ) {
            setFormCollapseOpen(true);
            dispatch(searchPosizioniOrganizzative(searchInput));
        } else {
            doSearch();
        }
        dispatch(loadPOFormSearch());
        setOpenAlert(false);
        dispatch(resetDetailResult);
        dispatch(resetStatus);
    }, []);

    useEffect(() => {
        searchForm.setValues({
            denominazione: searchInput?.denominazione || '',
            stato: searchInput?.stato || '' || undefined,
            tipo: searchInput?.tipo || '' || undefined,
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            strutturaOrganizzativa: searchInput?.strutturaOrganizzativa || '',
            direction: searchInput?.direction || '' || undefined,
            sort: searchInput?.sort || ''
        })
    }, [searchInput])

    const newButton = {
        buttonProperties: {outline: true},
        title: 'Nuova Elevata Qualificazione',
        buttonColor: 'primary',
        action: () => navigate(HREF_NUOVA_POSIZIONE_ORGANIZZATIVA),
        showButtonIcon: true,
        icon: 'it-plus',
        iconColor: 'primary',
    }

    const expandButton = {
        buttonProperties: { outline: true },
        title: 'Ricerca',
        buttonColor: 'primary',
        showButtonIcon: true,
        icon: 'it-search',
        iconColor: 'primary',
        action: () => { formCollapseOpen ? setFormCollapseOpen(false) : setFormCollapseOpen(true) },
    }

    return (
        <div>
            <PageHeader showIcon={false} title={t('elenco elevate qualificazioni')!}
                buttons={showInserisciPOOperations() ? [newButton, expandButton] : [expandButton]}
            />

            <Accordion background='active'>
                <AccordionHeader
                    active={formCollapseOpen}
                    onToggle={() => setFormCollapseOpen(!formCollapseOpen)}
                >
                    {t('filtri di ricerca')}
                </AccordionHeader>
                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-5">
                        <Input type="hidden" name="pageNum"
                            value={searchForm.values.pageNum}
                            onChange={searchForm.handleChange}
                        />

                        <Input type="text"
                            label="Denominazione"
                            id="input-denominazione-1"
                            placeholder="Inserisci una denominazione"
                            wrapperClassName="col col-md-6"
                            name="denominazione"
                            value={searchForm.values.denominazione}
                            onChange={searchForm.handleChange} />

                        <Col md="6">
                            <CustomSelect label="Stato"
                                name="stato"
                                placeholder="Seleziona stato"
                                options={statoOptions}
                                value={searchForm.values.stato}
                                onChange={handleChange} />
                        </Col>

                        <Col md="6">
                            <CustomAutocomplete
                                label="Struttura organizzativa"
                                placeholder="Seleziona un valore"
                                id="select-struttura-organizzativa"
                                // value={searchForm.values.strutturaOrganizzativa}
                                loadOptionsFn={autocompleteStruttureOrganizzative}
                                handleOptionSelect={(e: string) => handleStrutturaOrganizzativaSelect(e)}
                            />
                        </Col>

                        <Col md="6" className="mt-5">
                            <CustomSelect
                                label="Tipo EQ"
                                name="tipoEQ"
                                placeholder="Seleziona un tipo EQ"
                                options={tipoEqOptions}
                                value={searchForm.values.tipo}
                                onChange={handleChangeTipoEq}
                            />
                        </Col>

                    </div>

                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2" onClick={() => {
                            searchForm.handleSubmit()
                        }}>Applica</Button>
                        <Button outline color='primary' className="mt-2 mr-2"
                            onClick={searchForm.handleReset}>Pulisci</Button>
                    </div>
                </AccordionBody>

            </Accordion>

            <div className="mt-5 position-relative">
                {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
                {searchStatus === STATUS_FULLFILLED && hasResults &&
                    <>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th scope="col" onClick={() => ordinamento("descrizione")}>
                                        <div className="table-order">
                                            {t("Denominazione")}
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope="col" onClick={() => ordinamento("struttura_organizzativa")}>
                                        <div className="table-order">
                                            {t("Struttura organizzativa")}
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope="col" onClick={() => ordinamento("data_inserimento")}>
                                        <div className="table-order">
                                            {t("Inserimento")}
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col' onClick={() => ordinamento("data_modifica")}>
                                        <div className="table-order">
                                            {t("data modifica")}
                                            <div className="icon-direction">
                                                <Icon
                                                    icon="it-collapse"
                                                    size="xs"
                                                />
                                                <Icon
                                                    icon="it-expand"
                                                    size="xs"
                                                />
                                            </div>

                                        </div>
                                    </th>
                                    <th scope='col'>
                                        <div className="table-order">
                                            Stato
                                        </div>
                                    </th>
                                    <th scope='col'>
                                        <div className="table-order">
                                            Associato
                                        </div>
                                    </th>
                                    <th scope='col'>
                                        <div className="table-order">
                                            Tipo EQ
                                        </div>
                                    </th>
                                    <th scope='col'>
                                        <div className="table-order">
                                            Azioni
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(searchResult.data || []).map((x, i, dataset) =>
                                    <Fragment  key={i}><tr>
                                        <td>
                                            {x.denominazione}
                                        </td>
                                        <td>
                                            {x.nomeStrutturaOrganizzativa}
                                        </td>
                                        <td>
                                            <>{x.dataInserimento != null ? format(parseISO(x.dataInserimento!), 'dd/MM/yyyy') : ''}</>
                                        </td>
                                        <td>
                                            <>{x.dataModifica != null ? format(parseISO(x.dataModifica!), 'dd/MM/yyyy') : ''}</>
                                        </td>
                                        <td>
                                            {x.stato}
                                        </td>
                                        <td>
                                            {x.dipendenteRegionale}
                                        </td>
                                        <td>
                                            { x.tipo != null ? t("tipoEQ_" + x.tipo) : ''}
                                        </td>
                                        <td>
                                            {<UncontrolledDropdown direction="left">
                                                <DropdownToggle nav>
                                                    <Icon icon="it-more-actions" />
                                                </DropdownToggle>
                                                <DropdownMenu className="no-arrow">
                                                    <LinkList>
                                                        {showVisualizzaPOOperations() && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_VISUALIZZA_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`)}>
                                                            Visualizza
                                                        </LinkListItem>}
                                                        {showModificaPOOperations(x.stato!) && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_MODIFICA_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`)}>
                                                            Modifica
                                                        </LinkListItem>}
                                                        {x.protocollabile && <LinkListItem size="medium"
                                                            onClick={() => protocollaPatch(x.codiceUnivoco)}>
                                                            Protocolla
                                                        </LinkListItem>}
                                                        {showAssociaPOOperations(x.stato!) && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_ASSEGNAZIONE_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`)}>
                                                            Associa
                                                        </LinkListItem>}
                                                        {showDisassociaPOOperations(x.stato!) && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_DISASSOCIAZIONE_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`)}>
                                                            Disassocia
                                                        </LinkListItem>}
                                                        {showUploadNominaOperations(x.stato!) && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_UPLOAD_NOMINA}/${x.codiceUnivoco}`)}>
                                                            Upload nomina
                                                        </LinkListItem>}
                                                        {showUploadDeterminaOperations(x.stato!) && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_UPLOAD_DETERMINA_ISTITUZIONE_AVVISO}/${x.codiceUnivoco}`)}>
                                                            Upload determina istituzione
                                                        </LinkListItem>}
                                                        {showDownloadNullaOstaOperations(x.tipo!, x.stato!) && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_DOWNLOAD_NULLA_OSTA}/${x.codiceUnivoco}`)}>
                                                            Scarica Nulla Osta
                                                        </LinkListItem>}
                                                        {showDisattivaPOOperations() && x.disattivabile && <LinkListItem size="medium"
                                                            onClick={() => navigate(`${HREF_DISATTIVA_POSIZIONE_ORGANIZZATIVA}/${x.codiceUnivoco}`)}>
                                                            Disattiva
                                                        </LinkListItem>}
                                                    </LinkList>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>}
                                        </td>
                                    </tr>
                                    {/* corregge il popup delle azioni in caso di 1 risultato */}
                                    {dataset.length === 1 && <tr><td style={{height:100, border: 0}} colSpan={7}></td></tr>}
                                    </Fragment>
                                )}
                            </tbody>
                        </Table>

                        <Pagination totalCount={searchResult.totalElements || 0}
                            siblingCount={1}
                            currentPage={searchForm && searchForm.values && searchForm.values.pageNum ? searchForm.values.pageNum + 1 : 1}
                            pageSize={searchResult.pageSize || 10}
                            onPageChange={(page: number) => {
                                handlePageChange(page)
                            }}
                            className="justify-content-center" />
                    </>
                }
            </div>
        </div>
    )
}

export default ElencoPosizioniOrganizzative;
import { getUnixTime } from "date-fns";
import { JWTModel } from "../components/auth/jwtModel";
import jwt_decode from "jwt-decode";

export const getCookie = (cname: string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c !== name && c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function deleteJwtToken() {
    const jwtToken = getLocalStorageKey('au-user-token');
    const jsessionid = getCookie("JSESSIONID");
    if (!!jwtToken) {
        localStorage.removeItem('au-user-token');
    }
    if (!!jsessionid) {
        document.cookie = "JSESSIONID=";
    }
}

export function getUserFromCookieAuUserToken(): JWTModel | null | undefined {
    const cookie = getLocalStorageKey('au-user-token');
    if (!!cookie) {
        const decoded: JWTModel = jwt_decode(cookie);
        const result = getUnixTime(new Date())
        if (decoded.exp) {
            if (Number(decoded.exp) < Number(result)) {
                deleteJwtToken();
                return null;
            } else {    
                return decoded;
            }
        } 
    }
}

export function saveTokenFromUri() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('au-user-token');
    if(token) {
        localStorage.setItem("au-user-token", JSON.stringify(token));
        window.history.pushState({}, document.title, window.location.pathname);
    }
    return token;
}

export function saveTokenFromInput(token: string) {
    localStorage.setItem("au-user-token", JSON.stringify(token));
    window.history.pushState({}, document.title, window.location.pathname);
    return token;
}

export function getLocalStorageKey(key: string) {
    const saved = localStorage.getItem(key);
    return JSON.parse(saved!);
}
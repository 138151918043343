


import { RiepilogoBandoDetailInterface } from "../../models/RiepilogoBandoDetailInterface";
import { formatDate } from "../../utility/formatDate";

const RiepilogoBandoDetail = (props: {bando: RiepilogoBandoDetailInterface}) => {
    const {bando} = props;
    return (
        <div className="col-sm-12">
            <div className="card-div row">
                <div className="col-sm-12">
                    <div className="info-box-light info-box-light-bando">
                        <h6 className="mb-3">
                            Riepilogo bando
                        </h6>
                        <hr />
                        <div className="row">
                            <dl className="col-sm-12">
                                <div className="row">
                                    {bando.nomeBando && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Denominazione bando</dt>
                                            <dd className='col-sm-12'>{bando.nomeBando!}</dd>
                                        </div>
                                    )}
                                    {!!bando.idBando && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Identificativo</dt>
                                            <dd className='col-sm-12'>{bando.idBando!}</dd>
                                        </div>
                                    )}
                                    {!!bando.strutturaOrganizzativa && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Struttura organizzativa</dt>
                                            <dd className='col-sm-12'>{bando.strutturaOrganizzativa!}</dd>
                                        </div>
                                    )}
                                    {!!bando.dataApertura && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Apertura candidature</dt>
                                            <dd className='col-sm-12'>{formatDate(bando.dataApertura!)}</dd>
                                        </div>
                                    )}
                                    {!!bando.dataChiusura && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Chiusura candidature</dt>
                                            <dd className='col-sm-12'>{formatDate(bando.dataChiusura!)}</dd>
                                        </div>
                                    )}
                                    {!!bando.codiceCifraDeliberaDetermina && (
                                        <div className="col-sm-4">
                                            <dt className='col-sm-12'>Codice Cifra</dt>
                                            <dd className='col-sm-12'>{bando.codiceCifraDeliberaDetermina!}</dd>
                                        </div>
                                    )}
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RiepilogoBandoDetail;
import { useEffect, useState } from 'react'
import InformazioniPersonaliTab from './InformazioniPersonaliTab'
import EspProfessionaliTab from './EsperienzeProfessionaliTab'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import IstruzioneFormazioneTab from './IstruzioneFormazioneTab'
import CapacitaCompetenzaTab from './CapacitaCompetenze'
import { AllegatiTab } from './AllegatiTab'
import PrivacyTab from './PrivacyTab'
import { useAppSelector } from '../../hooks'
import { formatDate } from '../../utility/formatDate'
import { Callout } from 'design-react-kit'
import EspProfessionaliInRegioneTab from './EsperienzeProfessionaliInRegioneTab'
import { t } from 'i18next'

export interface GestioneCvDto {
    id?: number,
    nome?: string,
    cognome?: string
}

export interface GestioneCvFormProps {
    disabled: boolean
    scrollAction: Function,
}

export const GestioneCvForm = (props: GestioneCvFormProps) => {
    const[currentTab,setCurrentTab] = useState(0);
    const { detailResult } = useAppSelector((state) => state.curriculumVitae);

    useEffect(() => {
        props.scrollAction();
    }, [currentTab]);

    return (
        <div>
            {detailResult && (
                <div className="mb-3">
                    {detailResult.dataInserimento && detailResult.stato !== 'PUBBLICATO' &&
                    <dl className="row">
                        <dt className='col-sm-2'>Data inserimento</dt>
                        <dd className='col-sm-10'>{formatDate(detailResult?.dataInserimento)}</dd>
                    </dl>}
                    {detailResult.dataPubblicazione && 
                    <dl className="row">
                        <dt className='col-sm-2'>Data pubblicazione</dt>
                        <dd className='col-sm-10'>{formatDate(detailResult?.dataPubblicazione)}</dd>
                    </dl>}
                    {detailResult.dataModifica &&
                    <dl className="row">
                        <dt className='col-sm-2'>Data ultima modifica</dt>
                        <dd className='col-sm-10'>{formatDate(detailResult?.dataModifica)}</dd>
                    </dl>}
                </div>
            )}

            <div className='font-italic font-weight-medium mb-3'>
                {t("campiObbligatoriAsterisco")}
            </div>

            <Tabs onSelect={(e) => console.log(e)} selectedIndex={currentTab}>
                <TabList>
                    <Tab tabIndex="0" disabled={currentTab !== 0}>{"Introduzione"}</Tab>
                    <Tab tabIndex="1" disabled={currentTab !== 1}>{"Esperienze professionali in ARPAL Puglia"}</Tab>
                    <Tab tabIndex="2" disabled={currentTab !== 2}>{"Esperienze professionali"}</Tab>
                    <Tab tabIndex="3" disabled={currentTab !== 3}>{"Istruzione e formazione"}</Tab>
                    <Tab tabIndex="4" disabled={currentTab !== 4}>{"Capacità e competenze personali"}</Tab>
                    {/*<Tab tabIndex="4" disabled={currentTab !== 4}>{"Allegati"}</Tab>*/}
                    <Tab tabIndex="5" disabled={currentTab !== 5}>{"Privacy e autocertificazione"}</Tab>
                </TabList>

                <TabPanel>
                    <InformazioniPersonaliTab disabled={props.disabled} continuaAction={() => {setCurrentTab(1)}}  />
                </TabPanel>
                <TabPanel>
                    <EspProfessionaliInRegioneTab disabled={props.disabled} scrollAction={() => props.scrollAction()} indietroAction={() => setCurrentTab(0)} continuaAction={() => setCurrentTab(2)}  />
                </TabPanel>
                <TabPanel>
                    <EspProfessionaliTab disabled={props.disabled} scrollAction={() => props.scrollAction()} indietroAction={() => setCurrentTab(1)} continuaAction={() => setCurrentTab(3)}  />
                </TabPanel>
                <TabPanel>
                    <IstruzioneFormazioneTab disabled={props.disabled} scrollAction={() => props.scrollAction()} indietroAction={() => setCurrentTab(2)} continuaAction={() => setCurrentTab(4)} />
                </TabPanel>
                <TabPanel>
                    <CapacitaCompetenzaTab disabled={props.disabled} scrollAction={() => props.scrollAction()} indietroAction={() => setCurrentTab(3)} continuaAction={() => setCurrentTab(5)} />
                </TabPanel>
                {/* <TabPanel>
                    <AllegatiTab disabled={props.disabled} scrollAction={() => props.scrollAction()} indietroAction={() => setCurrentTab(3)} continuaAction={() => setCurrentTab(6)}/>
                </TabPanel> */}
                <TabPanel>
                    <PrivacyTab disabled={props.disabled} scrollAction={() => props.scrollAction()} indietroAction={() => setCurrentTab(4)} continuaAction={() => setCurrentTab(0)}/>
                </TabPanel>
            </Tabs>
        </div>
    );
}


import { useNavigate, useParams } from "react-router-dom"
import PageHeader from "../../components/page-header/PageHeader"
import { HREF_ELENCO_INCARICHI_DIRIGENZIALI } from "../../components/sidemenu/sidemenuConstants"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getContestoProposteIncarichi, getPropostaIncarico, reset } from "../../store/proposteIncarichiSlice"
import { STATUS_FULLFILLED,} from "../../store/store-constants"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { DatiAttoDto, IncaricoDirigenzialeDto } from "../../services/ms-anagrafica-unica"
import { Button, Icon, Table } from "design-react-kit"
import { downloadDocument } from "../../store/DocumentiSlice"
import { StrutturaOrganizzativaTipoEnum } from "../../services/ms-anagrafica-unica/model/struttura-organizzativa"
import { format } from "date-fns"
import DettaglioAtto from "../../components/dettaglio-atto-cifra/DettaglioAtto"

const VisualizzaIncarico = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { propostaSelezionataResult, propostaSelezionataStatus, contestoResult, contestoStatus } = useAppSelector((state) => state.proposteIncarichi);
    const { downloadedStatus } = useAppSelector((state) => state.documenti);
    const [ datiAtto, setDatiAtto ] = useState<DatiAttoDto>({});

    useEffect( () => {
        dispatch(reset())
        dispatch(getContestoProposteIncarichi())
        dispatch(getPropostaIncarico({id: Number(id)}))
            .unwrap().then( proposta => {
                setDatiAtto(proposta.assegnazioneIncarico?.datiAtto || {});
            })
    }, [])

    const retryButtonCallback = () => {
        dispatch(getPropostaIncarico({id: Number(id)}))
    }

    const riepilogoPropostaIncaricoVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
        const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(stato => 
            stato.label === 'INSERITA'
            || stato.label === 'ACCETTATA'
            || stato.label === 'RIGETTATA'
            || stato.label === 'NON_APPROVATA'
            || stato.label === 'APPROVATA'
            || stato.label === 'ASSEGNATA'
            || stato.label === 'TERMINATA')
            console.log('stati accettati >>> ', statiAmmessi);
            
        return proposta.stato && statiAmmessi?.find(stato => stato.valore === proposta.stato)
    }

    const valutazionePersonaleVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
        const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(stato => 
            stato.label === 'ACCETTATA'
            || stato.label === 'RIGETTATA'
            || stato.label === 'NON_APPROVATA'
            || stato.label === 'APPROVATA'
            || stato.label === 'ASSEGNATA'
            || stato.label === 'TERMINATA')
            console.log('stati accettati >>> ', statiAmmessi);
            
        return proposta.stato && statiAmmessi?.find(stato => stato.valore === proposta.stato)
    }

    const valutazioneGiuntaVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
        const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(stato => 
            stato.label === 'NON_APPROVATA'
            || stato.label === 'APPROVATA'
            || stato.label === 'ASSEGNATA'
            || stato.label === 'TERMINATA')
            console.log('stati accettati >>> ', statiAmmessi);
            
        return proposta.stato 
                && statiAmmessi?.find(stato => stato.valore === proposta.stato) 
                && (
                    proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaTipoEnum.Sezione
                    || proposta.tipoStrutturaOrganizzativa === StrutturaOrganizzativaTipoEnum.Dipartimento
                )
    }

    const assegnazioneVisualizzabile = (proposta: IncaricoDirigenzialeDto) => {
        const statiAmmessi = contestoResult?.statoPropostaIncarico?.filter(stato => stato.label === 'ASSEGNATA' || stato.label === 'TERMINATA')
            console.log('stati accettati >>> ', statiAmmessi);
            
        return proposta.stato 
                && statiAmmessi?.find(stato => stato.valore === proposta.stato)
    }

    const vediFile = (file:any) => {
        dispatch(downloadDocument({
            idDocumento: file.id
        })).unwrap().then(resp => {
            if (resp) {
                const blob = new Blob([resp], { type: file.contentType })
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = file.filename || '';
                link.click();
            }
        });
    }

    const downloadFile = (file:any) => {
        dispatch(downloadDocument({
            idDocumento: file.id
        })).unwrap().then(resp => {
            const blob = new Blob([resp], { type: file.contentType })
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noreferrer');
        });
    }

    const formatDataInizio = (proposta: IncaricoDirigenzialeDto) => {
        return proposta.assegnazioneIncarico && proposta.assegnazioneIncarico.dataInizio && format(new Date(proposta.assegnazioneIncarico.dataInizio), 'dd/MM/yyyy')
    }
    const formatDataFine = (proposta: IncaricoDirigenzialeDto) => {
        return proposta.assegnazioneIncarico && proposta.assegnazioneIncarico.dataFine && format(new Date(proposta.assegnazioneIncarico.dataFine), 'dd/MM/yyyy')
    }
    
    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_INCARICHI_DIRIGENZIALI} title={'visualizzazioneIncarico.titolo'} />
            {propostaSelezionataStatus === STATUS_FULLFILLED && !!propostaSelezionataResult && (<>
            <div className="col-sm-12">
                <div className="card-div">
                    <div className="col-sm-12">
                        <div className="info-box-light-so mb-5">
                            <h6 className='mb-3'>
                                Riepilogo della proposta
                            </h6>
                            <hr />
                            <div className="row">
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.denominazioneSo')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.denominazioneSo}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.codiceStruttura')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.codiceIncaricoSo}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.tipoStruttura')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.tipoStrutturaOrganizzativa}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.tipoIncarico')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.tipoIncarico}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.candidato')}</dt>
                                            {propostaSelezionataResult.candidatoInterno ? (
                                                <dd className='col-sm-12'>{propostaSelezionataResult.dipendenteRegionale?.nomeCompleto}</dd>
                                            ) : (
                                                <dd className='col-sm-12'>{`${propostaSelezionataResult.nomeCognomeEsterno} ${propostaSelezionataResult.codiceFiscaleEsterno ? `(${propostaSelezionataResult.codiceFiscaleEsterno})` : ''}`}</dd>
                                            )}
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.candidatoInterno')}</dt>
                                            {propostaSelezionataResult.candidatoInterno ? (
                                                <dd className='col-sm-12'>{t('si')}</dd>
                                            ) : (
                                                <dd className='col-sm-12'>{t('no')}</dd>
                                            )}
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.inComando')}</dt>
                                            {propostaSelezionataResult.inComando ? (
                                                <dd className='col-sm-12'>{t('si')}</dd>
                                            ) : (
                                                <dd className='col-sm-12'>{t('no')}</dd>
                                            )}
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('valutazioneProposta.riepilogo.estremiAttoConferimento')}</dt>
                                                <dd className='col-sm-12'>{propostaSelezionataResult?.assegnazioneIncarico?.estremiAttoConferimento}</dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                            <div className="row">
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                        {(datiAtto?.oggetto) && (
                                            <DettaglioAtto datiAtto={datiAtto} titolo="Dati Atto Conferimento"></DettaglioAtto>
                                        )}
                                        </div>
                                    </div>
                                </dl>
                            </div>
                            {riepilogoPropostaIncaricoVisualizzabile(propostaSelezionataResult) && 
                            <>
                            <hr />
                            <div className="row">
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.note')}</dt>
                                            <dd className='col-sm-12'>{!!propostaSelezionataResult.note || <small>Nessuna nota inserita</small>}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.documentoProposta')}</dt>
                                            <dd className='col-sm-12'>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    size="xs"
                                                    className="mx-2"
                                                    onClick={() => vediFile(propostaSelezionataResult.documenti?.at(0))}
                                                    >
                                                    <Icon
                                                        icon="it-password-visible"
                                                        size="xs"
                                                        color="primary"
                                                    ></Icon>{" "}
                                                    {propostaSelezionataResult.documenti?.at(0)?.documento?.filename}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    size="xs"
                                                    className="mx-2"
                                                    onClick={() => downloadFile(propostaSelezionataResult.documenti?.at(0))}
                                                    >
                                                    <Icon
                                                        icon="it-download"
                                                        size="xs"
                                                        color="primary"
                                                    ></Icon>{" "}
                                                    {propostaSelezionataResult.documenti?.at(0)?.documento?.filename}
                                                </Button>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                            </>
                            }
                        </div>
                        {valutazionePersonaleVisualizzabile(propostaSelezionataResult) && 
                        <div className="info-box-light-so mb-5">
                            <h6 className='mb-3'>
                                Valutazione del Personale
                            </h6>
                            <hr />
                            <div className="row">
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.valutazionePersonale')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.esitoValutazioneProposta}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.note')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.note || <small>Nessuna nota inserita</small>}</dd>
                                        </div>
                                    </div>
                                </dl>
                                {propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0) &&
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.documentoValutazione')}</dt>
                                            <dd className='col-sm-12'>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    size="xs"
                                                    className="mx-2"
                                                    onClick={() => downloadFile(propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0)?.documento)}
                                                    >
                                                    <Icon
                                                        icon="it-password-visible"
                                                        size="xs"
                                                        color="primary"
                                                    ></Icon>{" "}
                                                    {propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0)?.documento?.filename}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    size="xs"
                                                    className="mx-2"
                                                    onClick={() => vediFile(propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0)?.documento)}
                                                    >
                                                    <Icon
                                                        icon="it-download"
                                                        size="xs"
                                                        color="primary"
                                                    ></Icon>{" "}
                                                    {propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0)?.documento?.filename}
                                                </Button>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                                }
                            </div>
                        </div>
                        }
                        {valutazioneGiuntaVisualizzabile(propostaSelezionataResult) && 
                        <div className="info-box-light-so mb-5">
                            <h6 className='mb-3'>
                                Valutazione della Giunta
                            </h6>
                            <hr />
                            <div className="row">
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.valutazioneGiunta')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.esitoGiuntaValutazioneProposta}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.note')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.noteGiunta || <small>Nessuna nota inserita</small>}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.documentoValutazione')}</dt>
                                            <dd className='col-sm-12'>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    size="xs"
                                                    className="mx-2"
                                                    onClick={() => vediFile(propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0))}
                                                    >
                                                    <Icon
                                                        icon="it-password-visible"
                                                        size="xs"
                                                        color="primary"
                                                    ></Icon>{" "}
                                                    {propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0)?.documento?.filename}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    size="xs"
                                                    className="mx-2"
                                                    onClick={() => downloadFile(propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0))}
                                                    >
                                                    <Icon
                                                        icon="it-download"
                                                        size="xs"
                                                        color="primary"
                                                    ></Icon>{" "}
                                                    {propostaSelezionataResult.valutazionePropostaIncaDirigenziale?.documentiDto?.at(0)?.documento?.filename}
                                                </Button>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        }
                        {assegnazioneVisualizzabile(propostaSelezionataResult) && 
                        <div className="info-box-light-so mb-5">
                            <h6 className='mb-3'>
                                Assegnazione
                            </h6>
                            <hr />
                            <div className="row">
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.dipendenteAssegnato')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.assegnazioneIncarico?.nomeCompletoDipendente}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.cid')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.assegnazioneIncarico?.idDipendente}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.dataInizioIncarico')}</dt>
                                            <dd className='col-sm-12'>{formatDataInizio(propostaSelezionataResult)}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.dataFineIncarico')}</dt>
                                            <dd className='col-sm-12'>{formatDataFine(propostaSelezionataResult)}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dt className='col-sm-12'>{t('visualizzazioneIncarico.riepilogo.atto')}</dt>
                                            <dd className='col-sm-12'>{propostaSelezionataResult.assegnazioneIncarico?.estremiAttoConferimento}</dd>
                                        </div>
                                    </div>
                                </dl>
                                {propostaSelezionataResult.assegnazioneIncarico && propostaSelezionataResult.assegnazioneIncarico.elencoModifiche && propostaSelezionataResult.assegnazioneIncarico.elencoModifiche.length > 0 && (
                                    <Table>
                                        <tr>
                                            <th>N modifica</th>
                                            <th>Note</th>
                                            <th>File</th>
                                        </tr>
                                        {propostaSelezionataResult.assegnazioneIncarico.elencoModifiche.map ((proposta) => (

                                        <tr key={proposta.codiceUnivoco}>
                                            <td>{proposta.nmodifica}</td>
                                            <td>{proposta.note}</td>
                                            <td>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    size="xs"
                                                    className="mx-2"
                                                    onClick={() => vediFile(proposta.documento)}
                                                    >
                                                    <Icon
                                                        icon="it-password-visible"
                                                        size="xs"
                                                        color="primary"
                                                    ></Icon>{" "}
                                                    {proposta.documento?.filename}
                                                </Button>
                                            </td>
                                        </tr>
                                        ))}
                                    </Table>
                                )}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row-reverse">
                <Button color='secondary' className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_INCARICHI_DIRIGENZIALI}`) }} >{t('annulla')} </Button>
            </div>
            </>)}
        </div>
    )
}

export default VisualizzaIncarico
/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AttivitaIncarichiResponsabilitaRequestDto } from './attivita-incarichi-responsabilita-request-dto';

/**
 * 
 * @export
 * @interface EsperienzeProfessionaliInRegioneRequestDto
 */
export interface EsperienzeProfessionaliInRegioneRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'da'?: string;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'al'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'inCorso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'tipologiaDipendente'?: EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum;
    /**
     * 
     * @type {number}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'idAreaInquadramento'?: number;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'areaInquadramentoLabel'?: string;
    /**
     * 
     * @type {number}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'idProfiloProfessionale'?: number;
    /**
     * 
     * @type {number}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'idAmbitoDiRuolo'?: number;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'principaliAttivitaResponsabilita'?: string;
    /**
     * 
     * @type {Array<AttivitaIncarichiResponsabilitaRequestDto>}
     * @memberof EsperienzeProfessionaliInRegioneRequestDto
     */
    'elencoAttivita'?: Array<AttivitaIncarichiResponsabilitaRequestDto>;
}

export const EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum = {
    Dirigente: 'DIRIGENTE',
    Comparto: 'COMPARTO'
} as const;

export type EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum = typeof EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum[keyof typeof EsperienzeProfessionaliInRegioneRequestDtoTipologiaDipendenteEnum];



import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { detailsStruttureOrganizzative, assegnazioneUtenzeCifra, getUtenzeCifraByStruttura, deleteUtenzeCifra, utenzaCifraRequest, resetUpdateForm } from '../../store/strutturaOrganizzativaSlice';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail-form/StruttureOrganizzativeDetailsForm';
import { Alert, Button, Icon, Input, Table } from 'design-react-kit';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/sidemenu/sidemenuConstants';
import { useFormik } from 'formik';
import { CITTA, CODICE,  CODICE_CIFRA_DELIBERA, CODICE_CIFRA_DETERMINA, CODICE_DIPA_RAGIONERIA, CODICE_IPA,  CODICE_SEZI_RAGIONERIA,  DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, DIPARTIMENTO, EMAIL, FAX, INDIRIZZO, PEC, TELEFONO, TIPOLOGIA_STRUTTURA } from './struttureOrganizzativeConstants';
import PageLoader from '../../components/page-loader/PageLoader';
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from '../../store/store-constants';
import { SOUtenzaCifraRequestDto } from '../../services/ms-anagrafica-unica';
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import PageHeader from '../../components/page-header/PageHeader';

const initialValues = {
  utenteCifra: ''
}

const schema = z
  .object({
    utenteCifra: z.string({ required_error: 'required' }).trim().min(1).max(15, 'lenghtutenteCifra'),
  })

const AssegnazioneUtenzaCifra = () => {

  const { t } = useTranslation()
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fieldUsed: Array<string> = [CODICE, DENOMINAZIONE, DECLARATORIA, DATA_INIZIO_VALIDITA, TIPOLOGIA_STRUTTURA, DIPARTIMENTO, EMAIL, PEC, TELEFONO, FAX, INDIRIZZO, CITTA, CODICE_CIFRA_DETERMINA, CODICE_CIFRA_DELIBERA,  CODICE_DIPA_RAGIONERIA, CODICE_SEZI_RAGIONERIA, CODICE_IPA]

  useEffect(() => {
    dispatch(resetUpdateForm())
    getDetailsFromApi(id!)
  }, [0])

  const strutturaOrganizzativa = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult);
  const strutturaOrganizzativaResult = useAppSelector((state) => state.strutturaOrganizzativa.detailsStatus);

  const utenzaCifraList = useAppSelector((state) => state.strutturaOrganizzativa.utenzaCifraListResult);
  const utenzaCifraListStatus = useAppSelector((state) => state.strutturaOrganizzativa.utenzaCifraListStatus);
  const utenzaCifraInsertStatus = useAppSelector((state) => state.strutturaOrganizzativa.utenzaCifraStatus);
  const [utenze, setUtenze] = useState<SOUtenzaCifraRequestDto>()
  const [tableVisual, setVisualTable] = useState(false)
  const [utenzeArray, setUtenzeArray] = useState<string[]>([]);
  const [, setSubmitData] = useState(false)


  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  }

  const insert = () => {
    const body: utenzaCifraRequest = {
      idStrutturaOrganizzativa: strutturaOrganizzativa?.id,
      bodyRequest: utenze
    }

    dispatch(assegnazioneUtenzeCifra(body))
    setUtenzeArray([]);
    setSubmitData(true);
  }

  useEffect(() => {
    getList();
  }, [strutturaOrganizzativa])

  useEffect(() => {
    getList();
  }, [utenzaCifraInsertStatus])

  useEffect(() => {
    console.log('utenzaCifraList', utenzaCifraList?.length)
    console.log('utenzeArray', utenzeArray.length)
    if (utenzaCifraList?.length || utenzeArray.length) {
      setVisualTable(true)
    } else {
      setVisualTable(false)
    }
  }, [utenzaCifraList, utenzeArray])



  const getList = () => {
    dispatch(getUtenzeCifraByStruttura(strutturaOrganizzativa?.id!))
  }

  const formData = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: values => {
      const newUtenzeArray = [
        ...utenzeArray,
        values.utenteCifra
      ];
      setUtenzeArray(newUtenzeArray);
      setUtenze({ utenteCifra: newUtenzeArray });
      formData.handleReset(values);
      if (newUtenzeArray.length > 0) {
        setVisualTable(true)
      }
    },
  })


  const submitForm = () => {
    formData.submitForm();
  }


  const eliminaUtenzaCifra = (id: number) => {
    dispatch(deleteUtenzeCifra(id))
    setSubmitData(true)
  }

  const rimuoviUtenzeCifraArray = (index: number) => {
    const newUtenzeArray = utenzeArray.splice(index, 1);
    setUtenzeArray(newUtenzeArray);
    setUtenze({ utenteCifra: newUtenzeArray });
    if (newUtenzeArray.length <= 0 && utenzaCifraList?.length! <= 0) {
      setVisualTable(false)
    }
  }

  const getFieldError = (
    form: any,
    fieldName: any
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || "";
    } else return "";
  };



  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} title={t("assegnazione utenza CIFRA")!}></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED && <div>
        <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} />
        <div className="row mt-5">
          <div className="col-12">
            {
              utenzaCifraListStatus == STATUS_PENDING &&
              <PageLoader loadingMessage='Caricamento...' />
            }
            {
              utenzaCifraListStatus == STATUS_REJECTED &&
              <Alert color="danger">Siamo spiacenti, si &egrave; verificato un errore durante la richiesta</Alert>
            }
            {utenzaCifraListStatus === STATUS_FULLFILLED && <div>
              <div className="postion-relative">
                <div className="form-row">
                  <div className="col-10">
                    <Input
                      label='Utenza CIFRA'
                      type='text'
                      placeholder='Inserisci utenza CIFRA '
                      id='input-utenteCifra'
                      wrapperClassName="form-group required col-md-12"
                      name="utenteCifra"
                      value={formData.values.utenteCifra}
                      onChange={formData.handleChange}
                      invalid={!!getFieldError(formData, "utenteCifra")}
                      infoText={t(getFieldError(formData, "utenteCifra")) || ""}
                    />
                  </div>
                  <div className="col-2">
                    <Button outline color='primary' onClick={() => submitForm()}> <Icon icon='it-plus-circle' aria-hidden size='sm' color="primary" />Aggiungi</Button>
                  </div>
                </div>
              </div>

              {tableVisual && <Table striped size='sm' className='col-md-10'>
                <thead>
                  <tr>
                    <th></th>
                    <th className='col-md-10'>Utenza CIFRA</th>
                    <th className='col-md-2'></th>
                  </tr>
                </thead>
                <tbody>
                  {utenzaCifraList?.map((value, index) =>
                    <tr key={index}>
                      <td></td>
                      <td> {value.utenteCifra}</td>
                      <td> <Button size='xs' color='danger' outline onClick={() => eliminaUtenzaCifra(value.id!)} >  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                    </tr>
                  )}
                  {utenzeArray?.map((value, index) =>
                    <tr key={index}>
                      <td  className='notSaveElement'>*</td>
                      <td> {value}</td>
                      <td> <Button size='xs' color='danger' outline onClick={() => rimuoviUtenzeCifraArray(index)} >  <Icon className="bg-grey" color="danger" icon="it-delete" size="xs" />    Rimuovi </Button></td>
                    </tr>
                  )}
                </tbody>
              </Table>}
              {utenzeArray.length > 0 && <span>I campi contrassegnati da * non sono ancora stati salvati</span>}
            </div>}
          </div>
        </div>
        <div className="postion-relative py-5">
          <div className="d-flex flex-row-reverse">
            <Button color='primary' className="mt-2" disabled={utenzeArray.length <= 0} onClick={() => { insert() }}>{t('conferma')}</Button>
            <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`) }} >{t('annulla')}</Button>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default AssegnazioneUtenzaCifra
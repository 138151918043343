import { format } from 'date-fns'
import { it } from 'date-fns/locale'

export const formatDate = (date:string, numericFormat?:boolean) => {
    if (!date)
        return ''

    let mese = '';
    let separatore = '/';

    if (numericFormat) {
        mese = format(new Date(date), 'MM', {locale: it});
    } else {
        mese = format(new Date(date), 'MMMM', {locale: it});
        mese = mese.charAt(0).toUpperCase() + mese.slice(1).toLowerCase();
        separatore = ' ';
    }

    const giorno = format(new Date(date), 'dd');
    const anno = format(new Date(date), 'yyyy');

    return `${giorno}${separatore}${mese}${separatore}${anno}`
}
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {detailsPosizioneOrganizzativa, editPosizioneOrganizzativa} from '../../store/posizioneOrganizzativaSlice';
import {
    CODICE_UNIVOCO_PO,
    DATA_FINE_VALIDITA_PO,
    DATA_INIZIO_VALIDITA_PO,
    DECLARATORIA_PO,
    DENOMINAZIONE_PO,
    STRUTTURA_ORGANIZZATIVA_PO,
    TIPO_PO
} from './posizioniOrganizzativeConstants';
import {STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED} from '../../store/store-constants';
import {Button} from 'design-react-kit';
import PosizioniOrganizzativeDetailsComponent
    from '../../components/posizioni-organizzative-detail-component/PosizioniOrganizzativeDetailsComponent';
import {HREF_ELENCO_POSIZIONI_ORGANIZZATIVE} from '../../components/sidemenu/sidemenuConstants';
import DocumentUpload from '../../components/document-upload/DocumentUpload';
import {
    PosizioneOrganizzativaDtoStatoEnum,
    PosizioneOrganizzativaModificaRequestDtoAllOf
} from "../../services/ms-anagrafica-unica";
import {FormikProps, useFormik} from "formik";
import {z} from "zod";
import {toFormikValidationSchema} from "zod-formik-adapter";
import PageHeader from '../../components/page-header/PageHeader';

const ModificaPosizioneOrganizzativa = () => {
    const {t} = useTranslation();
    const {idCodiceIncarico} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fieldUsed: Array<string> = [CODICE_UNIVOCO_PO, DENOMINAZIONE_PO, DECLARATORIA_PO, STRUTTURA_ORGANIZZATIVA_PO, DATA_INIZIO_VALIDITA_PO, DATA_FINE_VALIDITA_PO, TIPO_PO];

    const posizioneOrganizzativa = useAppSelector((state) => state.posizioneOrganizzativa.detailsResult);
    const posizioneOrganizzativaResult = useAppSelector((state) => state.posizioneOrganizzativa.detailsStatus);

    useEffect(() => {
        getDetail();
    }, [0])

    const getDetailsFromApi = (idCodiceIncarico: string) => {
        dispatch(detailsPosizioneOrganizzativa({idCodiceIncarico}));
    }

    const getDetail = () => {
        getDetailsFromApi(idCodiceIncarico!);
    }

    const mostraUploadDetermina = () => {
        return posizioneOrganizzativa?.stato !== PosizioneOrganizzativaDtoStatoEnum.Inserita && 
                    posizioneOrganizzativa?.stato !== PosizioneOrganizzativaDtoStatoEnum.InseritaEComunicata
    }

    const initialValues: PosizioneOrganizzativaModificaRequestDtoAllOf = {
        idNotaPropostaIstituzione: posizioneOrganizzativa ? posizioneOrganizzativa.idNotaPropostaIstituzione : undefined,
        idNotaDiUscita: posizioneOrganizzativa ? posizioneOrganizzativa.idNotaDiUscita : undefined,
        idNullaOsta: posizioneOrganizzativa ? posizioneOrganizzativa.idNullaOsta : undefined,
        idDeterminaIstituzionAvviso: posizioneOrganizzativa? posizioneOrganizzativa.idDeterminaIstituzioneAvviso : undefined
    };

    const schema = z.object({
        idNotaPropostaIstituzione: z.number({required_error: "Nota proposta è un campo obbligatorio"}),
        idNotaDiUscita: z.number({required_error: "Nota di uscita è un campo obbligatorio"}),
        idNullaOsta: z.number({required_error: "Nulla osta è un campo obbligatorio"}),
        idDeterminaIstituzionAvviso: z.coerce.number().optional(),
    }).refine((data) => {
        if (mostraUploadDetermina()) 
            if(!data.idDeterminaIstituzionAvviso)
                return false
        return true
    },     {
        message: "Determina Istituzione e avviso è un campo obbligatorio",
        path: ["idDeterminaIstituzionAvviso"], // path of error
    });


    const formik: FormikProps<PosizioneOrganizzativaModificaRequestDtoAllOf> = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: values => {
            console.log('Submit form', JSON.stringify(values, null, 2));
            dispatch(editPosizioneOrganizzativa({
                patchPosizioneOrganizzativaRequest: {requestType: 'Modifica', codiceUnivoco: posizioneOrganizzativa ? posizioneOrganizzativa.codiceUnivoco : undefined, ...values},
                idCodiceIncarico: idCodiceIncarico!
            }));
        }
    });

    const setDocumentId = (fieldName: string, id: number | undefined) => {
        if (id && id != -1) {
            formik.setFieldValue(fieldName, id);
        } else {
            formik.setFieldValue(fieldName, null);
            console.error('errore durante la chiamata di caricamento documento');
        }
    }

    const getFieldError = (form: FormikProps<PosizioneOrganizzativaModificaRequestDtoAllOf>, fieldName: keyof PosizioneOrganizzativaModificaRequestDtoAllOf): string => {
        if (form.getFieldMeta(fieldName).touched) {
            return form.errors[fieldName] || "";
        } else return "";
    };

    return (
        <div>
            <PageHeader showIcon={true} urlBack={HREF_ELENCO_POSIZIONI_ORGANIZZATIVE} title={t("modifica elevata qualificazione")!}></PageHeader>
            {posizioneOrganizzativaResult === STATUS_FULLFILLED && <div>
                <div className="row">
                    <div className="col-12">
                        <PosizioniOrganizzativeDetailsComponent fields={fieldUsed} value={posizioneOrganizzativa!}/>
                    </div>
                </div>
                <div className={'form-row'}>
                    <div className="col-12">
                        <DocumentUpload documentName="Nota proposta di istituzione" className="my-3" idDocumento={formik.values.idNotaPropostaIstituzione? formik.values.idNotaPropostaIstituzione :posizioneOrganizzativa!.idNotaPropostaIstituzione}
                                        invalid={!!getFieldError(formik, "idNotaPropostaIstituzione")} infoText={t(getFieldError(formik, "idNotaPropostaIstituzione")) || ""}
                                        setDocumentId={(id) => setDocumentId('idNotaPropostaIstituzione', id)}/>
                        <DocumentUpload documentName="Nulla Osta" className="my-3" idDocumento={formik.values.idNullaOsta? formik.values.idNullaOsta :posizioneOrganizzativa!.idNullaOsta}
                                        invalid={!!getFieldError(formik, "idNullaOsta")} infoText={t(getFieldError(formik, "idNullaOsta")) || ""}
                                        setDocumentId={(id) => setDocumentId('idNullaOsta', id)}/>
                        <DocumentUpload documentName="Nota di uscita" className="my-3" idDocumento={ formik.values.idNotaDiUscita? formik.values.idNotaDiUscita : posizioneOrganizzativa!.idNotaDiUscita}
                                        invalid={!!getFieldError(formik, "idNotaDiUscita")} infoText={t(getFieldError(formik, "idNotaDiUscita")) || ""}
                                        setDocumentId={(id) => setDocumentId('idNotaDiUscita', id)}/>
                        {mostraUploadDetermina() && <DocumentUpload documentName="Determina di Istituzione EQ e Avviso" className="my-3" idDocumento={formik.values.idDeterminaIstituzionAvviso? formik.values.idDeterminaIstituzionAvviso :posizioneOrganizzativa!.idDeterminaIstituzioneAvviso}
                                        invalid={!!getFieldError(formik, "idDeterminaIstituzionAvviso")}
                                        infoText={t(getFieldError(formik, "idDeterminaIstituzionAvviso")) || ""}
                                        setDocumentId={(id) => setDocumentId('idDeterminaIstituzionAvviso', id)}/>}
                    </div>
                </div>
                <div className="py-5">
                    <div className="d-flex flex-row-reverse">
                        <Button color='primary' className="mt-2" onClick={formik.submitForm}>Modifica</Button>
                        <Button outline color='primary' className="mt-2 mr-2"
                                onClick={() => navigate(HREF_ELENCO_POSIZIONI_ORGANIZZATIVE)}>Indietro</Button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ModificaPosizioneOrganizzativa
import { DipendenteRegionaleRubricaDto, InformazioniPersonaliDto } from "../../../services/ms-anagrafica-unica";

const InformazioniPersonaliDetail = (props: {dati: InformazioniPersonaliDto, datiAnagrafici: DipendenteRegionaleRubricaDto}) => {
    const {dati, datiAnagrafici} = props;
    return (
        <div className="row">
            <dl className="col-sm-12">
                <div className="row">
                    {datiAnagrafici.nomeCompleto && (
                        <div className="col-sm-4">
                            <dt className='col-sm-12'>Nome e Cognome</dt>
                            <dd className='col-sm-12'>{datiAnagrafici.nomeCompleto}</dd>
                        </div>
                    )}
                    {datiAnagrafici.email && (
                        <div className="col-sm-4">
                            <dt className='col-sm-12'>Email</dt>
                            <dd className='col-sm-12'>{datiAnagrafici.email}</dd>
                        </div>
                    )}
                </div>
            </dl>
        </div>
    );
}

export default InformazioniPersonaliDetail;
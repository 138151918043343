import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ElencoDipendentiRegionaliForm, ElencoStruttureOrganizzativeForm, FormsControllerApi, FormsControllerApiGetElencoSORequest, GestioneIncarichiDirigenzialiControllerApi, GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest, GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest, GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest, GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest, GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest, GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest, GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest, GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest, GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest, GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest, IncaricoDirigenzialeDto, InsertIncaricoDirigForm, InsertUpdateIncarichiDirigenzialiRequestDto, InsertUpdateIncarichiDirigenzialiRequestDtoRequestTypeEnum, PagedDtoIncaricoDirigenzialeDto, ValutazionePropostaIncaDirigDto } from "../services/ms-anagrafica-unica";
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants";

export interface ProposteIncarichiState {
    searchResult: PagedDtoIncaricoDirigenzialeDto,
    searchStatus: string,
    searchInput: GestioneIncarichiDirigenzialiControllerApiInsertIncaricoDirigenzialeRequest | null,
    insertStatus: string,
    insertInput: InsertUpdateIncarichiDirigenzialiRequestDtoRequestTypeEnum | null,
    updateStatus: string,
    updateInput: GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest | null,
    contestoResult: InsertIncaricoDirigForm | null,
    contestoStatus: string,
    soSelezionabili: ElencoStruttureOrganizzativeForm | null,
    dipendentiSelezionabili: ElencoDipendentiRegionaliForm | null,
    propostaSelezionataResult: IncaricoDirigenzialeDto | null,
    propostaSelezionataStatus: string,
    propostaSelezionataInput: GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest| null,
    searchValutazioniResult: ValutazionePropostaIncaDirigDto | null,
    searchValutazioniStatus: string,
    searchValutazioniInput: GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest | null,
    insertValutazioneStatus: string,
    insertValutazioneInput: GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest | null,
    updateValutazioneStatus: string,
    updateValutazioneInput: GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest | null,
    insertAssegnazioneStatus: string,
    insertAssegnazioneInput: GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest | null,
    updateAssegnazioneStatus: string,
    updateAssegnazioneInput: GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest | null,
    updateApprovazioneStatus: string
}

const initialState: ProposteIncarichiState = {
    searchResult: {
        pageNum: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        data: []
    },
    searchStatus: '',
    searchInput: null,
    insertStatus: '',
    insertInput: null,
    updateStatus: '', 
    updateInput: null,
    contestoResult: null,
    contestoStatus: '',
    soSelezionabili: null,
    dipendentiSelezionabili: null,
    propostaSelezionataResult: null, 
    propostaSelezionataStatus: '',
    propostaSelezionataInput: null,
    searchValutazioniResult: null,
    searchValutazioniStatus: '',
    searchValutazioniInput: null,
    insertValutazioneStatus: '', 
    insertValutazioneInput: null,
    updateValutazioneStatus: '', 
    updateValutazioneInput: null,
    insertAssegnazioneStatus: '', 
    insertAssegnazioneInput: null,
    updateAssegnazioneStatus: '',
    updateAssegnazioneInput: null,
    updateApprovazioneStatus: ''
}

export const searchProposteIncarichi = createAsyncThunk(
    'proposteIncarico/proposta/search',
    async (args: GestioneIncarichiDirigenzialiControllerApiSearchIncaricoDirigenzialeRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.searchIncaricoDirigenziale(args);
        return response.data;
    }
)

export const getContestoProposteIncarichi = createAsyncThunk(
    'proposteIncarico/form/getContesto',
    async () => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getInsertIncaricoDirigForm();
        return response.data;
    }
)

export const insertPropostaIncarico = createAsyncThunk(
    'proposteIncarico/insert',
    async (body: InsertUpdateIncarichiDirigenzialiRequestDto) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.insertIncaricoDirigenziale({
            insertUpdateIncarichiDirigenzialiRequestDto: body
        })
        return response.data;
    }
)

export const updatePropostaIncarico = createAsyncThunk(
    'proposteIncarico/update',
    async (body: GestioneIncarichiDirigenzialiControllerApiUpdateIncaricoDirigenzialeRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.updateIncaricoDirigenziale({
            id: body.id,
            insertUpdateIncarichiDirigenzialiRequestDto: body.insertUpdateIncarichiDirigenzialiRequestDto
        })
        return response.data;
    }
)

export const getPropostaIncarico = createAsyncThunk(
    'proposteIncarico/show',
    async (args: GestioneIncarichiDirigenzialiControllerApiSearchByIdIncaricoDirigenzialeRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.searchByIdIncaricoDirigenziale(args)
        return response.data;
    }
)

export const getElencoSo = createAsyncThunk(
    'proposteIncarico/getElencoSo',
    async (args: FormsControllerApiGetElencoSORequest, {dispatch}) => {
        const api = new FormsControllerApi(MS_AU_CONFIG);
        const response = await api.getElencoSO(args)
        dispatch(saveStruttureOrganizzativeSelezionabili(response.data))
        return response.data;
    }
)

export const getValutazioniPropostaIncarico = createAsyncThunk(
    'proposteIncarico/valutazioni/search',
    async (args: GestioneIncarichiDirigenzialiControllerApiSearchValutazioniRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.searchValutazioni(args)
        return response.data;
    }
)


export const insertValutazionePropostaIncarico = createAsyncThunk(
    'proposteIncarico/valutazioni/insert',
    async (args: GestioneIncarichiDirigenzialiControllerApiInsertValutazionePersonaleRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.insertValutazionePersonale(args)
        return response.data;
    }
)

export const updateValutazionePropostaIncarico = createAsyncThunk(
    'proposteIncarico/valutazioni/update',
    async (args: GestioneIncarichiDirigenzialiControllerApiPatchValutazionePersonaleRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.patchValutazionePersonale(args)
        return response.data;
    }
)

export const updateApprovazionePropostaIncarico = createAsyncThunk(
    'proposteIncarico/approvazione/update',
    async (args: GestioneIncarichiDirigenzialiControllerApiValutazioneGiuntaRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.valutazioneGiunta(args)
        return response.data;
    }
)

export const insertAssegnazioneIncarico = createAsyncThunk(
    'proposteIncarico/assegnazione/ìnsert',
    async (args: GestioneIncarichiDirigenzialiControllerApiInsertAssegnazioneIncaricoDirigenzialeRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.insertAssegnazioneIncaricoDirigenziale(args)
        return response.data;
    }
)

export const updateAssegnazioneIncarico = createAsyncThunk(
    'proposteIncarico/assegnazione/update',
    async (args: GestioneIncarichiDirigenzialiControllerApiUpdateAssegnazioneIncaricoDirigenzialeRequest) => {
        const api = new GestioneIncarichiDirigenzialiControllerApi(MS_AU_CONFIG);
        const response = await api.updateAssegnazioneIncaricoDirigenziale(args)
        return response.data;
    }
)

export const proposteIncarichiSlice = createSlice({
    name: 'proposteIncarico',
    initialState,
    reducers: {
        reset: (state) => {
            state = {
                ...state,
                ...initialState
            }
        },
        saveStruttureOrganizzativeSelezionabili: (state, action) => {
            state.soSelezionabili = action.payload
        },
        saveDipendentiSelezionabili: (state, action) => {
            state.dipendentiSelezionabili = action.payload
        }
    },
    extraReducers: (builder) => {
        // Recupero del contesto per le proposte di incarichi dirigenziali
        builder.addCase(getContestoProposteIncarichi.pending, (state: any, action: any) => {
            state.contestoStatus = STATUS_PENDING;
        });
        builder.addCase(getContestoProposteIncarichi.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.contestoResult = action.payload;
            state.contestoStatus = STATUS_FULLFILLED;
        });
        builder.addCase(getContestoProposteIncarichi.rejected, (state: any, action: any) => {
            state.contestoStatus = STATUS_REJECTED;
        });

        // // Recupero SO selezionabili
        // builder.addCase(getElencoSo.pending, (state: any, action: any) => {
        //     state.elencoSoStatus = STATUS_PENDING;
        // });
        // builder.addCase(getElencoSo.fulfilled, (state: ProposteIncarichiState, action: any) => {
        //     state.elencoSoResult = action.payload;
        //     state.elencoSoStatus = STATUS_FULLFILLED;
        // });
        // builder.addCase(getElencoSo.rejected, (state: any, action: any) => {
        //     state.elencoSoStatus = STATUS_REJECTED;
        // });
        
        // Insert nuova proposta
        builder.addCase(insertPropostaIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.insertStatus = STATUS_PENDING;
            state.insertInput = action.meta.arg;
        });
        builder.addCase(insertPropostaIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.insertStatus = STATUS_FULLFILLED;
        });
        builder.addCase(insertPropostaIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.insertStatus = STATUS_REJECTED;
        });
        
        // Update proposta
        builder.addCase(updatePropostaIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.updateStatus = STATUS_PENDING;
            state.updateInput = action.meta.arg;
        });
        builder.addCase(updatePropostaIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.updateStatus = STATUS_FULLFILLED;
        });
        builder.addCase(updatePropostaIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.updateStatus = STATUS_REJECTED;
        });

        // Update proposta
        builder.addCase(updateApprovazionePropostaIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.updateApprovazioneStatus = STATUS_PENDING;
        });
        builder.addCase(updateApprovazionePropostaIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.updateApprovazioneStatus = STATUS_FULLFILLED;
        });
        builder.addCase(updateApprovazionePropostaIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.updateApprovazioneStatus = STATUS_REJECTED;
        });

        // Recupero di una proposta di incarico
        builder.addCase(getPropostaIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.propostaSelezionataStatus = STATUS_PENDING;
        });
        builder.addCase(getPropostaIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.propostaSelezionataResult = action.payload;
            state.propostaSelezionataStatus = STATUS_FULLFILLED;
            state.propostaSelezionataInput = action.meta.arg;
        });
        builder.addCase(getPropostaIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.propostaSelezionataStatus = STATUS_REJECTED;
        });        

        // Ricerca proposte incarico
        builder.addCase(searchProposteIncarichi.pending, (state: any, action: any) => {
            state.searchStatus = STATUS_PENDING;
        });
        builder.addCase(searchProposteIncarichi.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.searchResult = action.payload;
            state.searchStatus = STATUS_FULLFILLED;
            state.searchInput = action.meta.arg;
            
        });
        builder.addCase(searchProposteIncarichi.rejected, (state: any, action: any) => {
            state.searchStatus = STATUS_REJECTED;
        });

        // Ricerca valutazioni incarico
        builder.addCase(getValutazioniPropostaIncarico.pending, (state: any, action: any) => {
            state.searchValutazioniStatus = STATUS_PENDING;
        });
        builder.addCase(getValutazioniPropostaIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.searchValutazioniResult = action.payload;
            state.searchValutazioniStatus = STATUS_FULLFILLED;
            state.searchValutazioniInput = action.meta.arg;
            
        });
        builder.addCase(getValutazioniPropostaIncarico.rejected, (state: any, action: any) => {
            state.searchStatus = STATUS_REJECTED;
        });

        // Insert valutazione proposta
        builder.addCase(insertValutazionePropostaIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.insertValutazioneStatus = STATUS_PENDING;
            state.insertValutazioneInput = action.meta.arg;
        });
        builder.addCase(insertValutazionePropostaIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.insertValutazioneStatus = STATUS_FULLFILLED;
        });
        builder.addCase(insertValutazionePropostaIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.insertValutazioneStatus = STATUS_REJECTED;
        });
        // Update valutazione proposta
        builder.addCase(updateValutazionePropostaIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.updateValutazioneStatus = STATUS_PENDING;
            state.updateValutazioneInput = action.meta.arg;
        });
        builder.addCase(updateValutazionePropostaIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.updateValutazioneStatus = STATUS_FULLFILLED;
        });
        builder.addCase(updateValutazionePropostaIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.updateValutazioneStatus = STATUS_REJECTED;
        });
        // Insert assegnazione incarico
        builder.addCase(insertAssegnazioneIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.insertAssegnazioneStatus = STATUS_PENDING;
            state.insertAssegnazioneInput = action.meta.arg;
        });
        builder.addCase(insertAssegnazioneIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.insertAssegnazioneStatus = STATUS_FULLFILLED;
        });
        builder.addCase(insertAssegnazioneIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.insertAssegnazioneStatus = STATUS_REJECTED;
        });
        
        // Update assegnazione incarico
        builder.addCase(updateAssegnazioneIncarico.pending, (state: ProposteIncarichiState, action: any) => {
            state.updateAssegnazioneStatus = STATUS_PENDING;
            state.updateAssegnazioneInput = action.meta.arg;
        });
        builder.addCase(updateAssegnazioneIncarico.fulfilled, (state: ProposteIncarichiState, action: any) => {
            state.updateAssegnazioneStatus = STATUS_FULLFILLED;
        });
        builder.addCase(updateAssegnazioneIncarico.rejected, (state: ProposteIncarichiState, action: any) => {
            state.updateAssegnazioneStatus = STATUS_REJECTED;
        });
    }
});

export const { reset, saveStruttureOrganizzativeSelezionabili, saveDipendentiSelezionabili } = proposteIncarichiSlice.actions

export default proposteIncarichiSlice.reducer;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ConsulenteEsternoControllerApi, ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest, ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest, DipendenteRegionaleDto, PagedDtoDipendenteRegionaleDto } from '../services/ms-anagrafica-unica';
import { MS_AU_CONFIG, STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from './store-constants';
import { ErrorObject } from '../utility/ErrorObject';

export interface ConsulentiEsterniState {
    searchResult: PagedDtoDipendenteRegionaleDto,
    searchStatus: string,
    searchInput: SearchDipendentiRequest | null,
    editStatus: string,
    editResult: DipendenteRegionaleDto | null,
    detailResult: DipendenteRegionaleDto | null,
    detailStatus: string,
    insertResult: DipendenteRegionaleDto | null,
    insertStatus: string
    insertError: ErrorObject | null
    deleteStatus: string
    deleteError: ErrorObject | null
}

let deleteError: ErrorObject | null

const initialState: ConsulentiEsterniState = {
    searchResult: {
        pageNum: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        data: []
    },
    searchStatus: '',
    searchInput: null,
    editStatus: '',
    editResult: null,
    detailResult: null,
    detailStatus: '',
    insertResult: null,
    insertStatus: '',
    insertError: null,
    deleteStatus: '',
    deleteError: null
}

export interface SearchDipendentiRequest {
    id?: number | undefined,
    nome?: string,
    cognome?: string,
    codiceFiscale?: string,
    strutturaOrganizzativa?: string,
    pageSize?: number,
    pageNum?: number,
    sort?: string,
    direction?: any,
    q?: string
}

export interface DetailParam {
    idDipendente: number
}

export const searchConsulentiEsterni = createAsyncThunk(
    'consulenti-esterni/search',
    async (args: SearchDipendentiRequest) => {
        const api = new ConsulenteEsternoControllerApi(MS_AU_CONFIG);
        const response = await api.searchConsulenteEsterno({
            pageNum: args.pageNum,
            pageSize: args.pageSize,
            direction: args.direction,
            sort: args.sort,
            id: args.id == 0 ? undefined : args.id,
            nomeCompleto: args.cognome !== '' || args.nome !== '' ? args.cognome + " " + args.nome : '',
            strutturaOrganizzativa: args.strutturaOrganizzativa
        });
        return response.data;
    }
)

export const editConsulenteEsterno = createAsyncThunk(
    'consulenti-esterni/modifica',
    async (args: ConsulenteEsternoControllerApiPatchConsulenteEsternoRequest) => {
        const api = new ConsulenteEsternoControllerApi(MS_AU_CONFIG);
        const response = await api.patchConsulenteEsterno({
            idConsulente: args.idConsulente,
            patchConsulenteEsternoRequestDto: args.patchConsulenteEsternoRequestDto
        })
        return response.data
    }
)

export const detailConsulenteEsterno = createAsyncThunk(
    'consulenti-esterni/dettaglio',
    async (id: number) => {
        const api = new ConsulenteEsternoControllerApi(MS_AU_CONFIG);
        const response = await api.detailConsulenteEsterno({
            idConsulente: id,
        })
        return response.data
    }
)

export const insertConsulenteEsterno = createAsyncThunk(
    'consulenti-esterni/inserisci',
    async (args: ConsulenteEsternoControllerApiInsertConsulenteEsternoRequest) => {
        const api = new ConsulenteEsternoControllerApi(MS_AU_CONFIG);
        const response = await api.insertConsulenteEsterno({
            insertConsulenteEsternoRequestDto: args.insertConsulenteEsternoRequestDto
        })
        return response
    }
)

export const deleteConsulente = createAsyncThunk(
    'consulenti-esterni/cancella',
    async (args: number) => {
        const api = new ConsulenteEsternoControllerApi(MS_AU_CONFIG);
        try {
            const response = await api.deleteConsulenteEsterno({
                idConsulente: args
            })
            return response.status
        } catch (error: any) {
            deleteError = error.response.data
            throw error
        }
    }
)

export const ConsulentiEsterniSlice = createSlice({
    name: 'consulentiEsterniSlice',
    initialState,
    reducers: {
        resetInfoDettaglio: (state) => {
            state.detailResult = null;
            state.detailStatus = ''
        },
        resetEditStatus: (state) => {
            state.editStatus = '';
        },
        resetInsertStatus: (state) => {
            state.insertStatus = '';
            state.insertError = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(searchConsulentiEsterni.pending, (state: any, action: any) => {
            state.searchStatus = STATUS_PENDING;
        });
        builder.addCase(searchConsulentiEsterni.fulfilled, (state: ConsulentiEsterniState, action: any) => {
            state.searchResult = action.payload;
            state.searchStatus = STATUS_FULLFILLED;
            state.searchInput = action.meta.arg;
            
        });
        builder.addCase(searchConsulentiEsterni.rejected, (state: any, action: any) => {
            state.searchStatus = STATUS_REJECTED;
        });
        
        builder.addCase(editConsulenteEsterno.pending, (state: any, action: any) => {
            state.editStatus = STATUS_PENDING;
        });
        builder.addCase(editConsulenteEsterno.fulfilled, (state: ConsulentiEsterniState, action: any) => {
            state.editStatus = STATUS_FULLFILLED;
            state.editResult = action.payload
            
        });
        builder.addCase(editConsulenteEsterno.rejected, (state: any, action: any) => {
            state.editStatus = STATUS_REJECTED;
        });

        builder.addCase(detailConsulenteEsterno.rejected, (state: any, action: any) => {
            state.detailStatus = STATUS_REJECTED;
        });
        builder.addCase(detailConsulenteEsterno.pending, (state: any, action: any) => {
            state.detailStatus = STATUS_PENDING;
        });
        builder.addCase(detailConsulenteEsterno.fulfilled, (state: ConsulentiEsterniState, action: any) => {
            state.detailStatus = STATUS_FULLFILLED;
            state.detailResult = action.payload
        });

        builder.addCase(insertConsulenteEsterno.rejected, (state: any, action: any) => {
            state.insertStatus = STATUS_REJECTED;
        });
        builder.addCase(insertConsulenteEsterno.pending, (state: any, action: any) => {
            state.insertStatus = STATUS_PENDING;
        });
        builder.addCase(insertConsulenteEsterno.fulfilled, (state: ConsulentiEsterniState, action: any) => {
            state.insertStatus = STATUS_FULLFILLED;
            state.insertResult = action.payload
        });

        builder.addCase(deleteConsulente.rejected, (state: any, action: any) => {
            state.deleteStatus = STATUS_REJECTED;
            state.deleteError = deleteError;
        });
        builder.addCase(deleteConsulente.pending, (state: any, action: any) => {
            state.deleteStatus = STATUS_PENDING;
        });
        builder.addCase(deleteConsulente.fulfilled, (state: ConsulentiEsterniState, action: any) => {
            state.deleteStatus = STATUS_FULLFILLED
        });
    }
});

export const {resetEditStatus, resetInfoDettaglio, resetInsertStatus} = ConsulentiEsterniSlice.actions;

export default ConsulentiEsterniSlice.reducer;
/* tslint:disable */
/* eslint-disable */
/**
 * MS Anagrafica Unica
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EsperienzeProfessionaliRequestDto
 */
export interface EsperienzeProfessionaliRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'da'?: string;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'al'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'inCorso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'lavoroPosizioneRicoperta'?: string;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'principaliAttivitaResponsabilita'?: string;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'nomeIndirizzoLavoro'?: string;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'tipoAttivitaSettore'?: EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum;
    /**
     * 
     * @type {string}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'specificare'?: string;
    /**
     * 
     * @type {number}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'ambito'?: number;
    /**
     * 
     * @type {number}
     * @memberof EsperienzeProfessionaliRequestDto
     */
    'tipologiaContratto'?: number;
}

export const EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum = {
    AgricolturaSilvicolturaPesca: 'AGRICOLTURA_SILVICOLTURA_PESCA',
    EstrazioneMineraliCaveMiniere: 'ESTRAZIONE_MINERALI_CAVE_MINIERE',
    AttivitaManifatturiere: 'ATTIVITA_MANIFATTURIERE',
    FornituraDiEnergia: 'FORNITURA_DI_ENERGIA',
    FornituraDiAcqua: 'FORNITURA_DI_ACQUA',
    RetiFognarie: 'RETI_FOGNARIE',
    Costruzioni: 'COSTRUZIONI',
    Commercio: 'COMMERCIO',
    RiparazioneAutoveicoliMotocicli: 'RIPARAZIONE_AUTOVEICOLI_MOTOCICLI',
    TrasportoMagazzinaggio: 'TRASPORTO_MAGAZZINAGGIO',
    AttivitaAlloggioRistorazione: 'ATTIVITA_ALLOGGIO_RISTORAZIONE',
    ServiziInformazioneComunicazione: 'SERVIZI_INFORMAZIONE_COMUNICAZIONE',
    AttivitaFinanziarieAssicurative: 'ATTIVITA_FINANZIARIE_ASSICURATIVE',
    AttivitaImmobiliari: 'ATTIVITA_IMMOBILIARI',
    AttivitaProfessionaliScientificheTecniche: 'ATTIVITA_PROFESSIONALI_SCIENTIFICHE_TECNICHE',
    Noleggio: 'NOLEGGIO',
    AmministrazionePubblicaDifesa: 'AMMINISTRAZIONE_PUBBLICA_DIFESA',
    Istruzione: 'ISTRUZIONE',
    SanitaAssistenzaSociale: 'SANITA_ASSISTENZA_SOCIALE',
    AttivitaArtistiche: 'ATTIVITA_ARTISTICHE',
    AltreAttivitaServizi: 'ALTRE_ATTIVITA_SERVIZI',
    AttivitaDiFamiglie: 'ATTIVITA_DI_FAMIGLIE',
    OrganizzazioniOrganismiExtraterritoriali: 'ORGANIZZAZIONI_ORGANISMI_EXTRATERRITORIALI',
    Altro: 'ALTRO'
} as const;

export type EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum = typeof EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum[keyof typeof EsperienzeProfessionaliRequestDtoTipoAttivitaSettoreEnum];


